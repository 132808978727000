<template>
  <div>
    <div class="dashboard-container max-container">
      <BasicSpinner v-if="isLoading" />
      <div v-if="!isLoading">
        <div class="main-grid">
          <div class="col-span-full flex flex-col space-y-2">
            <div class="text-h3 font-semibold text-gray-900">
              {{ greeting }}
            </div>
            <div
              class="text-body-2 font-regular text-gray-900 hidden xl:block"
              v-if="financingMessage != null"
            >
              The Holly Springs community is around the corner, start
              {{ financingMessage }} to get ahead
            </div>
          </div>
        </div>
        <div class="main-grid xl:mt-16 mt-8">
          <NextSteps
            :person="person"
            class="col-start-1 col-span-full xl:col-span-3"
          />
          <div class="divider"></div>
          <UpcomingRelease class="col-start-1 col-span-full xl:col-start-5" />
        </div>
        <div class="h-[1px] bg-gray-900 bg-opacity-20 my-8 xl:hidden"></div>
        <div class="main-grid xl:mt-[72px]">
          <NeedHelp
            :firstName="firstName"
            class="col-start-1 col-span-full xl:col-span-3 order-3 xl:order-1"
          />
          <div class="divider order-2 xl:order-1"></div>
          <Questions class="col-start-1 col-span-full xl:col-start-5 order-1" />
        </div>
      </div>
    </div>
    <DashboardFooter v-if="!isLoading" />
  </div>
</template>
<script>
import { BasicSpinner } from "@/components/loading";
import {
  NextSteps,
  NeedHelp,
  UpcomingRelease,
  Questions,
  DashboardFooter,
} from "@/components";
import { Auth } from "aws-amplify";
import moment from "moment";
export default {
  name: "Dashboard",
  components: {
    BasicSpinner,
    NextSteps,
    NeedHelp,
    UpcomingRelease,
    Questions,
    DashboardFooter,
  },
  data() {
    return {
      isLoading: true,
      firstName: null,
      lastName: null,
      greeting: null,
      financingStatus: null,
      person: null,
    };
  },
  async mounted() {
    const authedUser = await Auth.currentAuthenticatedUser();
    const userEmail = authedUser.attributes.email;
    try {
      const response = await this.$API.get("hubspotApi", "/hubspot/contact", {
        headers: {},
        response: false,
        queryStringParameters: {
          email: userEmail,
          properties:
            "financing_status,home_selection_status,email,firstname,lastname",
        },
      });
      this.person = response.properties;
      const { financing_status, firstname, lastname } = response.properties;
      this.firstName = firstname;
      this.lastname = lastname;
      this.financingStatus = financing_status;
      this.greeting = this.getGreeting() + firstname;
    } catch (err) {
      this.$logger.error(`Error get contact info for ${userEmail}`);
      //TODO: Add some error screen info
    }
    this.isLoading = false;
  },
  methods: {
    getGreeting() {
      const hour = moment().format("HH");
      if (hour > 16) {
        return "Good evening, ";
      }
      if (hour > 11) {
        return "Good afternoon, ";
      }
      return "Good morning, ";
    },
  },
  computed: {
    financingMessage() {
      if (this.financingStatus == "not_started") {
        return "pre-approval";
      } else if (this.financingStatus == "pre_app_passed") {
        return "final approval";
      } else if (this.financingStatus == "fin_app_passed") {
        return "the reservation process";
      }
      return null;
    },
  },
};
</script>
<style scoped>
.dashboard-container {
  @apply pt-24 xl:pb-40 sm:pb-32;
}
.divider {
  @apply h-[1px] bg-gray-900 bg-opacity-20 my-8 xl:hidden col-start-1 col-span-full -mx-8;
}
</style>

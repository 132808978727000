<template>
  <div class="mt-8 md:mt-24 text-gray-900">
    <div class="grid grid-cols-10">
      <div class="col-span-full md:col-span-3 text-body-1">
        <div
          class="faq-filter"
          :key="filter.id"
          :class="{
            'faq-filter-active': filter.active,
            'show-dropdown': showDropdown,
          }"
          v-for="filter in questionFilters"
          @click="filterAnswers(filter.id)"
        >
          <div class="px-4 py-2 flex justify-between">
            <span> {{ filter.text }} </span>
            <img
              class="visible md:invisible transition-transform"
              :class="{
                hidden: filter.active,
                'rotate-arrow': showDropdown,
              }"
              src="@/assets/icons/chevron_down_sm.svg"
              alt="Arrow down icon"
            />
          </div>
        </div>
      </div>

      <div class="col-span-full md:col-span-6 md:col-start-5">
        <div v-for="faq in filteredAnswers" :key="faq.question">
          <hr />

          <div class="flex justify-between my-6" @click="showAnswer(faq.id)">
            <div class="flex flex-col justify-center">
              <div class="text-body-1 font-semibold pr-3">
                {{ faq.question }}
              </div>
              <div
                class="text-body-2"
                :class="faq.active ? 'mt-5' : 'hidden'"
                v-html="faq.answer"
              >
                {{ faq.answer }}
              </div>
            </div>

            <div
              class="open-button transition-transform"
              :class="{ rotate: !faq.active }"
            >
              <PrimaryImageOnlyBaseCta
                image="icons/close_black.svg"
                type="tertiary"
                class="round-button scale-90"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PrimaryImageOnlyBaseCta } from "@/components/buttons";
import { faqArray, questionFilters } from "./faqData";

export default {
  name: "MainSection",
  components: {
    PrimaryImageOnlyBaseCta,
  },
  data() {
    return {
      questionFilters,
      faqArray,
      filteredAnswers: [...faqArray],
      showDropdown: false,
    };
  },
  methods: {
    showAnswer(id, shouldSwitchCategory) {
      const setNewActive = () => {
        const newActive = this.faqArray.find((f) => f.id === id);

        // If id is out of range, just don't set open question
        if (newActive) {
          newActive.active = true;
          if (shouldSwitchCategory) {
            this.filterAnswers(newActive.questionFiltersId);
          }
        }
      };
      const oldActive = this.faqArray.find((f) => f.active);

      if (oldActive) {
        oldActive.active = false;
      } else {
        setNewActive();
      }

      if (oldActive?.id !== id) {
        setNewActive();
      }
    },
    filterAnswers(filterId) {
      // Handle opening/closing dropdown when on mobile
      if (window.innerWidth <= 768) {
        this.showDropdown = !this.showDropdown;
      }

      // Handle switching the filter question (left part with the questions)
      const oldActiveFilter = this.questionFilters.find((f) => f.active);
      oldActiveFilter.active = false;
      const newActiveFilter = this.questionFilters.find(
        (f) => f.id === filterId
      );
      newActiveFilter.active = true;

      // Handle showing correct question-answer pairs (right part with question-answer pairs)
      if (filterId === 0) {
        this.filteredAnswers = this.faqArray;
      } else {
        this.filteredAnswers = this.faqArray.filter(
          (faq) => faq.questionFiltersId === filterId
        );
      }
    },
    dropdownHandler() {
      if (window.innerWidth > 768) {
        this.showDropdown = false;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.dropdownHandler);

    const questionId = this.$route.params.id;
    if (questionId) {
      this.showAnswer(questionId, true);
    } else {
      this.showAnswer(0);
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.dropdownHandler);
  },
};
</script>

<style scoped>
.round-button {
  /* !important because we need to overwrite the default button border-radius */
  border-radius: 9999px !important;
  @apply border-opacity-20;
}

.faq-filter {
  @apply hidden md:block cursor-pointer rounded-16 hover:bg-creme-300 mb-5;
}

.faq-filter-active {
  @apply block bg-creme-300 font-semibold;
}

.show-dropdown {
  @apply block md:hidden;
}

.open-button {
  transition: transform 0.2s ease-in-out;
  @apply w-10 h-6 rounded-100 border-creme-200 flex justify-center items-center;
}

.rotate {
  transform: rotate(45deg);
}

.rotate-arrow {
  transform: rotate(180deg);
}
</style>

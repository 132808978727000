<template>
  <div v-if="isLoading" class="rounded-xl">
    <div
      class="relative rounded-xl overflow-auto p-2 space-y-2 animate-pulse h-full justify-top"
    >
      <div class="w-2/5 h-8 rounded-md bg-gray-200"></div>
      <div class="w-3/5 h-12 rounded-md bg-gray-200"></div>
      <div class="text-center rounded-lg bg-gray-200 overflow-hidden mx-auto">
        <img
          class="object-cover opacity-40 w-full h-full ring-black ring-1 rounded-md"
          :src="require('@/assets/hf_fake_map_overlay.svg')"
          alt="Overlay"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkeletonLoader",
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

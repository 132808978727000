<template>
  <div class="root !rounded-12 !lg:rounded-8" :class="[backgroundColor]">
    <img
      :src="require(`@/assets/${startImage}`)"
      class="img"
      v-if="startImage"
    />
    <slot name="text">Tag</slot>
    <img :src="require(`@/assets/${endImage}`)" class="img" v-if="endImage" />
  </div>
</template>
<script>
export default {
  name: "SelectionTag",
  props: {
    startImage: {
      type: String,
      default: null,
    },
    endImage: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: "Tag",
    },
    backgroundColor: {
      type: String,
      default: "bg-yellow-500",
    },
  },
};
</script>
<style scoped>
.root {
  @apply rounded-8 py-1 px-2 flex flex-row items-center justify-center space-x-1 lg:space-x-2;
}
.tag-text {
  @apply text-gray-900;
}
.img {
  @apply w-3 h-3 lg:w-4 lg:h-4;
}
</style>

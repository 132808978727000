<template>
  <div class="max-container">
    <div class="main-grid">
      <h2 class="col-span-full text-h2 text-center font-semibold mt-32 mb-12">
        Interested in <span class="text-blue-500">{{ location }}?</span>
      </h2>

      <p class="col-span-full text-center text-h4 max-w-2xl mx-auto">
        Start your HomeFounding journey. Contact our team of Home Specialists to
        get started!
      </p>

      <div class="col-span-full mx-auto my-10">
        <PrimaryBaseCta
          text="Contact our team"
          endImage="ico_circle_arrow_white.svg"
          class="w-80 h-16 text-h4 flex items-center justify-center"
          @click="onClick"
        />
      </div>

      <div class="col-span-full mx-auto my-12">
        <img
          class="max-w-lg"
          src="@/assets/images/douglasville_banner.png"
          alt="City banner"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { PrimaryBaseCta } from "@/components";

export default {
  props: ["location", "city"],
  components: {
    PrimaryBaseCta,
  },
  methods: {
    onClick() {
      this.$router.push({
        path: "/locations/hollysprings/interest",
      });
    },
  },
};
</script>

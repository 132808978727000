<template>
  <div>
    <div class="flex flex-row items-center justify-between">
      <div class="title">{{ title }}</div>
    </div>
    <form @submit.prevent="submit" class="flex flex-col">
      <div class="flex flex-col md:flex-row">
        <div class="flex flex-col md:mr-8">
          <div class="label">First name</div>
          <TextInput placeholder="Enter" v-model="firstName" />
        </div>

        <div class="separator md:hidden"></div>
        <div class="flex flex-col">
          <div class="label">Last name</div>
          <TextInput placeholder="Enter" v-model="lastName" />
        </div>
      </div>

      <div class="separator"></div>
      <div class="label">Email*</div>
      <TextInput
        placeholder="founder@example.com"
        :required="true"
        v-model="email"
      />

      <div class="separator"></div>
      <div class="label">Password</div>
      <TextInput
        placeholder="Enter"
        inputType="password"
        :required="true"
        v-model="password"
      />

      <div class="separator"></div>
      <div class="flex items-center">
        <Checkbox @input="(isChecked) => checkboxHandler(isChecked)" />
        <div class="ml-4">
          I agree with
          <!-- TODO add privacy policy and terms of service links -->
          <span class="font-semibold text-blue-500">Privacy Policy</span> and
          <span class="font-semibold text-blue-500">Terms of Service</span>
        </div>
      </div>

      <div
        v-if="errorMessage"
        class="flex flex-col mt-8 text-red-600 text-body-2"
      >
        {{ errorMessage }}
      </div>
      <div
        class="flex flex-col-reverse md:flex-row items-center justify-between my-12"
      >
        <div class="text-body-2 pt-3 md:pt-0 text-center">
          Already have an account?
          <span
            class="text-blue-500 cursor-pointer font-semibold"
            @click="showLogin()"
          >
            Log in
          </span>
        </div>

        <PrimaryBaseCta
          text="Create Acccount"
          fixedSize="small"
          eventName="submit"
          @click="submit"
          :disabled="disabled"
        />
      </div>
    </form>
  </div>
</template>
<script>
import { TextInput, PrimaryBaseCta, Checkbox } from "@/components";
import { Auth } from "aws-amplify";

export default {
  name: "Signup",
  components: {
    TextInput,
    PrimaryBaseCta,
    Checkbox,
  },
  props: {
    title: {
      type: String,
      default: "Create Account",
    },
  },
  data() {
    return {
      email: null,
      password: null,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      disabled: false,
      policyAccepted: false,
      errorMessage: null,
    };
  },
  methods: {
    async submit() {
      this.errorMessage = null;
      this.disabled = true;

      try {
        if (!this.policyAccepted) {
          throw new Error("Privacy Policy and Terms of Service not accepted");
        }

        const username = this.email;
        const password = this.password;
        await Auth.signUp({ username, password });
        const signInUser = await Auth.signIn(username, password);

        await this.$API.post("hubspotApi", "/hubspot/contact", {
          body: {
            firstName: this.firstName,
            lastName: this.lastName,
            phoneNumber: this.phoneNumber,
            email: this.email,
            lifecyclestage: "lead",
            is_agent: "false",
            is_fthb: "false",
            has_account: "true",
          },
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (signInUser) {
          // Close modal after successfull signup
          this.close();
        }
      } catch (err) {
        this.errorMessage = err.message;
        this.$logger.error(err.message);
      }

      this.disabled = false;
    },
    showLogin() {
      this.$emit("showLogin");
    },
    checkboxHandler(isChecked) {
      this.policyAccepted = isChecked;
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.root {
  @apply bg-creme-200 rounded-48 no-scrollbar max-h-[85vh];
}
.title {
  @apply text-h4 md:text-h3 text-gray-900 font-semibold max-w-[238px] md:max-w-[456px] mb-8;
}
.label {
  @apply text-body-1 text-gray-900 font-semibold pb-3;
}
.separator {
  @apply mt-8 xl:mt-12;
}
.login-text {
  @apply text-body-2 text-gray-900 font-semibold;
}
</style>

<template>
  <div>
    <NextStepSlot>
      <template v-slot:timelineCircle>
        <div class="timeline-circle">
          <div class="timeline-selected-circle"></div>
        </div>
      </template>
      <template v-slot:timelineContent>
        <div>
          <div class="timeline-title">
            Unfortunately you can&apos;t get pre-approved at this time
          </div>
          <div class="pt-3 text-gray-900 text-body-3 font-regular">
            Based on provided information you can’t get pre-approved for this
            home.<br /><br />Have questions? Schedule a call with our home
            specialist or send us an email
          </div>
          <PrimaryBaseCta
            class="pt-3 mt-4 xl:w-full w-[fit-content]"
            text="Schedule Call"
            fixedSize="small"
            endImage="icons/phone_blue.png"
            type="secondary"
          />
          <div class="pt-3">
            <a
              href="mailto:help@homefounder.com"
              class="mt-3 font-semibold text-blue-500 text-body-2"
              >help@homefounder.com</a
            >
          </div>
        </div>
      </template>
    </NextStepSlot>

    <NextStepSlot :isError="true">
      <template v-slot:timelineCircle>
        <div class="timeline-circle-disabled">
          <div class="timeline-selected-circle"></div>
        </div>
      </template>
      <template v-slot:timelineContent>
        <div class="timeline-title-disabled">Pick your home and lot</div>
        <div
          class="mt-3 px-2 py-1 rounded-8 w-[fit-content] text-body-3 text-gray-500"
        >
          Upcoming: {{ lotsAvailableDate }}
        </div>
      </template>
    </NextStepSlot>

    <NextStepSlot :isError="true">
      <template v-slot:timelineCircle>
        <div class="timeline-circle-disabled">
          <div class="timeline-selected-circle"></div>
        </div>
      </template>
      <template v-slot:timelineContent>
        <div class="timeline-title-disabled">Review and reserve</div>
      </template>
    </NextStepSlot>

    <NextStepSlot :isError="true">
      <template v-slot:timelineCircle>
        <div class="timeline-circle-disabled">
          <div class="timeline-selected-circle"></div>
        </div>
      </template>
      <template v-slot:timelineContent>
        <div class="timeline-title-disabled">
          Sign Purchase &amp; Sale agreement
        </div>
      </template>
    </NextStepSlot>

    <NextStepSlot :isError="true">
      <template v-slot:timelineCircle>
        <div class="timeline-circle-disabled">
          <div class="timeline-selected-circle"></div>
        </div>
      </template>
      <template v-slot:timelineContent>
        <div class="timeline-title-disabled">Secure financing</div>
      </template>
    </NextStepSlot>

    <NextStepSlot :isError="true">
      <template v-slot:timelineCircle>
        <div class="timeline-circle-disabled">
          <div class="timeline-selected-circle"></div>
        </div>
      </template>
      <template v-slot:timelineContent>
        <div class="timeline-title-disabled">Close on your home</div>
        <div
          class="mt-3 px-2 py-1 rounded-8 w-[fit-content] text-body-3 text-gray-500"
        >
          Deadline: {{ closeDeadline }}
        </div>
      </template>
    </NextStepSlot>

    <NextStepSlot :isError="true" :isEnd="true">
      <template v-slot:timelineCircle>
        <div class="timeline-circle-disabled">
          <div class="timeline-selected-circle"></div>
        </div>
      </template>
      <template v-slot:timelineContent>
        <div class="timeline-title-disabled">
          Track construction and move in
        </div>
        <div
          class="mt-3 px-2 py-1 rounded-8 w-[fit-content] text-body-3 text-gray-500"
        >
          Est. December 2022
        </div>
      </template>
    </NextStepSlot>
  </div>
</template>
<script>
import NextStepSlot from "./NextStepSlot.vue";
import PrimaryBaseCta from "@/components/buttons/PrimaryBaseCta.vue";
export default {
  components: {
    NextStepSlot,
    PrimaryBaseCta,
  },
  data() {
    return {
      reserveDate: process.env.VUE_APP_RESERVE_DATE,
      lotsAvailableDate: process.env.VUE_APP_LOTS_AVAILABLE,
      closeDeadline: process.env.VUE_APP_RESERVE_DATE,
    };
  },
};
</script>
<style scoped>
.timeline-circle {
  @apply min-w-[32px] h-8 rounded-full border border-gray-900 flex items-center justify-center;
}
.timeline-circle-disabled {
  @apply min-w-[32px] h-8 rounded-full border border-gray-500 flex items-center justify-center;
}
.timeline-selected-circle {
  @apply w-2 h-2 rounded-full align-middle;
}
.timeline-completed {
  background: url("~@/assets/icons/checkmark_black.png");
  @apply bg-no-repeat min-w-[16px] h-4;
}
.timeline-title {
  @apply text-body-2 text-gray-900 font-semibold;
}
.timeline-title-disabled {
  @apply text-body-2 text-gray-500 font-semibold;
}
.timeline-title-inactive {
  @apply text-body-2 text-gray-900;
}
.line {
  @apply h-auto w-0 border border-gray-900 mx-auto flex-1;
}
</style>

<template>
  <div>
    <StartingPage />
    <YellowStrip />
    <global-section>
      <ComingSoon />
    </global-section>
    <global-section type="light">
      <HowItWorks />
    </global-section>
    <global-section type="dark">
      <Calculator />
    </global-section>
    <global-section type="top-only-light">
      <FounderPage />
    </global-section>
  </div>
</template>

<script>
import {
  StartingPage,
  FounderPage,
  Calculator,
  YellowStrip,
  ComingSoon,
  GlobalSection,
  HowItWorks,
} from "@/components";

export default {
  name: "Home",
  components: {
    StartingPage,
    FounderPage,
    Calculator,
    YellowStrip,
    GlobalSection,
    ComingSoon,
    HowItWorks,
  },
};
</script>

<template>
  <button
    class="flex flex-row button-container-base button-container"
    :class="[
      `${type}-background${dark ? '-dark' : ''}`,
      fixedSize ? fixedSize : '',
    ]"
    @click="onClickHandler"
  >
    <div class="button-img-wrapper">
      <img :src="require('@/assets/' + image)" />
    </div>
  </button>
</template>
<script>
export default {
  name: "PrimaryImageOnlyBaseCta",
  props: {
    image: {
      type: String,
      default: "ico_placeholder.svg",
    },
    type: {
      type: String,
      default: "primary",
    },
    fixedSize: {
      type: String,
      default: null,
    },
    eventName: {
      type: String,
      default: "click",
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickHandler() {
      this.$emit(`${this.eventName}`);
    },
  },
};
</script>

<style scoped>
.button-container-base {
  @apply items-center justify-center cursor-pointer rounded-16 disabled:opacity-50 disabled:cursor-not-allowed;
}
.button-container {
  @apply p-2;
}
.button-img-wrapper {
  @apply w-6 h-6 flex justify-center items-center;
}
.button-img-wrapper > img {
  @apply w-4 h-4;
}

.primary-background {
  @apply bg-blue-500 border border-blue-500 shadow-primary-cta hover:shadow-primary-hover-cta hover:bg-gradient-to-t hover:from-hovered-0.1 hover:to-blue-500 active:bg-gradient-to-t active:from-hovered-0.1 active:to-blue-500 active:shadow-primary-cta disabled:shadow-primary-cta;
}

.secondary-background {
  @apply bg-transparent border border-blue-500 hover:bg-blue-100 active:bg-blue-200;
}

.tertiary-background {
  @apply bg-creme-200 border border-gray-900 hover:bg-gradient-to-t hover:from-hovered-0.05 hover:to-creme-200 active:to-creme-300;
}

.link-background {
  @apply bg-transparent hover:bg-blue-100 rounded-none py-0 px-0 active:bg-blue-200;
}

.tertiary-background-dark {
  @apply bg-transparent border;
}
</style>

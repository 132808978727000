/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:a9e754d9-a402-457b-b6c7-c2ef68c14dc5",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_vm37JBjut",
    "aws_user_pools_web_client_id": "4lvanojefel2ru8g8efqj4jisv",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "hubspotApi",
            "endpoint": "https://r8t42bvqmd.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://tipncqhhqvgnnc5c5hz3vld7vu.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-z7cmplfqgvhp5j4e2h6m2zsd7m"
};


export default awsmobile;

<template>
  <Modal @closeModal="internalClose()">
    <Login
      v-if="isShowingLogin"
      @showSignup="toggleShowingLogin()"
      @close="internalClose()"
    />

    <Signup
      v-else
      title="Create account to save your home and continue"
      @showLogin="toggleShowingLogin()"
      @close="internalClose()"
    />
  </Modal>
</template>
<script>
import { Signup, Login, Modal } from "@/components";
export default {
  name: "AccountModal",
  components: {
    Signup,
    Login,
    Modal,
  },
  data() {
    return {
      isShowingLogin: true,
    };
  },
  methods: {
    internalClose() {
      this.$emit("closeAccountModal");
    },
    toggleShowingLogin() {
      this.isShowingLogin = !this.isShowingLogin;
    },
  },
};
</script>

<template>
  <button
    type="button"
    class="hover:bg-gray-300 text-hfbase font-semibold text-black px-6 py-3 border border-black leading-normal rounded-xl focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out flex align-center"
    @click="click"
  >
    {{ text }}
    <svg
      class="ml-2"
      width="18"
      height="18"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12" r="9" fill="#F5DD7B" />
      <path
        d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2.00606 6.47966 6.47967 2.00606 12 2ZM12 20C16.3946 19.9989 19.9655 16.4531 19.9978 12.0587C20.03 7.66417 16.5114 4.06644 12.1173 4.00089C7.7232 3.93534 4.09888 7.42651 4 11.82L4 10.037L4 12C4.00496 16.4162 7.58378 19.995 12 20Z"
        fill="#2C2525"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: "YellowDot",
  props: {
    text: {
      type: String,
      default: "Button",
    },
  },
  methods: {
    click() {
      this.$emit("yellowDotClick");
    },
  },
};
</script>

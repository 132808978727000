<template>
  <div class="hidden xl:flex flex-row items-center justify-between flex-grow">
    <div class="divider"></div>
    <div class="flex flex-row flex-grow justify-start">
      <div class="flex flex-row space-x-6">
        <NavigationCta
          fixedSize="small"
          startImage="icons/dashboard.svg"
          text="Dashboard"
          :isSelected="isDashboardSelected"
          @click="navigateItem('dashboard')"
        />
        <NavigationCta
          fixedSize="small"
          startImage="icons/nav_home.svg"
          :isSelected="isMyHomeSelected"
          text="My home"
          @click="navigateItem('builder')"
        />
      </div>
    </div>

    <div class="hidden xl:flex flex-row items-center justify-end space-x-12">
      <div class="flex flex-col items-center relative group">
        <div class="flex flex-row justify-between space-x-3">
          <div class="nav-right-text">Help</div>
          <img
            class="group-hover:rotate-180 group-hover:transition-all duration-200 ease-in-out"
            :src="require('@/assets/ico_arrow_down.svg')"
          />
        </div>
        <div class="absolute top-12 min-w-[320px]">
          <BottomToolTip class="-mt-96 group-hover:mt-0 delay-100">
            <template v-slot:content>
              <div class="flex flex-col">
                <div class="flex flex-row justify-between">
                  <router-link to="/how-it-works">
                    <span class="font-semibold text-gray-900 text-body-1"
                      >How it works</span
                    >
                  </router-link>
                  <img :src="require('@/assets/icons/chevron_big_right.svg')" />
                </div>
              </div>
            </template>
          </BottomToolTip>
          <!-- group-hover will not work properly with h-auto so leaving it for now -->
        </div>
      </div>
      <div class="relative group">
        <div
          class="rounded-full bg-yellow-500 px-[14px] py-[12px] max-h-[40px] max-w-[40px]"
        >
          <img
            :src="require('@/assets/icons/nav_person.png')"
            class="object-center"
          />
        </div>
        <div class="absolute top-16 -left-56 min-w-[320px]">
          <BottomToolTipArrowRight
            left="left-[21.5%]"
            class="-mt-96 group-hover:mt-0 delay-100"
          >
            <template v-slot:content>
              <div class="flex flex-col">
                <div class="flex flex-row items-center justify-between">
                  <router-link to="/dashboard">
                    <span class="font-semibold text-gray-900 text-body-1"
                      >Account</span
                    >
                  </router-link>
                  <img :src="require('@/assets/icons/chevron_big_right.svg')" />
                </div>
                <div
                  class="mt-6 flex flex-row border-t border-gray-900 border-opacity-20 h-1"
                >
                  &nbsp;
                </div>
                <div class="mt-6 flex flex-row items-center justify-center">
                  <PrimaryBaseCta
                    fixedSize="small"
                    type="secondary"
                    text="Log out"
                    class="w-full"
                    @click="logout()"
                  />
                </div>
              </div>
            </template>
          </BottomToolTipArrowRight>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BottomToolTip from "@/components/tooltips/BottomToolTip.vue";
import BottomToolTipArrowRight from "@/components/tooltips/BottomToolTipArrowRight.vue";
import NavigationCta from "@/components/buttons/NavigationCta.vue";
import PrimaryBaseCta from "@/components/buttons/PrimaryBaseCta.vue";
import { Auth } from "aws-amplify";
export default {
  name: "NavDesktopApp",
  components: {
    BottomToolTip,
    BottomToolTipArrowRight,
    NavigationCta,
    PrimaryBaseCta,
  },
  methods: {
    navigateItem(slug) {
      this.$router.push({
        path: `${slug}`,
      });
    },
    async logout() {
      try {
        await Auth.signOut();
        this.$router.push({ name: "Home" });
      } catch (err) {
        console.error(`Error logging out ${err.message}`);
      }
    },
  },
  computed: {
    isDashboardSelected() {
      return this.$navActions.isDashboardSelected();
    },
    isMyHomeSelected() {
      return this.$navActions.isMyHomeSelected();
    },
  },
};
</script>
<style scoped>
.nav-right-text {
  @apply text-gray-900 font-semibold text-body-2 cursor-pointer hover:text-blue-500;
}
.login-btn {
  @apply border border-gray-900 rounded-12 text-blue-500 font-semibold text-body-2 bg-creme-200 text-center cursor-pointer;
}
.divider {
  @apply h-12 w-[1px] bg-gray-900 bg-opacity-20 mx-8;
}
</style>

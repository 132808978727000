<template>
  <div class="flex flex-row rounded-xl px-2 items-center">
    <span class="whitespace-nowrap">{{ text }}</span>
    <div
      v-if="color"
      :style="{ 'background-color': color }"
      class="ml-2 rounded-full border-black border-opacity-20 border-2 w-4 h-4"
    />
  </div>
</template>

<script>
export default {
  name: "SummaryChip",
  props: {
    text: {
      type: String,
      default: "Value",
    },
    color: {
      type: String,
    },
  },
};
</script>

<template>
  <div class="thank-you-container max-container">
    <div class="main-grid">
      <div class="col-span-full flex flex-col items-center">
        <img :src="require('@/assets/bg_thank_you.svg')" />
        <h1
          class="pt-16 text-h2 xl:text-h1 text-center font-semibold text-blue-500"
        >
          Thank you!
        </h1>

        <h3
          class="pt-16 text-h4 xl:text-h3 font-semibold text-gray-900 text-center max-w-[640px]"
        >
          Look for an email to connect with your Home Specialist.
        </h3>
        <p class="text-center pt-8 text-body-2 text-gray-900 max-w-[344px]">
          Email can take up to 30 minutes. If you still can't find it please
          check your spam box.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThankYouHolly",
};
</script>

<style scoped>
.thank-you-container {
  @apply pt-24 xl:pb-40 pb-32 h-full flex justify-center items-center;
}
</style>

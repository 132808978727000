<template>
  <div id="homecustomizations" class="mt-32">
    <BuilderHeader title="Customize" />

    <div v-if="!customizations" class="text-black">
      Customization not available for this model
    </div>

    <div :id="kitchenId" class="mb-5">
      <Customization
        v-if="customizations && customizations.kitchen"
        image-id="kitchen-images"
        custom-type="kitchen"
        title="Kitchen Colors"
        :customization="customizations.kitchen"
      />
    </div>

    <div
      :id="flooringId"
      :class="[!(customizations && customizations.flooring) ? 'mt-16' : '']"
    >
      <Customization
        v-if="customizations && customizations.flooring"
        image-id="flooring-images"
        custom-type="flooring"
        title="Flooring Colors"
        :customization="customizations.flooring"
      />
    </div>
  </div>
</template>

<script>
import BuilderHeader from "@/components/text/BuilderHeader";
import Customization from "./Customization.vue";
import { BUILDER_COMPONENTS_IDS } from "@/constants";

export default {
  name: "HomeCustomizations",
  components: {
    BuilderHeader,
    Customization,
  },
  props: {
    customizations: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      kitchenId: BUILDER_COMPONENTS_IDS.KITCHEN_COLORS,
      flooringId: BUILDER_COMPONENTS_IDS.FLOORING_COLORS,
    };
  },
};
</script>

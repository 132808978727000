<template>
  <div>
    <!-- Desktop summary foter -->
    <div class="hidden xl:flex flex-col bg-creme-300 rounded-xl p-2 text-black">
      <div class="flex flex-row w-full text-body-1">
        <div class="p-2 font-semibold w-1/2">Home price</div>
        <div class="p-2 font-semibold w-1/2 text-right">
          {{ home_price }}
        </div>
      </div>

      <div class="flex flex-row justify-between w-full mb-5 mt-2">
        <div class="px-2 text-body-2 font-semibold text-blue-500">
          See breakdown
        </div>
        <div
          class="flex flex-row items-center bg-green-500 px-2 py-1 rounded-12"
        >
          <img
            class="w-4"
            src="@/assets/icons/checkmark.svg"
            alt="checkmark icon"
          />
          <span class="mx-1 text-white text-body-3 font-semibold">
            GUARANTEED
          </span>
        </div>
      </div>

      <hr
        v-if="!home_price || !home_value"
        class="border-black border-opacity-10 text-center mb-5"
      />

      <div
        v-if="!home_price || !home_value"
        class="p-2 text-center text-body-2"
      >
        Pick a lot &amp; home model to see guaranteed price and savings
      </div>

      <div v-else class="px-2 flex flex-col w-full mb-5">
        <div class="flex flex-row justify-between">
          <div class="text-body-2">HomeFounder price</div>
          <div class="font-semibold flex flex-row items-center">
            <span class="mr-1">{{ home_price }}</span>
            <img
              class="w-5"
              src="@/assets/icons/information_blue.svg"
              alt="Information icon"
            />
          </div>
        </div>

        <div class="flex flex-row justify-between mt-2">
          <div class="text-body-2">Your savings</div>
          <div class="font-semibold flex flex-row items-center">
            <span class="mr-1">{{ savings }}</span>
            <img
              class="w-5"
              src="@/assets/icons/information_blue.svg"
              alt="Information icon"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="hidden xl:flex w-full justify-center text-body-2 mt-8">
      <a href="/signup">
        <span class="font-semibol text-blue-500 mr-1">Create an account</span>
      </a>
      <span>to save progress</span>
    </div>

    <!-- Mobile summary footer -->
    <div class="xl:hidden fixed-bottom bg-creme-300 rounded-b-16">
      <div
        class="flex flex-row justify-around md:justify-center items-center h-20 px-4"
      >
        <div
          class="flex h-14 w-[115px] border-r border-black border-opacity-20"
        >
          <div v-if="!home_value" class="flex flex-col">
            <span class="text-body-1 font-semibold mb-1"> $380,000+ </span>
            <span class="text-body-3"> Starting home value </span>
          </div>
          <div v-else class="flex flex-col">
            <span class="text-body-1 font-semibold mb-1">{{ home_value }}</span>
            <span class="text-body-3">Home value</span>
          </div>
        </div>

        <div v-if="home_price" class="flex flex-col justify-center px-6">
          <span class="text-body-1 font-semibold mb-1">{{ home_price }}</span>
          <div class="flex flex-row flex-wrap justify-center items-center">
            <span class="text-body-3 mr-2">Home price</span>
            <div class="flex flex-row bg-green-500 px-2 py-1 rounded-12">
              <img src="@/assets/icons/checkmark.svg" alt="checkmark icon" />
              <span class="mx-1 text-white text-body-3 font-semibold">
                GUARANTEED
              </span>
            </div>
          </div>
        </div>
        <div v-else class="flex flex-col justify-center px-6">
          <p class="text-center text-body-3 w-[180px]">
            Pick a lot & home model to see guaranteed price and savings
          </p>
        </div>

        <div>
          <img
            src="@/assets/icons/information_blue.svg"
            alt="Information icon"
          />
        </div>
      </div>
      <div class="w-full text-center h-12 bg-black bg-opacity-5">
        <div class="h-full flex justify-center items-center">
          <a href="#" class="mr-1">
            <span class="text-blue-500 font-semibold">Get pre-approved</span>
          </a>
          to reserve a lot
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SumaryFooter",
  props: {
    home_price: {
      type: String,
      default: "0",
    },
    home_value: {
      type: String,
      default: "0",
    },
    savings: {
      type: String,
      default: "0",
    },
  },
};
</script>

<template>
  <div :id="containerId">
    <BuilderHeader title="Choose your home model" />

    <div class="flex flex-wrap mb-6">
      <div
        v-for="home in homeModels"
        :key="home.id"
        class="rounded-16 border border-black mr-4"
        :class="[
          home.name === selectedModel.name
            ? 'bg-yellow-500 font-semibold elevated-button'
            : '',
          home.isAvailable ? 'cursor-pointer' : 'cursor-not-allowed',
        ]"
        @click="selectModel(home)"
      >
        <div class="m-2 text-body-2 text-black">
          {{ home.name }}
        </div>
      </div>
    </div>

    <div class="text-black border-black border rounded-16">
      <div class="px-6 pt-6 lg:px-7 lg:pt-7">
        <div class="flex flex-row flex-wrap justify-between mb-6 lg:mb-3">
          <div class="lg:w-full flex flex-col lg:flex-row lg:justify-between">
            <div class="font-semibold text-h3">
              {{ selectedModel.name }}
            </div>
            <div class="text-body-2">
              Available on {{ availableOnLots }} lots
            </div>
          </div>
          <div class="lg:hidden">
            <div v-if="selectedModel.price" class="font-semibold text-h3">
              {{
                $currencyNoDecimal
                  .format($get(selectedModel, "price", "0"))
                  .slice(0, 4)
              }}K+
            </div>
            <div class="text-body-2">Starting price</div>
          </div>
        </div>

        <div class="w-full lg:w-5/6 text-body-2 mb-8">
          {{ selectedModel.summary }}
          <a v-if="selectedModel !== null" href="#">
            <span class="font-semibold text-blue-500">Read more</span>
          </a>
        </div>

        <div class="flex flex-row justify-between mb-8">
          <div
            class="flex flex-row flex-wrap justify-between w-full lg:w-[270px]"
          >
            <div class="flex lg:flex-col items-center">
              <div class="w-full my-auto lg:mb-2 mr-3 lg:mr-0">
                <img
                  class="w-6 lg:w-8 h-6 lg:h-8"
                  src="@/assets/ic_hm_house.svg"
                />
              </div>
              <div class="flex flex-col">
                <div class="text-h3">
                  {{ selectedModel.squareFootage || "--" }}
                </div>
                <div class="text-body-3">Square ft.</div>
              </div>
            </div>
            <div class="flex lg:flex-col items-center">
              <div class="my-auto lg:mb-2 mr-3 lg:mr-0">
                <img
                  class="w-6 lg:w-8 h-6 lg:h-8"
                  src="@/assets/ic_hm_bed.svg"
                />
              </div>
              <div class="flex flex-col">
                <div class="text-h3">
                  {{ selectedModel.numberBedrooms || "--" }}
                </div>
                <div class="text-body-3">Beds</div>
              </div>
            </div>
            <div class="flex lg:flex-col items-center">
              <div class="my-auto lg:mb-2 mr-3 lg:mr-0">
                <img
                  class="w-6 lg:w-8 h-6 lg:h-8"
                  src="@/assets/ic_hm_bath.svg"
                />
              </div>
              <div class="flex flex-col">
                <div class="text-h3">
                  {{ selectedModel.numberBathrooms || "--" }}
                </div>
                <div class="text-body-3">Baths</div>
              </div>
            </div>
          </div>

          <div class="flex-col justify-end hidden lg:flex">
            <div class="text-h3 font-semibold text-right">
              {{ $currencyNoDecimal.format(selectedModel.price || "0") }}
            </div>
            <div>Starting price (excluding land cost)</div>
          </div>
        </div>
      </div>

      <Carousel
        v-if="selectedModel && selectedModel.houseImages"
        id="home-images"
        :fixedHeight="440"
        :images="selectedModel.houseImages.items"
        :floorplansImages="selectedModel.floorplansImages.items"
      />

      <div class="px-6 pb-6 lg:px-7 lg:pb-7">
        <div
          v-if="selectedModel && features.length > 0"
          class="text-h4 font-semibold mb-3"
        >
          Features
        </div>

        <div
          v-if="features.length"
          class="flex flex-wrap flex-row text-body-2 w-full mb-8"
        >
          <div
            class="w-1/2 mb-2 pr-2"
            v-for="feature in features.slice(0, featureLimit)"
            :key="feature.name"
          >
            {{ feature.name }}
          </div>

          <button
            v-if="features.length > featureLimit"
            type="button"
            class="inline-block bg-transparent text-blue-500 font-semibold rounded"
            @click="showMoreLess(features.length)"
          >
            + {{ features.length - featureLimit }} additional features
          </button>

          <button
            v-if="features.length === featureLimit"
            type="button"
            class="inline-block bg-transparent text-blue-500 font-semibold rounded"
            @click="showMoreLess(6)"
          >
            Show Less
          </button>
        </div>

        <div class="text-body-2 text-blue-500 font-semibold cursor-pointer">
          <a href="#"> Read more about "{{ selectedModel.name }}" features </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BuilderHeader from "@/components/text/BuilderHeader";
import { Carousel } from "@/components/carousels";
import { BUILDER_COMPONENTS_IDS, BUILDER_EVENTS } from "@/constants";

export default {
  name: "HomeModel",
  components: {
    BuilderHeader,
    Carousel,
  },
  props: {
    homeModels: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectedModel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      features: [],
      featureLimit: 6,
      containerId: BUILDER_COMPONENTS_IDS.HOME_MODEL,
    };
  },
  computed: {
    availableOnLots() {
      return this.selectedModel?.lots?.items
        ? this.selectedModel.lots.items.length
        : 0;
    },
  },
  methods: {
    selectModel(home) {
      if (home.isAvailable) {
        this.$root.$emit(BUILDER_EVENTS.MODEL_SELECTION, home);
      }
    },
    showMoreLess(val) {
      this.featureLimit = val;
    },
  },
  mounted() {
    this.features = this.selectedModel?.features?.items ?? [];

    this.$root.$emit(BUILDER_EVENTS.MODEL_SELECTION, this.selectedModel);
  },
};
</script>

<style scoped>
.elevated-button {
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0px 4px 12px 0px #0000001a;
}
</style>

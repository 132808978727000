<template>
  <div class="text-gray-900">
    <div v-if="!showForgotPassword">
      <div class="flex flex-row items-center justify-between">
        <div class="title">{{ title }}</div>
      </div>
      <form @submit.prevent="submit" class="flex flex-col mt-16">
        <div class="label">Email</div>
        <TextInput
          placeholder="founder@example.com"
          inputType="email"
          :required="true"
          v-model="email"
        />

        <div class="separator"></div>
        <div class="flex justify-between flex-wrap">
          <div class="label">Password</div>
          <div
            class="text-blue-500 font-semibold text-body-2 mb-4 md:mb-0 cursor-pointer"
            @click="forgotPasswordHandler()"
          >
            Forgot password?
          </div>
        </div>
        <TextInput
          placeholder="Enter"
          inputType="password"
          :required="true"
          v-model="password"
        />

        <div
          v-if="errorMessage"
          class="flex flex-col mt-8 text-red-600 text-body-1"
        >
          {{ errorMessage }}
        </div>
        <div
          class="flex flex-col-reverse md:flex-row items-center justify-between my-12"
        >
          <div class="text-body-2 pt-3 md:pt-0 text-center">
            <span
              class="text-blue-500 font-semibold cursor-pointer"
              @click="showAccountCreate()"
            >
              Create Account
            </span>
            if you don't have one
          </div>

          <PrimaryBaseCta
            class="min-w-[148px]"
            text="Login"
            eventName="submit"
            fixedSize="small"
            :disabled="disabled"
            @click="submit"
          />
        </div>
      </form>
    </div>

    <ForgotPassword v-else :email="this.email" @showLogin="showLogin()" />
  </div>
</template>
<script>
import { TextInput, PrimaryBaseCta } from "@/components";
import ForgotPassword from "./ForgotPassword.vue";
import { Auth } from "aws-amplify";

export default {
  name: "Login",
  props: {
    title: {
      type: String,
      default: "Log in",
    },
  },
  components: {
    TextInput,
    PrimaryBaseCta,
    ForgotPassword,
  },
  data() {
    return {
      email: null,
      password: null,
      disabled: false,
      errorMessage: null,
      showForgotPassword: false,
    };
  },
  methods: {
    async submit() {
      try {
        this.disabled = true;
        await Auth.signIn(this.email, this.password);
        this.disabled = false;
        this.close();
        this.$router.push({ name: "Dashboard" });
      } catch (e) {
        this.errorMessage = e.message;
        this.disabled = false;
      }
    },
    showAccountCreate() {
      this.$emit("showSignup");
    },
    close() {
      // Close the modal
      this.$emit("close");
    },
    showLogin() {
      this.showForgotPassword = false;
      // We want to clear the input field for the new password
      this.password = null;
    },
    forgotPasswordHandler() {
      if (!this.email || this.email === "") {
        this.errorMessage = "Please type in your email";
      } else {
        this.errorMessage = "";

        Auth.forgotPassword(this.email)
          .then(() => {
            this.showForgotPassword = true;
          })
          .catch((err) => {
            if (
              err.message.indexOf("Username/client id combination not found") >=
              0
            ) {
              this.errorMessage = `User with email "${this.email}" was not found.`;
            }
          });
      }
    },
  },
};
</script>
<style scoped>
.title {
  @apply text-h3 font-semibold;
}
.label {
  @apply text-body-1 font-semibold pb-3;
}
.separator {
  @apply mt-8 xl:mt-12;
}
.login-text {
  @apply text-body-2 font-semibold;
}
</style>

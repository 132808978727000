<template>
  <div class="px-8 md:px-16 lg:px-16 xl:px-24">
    <NavMobileMarketing
      @closeMobile="closeMobile"
      :items="items"
      v-if="isMarketingNavigation"
      @login="login()"
    />
    <NavMobileApp v-if="isAppNavigation" @closeMobile="closeMobile" />
  </div>
</template>
<script>
import NavMobileMarketing from "./NavMobileMarketing.vue";
import NavMobileApp from "./NavMobileApp.vue";
import { getNavLocations } from "@/mocks/useNavLocationsModel";
import { isSignedIn } from "@/store/useAuthStore.js";
export default {
  components: {
    NavMobileMarketing,
    NavMobileApp,
  },
  data() {
    return {
      items: getNavLocations(),
    };
  },
  computed: {
    isMarketingNavigation() {
      return this.$navActions.isMarketingNavigation();
    },
    isAppNavigation() {
      return this.$navActions.isAppNavigation();
    },
    isSignedIn() {
      return isSignedIn();
    },
  },
  methods: {
    closeMobile() {
      this.$emit("closeMobile");
    },
    login() {
      this.$emit("login");
    },
  },
};
</script>

<template>
  <div class="locations-container max-container">
    <div class="main-grid">
      <h1 class="column-def title">Looking for another location?</h1>
      <h4 class="column-def sub-title">
        We're always scouting new locations! Let us know where you want to move
        and join our interest list to hear when we find a spot.
      </h4>

      <form @submit.prevent="submit" class="column-def xl:mt-12 mt-8">
        <div>
          <div class="field-label">What location(s) are you interested in?</div>
          <div class="relative">
            <LocationsDropdown
              :choices="choices"
              v-model="locations"
              placeholder="Choose locations"
              class="absolute"
            />
          </div>
        </div>
        <div class="flex flex-row xl:gap-x-8 xl:gap-y-0 gap-y-8 xl:mt-16 mt-8">
          <div class="mr-8 w-full">
            <div class="field-label">First name</div>
            <TextInput v-model="firstName" :required="true" />
          </div>
          <div class="w-full">
            <div class="field-label">Last name</div>
            <TextInput v-model="lastName" :required="true" />
          </div>
        </div>
        <div class="field-wrapper">
          <div>
            <div class="field-label">Email</div>
            <TextInput inputType="email" :required="true" v-model="email" />
          </div>
          <div>
            <div class="field-label">When is your ideal move date?</div>
            <Dropdown
              :choices="moveDates"
              :oneChoiceAllowed="true"
              v-model="idealMoveDate"
              placeholder="(Optional) Choose an option"
            />
          </div>
        </div>

        <div class="mt-16">
          <div class="field-label">Any other comments?</div>
          <div class="mt-3">
            <TextArea
              placeholder="(Optional) Enter your comment. Maybe you're looking for a neighborhood we didn't list above, or just have a question."
              v-model="comments"
            />
          </div>
        </div>

        <div class="xl:mt-16 mt-8">
          <div class="flex flex-row items-center align-middle">
            <Checkbox v-model="isAgent" ref="chk" />
            <div class="ml-6 text-body-2 xl:text-h4 font-regular text-gray-900">
              I am a real estate agent.
            </div>
          </div>
        </div>

        <div
          class="xl:mt-16 mt-8 font-semibold text-body-2 xl:text-body-1 text-red-500"
          v-if="errorText"
        >
          {{ errorText }}
        </div>

        <div class="cta-1">
          <PrimaryBaseCta
            text="Get Notified"
            fixedSize="large"
            endImage="ico_circle_arrow_white.svg"
            @click="submit"
            eventName="submit"
            :disabled="disabled"
          />
        </div>
      </form>
    </div>
    <div
      class="mt-12 w-full text-center text-body-2 xl:text-h4 font-regular text-gray-900 xl:px-0 px-4 max-w-xs xl:max-w-max mx-auto"
    >
      Our newest community is in Douglasville, GA.
      <router-link
        to="/locations/hollysprings"
        class="text-blue-500 font-semibold cursor-pointer"
      >
        Learn more
      </router-link>
    </div>
  </div>
</template>

<script>
import {
  TextInput,
  PrimaryBaseCta,
  Checkbox,
  Dropdown,
  LocationsDropdown,
  TextArea,
} from "@/components";
import { getLocationList } from "@/mocks/useOtherLocationMocks.js";
import { HUBSPOT_PAGES, MOVE_IN_DATES_ARRAY } from "@/constants";
import { postToHubspot } from "@/helpers/postToHubspot.js";

export default {
  name: "LocationInterest",
  components: {
    TextInput,
    PrimaryBaseCta,
    Checkbox,
    Dropdown,
    LocationsDropdown,
    TextArea,
  },
  data() {
    return {
      locations: null,
      firstName: null,
      lastName: null,
      email: null,
      isAgent: false,
      idealMoveDate: null,
      comments: null,
      disabled: false,
      errorText: null,
      choices: getLocationList(),
      moveDates: MOVE_IN_DATES_ARRAY,
    };
  },
  methods: {
    submit() {
      this.disabled = true;
      this.errorText = null;

      const data = {
        firstname: this.firstName,
        lastname: this.lastName,
        email: this.email,
        ideal_move_date: this.idealMoveDate,
        is_agent: this.isAgent,
        locations_interested: this.locations,
        lead_form_comments: this.comments,
      };

      if (!data.locations_interested) {
        this.errorText = "Please check locations you're interested in";
      } else {
        postToHubspot(HUBSPOT_PAGES.LOCATIONS, data)
          .then((res) => {
            if (res.success) {
              this.$router.push({
                path: "/thanks-locations",
              });
            }
          })
          .catch((error) => (this.errorText = error));
      }

      this.disabled = false;
    },
  },
};
</script>

<style scoped>
.locations-container {
  @apply pt-24 pb-12 xl:pb-32 sm:pt-8 md:pt-16;
}
.column-def {
  @apply xl:col-start-2 xl:col-span-10 col-span-full;
}
.title {
  @apply text-gray-900 text-h3 xl:text-h1 font-semibold;
}
.sub-title {
  @apply text-gray-900 pt-8 text-body-2 xl:text-h4 font-regular max-w-[730px];
}
.required {
  @apply text-gray-900 pt-8 text-body-2 font-regular;
}
.field-wrapper {
  @apply flex xl:flex-row xl:gap-x-8 xl:gap-y-0 gap-y-8 flex-col xl:mt-16 mt-8;
}
.field-wrapper > div {
  @apply xl:w-1/2 w-full;
}
.field-label {
  @apply text-body-1 xl:text-h4 font-semibold text-gray-900 mb-3;
}
.cta-1 {
  @apply mt-12 flex justify-center xl:justify-end;
}
</style>

<template>
  <div class="root">
    <div :class="[background]" class="wrapper">
      <slot name="content"></slot>
    </div>
    <div class="relative flex flex-row items-center justify-center h-8">
      <div :class="[background]" class="absolute diamond">&nbsp;</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LeftToolTip",
  props: {
    background: {
      type: String,
      default: "bg-creme-50",
    },
  },
};
</script>
<style scoped>
.root {
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.15);
  @apply flex flex-row items-center justify-center rounded-16;
}
.diamond {
  transform: rotate(45deg);
  @apply w-8 h-8;
}
.wrapper {
  z-index: 100;
  @apply w-full p-6 space-y-6 rounded-16;
}
</style>

<template>
  <div class="flex-grow">
    <NavDesktopMarketing
      @login="login()"
      :items="items"
      v-if="isMarketingNavigation"
    />
    <NavDesktopApp v-if="isAppNavigation" />
  </div>
</template>
<script>
import NavDesktopMarketing from "./NavDesktopMarketing.vue";
import NavDesktopApp from "./NavDesktopApp.vue";
import { getNavLocations } from "@/mocks/useNavLocationsModel";
export default {
  components: {
    NavDesktopMarketing,
    NavDesktopApp,
  },
  data() {
    return {
      items: getNavLocations(),
    };
  },
  computed: {
    isMarketingNavigation() {
      return this.$navActions.isMarketingNavigation();
    },
    isAppNavigation() {
      return this.$navActions.isAppNavigation();
    },
  },
  methods: {
    login() {
      this.$emit("login");
    },
  },
};
</script>

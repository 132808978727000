export const BUILDER_COMPONENTS_IDS = {
  HOME_MODEL: "homemodel",
  LOT_MAP: "lotmap",
  KITCHEN_COLORS: "kitchencolors",
  FLOORING_COLORS: "flooringcolors",
  ADDONS: "addons",
  SAVE_HOME: "savehome",
};

export const SCROLL_OFFSET = 400;

export const BUILDER_EVENTS = {
  MODEL_SELECTION: "model_selection",
  LOT_SELECTION: "lot_selection",
  KITCHEN_SELECTION: "kitchen_selection",
  FLOORING_SELECTION: "floring_selection",
  ADDON_SELECTION: "addon_selection",
};

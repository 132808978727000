<template>
  <div class="bg-creme-300">
    <div class="max-container xl:px-0 px-12 overflow-hidden">
      <div class="py-6 lg:py-16">
        <div class="flex flex-row justify-between items-start lg:items-center">
          <div
            class="hidden lg:flex flex-row justify-between relative w-full lg:w-max mb-8 lg:mb-0"
          >
            <h4
              class="text-h4 font-semibold text-center lg:text-right mx-auto max-w-full lg:max-w-[140px]"
            >
              Our quality promise.
            </h4>
            <img
              class="absolute bottom-[-84px] right-[-120px] hidden lg:block"
              src="@/assets/icons/pinky_swear.svg"
              alt="Pinky Swear hand icon"
            />
          </div>
          <div class="strip-item lg:ml-40 mr-4 lg:mr-0 flex-grow">
            <h4>Award-winning</h4>
            <span>home designs</span>
          </div>
          <div class="strip-item hidden lg:flex">
            <h4>High-Quality</h4>
            <span>construction and finishings</span>
          </div>
          <div class="strip-item mr-4 lg:mr-0">
            <h4 class="text-center lg:text-left">2 year</h4>
            <span class="inline-block w-full text-center lg:text-left"
              >warranty</span
            >
          </div>
          <div class="strip-item flex lg:hidden">
            <h4 class="text-right lg:text-left">High-Quality</h4>
            <span class="text-right lg:text-left">construction</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "YellowStrip",
  methods: {
    onClick() {
      this.$router.push({
        path: "/locations/hollysprings",
      });
    },
  },
};
</script>

<style scoped>
.strip-item {
  @apply flex-col text-left;
  flex-grow: 1;
}

.strip-item h4 {
  @apply font-semibold text-body-2 lg:text-h4;
}

.strip-item span {
  @apply text-body-3 lg:text-body-1;
}

.max-container {
  max-width: 1088px;
}
</style>

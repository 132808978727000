<template>
  <div class="xl:sticky xl:top-16">
    <div
      class="flex xl:flex-col flex-row overflow-scroll xl:px-4 xl:max-w-[340px] smooth-scroll-n-hide edge-to-edge-mobile"
    >
      <p
        class="hidden xl:block text-black text-h3 font-semibold text-center mb-8"
      >
        Design your home
      </p>

      <div
        class="summary-section ml-0"
        :class="[
          currentHighlight === BUILDER_COMPONENTS_IDS.HOME_MODEL
            ? 'bg-creme-300'
            : '',
          BUILDER_COMPONENTS_IDS.HOME_MODEL,
        ]"
        @click="scrollToComponent(BUILDER_COMPONENTS_IDS.HOME_MODEL)"
      >
        <div
          :class="[home === 'Not selected' ? '' : 'font-semibold']"
          class="whitespace-nowrap my-auto p-1 xl:p-2"
        >
          Home Model
        </div>
        <SummaryChip
          :class="[
            home === 'Not selected'
              ? 'bg-creme-200 text-gray-500'
              : 'bg-yellow-500',
          ]"
          :text="home"
        />
      </div>

      <div
        class="summary-section"
        :class="[
          currentHighlight === BUILDER_COMPONENTS_IDS.LOT_MAP
            ? 'bg-creme-300'
            : '',
          BUILDER_COMPONENTS_IDS.LOT_MAP,
        ]"
        @click="scrollToComponent(BUILDER_COMPONENTS_IDS.LOT_MAP)"
      >
        <div
          :class="[lot === 'Not selected' ? '' : 'font-semibold']"
          class="whitespace-nowrap my-auto p-1 xl:p-2"
        >
          Lot
        </div>
        <SummaryChip
          :class="[
            lot === 'Not selected'
              ? 'bg-creme-200 text-gray-500'
              : 'bg-yellow-500',
          ]"
          :text="lot"
        />
      </div>

      <div
        class="summary-section"
        :class="[
          currentHighlight === BUILDER_COMPONENTS_IDS.KITCHEN_COLORS
            ? 'bg-creme-300'
            : '',
          BUILDER_COMPONENTS_IDS.KITCHEN_COLORS,
        ]"
        @click="scrollToComponent(BUILDER_COMPONENTS_IDS.KITCHEN_COLORS)"
      >
        <div
          :class="[kitchen.text === 'Not selected' ? '' : 'font-semibold']"
          class="whitespace-nowrap my-auto p-1 xl:p-2"
        >
          Kitchen Colors
        </div>
        <SummaryChip
          :class="[
            kitchen.text === 'Not selected'
              ? 'bg-creme-200 text-gray-500'
              : 'bg-yellow-500',
          ]"
          :text="kitchen.text"
          :color="kitchen.color"
        />
      </div>

      <div
        class="summary-section"
        :class="[
          currentHighlight === BUILDER_COMPONENTS_IDS.FLOORING_COLORS
            ? 'bg-creme-300'
            : '',
          BUILDER_COMPONENTS_IDS.FLOORING_COLORS,
        ]"
        @click="scrollToComponent(BUILDER_COMPONENTS_IDS.FLOORING_COLORS)"
      >
        <div
          :class="[flooring.text === 'Not selected' ? '' : 'font-semibold']"
          class="whitespace-nowrap my-auto p-1 xl:p-2"
        >
          Flooring Colors
        </div>
        <SummaryChip
          :class="[
            flooring.text === 'Not selected'
              ? 'bg-creme-200 text-gray-500'
              : 'bg-yellow-500',
          ]"
          :text="flooring.text"
          :color="flooring.color"
        />
      </div>

      <div
        class="summary-section"
        :class="[
          currentHighlight === BUILDER_COMPONENTS_IDS.ADDONS
            ? 'bg-creme-300'
            : '',
          BUILDER_COMPONENTS_IDS.ADDONS,
        ]"
        @click="scrollToComponent(BUILDER_COMPONENTS_IDS.ADDONS)"
      >
        <div
          :class="[addons.length === 0 ? '' : 'font-semibold']"
          class="whitespace-nowrap my-auto p-1 xl:p-2"
        >
          Add-ons
        </div>
        <SummaryChip
          :class="[
            addons.length === 0
              ? 'bg-creme-200 text-gray-500'
              : 'bg-yellow-500',
          ]"
          :text="`${addons.length ? addons.length : 'Not'} selected`"
        />
      </div>

      <hr
        class="hidden xl:block border-black border-opacity-10 text-center my-8"
      />

      <SummaryFooter
        v-if="basePrice"
        :home_price="$currencyNoDecimal.format(basePrice, 0)"
        :home_value="$currencyNoDecimal.format(basePrice, 0)"
        :savings="$currencyNoDecimal.format(basePrice - basePrice, 0)"
      />
    </div>
  </div>
</template>

<script>
import { SummaryChip } from "@/components";
import SummaryFooter from "./SummaryFooter.vue";
import {
  BUILDER_COMPONENTS_IDS,
  SCROLL_OFFSET,
  BUILDER_EVENTS,
} from "@/constants";

const { HOME_MODEL, LOT_MAP, KITCHEN_COLORS, FLOORING_COLORS, ADDONS } =
  BUILDER_COMPONENTS_IDS;

const {
  MODEL_SELECTION,
  LOT_SELECTION,
  KITCHEN_SELECTION,
  FLOORING_SELECTION,
  ADDON_SELECTION,
} = BUILDER_EVENTS;

const defaultSummaryState = {
  basePrice: 380000,
  currentHighlight: HOME_MODEL,
  lot: "Not selected",
  home: "Not selected",
  kitchen: {
    text: "Not selected",
    color: "",
  },
  flooring: {
    text: "Not selected",
    color: "",
  },
  addons: [],
};

export default {
  name: "Summary",
  components: {
    SummaryChip,
    SummaryFooter,
  },
  data() {
    return {
      BUILDER_COMPONENTS_IDS,
      ...defaultSummaryState,
      builderPositions: {
        homemodel: null,
        lotmap: null,
        kitchencolors: null,
        flooringcolors: null,
        addons: null,
      },
    };
  },
  methods: {
    scrollHandler() {
      if (window.scrollY < this.builderPositions.lotmap) {
        if (this.currentHighlight !== HOME_MODEL) {
          this.currentHighlight = HOME_MODEL;
          this.mobileNavScrollIntoView(HOME_MODEL);
        }
      }

      if (
        window.scrollY >= this.builderPositions.lotmap &&
        window.scrollY < this.builderPositions.kitchencolors
      ) {
        if (this.currentHighlight !== LOT_MAP) {
          this.currentHighlight = LOT_MAP;
          this.mobileNavScrollIntoView(LOT_MAP);
        }
      }

      if (
        window.scrollY >= this.builderPositions.kitchencolors &&
        window.scrollY < this.builderPositions.flooringcolors
      ) {
        if (
          this.currentHighlight !== KITCHEN_COLORS &&
          this.builderPositions.kitchencolors
        ) {
          this.currentHighlight = KITCHEN_COLORS;
          this.mobileNavScrollIntoView(KITCHEN_COLORS);
        }
      }

      if (
        window.scrollY >= this.builderPositions.flooringcolors &&
        window.scrollY < this.builderPositions.addons
      ) {
        if (
          this.currentHighlight !== FLOORING_COLORS &&
          this.builderPositions.flooringcolors
        ) {
          this.currentHighlight = FLOORING_COLORS;
          this.mobileNavScrollIntoView(FLOORING_COLORS);
        }
      }

      if (window.scrollY >= this.builderPositions.addons) {
        if (this.currentHighlight !== ADDONS) {
          this.currentHighlight = ADDONS;
          this.mobileNavScrollIntoView(ADDONS);
        }
      }
    },
    initBuilderPositions() {
      const homemodel = document.getElementById(HOME_MODEL);
      const lotmap = document.getElementById(LOT_MAP);
      const kitchencolors = document.getElementById(KITCHEN_COLORS);
      const flooringcolors = document.getElementById(FLOORING_COLORS);
      const addons = document.getElementById(ADDONS);

      if (homemodel) {
        this.builderPositions.homemodel = homemodel.offsetTop - SCROLL_OFFSET;
      }
      if (lotmap) {
        this.builderPositions.lotmap = lotmap.offsetTop - SCROLL_OFFSET;
      }
      if (kitchencolors) {
        this.builderPositions.kitchencolors =
          kitchencolors.offsetTop - SCROLL_OFFSET;
      }
      if (flooringcolors) {
        this.builderPositions.flooringcolors =
          flooringcolors.offsetTop - SCROLL_OFFSET;
      }
      if (addons) {
        this.builderPositions.addons = addons.offsetTop - SCROLL_OFFSET;
      }
    },
    scrollToComponent(id) {
      const componentOffset = this.builderPositions[id];
      window.scrollTo({ top: componentOffset, behavior: "smooth" });
    },
    mobileNavScrollIntoView(id) {
      // This function handles horizontal scrolling of the Summary in mobile view
      if (window.innerWidth < 1280) {
        const amountToScroll = document.querySelector(
          `.summary-section.${id}`
        ).offsetLeft;

        document.querySelector(".smooth-scroll-n-hide").scrollLeft =
          amountToScroll;
      }
    },
  },
  created() {
    // We have to catch correct positions after window is fully loaded
    window.addEventListener("load", this.initBuilderPositions);
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandler);

    this.$root.$on(MODEL_SELECTION, (val) => {
      this.home = val.name;
      this.basePrice = val.price;

      // Reset all choices when different model is selected
      this.lot = defaultSummaryState.lot;
      this.kitchen = { ...defaultSummaryState.kitchen };
      this.flooring = { ...defaultSummaryState.flooring };
      this.addons = defaultSummaryState.addons;

      // Re-init builder components' position because kitchen and flooring colors
      // don't exist until a house model is selected and the whole layout is moved
      // becase of it
      this.initBuilderPositions();
    });
    this.$root.$on(LOT_SELECTION, (val) => {
      this.lot = val;
    });
    this.$root.$on(KITCHEN_SELECTION, (val) => {
      this.kitchen.text = val.name;
      this.kitchen.color = val.colorCode;
    });
    this.$root.$on(FLOORING_SELECTION, (val) => {
      this.flooring.text = val.name;
      this.flooring.color = val.colorCode;
    });
    this.$root.$on(ADDON_SELECTION, (val) => {
      if (this.addons.includes(val.name)) {
        this.addons = this.addons.filter((elem) => elem !== val.name);
      } else {
        this.addons = [...this.addons, val.name];
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollHandler);
    window.removeEventListener("load", this.initBuilderPositions);
  },
};
</script>

<style scoped>
.summary-section {
  @apply flex flex-row justify-between p-2 text-body-2 rounded-xl cursor-pointer h-full mr-3;
}

.edge-to-edge-mobile {
  @apply w-screen xl:w-full ml-[-32px] pl-[32px] xl:ml-0 xl:pl-0;
}

.smooth-scroll-n-hide {
  scroll-behavior: smooth;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.smooth-scroll-n-hide::-webkit-scrollbar {
  display: none;
}
</style>

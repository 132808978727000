<template>
  <button
    type="button"
    class="hover:bg-gray-300 text-hfbase font-semibold text-black px-6 py-3 border border-black leading-normal rounded-xl focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out flex align-center"
    @click="click"
  >
    {{ text }}
    <svg
      class="ml-2"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      stroke="#2C2525"
      stroke-width="2"
    >
      <circle
        cx="12.5"
        cy="12.5"
        r="10"
        fill="#F5DD7B"
        stroke-width="1"
      ></circle>
      <line
        x1="6"
        y1="12.5"
        x2="18.5"
        y2="12.5"
        stroke="#2C2525"
        stroke-width="1.5"
      ></line>
      <line
        x1="12.5"
        y1="6"
        x2="12.5"
        y2="19"
        stroke="#2C2525"
        stroke-width="1.5"
      ></line>
    </svg>
  </button>
</template>

<script>
export default {
  name: "YellowCross",
  props: {
    text: {
      type: String,
      default: "Button",
    },
  },
  methods: {
    click() {
      this.$emit("yellowCrossClick");
    },
  },
};
</script>

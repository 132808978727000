<template>
  <div class="main-container">
    <global-section>
      <div class="max-container">
        <h1 class="column-def title">Priority Access</h1>
        <form @submit.prevent="submit" class="column-def mt-16">
          <div id="priority-access" class="application-open">
            <div class="flex flex-col lg:flex-row lg:justify-between main">
              <div class="w-full lg:max-w-[474px] flex flex-col">
                <div>
                  <!-- <p class="text-body-2">
                    Apply for priority access to reserve the homes ahead of
                    public announcement and vote on development details.
                  </p> -->
                  <p class="text-body-2 mt-3">
                    Get priority access to our upcoming 10-home release! You'll
                    be able to reserve your home ahead of the public and vote on
                    development details. For a limited time, deposit-free
                    (typically $100). **Expires 6/30/2022
                  </p>
                </div>
                <div class="my-12 no-select">
                  <div class="field-label">Email</div>
                  <TextInput
                    inputType="email"
                    v-model="email"
                    :invalid="errors['email']"
                    ref="email"
                  />
                </div>
              </div>
              <img
                src="@/assets/priority-form-open.png"
                class="w-full h-full max-w-[400px]"
                v-if="priorityAccess"
              />
              <img
                src="@/assets/priority-form-closed.png"
                class="w-full h-full max-w-[400px]"
                v-else
              />
            </div>
            <div class="py-8 flex flex-col text-body-2 priority-access-form">
              <div class="flex flex-col items-center lg:flex-row">
                <img src="@/assets/icons/shield.svg" class="mb-8 lg:mb-0" />
                <p class="lg:ml-8 text-center lg:text-left">
                  Confidentiality: HomeFounder strives to ensure the protection
                  of your personal information. We do not sell your data.
                </p>
              </div>
              <global-section type="top-only">
                <div class="text-body-1 font-semibold text-center">
                  I. Your current living situation
                </div>
                <div
                  class="flex flex-col lg:flex-row lg:flex-wrap mt-12 lg:justify-between items-end"
                >
                  <div class="lg:w-[32%] w-full mb-8 lg:mb-0 justify-end">
                    <div class="field-label">
                      1. What’s your current zip code?
                    </div>
                    <TextInput
                      v-model="zip"
                      :invalid="errors['zip']"
                      ref="zip"
                    />
                  </div>
                  <div class="lg:w-[32%] w-full mb-8 lg:mb-0 justify-end">
                    <div class="field-label">
                      2. Do you currently rent or own?
                    </div>
                    <Dropdown
                      :choices="rentOrOwnChoices"
                      :oneChoiceAllowed="true"
                      v-model="rent_or_own"
                      placeholder="Choose an option"
                      ref="rent_or_own"
                      :invalid="errors['rent_or_own']"
                    />
                  </div>
                  <div class="lg:w-[32%] w-full justify-end">
                    <div class="field-label">
                      3. What type of dwelling do you live in?
                    </div>
                    <Dropdown
                      :choices="dwellingChoices"
                      :oneChoiceAllowed="true"
                      v-model="dwelling"
                      placeholder="Choose an option"
                      ref="dwelling"
                      :invalid="errors['dwelling']"
                    />
                  </div>
                </div>
              </global-section>
              <global-section type="top-only">
                <div class="text-body-1 font-semibold text-center">
                  II. What you’re looking for in your next home
                </div>
                <div
                  class="flex flex-col lg:flex-row lg:flex-wrap mt-12 lg:justify-between items-end"
                >
                  <div
                    class="lg:w-[32%] w-full flex flex-col justify-end mb-8 lg:mb-0"
                  >
                    <div class="field-label">
                      4. For what other reasons do you want to buy a new home?
                      (select all)
                    </div>
                    <Dropdown
                      :choices="mainChoices"
                      :oneChoiceAllowed="false"
                      v-model="reasons_for_new_home"
                      placeholder="Choose an option"
                      ref="reasons_for_new_home"
                      :invalid="errors['reasons_for_new_home']"
                    />
                  </div>
                  <div
                    class="lg:w-[32%] w-full flex flex-col justify-end mb-8 lg:mb-0"
                  >
                    <div class="field-label">
                      5. Who do you plan to live with, in your next home?
                    </div>
                    <Dropdown
                      :choices="liveWithChoices"
                      :oneChoiceAllowed="true"
                      v-model="who_do_you_plan_to_live_with__in_your_next_home_"
                      placeholder="Choose an option"
                      ref="who_do_you_plan_to_live_with__in_your_next_home_"
                      :invalid="
                        errors[
                          'who_do_you_plan_to_live_with__in_your_next_home_'
                        ]
                      "
                    />
                  </div>
                  <div
                    class="lg:w-[32%] w-full flex flex-col justify-end relative mb-8 lg:mb-0"
                  >
                    <div class="field-label">
                      6. How many bedrooms would you like?
                    </div>
                    <div
                      class="flex flex-col lg:flex-row lg:justify-between items-center"
                    >
                      <div class="w-full lg:w-[48%]">
                        <Dropdown
                          :choices="bedroomChoices"
                          :oneChoiceAllowed="true"
                          v-model="minimum_bedrooms"
                          placeholder="Min"
                          ref="minimum_bedrooms"
                          :invalid="errors['minimum_bedrooms']"
                        />
                      </div>
                      <span class="px-4"> to </span>
                      <div class="w-full lg:w-[48%]">
                        <Dropdown
                          :choices="bedroomChoices"
                          :oneChoiceAllowed="true"
                          v-model="maximum_bedrooms"
                          placeholder="Max"
                          ref="maximum_bedrooms"
                          :invalid="errors['maximum_bedrooms']"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </global-section>
              <global-section type="top-only">
                <div class="text-body-1 font-semibold text-center">
                  III. Personal details
                </div>
                <div
                  class="flex flex-col lg:flex-row lg:flex-wrap mt-12 lg:justify-between"
                >
                  <div class="lg:w-[32%] w-full flex flex-col justify-end mb-8">
                    <div class="field-label">7. Your Age</div>
                    <Dropdown
                      :choices="ageChoices"
                      :oneChoiceAllowed="true"
                      v-model="age_range"
                      placeholder="Choose an option"
                      ref="age_range"
                      :invalid="errors['age_range']"
                    />
                  </div>
                  <div class="lg:w-[32%] w-full flex flex-col justify-end mb-8">
                    <div class="field-label">
                      8. How many people are in your household/family?
                    </div>
                    <Dropdown
                      :choices="householdSizeChoices"
                      :oneChoiceAllowed="true"
                      v-model="how_many_people_are_in_your_household__family_"
                      placeholder="Choose an option"
                      ref="how_many_people_are_in_your_household__family_"
                      :invalid="
                        errors['how_many_people_are_in_your_household__family_']
                      "
                    />
                  </div>
                  <div class="lg:w-[32%] w-full flex flex-col justify-end mb-8">
                    <div class="field-label">
                      9. Head of Household’s Employment Status
                    </div>
                    <Dropdown
                      :choices="employmentStatusChoices"
                      :oneChoiceAllowed="true"
                      v-model="head_of_household_s_employment_status"
                      placeholder="Choose an option"
                      ref="head_of_household_s_employment_status"
                      :invalid="errors['head_of_household_s_employment_status']"
                    />
                  </div>
                  <div class="lg:w-[48%] w-full flex flex-col justify-end mb-8">
                    <div class="field-label">
                      10. Are you a first-time homebuyer?
                    </div>
                    <Dropdown
                      :choices="fthbChoices"
                      :oneChoiceAllowed="true"
                      v-model="is_fthb"
                      placeholder="Choose an option"
                      ref="is_fthb"
                      :invalid="errors['is_fthb']"
                    />
                  </div>
                  <div class="lg:w-[48%] w-full flex flex-col justify-end mb-8">
                    <div class="field-label">
                      11. Estimated Annual Household Income
                    </div>
                    <Dropdown
                      :choices="incomeChoices"
                      :oneChoiceAllowed="true"
                      v-model="estimated_annual_household_income"
                      placeholder="Choose an option"
                      ref="estimated_annual_household_income"
                      :invalid="errors['estimated_annual_household_income']"
                    />
                  </div>
                  <div class="lg:w-[48%] w-full flex flex-col justify-end mb-8">
                    <div class="field-label">
                      12. How are you planning for pay for your new home?
                    </div>
                    <Dropdown
                      :choices="payChoices"
                      :oneChoiceAllowed="true"
                      v-model="financing"
                      placeholder="Choose an option"
                      ref="financing"
                      :invalid="errors['financing']"
                    />
                  </div>
                  <div class="lg:w-[48%] w-full flex flex-col justify-end mb-8">
                    <div class="field-label">
                      13. How much do you have set aside for a downpayment?
                    </div>
                    <Dropdown
                      :choices="downpaymentChoices"
                      :oneChoiceAllowed="true"
                      v-model="downpayment"
                      placeholder="Choose an option"
                      ref="downpayment"
                      :invalid="errors['downpayment']"
                    />
                  </div>
                  <div class="lg:w-[48%] w-full flex flex-col justify-end mb-8">
                    <div class="field-label">
                      14. What is your max comfortable budget for a monthly
                      payment?
                    </div>
                    <Dropdown
                      :choices="monthlyPaymentChoices"
                      :oneChoiceAllowed="true"
                      v-model="monthly_payment"
                      placeholder="Choose an option"
                      ref="monthly_payment"
                      :invalid="errors['monthly_payment']"
                    />
                  </div>
                  <div class="lg:w-[48%] w-full flex flex-col justify-end mb-8">
                    <div class="field-label">
                      15. If you could borrow money to put down a bigger
                      downpayment, how much would you borrow? Assume it’s at a
                      similar interest rate to your mortgage.
                    </div>
                    <Dropdown
                      :choices="borrowChoices"
                      :oneChoiceAllowed="true"
                      v-model="downpayment_borrowing"
                      placeholder="Choose an option"
                      ref="downpayment_borrowing"
                      :invalid="errors['downpayment_borrowing']"
                    />
                  </div>
                  <div
                    class="lg:w-[48%] w-full flex flex-col justify-end mb-8 lg:mb-0"
                  >
                    <div class="field-label">
                      16. What’s your current credit score?
                    </div>
                    <Dropdown
                      :choices="creditScoreChoices"
                      :oneChoiceAllowed="true"
                      v-model="credit_score_2"
                      placeholder="Choose an option"
                      ref="credit_score_2"
                      :invalid="errors['credit_score_2']"
                    />
                  </div>
                  <div class="lg:w-[48%] w-full flex flex-col justify-end">
                    <div class="field-label">
                      17. How comfortable are you purchasing without a real
                      estate agent? Working directly with us helps maximize
                      savings, but is not required.
                    </div>
                    <Dropdown
                      :choices="agentChoices"
                      :oneChoiceAllowed="true"
                      v-model="purchase_without_agent"
                      placeholder="Choose an option"
                      ref="purchase_without_agent"
                      :invalid="errors['purchase_without_agent']"
                    />
                  </div>
                </div>
              </global-section>
            </div>
          </div>

          <div
            class="flex flex-col lg:flex-row justify-center lg:justify-end items-center mt-16 w-full"
          >
            <div
              class="text-body-1 mr-8 mb-8 lg:mb-0 text-center lg:text-right"
            >
              <span
                class="lg:mt-16 mt-8 font-semibold text-body-2 text-red-500"
                v-if="errorText"
              >
                {{ errorText }}
              </span>
              <span v-else>
                {{ friendlyValidationMessage() }}
              </span>
            </div>
            <PrimaryBaseCta
              :text="priorityAccess ? 'Submit your application' : 'Submit'"
              fixedSize="large"
              endImage="ico_circle_arrow_white.svg"
              @click="submit"
              eventName="submit"
              :disabled="this.disabled"
            />
          </div>
        </form>
      </div>
    </global-section>
  </div>
</template>

<script>
import {
  TextInput,
  PrimaryBaseCta,
  Dropdown,
  GlobalSection,
} from "@/components";
import { getLocationList } from "@/mocks/useOtherLocationMocks.js";
import { HUBSPOT_PAGES, CONSTRUCTION_TIMELINE_ARRAY } from "@/constants";
import { postToHubspot } from "@/helpers/postToHubspot.js";
import {
  DWELLING_ARRAY,
  RENT_OR_OWN_ARRAY,
  MAIN_REASONS_ARRAY,
  LIVE_WITH_ARRAY,
  BEDROOMS_ARRAY,
  MOST_IMPORTANT_ARRAY,
  AGE_RANGE_ARRAY,
  HOUSEHOLD_SIZE_ARRAY,
  EMPLOYMENT_STATUS_ARRAY,
  PAY_ARRAY,
  FTHB_ARRAY,
  INCOME_ARRAY,
  DOWNPAYMENT_ARRAY,
  MONTHLY_PAYMENT_ARRAY,
  BORROW_ARRAY,
  CREDIT_SCORE_ARRAY,
  AGENT_ARRAY,
} from "../constants";

export default {
  name: "SubscribePriority",
  components: {
    TextInput,
    PrimaryBaseCta,
    Dropdown,
    GlobalSection,
  },
  data() {
    return {
      email: this.email || this.$route.query.email,
      zip: null,
      rent_or_own: null,
      dwelling: null,
      reasons_for_new_home: null,
      who_do_you_plan_to_live_with__in_your_next_home_: null,
      minimum_bedrooms: null,
      maximum_bedrooms: null,
      age_range: null,
      how_many_people_are_in_your_household__family_: null,
      head_of_household_s_employment_status: null,
      is_fthb: null,
      estimated_annual_household_income: null,
      downpayment: null,
      financing: null,
      monthly_payment: null,
      downpayment_borrowing: null,
      credit_score_2: null,
      purchase_without_agent: null,
      priorityAccess: true,
      comments: null,
      disabled: false,
      selectionMade: false,
      errorText: null,
      choices: getLocationList(),
      constructionChoices: CONSTRUCTION_TIMELINE_ARRAY,
      rentOrOwnChoices: RENT_OR_OWN_ARRAY,
      dwellingChoices: DWELLING_ARRAY,
      mainChoices: MAIN_REASONS_ARRAY,
      liveWithChoices: LIVE_WITH_ARRAY,
      bedroomChoices: BEDROOMS_ARRAY,
      mostImportantChoices: MOST_IMPORTANT_ARRAY,
      ageChoices: AGE_RANGE_ARRAY,
      householdSizeChoices: HOUSEHOLD_SIZE_ARRAY,
      employmentStatusChoices: EMPLOYMENT_STATUS_ARRAY,
      downpaymentChoices: DOWNPAYMENT_ARRAY,
      monthlyPaymentChoices: MONTHLY_PAYMENT_ARRAY,
      borrowChoices: BORROW_ARRAY,
      creditScoreChoices: CREDIT_SCORE_ARRAY,
      agentChoices: AGENT_ARRAY,
      payChoices: PAY_ARRAY,
      fthbChoices: FTHB_ARRAY,
      incomeChoices: INCOME_ARRAY,
      errors: {},
    };
  },
  watch: {
    email() {
      this.validateFieldsInRealTime();
    },
    rent_or_own() {
      this.validateFieldsInRealTime();
    },
    reasons_for_new_home() {
      this.validateFieldsInRealTime();
    },
    who_do_you_plan_to_live_with__in_your_next_home_() {
      this.validateFieldsInRealTime();
    },
    minimum_bedrooms() {
      this.validateFieldsInRealTime();
    },
    maximum_bedrooms() {
      this.validateFieldsInRealTime();
    },
    age_range() {
      this.validateFieldsInRealTime();
    },
    how_many_people_are_in_your_household__family_() {
      this.validateFieldsInRealTime();
    },
    head_of_household_s_employment_status() {
      this.validateFieldsInRealTime();
    },
    is_fthb() {
      this.validateFieldsInRealTime();
    },
    estimated_annual_household_income() {
      this.validateFieldsInRealTime();
    },
    downpayment() {
      this.validateFieldsInRealTime();
    },
    monthly_payment() {
      this.validateFieldsInRealTime();
    },
    downpayment_borrowing() {
      this.validateFieldsInRealTime();
    },
    credit_score_2() {
      this.validateFieldsInRealTime();
    },
    purchase_without_agent() {
      this.validateFieldsInRealTime();
    },
    financing() {
      this.validateFieldsInRealTime();
    },
  },
  updated() {
    this.validateFieldsInRealTime();
  },
  methods: {
    getFormFields() {
      var fields = ["email"];
      if (this.priorityAccess) {
        fields.push(
          "zip",
          "rent_or_own",
          "dwelling",
          "reasons_for_new_home",
          "who_do_you_plan_to_live_with__in_your_next_home_",
          "minimum_bedrooms",
          "maximum_bedrooms",
          "age_range",
          "how_many_people_are_in_your_household__family_",
          "head_of_household_s_employment_status",
          "is_fthb",
          "estimated_annual_household_income",
          "downpayment",
          "monthly_payment",
          "downpayment_borrowing",
          "credit_score_2",
          "purchase_without_agent",
          "financing"
        );
      }
      return fields;
    },
    friendlyValidationMessage() {
      if (!this.priorityAccess) {
        return "";
      }
      let fields = this.getFormFields();
      var missing_fields = 0;
      for (var i in fields) {
        let field = fields[i];
        if (field == null || field == "") {
          missing_fields = missing_fields + 1;
        }
      }

      if (missing_fields > 3) {
        return "Complete all input fields to submit";
      } else if (missing_fields > 0) {
        return (
          "Almost there! Only " +
          missing_fields +
          " field" +
          (missing_fields != 1 ? "s" : "") +
          " left"
        );
      }

      return "";
    },
    validateFieldsInRealTime() {
      if (this.errorText) {
        let count = this.validateFields();
        if (count == 0) {
          this.errorText = null;
        } else {
          this.errorText = "All fields required";
        }
      }
    },
    validateFields() {
      var errorCount = 0;
      let fields = this.getFormFields();
      for (var i in fields) {
        let fieldName = fields[i];
        let field = this[fieldName];
        var fieldEmpty = false;
        if (fieldName == "selectionMade") {
          fieldEmpty = !field;
        } else {
          fieldEmpty = field == null || field == "";
        }

        if (fieldEmpty) {
          errorCount = errorCount + 1;
          this.errors[fieldName] = true;
          if (errorCount == 1) {
            if (this.$refs[fieldName]) {
              var element = null;
              if (fieldName == "selectionMade") {
                element = this.$refs[fieldName];
              } else {
                element = this.$refs[fieldName].$el;
              }
              if (element) {
                element.scrollIntoView({
                  block: "start",
                  inline: "nearest",
                  behavior: "smooth",
                });
              }
            }
          }
        } else {
          this.errors[fieldName] = false;
        }
      }
      return errorCount;
    },
    validate() {
      var errorCount = this.validateFields();

      if (errorCount > 0) {
        return false;
      }

      return true;
    },
    submit() {
      this.errorText = null;

      if (!this.validate()) {
        this.errorText = "All fields are required";
        this.disabled = false;
      } else {
        this.disabled = true;

        var data = {
          priorityAccess: this.priorityAccess,
        };

        let fields = this.getFormFields();
        for (var i in fields) {
          let field = fields[i];
          data[field] = this[field];
        }

        postToHubspot(HUBSPOT_PAGES.SUBSCRIBE, data)
          .then((res) => {
            this.disabled = false;
            if (res.success) {
              this.$router.push({
                path: this.priorityAccess
                  ? "/subscribe/priority-thankyou"
                  : "/subscribe/subscribe-thankyou",
              });
            }
          })
          .catch((error) => {
            this.errorText = error;
            console.error(error);
            this.disabled = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.max-container {
  max-width: 1088px;
}

.required {
  @apply text-gray-900 pt-8 text-body-2 font-regular;
}
.field-label {
  @apply text-body-2 font-semibold text-gray-900 mb-3;
}

.title {
  margin: 0;
  @apply text-gray-900 text-h2 font-semibold;
}
.sub-title {
  @apply text-gray-900 pt-8 text-body-1 font-regular max-w-[730px];
}

#priority-access.application-open .main {
  @apply border-b border-gray-900 border-opacity-20;
}

#priority-access.application-open .field-label {
  @apply font-regular;
}

#priority-access .priority-access-form {
  display: none;
}

#priority-access.application-open .priority-access-form {
  display: flex;
  flex-direction: column;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>

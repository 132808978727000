<template>
  <div class="main-container" id="calculator">
    <div class="max-container">
      <div class="flex flex-col items-center">
        <h2
          class="text-h3 lg:text-h2 font-semibold text-center max-w-[758px] mx-auto mb-6 sm:mb-12"
        >
          How much will you <span class="text-blue-500">save</span>?
        </h2>
        <h4
          class="text-body-1 lg:text-h4 mx-auto text-center mb-6 max-w-[652px]"
        >
          Select a typical home value to see a cost breakdown and how much you
          can save:
        </h4>

        <div class="max-w-[652px]">
          <div
            class="flex flex-nowrap items-center gap-6 sm:gap-12 justify-around text-body-1 sm:text-h4 mb-12"
          >
            <div v-for="item in homePrices" :key="item.id">
              <button
                class="calc-price-button"
                :class="{ active: activeTab === item.id }"
                @click.prevent="setActiveTab(item.id)"
              >
                {{ roundNumberToShorthandCurrency(item.name) }}
              </button>
            </div>
          </div>
        </div>

        <div class="flex flex-col lg:flex-row lg:justify-between w-full">
          <div class="w-full lg:w-[48%] rounded-32 bg-creme-50 mb-8 lg:mb-0">
            <div
              class="rounded-t-32 bg-blue-500 text-white text-body-3 lg:text-body-1 p-6"
            >
              <div class="flex flex-row">
                <div class="w-1/2">
                  <p>Price to you</p>
                  <div class="text-h3 lg:text-h2 font-semibold">
                    {{
                      roundNumberToShorthandCurrency(
                        currentHomePrice.homeFounderPrice
                      )
                    }}
                  </div>
                </div>
                <div class="w-1/2">
                  <p>Est. appraised value</p>
                  <div class="text-h3 lg:text-h2 font-semibold">
                    {{
                      roundNumberToShorthandCurrency(
                        currentHomePrice.appraisedHomeValue
                      )
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col rounded-b-32 bg-creme-50 p-6">
              <div
                class="flex flex-row justify-between text-body-2 lg:text-body-1 mb-6"
              >
                <span class="font-semibold">Land</span>
                <span class="">{{
                  currencyNoDecimal(currentHomePrice.land)
                }}</span>
              </div>
              <div
                class="flex flex-row justify-between text-body-2 lg:text-body-1 mb-6"
              >
                <span class="font-semibold">Development</span>
                <span class="">{{
                  currencyNoDecimal(currentHomePrice.landPreparation)
                }}</span>
              </div>
              <div
                class="flex flex-row justify-between text-body-2 lg:text-body-1 mb-6"
              >
                <span class="font-semibold">Home construction</span>
                <span class="">{{
                  currencyNoDecimal(currentHomePrice.construction)
                }}</span>
              </div>
              <div
                class="flex flex-row justify-between text-body-2 lg:text-body-1"
              >
                <span class="font-semibold">HomeFounder flat fee</span>
                <span class="">{{
                  currencyNoDecimal(currentHomePrice.homeFounderFee)
                }}</span>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-[48%] rounded-32 bg-creme-50">
            <div
              class="rounded-t-32 bg-yellow-500 text-body-3 lg:text-body-1 p-6"
            >
              <p>Upfront home savings</p>
              <div class="text-h3 lg:text-h2 font-semibold">
                {{ currencyNoDecimal(currentHomePrice.upfrontHomeSavings) }}
              </div>
            </div>
            <div class="flex flex-col rounded-b-32 bg-creme-50 p-6">
              <div class="flex flex-col mb-8">
                <span class="text-body-3 lg:text-body-1">
                  Monthly mortgage savings
                </span>
                <span class="calc-infoblock-number text-body-1 lg:text-h3">
                  {{ currencyNoDecimal(currentHomePrice.monthlyMortageSaving) }}
                </span>
              </div>

              <div class="flex flex-col">
                <span class="text-body-3 lg:text-body-1">
                  Total interest savings
                </span>
                <span class="calc-infoblock-number text-body-1 lg:text-h3">
                  {{ currencyNoDecimal(currentHomePrice.totalInterestSaving) }}
                  <span class="font-regular text-body-2 text-black">
                    over 30 years
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCalculatorHomePrices } from "./calculatorData";
import {
  roundNumberToShorthandCurrency,
  currencyNoDecimal,
} from "@/helpers/currencyFormaterHelper.js";
import "swiper/css/swiper.css";

export default {
  name: "Calculator",
  components: {},
  data() {
    return {
      homePrices: getCalculatorHomePrices(),
      activeTab: 1,
      currentHomePrice: {},
      item: {},
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
      },
    };
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
      this.currentHomePrice = this.homePrices.find((item) => item.id === tab);
      console.log(this.currentHomePrice);
    },
    subscribe() {
      this.$router.push({ path: "/learn-more" });
    },
    faq() {
      this.$router.push({ path: "/faq/4" });
    },
    roundNumberToShorthandCurrency,
    currencyNoDecimal,
  },
  mounted() {
    this.setActiveTab(1);
  },
  swiper() {
    return this.$refs.mySwiper.$swiper;
  },
};
</script>

<style scoped>
.calc-infoblock-number {
  @apply font-semibold text-h3;
}
.calc-main-grid {
  @apply col-span-full lg:col-span-10 lg:col-start-2 grid grid-cols-1 grid-rows-3 lg:grid-rows-2 lg:grid-cols-2 gap-8;
  grid-template-rows: repeat(1, minmax(min-content, 1fr)); /* fix this */
  grid-auto-rows: minmax(1fr, auto);
}

.calc-price-button {
  @apply px-5 py-3 sm:px-6 sm:py-4;
}

.active {
  @apply bg-yellow-500 font-semibold rounded-[20px];
}

.swiper-style {
  @apply mx-12 lg:mx-16 text-center flex justify-between items-center flex-row sm:flex-col lg:flex-row overflow-hidden;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: "";
}

.swiper-button-prev,
.swiper-button-next {
  width: 40px;
}

.swiper-button-next {
  right: 0px;
}

.swiper-button-prev {
  left: 0px;
}

.what-will-you-do {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.max-container {
  max-width: 1088px;
}
</style>

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getHouseByName = /* GraphQL */ `
  query GetHouseByName($houseName: String) {
    getHouseByName(houseName: $houseName) {
      id
      name
      isAvailable
      summary
      squareFootage
      numberBedrooms
      numberBathrooms
      price
      value
      houseImages {
        items {
          id
          src
          alt
          createdAt
          updatedAt
          customizationImagesId
          addonImagesId
          houseHouseImagesId
          houseFloorplansImagesId
        }
        nextToken
      }
      floorplansImages {
        items {
          id
          src
          alt
          createdAt
          updatedAt
          customizationImagesId
          addonImagesId
          houseHouseImagesId
          houseFloorplansImagesId
        }
        nextToken
      }
      features {
        items {
          id
          name
          description
          createdAt
          updatedAt
          houseFeaturesId
        }
        nextToken
      }
      lots {
        items {
          id
          name
          house {
            id
            name
            isAvailable
            summary
            squareFootage
            numberBedrooms
            numberBathrooms
            price
            value
            houseImages {
              items {
                id
                src
                alt
                createdAt
                updatedAt
                customizationImagesId
                addonImagesId
                houseHouseImagesId
                houseFloorplansImagesId
              }
              nextToken
            }
            floorplansImages {
              items {
                id
                src
                alt
                createdAt
                updatedAt
                customizationImagesId
                addonImagesId
                houseHouseImagesId
                houseFloorplansImagesId
              }
              nextToken
            }
            features {
              items {
                id
                name
                description
                createdAt
                updatedAt
                houseFeaturesId
              }
              nextToken
            }
            lots {
              items {
                id
                name
                house {
                  id
                  name
                  isAvailable
                  summary
                  squareFootage
                  numberBedrooms
                  numberBathrooms
                  price
                  value
                  createdAt
                  updatedAt
                  houseAllCustomizationsId
                }
                location {
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                houseLotsId
                locationLotsId
              }
              nextToken
            }
            allCustomizations {
              id
              kitchen {
                items {
                  id
                  name
                  colorCode
                  createdAt
                  updatedAt
                  allCustomizationsKitchenId
                  allCustomizationsFlooringId
                }
                nextToken
              }
              flooring {
                items {
                  id
                  name
                  colorCode
                  createdAt
                  updatedAt
                  allCustomizationsKitchenId
                  allCustomizationsFlooringId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addons {
              items {
                id
                name
                price
                value
                description
                images {
                  nextToken
                }
                createdAt
                updatedAt
                houseAddonsId
              }
              nextToken
            }
            createdAt
            updatedAt
            houseAllCustomizationsId
          }
          location {
            id
            name
            lots {
              items {
                id
                name
                house {
                  id
                  name
                  isAvailable
                  summary
                  squareFootage
                  numberBedrooms
                  numberBathrooms
                  price
                  value
                  createdAt
                  updatedAt
                  houseAllCustomizationsId
                }
                location {
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                houseLotsId
                locationLotsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          houseLotsId
          locationLotsId
        }
        nextToken
      }
      allCustomizations {
        id
        kitchen {
          items {
            id
            name
            colorCode
            images {
              items {
                id
                src
                alt
                createdAt
                updatedAt
                customizationImagesId
                addonImagesId
                houseHouseImagesId
                houseFloorplansImagesId
              }
              nextToken
            }
            createdAt
            updatedAt
            allCustomizationsKitchenId
            allCustomizationsFlooringId
          }
          nextToken
        }
        flooring {
          items {
            id
            name
            colorCode
            images {
              items {
                id
                src
                alt
                createdAt
                updatedAt
                customizationImagesId
                addonImagesId
                houseHouseImagesId
                houseFloorplansImagesId
              }
              nextToken
            }
            createdAt
            updatedAt
            allCustomizationsKitchenId
            allCustomizationsFlooringId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      addons {
        items {
          id
          name
          price
          value
          description
          images {
            items {
              id
              src
              alt
              createdAt
              updatedAt
              customizationImagesId
              addonImagesId
              houseHouseImagesId
              houseFloorplansImagesId
            }
            nextToken
          }
          createdAt
          updatedAt
          houseAddonsId
        }
        nextToken
      }
      createdAt
      updatedAt
      houseAllCustomizationsId
    }
  }
`;
export const getImage = /* GraphQL */ `
  query GetImage($id: ID!) {
    getImage(id: $id) {
      id
      src
      alt
      createdAt
      updatedAt
      customizationImagesId
      addonImagesId
      houseHouseImagesId
      houseFloorplansImagesId
    }
  }
`;
export const listImages = /* GraphQL */ `
  query ListImages(
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        src
        alt
        createdAt
        updatedAt
        customizationImagesId
        addonImagesId
        houseHouseImagesId
        houseFloorplansImagesId
      }
      nextToken
    }
  }
`;
export const getFeature = /* GraphQL */ `
  query GetFeature($id: ID!) {
    getFeature(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
      houseFeaturesId
    }
  }
`;
export const listFeatures = /* GraphQL */ `
  query ListFeatures(
    $filter: ModelFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        houseFeaturesId
      }
      nextToken
    }
  }
`;
export const getCustomization = /* GraphQL */ `
  query GetCustomization($id: ID!) {
    getCustomization(id: $id) {
      id
      name
      colorCode
      images {
        items {
          id
          src
          alt
          createdAt
          updatedAt
          customizationImagesId
          addonImagesId
          houseHouseImagesId
          houseFloorplansImagesId
        }
        nextToken
      }
      createdAt
      updatedAt
      allCustomizationsKitchenId
      allCustomizationsFlooringId
    }
  }
`;
export const listCustomizations = /* GraphQL */ `
  query ListCustomizations(
    $filter: ModelCustomizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        colorCode
        images {
          items {
            id
            src
            alt
            createdAt
            updatedAt
            customizationImagesId
            addonImagesId
            houseHouseImagesId
            houseFloorplansImagesId
          }
          nextToken
        }
        createdAt
        updatedAt
        allCustomizationsKitchenId
        allCustomizationsFlooringId
      }
      nextToken
    }
  }
`;
export const getAllCustomizations = /* GraphQL */ `
  query GetAllCustomizations($id: ID!) {
    getAllCustomizations(id: $id) {
      id
      kitchen {
        items {
          id
          name
          colorCode
          images {
            items {
              id
              src
              alt
              createdAt
              updatedAt
              customizationImagesId
              addonImagesId
              houseHouseImagesId
              houseFloorplansImagesId
            }
            nextToken
          }
          createdAt
          updatedAt
          allCustomizationsKitchenId
          allCustomizationsFlooringId
        }
        nextToken
      }
      flooring {
        items {
          id
          name
          colorCode
          images {
            items {
              id
              src
              alt
              createdAt
              updatedAt
              customizationImagesId
              addonImagesId
              houseHouseImagesId
              houseFloorplansImagesId
            }
            nextToken
          }
          createdAt
          updatedAt
          allCustomizationsKitchenId
          allCustomizationsFlooringId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAllCustomizations = /* GraphQL */ `
  query ListAllCustomizations(
    $filter: ModelAllCustomizationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAllCustomizations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        kitchen {
          items {
            id
            name
            colorCode
            images {
              items {
                id
                src
                alt
                createdAt
                updatedAt
                customizationImagesId
                addonImagesId
                houseHouseImagesId
                houseFloorplansImagesId
              }
              nextToken
            }
            createdAt
            updatedAt
            allCustomizationsKitchenId
            allCustomizationsFlooringId
          }
          nextToken
        }
        flooring {
          items {
            id
            name
            colorCode
            images {
              items {
                id
                src
                alt
                createdAt
                updatedAt
                customizationImagesId
                addonImagesId
                houseHouseImagesId
                houseFloorplansImagesId
              }
              nextToken
            }
            createdAt
            updatedAt
            allCustomizationsKitchenId
            allCustomizationsFlooringId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAddon = /* GraphQL */ `
  query GetAddon($id: ID!) {
    getAddon(id: $id) {
      id
      name
      price
      value
      description
      images {
        items {
          id
          src
          alt
          createdAt
          updatedAt
          customizationImagesId
          addonImagesId
          houseHouseImagesId
          houseFloorplansImagesId
        }
        nextToken
      }
      createdAt
      updatedAt
      houseAddonsId
    }
  }
`;
export const listAddons = /* GraphQL */ `
  query ListAddons(
    $filter: ModelAddonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        price
        value
        description
        images {
          items {
            id
            src
            alt
            createdAt
            updatedAt
            customizationImagesId
            addonImagesId
            houseHouseImagesId
            houseFloorplansImagesId
          }
          nextToken
        }
        createdAt
        updatedAt
        houseAddonsId
      }
      nextToken
    }
  }
`;
export const getHouse = /* GraphQL */ `
  query GetHouse($id: ID!) {
    getHouse(id: $id) {
      id
      name
      isAvailable
      summary
      squareFootage
      numberBedrooms
      numberBathrooms
      price
      value
      houseImages {
        items {
          id
          src
          alt
          createdAt
          updatedAt
          customizationImagesId
          addonImagesId
          houseHouseImagesId
          houseFloorplansImagesId
        }
        nextToken
      }
      floorplansImages {
        items {
          id
          src
          alt
          createdAt
          updatedAt
          customizationImagesId
          addonImagesId
          houseHouseImagesId
          houseFloorplansImagesId
        }
        nextToken
      }
      features {
        items {
          id
          name
          description
          createdAt
          updatedAt
          houseFeaturesId
        }
        nextToken
      }
      lots {
        items {
          id
          name
          house {
            id
            name
            isAvailable
            summary
            squareFootage
            numberBedrooms
            numberBathrooms
            price
            value
            houseImages {
              items {
                id
                src
                alt
                createdAt
                updatedAt
                customizationImagesId
                addonImagesId
                houseHouseImagesId
                houseFloorplansImagesId
              }
              nextToken
            }
            floorplansImages {
              items {
                id
                src
                alt
                createdAt
                updatedAt
                customizationImagesId
                addonImagesId
                houseHouseImagesId
                houseFloorplansImagesId
              }
              nextToken
            }
            features {
              items {
                id
                name
                description
                createdAt
                updatedAt
                houseFeaturesId
              }
              nextToken
            }
            lots {
              items {
                id
                name
                house {
                  id
                  name
                  isAvailable
                  summary
                  squareFootage
                  numberBedrooms
                  numberBathrooms
                  price
                  value
                  createdAt
                  updatedAt
                  houseAllCustomizationsId
                }
                location {
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                houseLotsId
                locationLotsId
              }
              nextToken
            }
            allCustomizations {
              id
              kitchen {
                items {
                  id
                  name
                  colorCode
                  createdAt
                  updatedAt
                  allCustomizationsKitchenId
                  allCustomizationsFlooringId
                }
                nextToken
              }
              flooring {
                items {
                  id
                  name
                  colorCode
                  createdAt
                  updatedAt
                  allCustomizationsKitchenId
                  allCustomizationsFlooringId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addons {
              items {
                id
                name
                price
                value
                description
                images {
                  nextToken
                }
                createdAt
                updatedAt
                houseAddonsId
              }
              nextToken
            }
            createdAt
            updatedAt
            houseAllCustomizationsId
          }
          location {
            id
            name
            lots {
              items {
                id
                name
                house {
                  id
                  name
                  isAvailable
                  summary
                  squareFootage
                  numberBedrooms
                  numberBathrooms
                  price
                  value
                  createdAt
                  updatedAt
                  houseAllCustomizationsId
                }
                location {
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                houseLotsId
                locationLotsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          houseLotsId
          locationLotsId
        }
        nextToken
      }
      allCustomizations {
        id
        kitchen {
          items {
            id
            name
            colorCode
            images {
              items {
                id
                src
                alt
                createdAt
                updatedAt
                customizationImagesId
                addonImagesId
                houseHouseImagesId
                houseFloorplansImagesId
              }
              nextToken
            }
            createdAt
            updatedAt
            allCustomizationsKitchenId
            allCustomizationsFlooringId
          }
          nextToken
        }
        flooring {
          items {
            id
            name
            colorCode
            images {
              items {
                id
                src
                alt
                createdAt
                updatedAt
                customizationImagesId
                addonImagesId
                houseHouseImagesId
                houseFloorplansImagesId
              }
              nextToken
            }
            createdAt
            updatedAt
            allCustomizationsKitchenId
            allCustomizationsFlooringId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      addons {
        items {
          id
          name
          price
          value
          description
          images {
            items {
              id
              src
              alt
              createdAt
              updatedAt
              customizationImagesId
              addonImagesId
              houseHouseImagesId
              houseFloorplansImagesId
            }
            nextToken
          }
          createdAt
          updatedAt
          houseAddonsId
        }
        nextToken
      }
      createdAt
      updatedAt
      houseAllCustomizationsId
    }
  }
`;
export const listHouses = /* GraphQL */ `
  query ListHouses(
    $filter: ModelHouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHouses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        isAvailable
        summary
        squareFootage
        numberBedrooms
        numberBathrooms
        price
        value
        houseImages {
          items {
            id
            src
            alt
            createdAt
            updatedAt
            customizationImagesId
            addonImagesId
            houseHouseImagesId
            houseFloorplansImagesId
          }
          nextToken
        }
        floorplansImages {
          items {
            id
            src
            alt
            createdAt
            updatedAt
            customizationImagesId
            addonImagesId
            houseHouseImagesId
            houseFloorplansImagesId
          }
          nextToken
        }
        features {
          items {
            id
            name
            description
            createdAt
            updatedAt
            houseFeaturesId
          }
          nextToken
        }
        lots {
          items {
            id
            name
            house {
              id
              name
              isAvailable
              summary
              squareFootage
              numberBedrooms
              numberBathrooms
              price
              value
              houseImages {
                items {
                  id
                  src
                  alt
                  createdAt
                  updatedAt
                  customizationImagesId
                  addonImagesId
                  houseHouseImagesId
                  houseFloorplansImagesId
                }
                nextToken
              }
              floorplansImages {
                items {
                  id
                  src
                  alt
                  createdAt
                  updatedAt
                  customizationImagesId
                  addonImagesId
                  houseHouseImagesId
                  houseFloorplansImagesId
                }
                nextToken
              }
              features {
                items {
                  id
                  name
                  description
                  createdAt
                  updatedAt
                  houseFeaturesId
                }
                nextToken
              }
              lots {
                items {
                  id
                  name
                  createdAt
                  updatedAt
                  houseLotsId
                  locationLotsId
                }
                nextToken
              }
              allCustomizations {
                id
                kitchen {
                  nextToken
                }
                flooring {
                  nextToken
                }
                createdAt
                updatedAt
              }
              addons {
                items {
                  id
                  name
                  price
                  value
                  description
                  createdAt
                  updatedAt
                  houseAddonsId
                }
                nextToken
              }
              createdAt
              updatedAt
              houseAllCustomizationsId
            }
            location {
              id
              name
              lots {
                items {
                  id
                  name
                  createdAt
                  updatedAt
                  houseLotsId
                  locationLotsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            houseLotsId
            locationLotsId
          }
          nextToken
        }
        allCustomizations {
          id
          kitchen {
            items {
              id
              name
              colorCode
              images {
                items {
                  id
                  src
                  alt
                  createdAt
                  updatedAt
                  customizationImagesId
                  addonImagesId
                  houseHouseImagesId
                  houseFloorplansImagesId
                }
                nextToken
              }
              createdAt
              updatedAt
              allCustomizationsKitchenId
              allCustomizationsFlooringId
            }
            nextToken
          }
          flooring {
            items {
              id
              name
              colorCode
              images {
                items {
                  id
                  src
                  alt
                  createdAt
                  updatedAt
                  customizationImagesId
                  addonImagesId
                  houseHouseImagesId
                  houseFloorplansImagesId
                }
                nextToken
              }
              createdAt
              updatedAt
              allCustomizationsKitchenId
              allCustomizationsFlooringId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        addons {
          items {
            id
            name
            price
            value
            description
            images {
              items {
                id
                src
                alt
                createdAt
                updatedAt
                customizationImagesId
                addonImagesId
                houseHouseImagesId
                houseFloorplansImagesId
              }
              nextToken
            }
            createdAt
            updatedAt
            houseAddonsId
          }
          nextToken
        }
        createdAt
        updatedAt
        houseAllCustomizationsId
      }
      nextToken
    }
  }
`;
export const getLot = /* GraphQL */ `
  query GetLot($id: ID!) {
    getLot(id: $id) {
      id
      name
      house {
        id
        name
        isAvailable
        summary
        squareFootage
        numberBedrooms
        numberBathrooms
        price
        value
        houseImages {
          items {
            id
            src
            alt
            createdAt
            updatedAt
            customizationImagesId
            addonImagesId
            houseHouseImagesId
            houseFloorplansImagesId
          }
          nextToken
        }
        floorplansImages {
          items {
            id
            src
            alt
            createdAt
            updatedAt
            customizationImagesId
            addonImagesId
            houseHouseImagesId
            houseFloorplansImagesId
          }
          nextToken
        }
        features {
          items {
            id
            name
            description
            createdAt
            updatedAt
            houseFeaturesId
          }
          nextToken
        }
        lots {
          items {
            id
            name
            house {
              id
              name
              isAvailable
              summary
              squareFootage
              numberBedrooms
              numberBathrooms
              price
              value
              houseImages {
                items {
                  id
                  src
                  alt
                  createdAt
                  updatedAt
                  customizationImagesId
                  addonImagesId
                  houseHouseImagesId
                  houseFloorplansImagesId
                }
                nextToken
              }
              floorplansImages {
                items {
                  id
                  src
                  alt
                  createdAt
                  updatedAt
                  customizationImagesId
                  addonImagesId
                  houseHouseImagesId
                  houseFloorplansImagesId
                }
                nextToken
              }
              features {
                items {
                  id
                  name
                  description
                  createdAt
                  updatedAt
                  houseFeaturesId
                }
                nextToken
              }
              lots {
                items {
                  id
                  name
                  createdAt
                  updatedAt
                  houseLotsId
                  locationLotsId
                }
                nextToken
              }
              allCustomizations {
                id
                kitchen {
                  nextToken
                }
                flooring {
                  nextToken
                }
                createdAt
                updatedAt
              }
              addons {
                items {
                  id
                  name
                  price
                  value
                  description
                  createdAt
                  updatedAt
                  houseAddonsId
                }
                nextToken
              }
              createdAt
              updatedAt
              houseAllCustomizationsId
            }
            location {
              id
              name
              lots {
                items {
                  id
                  name
                  createdAt
                  updatedAt
                  houseLotsId
                  locationLotsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            houseLotsId
            locationLotsId
          }
          nextToken
        }
        allCustomizations {
          id
          kitchen {
            items {
              id
              name
              colorCode
              images {
                items {
                  id
                  src
                  alt
                  createdAt
                  updatedAt
                  customizationImagesId
                  addonImagesId
                  houseHouseImagesId
                  houseFloorplansImagesId
                }
                nextToken
              }
              createdAt
              updatedAt
              allCustomizationsKitchenId
              allCustomizationsFlooringId
            }
            nextToken
          }
          flooring {
            items {
              id
              name
              colorCode
              images {
                items {
                  id
                  src
                  alt
                  createdAt
                  updatedAt
                  customizationImagesId
                  addonImagesId
                  houseHouseImagesId
                  houseFloorplansImagesId
                }
                nextToken
              }
              createdAt
              updatedAt
              allCustomizationsKitchenId
              allCustomizationsFlooringId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        addons {
          items {
            id
            name
            price
            value
            description
            images {
              items {
                id
                src
                alt
                createdAt
                updatedAt
                customizationImagesId
                addonImagesId
                houseHouseImagesId
                houseFloorplansImagesId
              }
              nextToken
            }
            createdAt
            updatedAt
            houseAddonsId
          }
          nextToken
        }
        createdAt
        updatedAt
        houseAllCustomizationsId
      }
      location {
        id
        name
        lots {
          items {
            id
            name
            house {
              id
              name
              isAvailable
              summary
              squareFootage
              numberBedrooms
              numberBathrooms
              price
              value
              houseImages {
                items {
                  id
                  src
                  alt
                  createdAt
                  updatedAt
                  customizationImagesId
                  addonImagesId
                  houseHouseImagesId
                  houseFloorplansImagesId
                }
                nextToken
              }
              floorplansImages {
                items {
                  id
                  src
                  alt
                  createdAt
                  updatedAt
                  customizationImagesId
                  addonImagesId
                  houseHouseImagesId
                  houseFloorplansImagesId
                }
                nextToken
              }
              features {
                items {
                  id
                  name
                  description
                  createdAt
                  updatedAt
                  houseFeaturesId
                }
                nextToken
              }
              lots {
                items {
                  id
                  name
                  createdAt
                  updatedAt
                  houseLotsId
                  locationLotsId
                }
                nextToken
              }
              allCustomizations {
                id
                kitchen {
                  nextToken
                }
                flooring {
                  nextToken
                }
                createdAt
                updatedAt
              }
              addons {
                items {
                  id
                  name
                  price
                  value
                  description
                  createdAt
                  updatedAt
                  houseAddonsId
                }
                nextToken
              }
              createdAt
              updatedAt
              houseAllCustomizationsId
            }
            location {
              id
              name
              lots {
                items {
                  id
                  name
                  createdAt
                  updatedAt
                  houseLotsId
                  locationLotsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            houseLotsId
            locationLotsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      houseLotsId
      locationLotsId
    }
  }
`;
export const listLots = /* GraphQL */ `
  query ListLots(
    $filter: ModelLotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        house {
          id
          name
          isAvailable
          summary
          squareFootage
          numberBedrooms
          numberBathrooms
          price
          value
          houseImages {
            items {
              id
              src
              alt
              createdAt
              updatedAt
              customizationImagesId
              addonImagesId
              houseHouseImagesId
              houseFloorplansImagesId
            }
            nextToken
          }
          floorplansImages {
            items {
              id
              src
              alt
              createdAt
              updatedAt
              customizationImagesId
              addonImagesId
              houseHouseImagesId
              houseFloorplansImagesId
            }
            nextToken
          }
          features {
            items {
              id
              name
              description
              createdAt
              updatedAt
              houseFeaturesId
            }
            nextToken
          }
          lots {
            items {
              id
              name
              house {
                id
                name
                isAvailable
                summary
                squareFootage
                numberBedrooms
                numberBathrooms
                price
                value
                houseImages {
                  nextToken
                }
                floorplansImages {
                  nextToken
                }
                features {
                  nextToken
                }
                lots {
                  nextToken
                }
                allCustomizations {
                  id
                  createdAt
                  updatedAt
                }
                addons {
                  nextToken
                }
                createdAt
                updatedAt
                houseAllCustomizationsId
              }
              location {
                id
                name
                lots {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              houseLotsId
              locationLotsId
            }
            nextToken
          }
          allCustomizations {
            id
            kitchen {
              items {
                id
                name
                colorCode
                images {
                  nextToken
                }
                createdAt
                updatedAt
                allCustomizationsKitchenId
                allCustomizationsFlooringId
              }
              nextToken
            }
            flooring {
              items {
                id
                name
                colorCode
                images {
                  nextToken
                }
                createdAt
                updatedAt
                allCustomizationsKitchenId
                allCustomizationsFlooringId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          addons {
            items {
              id
              name
              price
              value
              description
              images {
                items {
                  id
                  src
                  alt
                  createdAt
                  updatedAt
                  customizationImagesId
                  addonImagesId
                  houseHouseImagesId
                  houseFloorplansImagesId
                }
                nextToken
              }
              createdAt
              updatedAt
              houseAddonsId
            }
            nextToken
          }
          createdAt
          updatedAt
          houseAllCustomizationsId
        }
        location {
          id
          name
          lots {
            items {
              id
              name
              house {
                id
                name
                isAvailable
                summary
                squareFootage
                numberBedrooms
                numberBathrooms
                price
                value
                houseImages {
                  nextToken
                }
                floorplansImages {
                  nextToken
                }
                features {
                  nextToken
                }
                lots {
                  nextToken
                }
                allCustomizations {
                  id
                  createdAt
                  updatedAt
                }
                addons {
                  nextToken
                }
                createdAt
                updatedAt
                houseAllCustomizationsId
              }
              location {
                id
                name
                lots {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              houseLotsId
              locationLotsId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        houseLotsId
        locationLotsId
      }
      nextToken
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      name
      lots {
        items {
          id
          name
          house {
            id
            name
            isAvailable
            summary
            squareFootage
            numberBedrooms
            numberBathrooms
            price
            value
            houseImages {
              items {
                id
                src
                alt
                createdAt
                updatedAt
                customizationImagesId
                addonImagesId
                houseHouseImagesId
                houseFloorplansImagesId
              }
              nextToken
            }
            floorplansImages {
              items {
                id
                src
                alt
                createdAt
                updatedAt
                customizationImagesId
                addonImagesId
                houseHouseImagesId
                houseFloorplansImagesId
              }
              nextToken
            }
            features {
              items {
                id
                name
                description
                createdAt
                updatedAt
                houseFeaturesId
              }
              nextToken
            }
            lots {
              items {
                id
                name
                house {
                  id
                  name
                  isAvailable
                  summary
                  squareFootage
                  numberBedrooms
                  numberBathrooms
                  price
                  value
                  createdAt
                  updatedAt
                  houseAllCustomizationsId
                }
                location {
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                houseLotsId
                locationLotsId
              }
              nextToken
            }
            allCustomizations {
              id
              kitchen {
                items {
                  id
                  name
                  colorCode
                  createdAt
                  updatedAt
                  allCustomizationsKitchenId
                  allCustomizationsFlooringId
                }
                nextToken
              }
              flooring {
                items {
                  id
                  name
                  colorCode
                  createdAt
                  updatedAt
                  allCustomizationsKitchenId
                  allCustomizationsFlooringId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            addons {
              items {
                id
                name
                price
                value
                description
                images {
                  nextToken
                }
                createdAt
                updatedAt
                houseAddonsId
              }
              nextToken
            }
            createdAt
            updatedAt
            houseAllCustomizationsId
          }
          location {
            id
            name
            lots {
              items {
                id
                name
                house {
                  id
                  name
                  isAvailable
                  summary
                  squareFootage
                  numberBedrooms
                  numberBathrooms
                  price
                  value
                  createdAt
                  updatedAt
                  houseAllCustomizationsId
                }
                location {
                  id
                  name
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                houseLotsId
                locationLotsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          houseLotsId
          locationLotsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        lots {
          items {
            id
            name
            house {
              id
              name
              isAvailable
              summary
              squareFootage
              numberBedrooms
              numberBathrooms
              price
              value
              houseImages {
                items {
                  id
                  src
                  alt
                  createdAt
                  updatedAt
                  customizationImagesId
                  addonImagesId
                  houseHouseImagesId
                  houseFloorplansImagesId
                }
                nextToken
              }
              floorplansImages {
                items {
                  id
                  src
                  alt
                  createdAt
                  updatedAt
                  customizationImagesId
                  addonImagesId
                  houseHouseImagesId
                  houseFloorplansImagesId
                }
                nextToken
              }
              features {
                items {
                  id
                  name
                  description
                  createdAt
                  updatedAt
                  houseFeaturesId
                }
                nextToken
              }
              lots {
                items {
                  id
                  name
                  createdAt
                  updatedAt
                  houseLotsId
                  locationLotsId
                }
                nextToken
              }
              allCustomizations {
                id
                kitchen {
                  nextToken
                }
                flooring {
                  nextToken
                }
                createdAt
                updatedAt
              }
              addons {
                items {
                  id
                  name
                  price
                  value
                  description
                  createdAt
                  updatedAt
                  houseAddonsId
                }
                nextToken
              }
              createdAt
              updatedAt
              houseAllCustomizationsId
            }
            location {
              id
              name
              lots {
                items {
                  id
                  name
                  createdAt
                  updatedAt
                  houseLotsId
                  locationLotsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            houseLotsId
            locationLotsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

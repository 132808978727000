<template>
  <div class="text-h3 font-semibold mb-8 text-black">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: "BuilderHeader",
  props: {
    title: {
      type: String,
      default: "Choose your lot",
    },
  },
};
</script>

<template>
  <div class="flex flex-col items-center space-y-6 mx-8">
    <NavigationCta
      fixedSize="small"
      text="Dashboard"
      startImage="icons/dashboard.svg"
      :isSelected="isDashboardSelected"
      @click="navigateItem('dashboard')"
    />
    <NavigationCta
      fixedSize="small"
      text="My home"
      startImage="icons/nav_home.svg"
      :isSelected="isMyHomeSelected"
      @click="navigateItem('builder')"
    />
    <div
      class="flex flex-row w-full border-t border-gray-900 border-opacity-20 h-1"
    >
      &nbsp;
    </div>

    <div class="group w-full cursor-pointer" @click="toggleHelp()">
      <div class="flex flex-row w-full items-center justify-between">
        <span class="nav-right-text">Help</span>
        <img
          :class="[isHelpOpen ? 'rotate-180' : '']"
          class="transition-all duration-200 ease-in-out"
          :src="require('@/assets/icons/chevron_down_sm.svg')"
        />
      </div>
      <div v-if="isHelpOpen" class="help">
        <div @click="navigateItem('/how-it-works')">
          <span class="nav-right-text">How it works</span>
          <img :src="require('@/assets/icons/chevron_right_sm.svg')" />
        </div>
        <div @click="navigateItem('/faq')">
          <span class="nav-right-text">FAQ</span>
          <img :src="require('@/assets/icons/chevron_right_sm.svg')" />
        </div>
      </div>
    </div>
    <div
      class="flex flex-row w-full border-t border-gray-900 border-opacity-20 h-1"
    >
      &nbsp;
    </div>
    <div class="group w-full cursor-pointer" @click="toggleAccount()">
      <div class="flex flex-row w-full items-center justify-between">
        <span class="nav-right-text">Account</span>
        <img
          :class="[isAccountOpen ? 'rotate-180' : '']"
          class="transition-all duration-200 ease-in-out"
          :src="require('@/assets/icons/chevron_down_sm.svg')"
        />
      </div>
      <div v-if="isAccountOpen" class="flex flex-col">
        <div
          @click="navigateItem('/dashboard')"
          class="flex flex-row justify-between mt-4 mx-4 cursor-pointer"
        >
          <span class="nav-right-text">Dashboard</span>
          <img :src="require('@/assets/icons/chevron_right_sm.svg')" />
        </div>
        <div class="flex flex-row w-full items-center justify-center mt-6">
          <PrimaryBaseCta
            text="Log out"
            fixedSize="small"
            type="secondary"
            class="w-full"
            @click="logout()"
          />
        </div>
      </div>
    </div>
    <div
      class="flex flex-row w-full border-t border-gray-900 border-opacity-20 h-1"
    >
      &nbsp;
    </div>
  </div>
</template>
<script>
import NavigationCta from "@/components/buttons/NavigationCta.vue";
import PrimaryBaseCta from "@/components/buttons/PrimaryBaseCta.vue";
import { Auth } from "aws-amplify";
import { isSignedIn } from "@/store/useAuthStore.js";
export default {
  name: "NavMobileApp",
  components: {
    NavigationCta,
    PrimaryBaseCta,
  },
  data() {
    return {
      isHelpOpen: false,
      isAccountOpen: false,
    };
  },
  methods: {
    toggleHelp() {
      this.isHelpOpen = !this.isHelpOpen;
    },
    toggleAccount() {
      this.isAccountOpen = !this.isAccountOpen;
    },
    navigateItem(slug) {
      const _this = this;
      setTimeout(() => {
        _this.isHelpOpen = false;
        _this.isAccountOpen = false;
        _this.closeMobile();

        _this.$router.push({
          path: `${slug}`,
        });
      }, 100);
    },
    closeMobile() {
      this.$emit("closeMobile");
    },
    async logout() {
      try {
        await Auth.signOut();
        this.$router.push({ name: "Home" });
      } catch (err) {
        console.error(`Error logging out ${err.message}`);
      }
    },
  },
  computed: {
    isUserSignedIn() {
      return isSignedIn;
    },
    isDashboardSelected() {
      return this.$navActions.isDashboardSelected();
    },
    isMyHomeSelected() {
      return this.$navActions.isMyHomeSelected();
    },
  },
};
</script>
<style scoped>
.nav-right-text {
  @apply text-gray-900 font-semibold text-body-2 cursor-pointer hover:text-blue-500;
}
.login-btn {
  @apply border border-gray-900 rounded-12 text-blue-500 font-semibold text-body-2 bg-creme-200 text-center cursor-pointer;
}
.help {
  @apply flex flex-col overflow-hidden;
}
.help div {
  @apply flex flex-row justify-between mt-4 cursor-pointer mx-4;
}
</style>

<template>
  <div class="w-full relative">
    <div
      class="rounded-8 flex flex-row border-t border-l border-r border-checkbox border-b border-gray-500 lg:p-4 px-4 py-3 cursor-pointer transition-all duration-200 ease-in-out"
      :class="[
        isOpen ? 'bg-creme-50' : 'bg-creme-200',
        invalid ? 'invalid' : '',
      ]"
      @click="toggle"
    >
      <div class="flex flex-row w-full items-center justify-between">
        <div v-if="selections.length === 0" class="text-body-2 text-gray-500">
          {{ placeholder }}
        </div>
        <div v-else class="text-body-2 text-gray-900">
          {{ getSelections }}
        </div>
        <img
          class="transition-transform fill-blue-500"
          src="@/assets/ico_arrow_down.svg"
          :class="{ 'rotate-180': isOpen }"
        />
      </div>
    </div>

    <div
      class="open transition-all duration-200 ease-in-out absolute"
      :class="[
        isOpen ? 'rounded-b-8 border border-gray-500 w-full h-auto' : 'h-0',
      ]"
    >
      <div class="flex mt-4">
        <div class="my-4 pl-6 w-1/2 flex flex-row items-center">
          <Checkbox :ref="all" @input="selectPerimeter(all)" />
          <div
            class="text-gray-900 text-body-2 pl-2 flex items-center flex-grow"
            @click="selectPerimeter(all)"
          >
            All Atlanta Locations
          </div>
        </div>

        <div class="my-4 pl-6 w-1/2 flex flex-row items-center">
          <Checkbox :ref="outside" @input="outsideAtlanta()" />
          <div
            class="text-gray-900 text-body-2 pl-2 flex items-center flex-grow"
            @click="outsideAtlanta()"
          >
            Outside of Atlanta
          </div>
        </div>
      </div>

      <div class="open-wrapper">
        <div class="ml-6 my-4 w-full" v-for="(c, i) in choices" :key="i">
          <div class="text-gray-900 font-extrabold text-body-2 mb-6">
            {{ c.name }}
          </div>
          <div class="flex flex-wrap relative">
            <div
              v-for="(v, i) in c.values"
              :key="i"
              class="flex flex-row py-2 min-w-[50%]"
            >
              <Checkbox
                ref="chk-item"
                :class="
                  c.name.toLowerCase().includes(outside) ? outside : inside
                "
                :id="JSON.stringify(v)"
                @input="checkboxInput(v, true)"
              />
              <div
                class="text-gray-900 text-body-2 pl-4 flex items-center flex-grow"
                @click="checkboxInput(v, true)"
              >
                {{ v.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="text-blue-500 font-extrabold text-body-2 p-4 text-center bg-blue-100 cursor-pointer"
        @click="toggle"
      >
        Done {{ getSelectionsCount }}
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/inputs/Checkbox.vue";

export default {
  name: "LocationsDropdown",
  components: {
    Checkbox,
  },
  props: {
    choices: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "Choose",
    },
    invalid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inside: "inside",
      outside: "outside",
      all: "all",
      isOpen: false,
      selections: [],
      visibleSelections: [],
      listener: null,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    checkboxInput(v, clicked) {
      // If selection already exists, just untick it
      if (this.selections.indexOf(v.value) > -1) {
        this.selections.splice(this.selections.indexOf(v.value), 1);
        this.visibleSelections.splice(
          this.visibleSelections.indexOf(v.name),
          1
        );
        this.$refs["chk-item"].forEach((r) => {
          // Reset all checkboxes except the one we clicked on now
          if (r.$el.id.includes(v.value)) {
            r.$data.checked = false;
          }
        });
      } else {
        if (clicked) {
          if (this.$refs["outside"].$data.checked) {
            this.visibleSelections = [];
            this.selections = [];
            this.$refs["outside"].$data.checked = false;
          }
        }
        this.selections.push(v.value);
        this.visibleSelections.push(v.name);
        this.$refs["chk-item"].forEach((r) => {
          // Reset all checkboxes except the one we clicked on now
          if (r.$el.id.includes(v.value)) {
            r.$data.checked = true;
          }
        });
      }

      this.$emit("input", this.selections.join(";"));
    },
    outsideAtlanta() {
      this.$refs["all"].$data.checked = false;
      this.$refs["chk-item"].forEach((checkbox) => {
        checkbox.$data.checked = false;
      });
      if (this.selections.indexOf("Outside of Atlanta") == -1) {
        this.$refs["outside"].$data.checked = true;
      } else {
        this.$refs["outside"].$data.checked = false;
      }
      this.visibleSelections = [];
      this.selections = [];
      if (this.$refs["outside"].$data.checked) {
        this.selections.push("Outside of Atlanta");
        this.visibleSelections.push("Outside of Atlanta");
      }
      this.$emit("input", this.selections.join(";"));
    },
    selectPerimeter(perimiter) {
      const shouldCheck =
        this.$refs["outside"].$data.checked || this.selections.length != 38;

      this.resetCheckboxes(perimiter);

      this.$refs["chk-item"].forEach((checkbox) => {
        // If perimiter is "inside" or "outside"
        if (
          checkbox.$data.checked !== shouldCheck &&
          [...checkbox.$el.classList].includes(perimiter)
        ) {
          checkbox.$data.checked = shouldCheck;
          this.checkboxInput(JSON.parse(checkbox.$el.id));
        }

        // If all checkboxes have to be selected
        if (checkbox.$data.checked !== shouldCheck && perimiter === this.all) {
          checkbox.$data.checked = shouldCheck;
          this.checkboxInput(JSON.parse(checkbox.$el.id));
        }
      });

      this.$refs[perimiter].$data.checked = shouldCheck;
      this.$refs["outside"].$data.checked = false;

      this.$emit("input", this.selections.join(";"));
    },
    resetCheckboxes(perimiter) {
      const allLocationsRef = this.$refs[this.all];
      const outsideLocationsRef = this.$refs[this.outside];

      // If one perimiter choice is already selected, deselect it
      if (perimiter === this.all && outsideLocationsRef.$data.checked) {
        outsideLocationsRef.$data.checked = false;
      }
      if (perimiter === this.outside && allLocationsRef.$data.checked) {
        allLocationsRef.$data.checked = false;
      }

      this.$refs["chk-item"].forEach(
        (checkbox) => (checkbox.$data.checked = false)
      );
      this.visibleSelections = [];
      this.selections = [];
    },
    getTotalCount() {
      let count = 0;
      this.choices.forEach((c) => {
        count += c.values.length;
      });
      return count;
    },
  },
  computed: {
    getSelections() {
      if (this.visibleSelections.length <= 3) {
        return this.visibleSelections.join(", ");
      } else {
        return `${this.visibleSelections.length} locations selected`;
      }
    },
    getSelectionsCount() {
      if (this.visibleSelections.length > 0) {
        return `(${this.visibleSelections.length} selected)`;
      }
      return "";
    },
  },
  created() {
    this.listener = (e) => {
      if (!this.$el.contains(e.target)) {
        if (this.isOpen) {
          this.toggle();
        }
      }
    };
    window.addEventListener("click", this.listener);
  },
  beforeDestroy() {
    if (this.listener !== null) {
      window.removeEventListener("click", this.listener);
    }
  },
};
</script>
<style scoped>
.open {
  z-index: 100;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  @apply flex flex-col -mt-1 overflow-y-scroll overflow-x-hidden bg-creme-200;
}
.open-wrapper {
  @apply flex flex-col lg:flex-row;
}
</style>

<template>
  <label class="input-container-base">
    <textarea
      class="text-area"
      :placeholder="placeholder"
      :value="value"
      @input="updateValue($event.target.value)"
    />
  </label>
</template>

<script>
export default {
  name: "TextArea",
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  methods: {
    updateValue(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style scoped>
.input-container-base {
  @apply relative flex flex-row items-center justify-center border border-gray-500 rounded-8 px-5 py-4 focus-within:bg-creme-100;
}
.text-area {
  @apply block w-full rounded-8 border-0 bg-transparent placeholder-gray-500 text-body-2 text-gray-900 focus:bg-creme-100 focus:outline-none appearance-none focus:ring-transparent;
}
</style>

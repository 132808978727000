var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"xl:sticky xl:top-16"},[_c('div',{staticClass:"flex xl:flex-col flex-row overflow-scroll xl:px-4 xl:max-w-[340px] smooth-scroll-n-hide edge-to-edge-mobile"},[_c('p',{staticClass:"hidden xl:block text-black text-h3 font-semibold text-center mb-8"},[_vm._v(" Design your home ")]),_c('div',{staticClass:"summary-section ml-0",class:[
        _vm.currentHighlight === _vm.BUILDER_COMPONENTS_IDS.HOME_MODEL
          ? 'bg-creme-300'
          : '',
        _vm.BUILDER_COMPONENTS_IDS.HOME_MODEL ],on:{"click":function($event){return _vm.scrollToComponent(_vm.BUILDER_COMPONENTS_IDS.HOME_MODEL)}}},[_c('div',{staticClass:"whitespace-nowrap my-auto p-1 xl:p-2",class:[_vm.home === 'Not selected' ? '' : 'font-semibold']},[_vm._v(" Home Model ")]),_c('SummaryChip',{class:[
          _vm.home === 'Not selected'
            ? 'bg-creme-200 text-gray-500'
            : 'bg-yellow-500' ],attrs:{"text":_vm.home}})],1),_c('div',{staticClass:"summary-section",class:[
        _vm.currentHighlight === _vm.BUILDER_COMPONENTS_IDS.LOT_MAP
          ? 'bg-creme-300'
          : '',
        _vm.BUILDER_COMPONENTS_IDS.LOT_MAP ],on:{"click":function($event){return _vm.scrollToComponent(_vm.BUILDER_COMPONENTS_IDS.LOT_MAP)}}},[_c('div',{staticClass:"whitespace-nowrap my-auto p-1 xl:p-2",class:[_vm.lot === 'Not selected' ? '' : 'font-semibold']},[_vm._v(" Lot ")]),_c('SummaryChip',{class:[
          _vm.lot === 'Not selected'
            ? 'bg-creme-200 text-gray-500'
            : 'bg-yellow-500' ],attrs:{"text":_vm.lot}})],1),_c('div',{staticClass:"summary-section",class:[
        _vm.currentHighlight === _vm.BUILDER_COMPONENTS_IDS.KITCHEN_COLORS
          ? 'bg-creme-300'
          : '',
        _vm.BUILDER_COMPONENTS_IDS.KITCHEN_COLORS ],on:{"click":function($event){return _vm.scrollToComponent(_vm.BUILDER_COMPONENTS_IDS.KITCHEN_COLORS)}}},[_c('div',{staticClass:"whitespace-nowrap my-auto p-1 xl:p-2",class:[_vm.kitchen.text === 'Not selected' ? '' : 'font-semibold']},[_vm._v(" Kitchen Colors ")]),_c('SummaryChip',{class:[
          _vm.kitchen.text === 'Not selected'
            ? 'bg-creme-200 text-gray-500'
            : 'bg-yellow-500' ],attrs:{"text":_vm.kitchen.text,"color":_vm.kitchen.color}})],1),_c('div',{staticClass:"summary-section",class:[
        _vm.currentHighlight === _vm.BUILDER_COMPONENTS_IDS.FLOORING_COLORS
          ? 'bg-creme-300'
          : '',
        _vm.BUILDER_COMPONENTS_IDS.FLOORING_COLORS ],on:{"click":function($event){return _vm.scrollToComponent(_vm.BUILDER_COMPONENTS_IDS.FLOORING_COLORS)}}},[_c('div',{staticClass:"whitespace-nowrap my-auto p-1 xl:p-2",class:[_vm.flooring.text === 'Not selected' ? '' : 'font-semibold']},[_vm._v(" Flooring Colors ")]),_c('SummaryChip',{class:[
          _vm.flooring.text === 'Not selected'
            ? 'bg-creme-200 text-gray-500'
            : 'bg-yellow-500' ],attrs:{"text":_vm.flooring.text,"color":_vm.flooring.color}})],1),_c('div',{staticClass:"summary-section",class:[
        _vm.currentHighlight === _vm.BUILDER_COMPONENTS_IDS.ADDONS
          ? 'bg-creme-300'
          : '',
        _vm.BUILDER_COMPONENTS_IDS.ADDONS ],on:{"click":function($event){return _vm.scrollToComponent(_vm.BUILDER_COMPONENTS_IDS.ADDONS)}}},[_c('div',{staticClass:"whitespace-nowrap my-auto p-1 xl:p-2",class:[_vm.addons.length === 0 ? '' : 'font-semibold']},[_vm._v(" Add-ons ")]),_c('SummaryChip',{class:[
          _vm.addons.length === 0
            ? 'bg-creme-200 text-gray-500'
            : 'bg-yellow-500' ],attrs:{"text":((_vm.addons.length ? _vm.addons.length : 'Not') + " selected")}})],1),_c('hr',{staticClass:"hidden xl:block border-black border-opacity-10 text-center my-8"}),(_vm.basePrice)?_c('SummaryFooter',{attrs:{"home_price":_vm.$currencyNoDecimal.format(_vm.basePrice, 0),"home_value":_vm.$currencyNoDecimal.format(_vm.basePrice, 0),"savings":_vm.$currencyNoDecimal.format(_vm.basePrice - _vm.basePrice, 0)}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
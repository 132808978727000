<template>
  <div class="w-full flex flex-col">
    <div class="text-h4 font-semibold text-gray-900 pb-8">Next Step</div>

    <div v-if="didPreApprovalFail">
      <PreApprovalError />
    </div>
    <div v-else class="flex flex-col">
      <!-- Pick home and lot -->
      <NextStepSlot
        :class="[
          isPreRelease ? 'order-2' : '',
          !isPreRelease && !isPreApproved && !isHomeSelectedAgg
            ? 'order-1'
            : '',
          !isPreRelease && isPreApproved ? 'order-2' : '',
          !isPreRelease && isPreApproved && !isHomeSelectedAgg ? 'order-1' : '',
        ]"
      >
        <template v-slot:timelineCircle>
          <div
            class="timeline-circle"
            :class="[isHomeSelectedAgg ? 'bg-yellow-500' : '']"
          >
            <div
              class="timeline-selected-circle"
              v-if="!isHomeSelectedAgg && !isPreRelease"
            ></div>
            <div
              class="timeline-completed"
              v-if="isHomeSelectedAgg && !isPreRelease"
            ></div>
          </div>
        </template>
        <template v-slot:timelineContent>
          <div v-if="!isPreRelease && !isHomeSelectedAgg">
            <div class="timeline-title">
              Next: Explore and pick your home and lot
            </div>
            <div
              class="mt-4 text-gray-900 text-body-3 font-regular xl:w-full w-1/2"
            >
              Explore available lots, home models and see your potential
              savings. Start customizing your future home.
            </div>
            <PrimaryBaseCta
              class="mt-4 xl:w-full w-[fit-content]"
              text="Explore Homes"
              fixedSize="small"
              endImage="ico_circle_arrow_white.svg"
            />
          </div>
          <div
            v-else-if="!isPreRelease && isHomeSelectedAgg"
            class="timeline-title-inactive"
          >
            Pick your home and lot
          </div>
          <div v-else>
            <div>Pick your home and lot</div>
            <div class="font-semibold text-gray-900 text-body-3">
              Upcoming: {{ upcomingReleaseDate }}
            </div>
          </div>
        </template>
      </NextStepSlot>

      <!-- Pre-Approval -->
      <NextStepSlot
        :class="[
          isPreRelease ? 'order-1' : '',
          !isPreRelease && !isPreApproved && !isHomeSelectedAgg
            ? 'order-2'
            : '',
          isPreRelease && isPreApproved ? 'order-1' : '',
          !isPreRelease && isPreApproved && isHomeSelectedAgg ? 'order-2' : '',
        ]"
      >
        <template v-slot:timelineCircle>
          <div
            class="timeline-circle"
            :class="[isPreApproved ? 'bg-yellow-500' : '']"
          >
            <div
              class="timeline-selected-circle"
              v-if="
                (!isPreRelease && !isPreApproved) ||
                (!isPreApproved && isHomeSelected) ||
                (isPreRelease && !isPreApproved)
              "
            ></div>
            <div class="timeline-completed" v-if="isPreApproved"></div>
          </div>
        </template>
        <template v-slot:timelineContent>
          <div v-if="!isPreApproved">
            <div class="timeline-title" v-if="!isPreRelease && !isPreApproved">
              Get pre-approved
            </div>
            <div class="timeline-title" v-if="isPreRelease">
              Next: Get pre-approved
            </div>
            <div
              v-if="
                isPreRelease &&
                !isPreApproved &&
                (isFinancingSoftQualStarted || isFinancingSoftQualPassed)
              "
            >
              <div
                class="mt-4 text-gray-900 text-body-3 font-regular xl:w-full w-1/2"
                v-if="isFinancingSoftQualStarted"
              >
                Prepare and get ahead of the line before the release. Go through
                a quick pre-approval process with our financial partner.
              </div>
              <div
                class="mt-4 text-gray-900 text-body-3 font-regular xl:w-full w-1/2"
                v-else
              >
                Note that it might take a few hours for your approval status to
                change after you submit your documents with financial partner
              </div>
              <PrimaryBaseCta
                class="mt-4 xl:w-full w-[fit-content]"
                text="Pre-approval: In progress"
                startImage="icons/clock_white.png"
                fixedSize="small"
              />
              <div
                class="mt-3 bg-creme-300 px-4 py-3 rounded-8 w-[fit-content] text-body-3 text-gray-900"
                v-if="isFinancingSoftQualStarted"
              >
                <div class="font-bold">TODO - Get time of meeting</div>
                <div class="pt-1">Upcoming time</div>
              </div>
            </div>
            <div
              v-if="
                !isPreApproved &&
                !isFinancingSoftQualStarted &&
                !isFinancingSoftQualPassed
              "
            >
              <div
                class="mt-3 bg-creme-300 px-2 py-1 rounded-8 w-[fit-content] text-body-3 text-gray-900 font-bold"
              >
                {{ startPreApprovalDate }}
              </div>
              <div
                class="mt-4 text-gray-900 text-body-3 font-regular xl:w-full w-1/2"
              >
                Prepare and get ahead of the line before the release. Go through
                a quick pre-approval process with our financial partner.
              </div>
              <PrimaryBaseCta
                class="mt-4 xl:w-full w-[fit-content]"
                text="Get pre-approved"
                fixedSize="small"
                :endImage="
                  !isPreRelease && !isHomeSelectedAgg
                    ? 'ico_circle_arrow.svg'
                    : 'ico_circle_arrow_white.svg'
                "
                :type="
                  !isPreRelease && !isHomeSelectedAgg ? 'secondary' : 'primary'
                "
              />
            </div>
          </div>
          <div v-else>
            <div class="timeline-title-inactive">Get pre-approved</div>
            <div
              class="text-body-3 text-gray-900 font-regular pt-3"
              v-if="isPreRelease"
            >
              Congratulations, you are pre-approved! Once homes and lots become
              avilable in {{ lotsAvailableDate }} you will be able to
              immediately reserve on the day of release, getting ahead of the
              line.
            </div>
          </div>
        </template>
      </NextStepSlot>

      <!-- Reservation -->
      <NextStepSlot class="order-5">
        <template v-slot:timelineCircle>
          <div
            class="timeline-circle"
            :class="[
              !isPreRelease && isPreApproved && isHomeReservedAgg
                ? 'bg-yellow-500'
                : '',
            ]"
          >
            <div
              class="timeline-selected-circle"
              v-if="
                !isPreRelease &&
                isPreApproved &&
                isHomeSelected &&
                !isHomeReservedAgg
              "
            ></div>
            <div
              class="timeline-completed"
              v-if="!isPreRelease && isPreApproved && isHomeReservedAgg"
            ></div>
          </div>
        </template>
        <template v-slot:timelineContent>
          <div
            v-if="
              !isPreRelease &&
              isPreApproved &&
              isHomeSelectedAgg &&
              !isHomeReservedAgg
            "
          >
            <div class="timeline-title">Finalize picks and reserve</div>
            <div
              class="mt-3 bg-creme-300 px-2 py-1 rounded-8 w-[fit-content] text-body-3 text-gray-900 font-bold"
            >
              Reserve by {{ reserveDate }}
            </div>
            <div
              class="mt-4 text-gray-900 text-body-3 font-regular xl:w-full w-1/2"
            >
              Review your home and lot picks and reserve. You will have 48 hours
              to review and sign Purchase and Sale agreement
            </div>
            <PrimaryBaseCta
              class="mt-4 xl:w-full w-[fit-content]"
              text="Review and reserve"
              fixedSize="small"
              endImage="ico_circle_arrow_white.svg"
            />
          </div>
          <div
            v-else-if="
              !isPreRelease &&
              isPreApproved &&
              isHomeSelectedAgg &&
              isHomeReservedAgg
            "
          >
            <div class="timeline-title-inactive">
              Finalize picks and reserve
            </div>
          </div>
          <div v-else>
            <div class="timeline-title-inactive">Review and Reserve</div>
          </div>
        </template>
      </NextStepSlot>

      <!-- Sign P&S -->
      <NextStepSlot class="order-5">
        <template v-slot:timelineCircle>
          <div
            class="timeline-circle"
            :class="[
              !isPreRelease &&
              isPreApproved &&
              isHomeReservedAgg &&
              isReadyForPandSAgg &&
              isHomeUnderContractAgg
                ? 'bg-yellow-500'
                : '',
            ]"
          >
            <div
              class="timeline-selected-circle"
              v-if="
                !isPreRelease &&
                isPreApproved &&
                isHomeReservedAgg &&
                isReadyForPandSAgg &&
                !isHomeUnderContractAgg
              "
            ></div>
            <div
              class="timeline-completed"
              v-if="
                !isPreRelease &&
                isPreApproved &&
                isHomeReservedAgg &&
                isReadyForPandSAgg &&
                isHomeUnderContractAgg
              "
            ></div>
          </div>
        </template>
        <template v-slot:timelineContent>
          <div
            v-if="
              !isPreRelease &&
              isPreApproved &&
              isHomeReservedAgg &&
              isReadyForPandSAgg &&
              !isHomeUnderContractAgg
            "
          >
            <div class="timeline-title">Sign Purchase &amp; Sale agreement</div>
            <div
              class="mt-3 bg-creme-300 px-2 py-1 rounded-8 w-[fit-content] text-body-3 text-gray-900 font-bold"
            >
              Sign by TODO_CALCULATE_DATE_BASED_ON_DEAL
            </div>
            <div
              class="mt-4 text-gray-900 text-body-3 font-regular xl:w-full w-1/2"
            >
              You have 48 hours to review and sign Purchase and Sale agreement.
              Please upload relevant documents by
              TODO_CALCULATE_DATE_BASED_ON_DEAL to keep your reservation.
            </div>
            <PrimaryBaseCta
              class="mt-4 xl:w-full w-[fit-content]"
              text="Sign P&amp;S agreement"
              fixedSize="small"
              endImage="ico_circle_arrow_white.svg"
            />
          </div>
          <div v-else>
            <div class="timeline-title-inactive">
              Sign Purchase &amp; Sale agreement
            </div>
          </div>
        </template>
      </NextStepSlot>

      <!-- Secure financing -->
      <NextStepSlot class="order-5">
        <template v-slot:timelineCircle>
          <div
            class="timeline-circle"
            :class="[
              !isPreRelease &&
              isPreApproved &&
              isHomeReservedAgg &&
              isReadyForPandSAgg &&
              isHomeUnderContractAgg &&
              isFinancingFinalApprovalPassed
                ? 'bg-yellow-500'
                : '',
            ]"
          >
            <div
              class="timeline-selected-circle"
              v-if="
                !isPreRelease &&
                isPreApproved &&
                isHomeReservedAgg &&
                isReadyForPandSAgg &&
                isHomeUnderContractAgg &&
                !isFinancingFinalApprovalPassed
              "
            ></div>
            <div
              class="timeline-completed"
              v-if="
                !isPreRelease &&
                isPreApproved &&
                isHomeReservedAgg &&
                isReadyForPandSAgg &&
                isHomeUnderContractAgg &&
                isFinancingFinalApprovalPassed
              "
            ></div>
          </div>
        </template>
        <template v-slot:timelineContent>
          <div
            v-if="
              !isPreRelease &&
              isPreApproved &&
              isHomeReservedAgg &&
              isReadyForPandSAgg &&
              isHomeUnderContractAgg &&
              !isFinancingFinalApprovalPassed
            "
          >
            <div class="timeline-title">Secure financing</div>
            <div
              class="mt-4 text-gray-900 text-body-3 font-regular xl:w-full w-1/2"
            >
              Work with loan officer and home specialist to finalize your loan.
            </div>
            <PrimaryBaseCta
              class="mt-4 xl:w-full w-[fit-content]"
              text="Loan Approval"
              fixedSize="small"
              endImage="ico_circle_arrow_white.svg"
            />
          </div>
          <div v-else>
            <div class="timeline-title-inactive">Secure financing</div>
          </div>
        </template>
      </NextStepSlot>

      <!-- Close on home -->
      <NextStepSlot class="order-5">
        <template v-slot:timelineCircle>
          <div
            class="timeline-circle"
            :class="[
              !isPreRelease &&
              isPreApproved &&
              isHomeReservedAgg &&
              isReadyForPandSAgg &&
              isHomeUnderContractAgg &&
              isFinancingFinalApprovalPassed &&
              isHomeClosed
                ? 'bg-yellow-500'
                : '',
            ]"
          >
            <div
              class="timeline-selected-circle"
              v-if="
                !isPreRelease &&
                isPreApproved &&
                isHomeReservedAgg &&
                isReadyForPandSAgg &&
                isHomeUnderContractAgg &&
                isFinancingFinalApprovalPassed &&
                !isHomeClosed
              "
            ></div>
            <div
              class="timeline-completed"
              v-if="
                !isPreRelease &&
                isPreApproved &&
                isHomeReservedAgg &&
                isReadyForPandSAgg &&
                isHomeUnderContractAgg &&
                isFinancingFinalApprovalPassed &&
                isHomeClosed
              "
            ></div>
          </div>
        </template>
        <template v-slot:timelineContent>
          <div
            :class="[
              !isPreRelease &&
              isPreApproved &&
              isHomeReservedAgg &&
              isReadyForPandSAgg &&
              isHomeUnderContractAgg &&
              isFinancingFinalApprovalPassed &&
              !isHomeClosed
                ? 'timeline-title'
                : 'timeline-title-inactive',
            ]"
          >
            Close on your home
          </div>
          <div class="text-body-3 text-gray-900">Dealine: May 28</div>
        </template>
      </NextStepSlot>

      <!-- Track construction -->
      <NextStepSlot class="order-5" :isEnd="true">
        <template v-slot:timelineCircle>
          <div class="timeline-circle">
            <div
              class="timeline-selected-circle"
              v-if="
                !isPreRelease &&
                isPreApproved &&
                isHomeReservedAgg &&
                isReadyForPandSAgg &&
                isHomeUnderContractAgg &&
                isFinancingFinalApprovalPassed &&
                isHomeClosed
              "
            ></div>
          </div>
        </template>
        <template v-slot:timelineContent>
          <div
            :class="[
              !isPreRelease &&
              isPreApproved &&
              isHomeReservedAgg &&
              isReadyForPandSAgg &&
              isHomeUnderContractAgg &&
              isFinancingFinalApprovalPassed &&
              isHomeClosed
                ? 'timeline-title'
                : 'timeline-title-inactive',
            ]"
          >
            Track construction and move in
          </div>
          <div class="text-body-3 text-gray-900">Est: December 2022</div>
        </template>
      </NextStepSlot>
    </div>
  </div>
</template>
<script>
import { PrimaryBaseCta } from "@/components";
import NextStepSlot from "./NextStepSlot.vue";
import PreApprovalError from "./PreApprovalError.vue";
export default {
  name: "NextSteps",
  components: {
    PrimaryBaseCta,
    NextStepSlot,
    PreApprovalError,
  },
  props: {
    person: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      showAllSteps: false,
      startPreApprovalDate: process.env.VUE_APP_START_PRE_APPROVAL,
      lotsAvailableDate: process.env.VUE_APP_LOTS_AVAILABLE,
      reserveDate: process.env.VUE_APP_RESERVE_DATE,
      upcomingReleaseDate: process.env.VUE_APP_UPCOMING_RELEASE_LOTS,
    };
  },
  methods: {
    toggleNextSteps() {
      this.showAllSteps = !this.showAllSteps;
    },
  },
  computed: {
    showStepsText() {
      if (!this.showAllSteps) {
        return "View all steps";
      }
      return "View next steps";
    },
    isPreRelease() {
      return this.$preReleaseActions.isPreRelease();
    },

    /* Home Selection Status' */
    isHomeUnselected() {
      return this.person.home_selection_status == "not_selected";
    },
    isHomeSelected() {
      return this.person.home_selection_status == "selected";
    },
    isHomeReserved() {
      return this.person.home_selection_status == "reserved";
    },
    isHomeUnderContract() {
      return this.person.home_selection_status == "under_contract";
    },
    isHomeClosed() {
      return this.person.home_selection_status == "closed";
    },
    isHomeInErrorState() {
      return (
        this.person.home_selection_status == "selected_err" ||
        this.person.home_selection_status == "reserved_err" ||
        this.person.home_selection_status == "under_contract_err" ||
        this.person.home_selection_status == "closed_error"
      );
    },

    /* Financing Status */
    isFinancingNotStarted() {
      return this.person.financing_status == "not_started";
    },
    isFinancingSoftQualStarted() {
      return this.person.financing_status == "soft_qual_started";
    },
    isFinancingSoftQualPassed() {
      return this.person.financing_status == "soft_qual_passed";
    },
    isFinancingPreApprovalStarted() {
      return this.person.financing_status == "pre_app_started";
    },
    isFinancingPreApprovalPassed() {
      return this.person.financing_status == "pre_app_passed";
    },
    isFinancingFinalApprovalStarted() {
      return this.person.financing_status == "fin_app_started";
    },
    isFinancingFinalApprovalPassed() {
      return this.person.financing_status == "fin_app_passed";
    },
    isFinancingInErrorState() {
      return (
        this.person.financing_status == "pre_app_err" ||
        this.person.financing_status == "fin_app_err"
      );
    },
    didPreApprovalFail() {
      return this.person.financing_status == "pre_app_err";
    },
    didFinalApprovalFail() {
      return this.person.financing_status == "fin_app_err";
    },

    /* Aggregate States */
    isPreApproved() {
      return (
        // !this.isFinancingNotStarted &&
        // !this.isFinancingSoftQualStarted &&
        // !this.isFinancingSoftQualPassed &&
        // !this.isFinancingPreApprovalStarted &&
        this.isFinancingPreApprovalPassed ||
        this.isFinancingFinalApprovalStarted ||
        this.isFinancingFinalApprovalPassed
      );
    },
    isHomeSelectedAgg() {
      return (
        this.isHomeSelected ||
        this.isHomeReserved ||
        this.isHomeUnderContract ||
        this.isHomeClosed ||
        this.isHomeInErrorState
      );
    },
    isHomeReservedAgg() {
      return (
        this.isHomeReserved || this.isHomeUnderContract || this.isHomeClosed
      );
    },
    isHomeUnderContractAgg() {
      return this.isHomeUnderContract || this.isHomeClosed;
    },
    isReadyForPandSAgg() {
      return (
        this.isHomeReservedAgg && this.isPreApproved
        // (this.isFinancingPreApprovalPassed ||
        //   this.isFinancingFinalApprovalStarted ||
        //   this.isFinancingFinalApprovalPassed)
      );
    },
  },
  mounted() {},
};
</script>
<style scoped>
.timeline-circle {
  @apply min-w-[32px] h-8 rounded-full border border-gray-900 flex items-center justify-center;
}
.timeline-selected-circle {
  @apply w-2 h-2 rounded-full bg-gray-900 align-middle;
}
.timeline-completed {
  background: url("~@/assets/icons/checkmark_black.png");
  @apply bg-no-repeat min-w-[16px] h-4;
}
.timeline-title {
  @apply text-body-2 text-gray-900 font-semibold;
}
.timeline-title-inactive {
  @apply text-body-2 text-gray-900;
}
.line {
  @apply h-auto w-0 border border-gray-900 mx-auto flex-1;
}
</style>

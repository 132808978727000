<template>
  <div class="max-container">
    <div class="main-grid gap-y-8 lg:gap-y-16 gap-x-4 lg:gap-x-8">
      <div class="col-span-full">
        <h3 class="text-h4 lg:text-h2 font-semibold text-center">
          Take a look inside
        </h3>
      </div>

      <div class="col-span-full">
        <div class="iframe-container">
          <iframe
            :src="iframeUrl"
            frameborder="0"
            allowfullscreen
            allow="xr-spatial-tracking"
          ></iframe>
        </div>

        <div class="description-wrapper">
          <div class="description-title">{{ kitchen.alt }}</div>
          <div class="description-text">
            {{ kitchen.floor }} -
            <span class="hidden lg:inline">&nbsp;Description</span>
            <span class="lg:hidden text-body-3">{{ kitchen.description }}</span>
          </div>
        </div>
      </div>

      <div class="col-span-3 lg:col-span-6">
        <img
          :src="livingRoom[0].src"
          :alt="livingRoom[0].alt"
          @click="setPreselectedImageId(livingRoom[0].id)"
        />

        <div class="description-wrapper">
          <div class="description-title">{{ livingRoom[0].alt }}</div>
          <div class="description-text">
            {{ livingRoom[0].floor }}
          </div>
        </div>
      </div>
      <div class="col-span-3 col-start-4 lg:col-span-6 lg:col-start-7">
        <img
          :src="livingRoom[1].src"
          :alt="livingRoom[1].alt"
          @click="setPreselectedImageId(livingRoom[1].id)"
        />

        <div class="description-wrapper">
          <div class="description-title text-right">
            {{ livingRoom[1].alt }}
          </div>
          <div class="description-text text-right">
            {{ livingRoom[1].floor }}
          </div>
        </div>
      </div>

      <div class="col-span-full">
        <img
          :src="bedroom.src"
          :alt="bedroom.alt"
          @click="setPreselectedImageId(bedroom.id)"
        />

        <div class="flex justify-between mt-3">
          <div class="description-title">{{ bedroom.alt }}</div>
          <div class="description-text">
            {{ bedroom.floor }} -
            <span>&nbsp;Description</span>
            <span class="lg:hidden text-body-3">{{ bedroom.description }}</span>
          </div>
        </div>
      </div>

      <div class="col-span-3 lg:col-span-6">
        <img
          :src="bathroom.src"
          :alt="bathroom.alt"
          @click="setPreselectedImageId(bathroom.id)"
        />

        <div class="description-wrapper">
          <div class="description-title">{{ bathroom.alt }}</div>
          <div class="description-text">
            {{ bathroom.floor }}
          </div>
        </div>
      </div>
      <div class="col-span-3 col-start-4 lg:col-span-6 lg:col-start-7">
        <img
          :src="lounge.src"
          :alt="lounge.alt"
          @click="setPreselectedImageId(lounge.id)"
        />

        <div class="description-wrapper">
          <div class="description-title text-right">
            {{ lounge.alt }}
          </div>
          <div class="description-text text-right">
            {{ lounge.floor }}
          </div>
        </div>
      </div>

      <div
        class="col-span-full text-center max-w-[564px] text-body-3 lg:text-body-2 mx-auto"
      >
        *All photos are of a model house. Actual configuration and features may
        vary. Home Specialist will help you understand possible variations
      </div>

      <div class="col-span-full flex justify-center">
        <PrimaryBaseCta
          type="tertiary"
          fixedSize="medium"
          text="All photos"
          endImage="icons/fullscreen_arrows.svg"
          class="w-max mr-3"
          @click="toggleCarousel"
        />

        <PrimaryBaseCta
          type="primary"
          fixedSize="medium"
          text="I'm interested"
          endImage="ico_circle_arrow_white.svg"
          class="w-max ml-3"
          @click="toggleLocationInterestModal"
        />
      </div>

      <div class="col-span-full lg:col-span-10 lg:col-start-2">
        <hr />
      </div>

      <div class="col-span-full lg:col-span-4 lg:col-start-2">
        <h3 class="text-h4 lg:text-h3 font-semibold">
          We vet builders <br />
          so you don't have to.
        </h3>

        <p class="text-body-2 lg:text-body-1 max-w-[420px] mt-8">
          Have questions or concerns? We'll work with the builder to resolve any
          questions.
        </p>
      </div>

      <div class="col-span-full lg:col-span-4 lg:col-start-8">
        <div class="flex flex-col mt-6">
          <div class="flex mb-6">
            <img
              class="max-w-[24px]"
              src="@/assets/icons/plus_circle_yellow.svg"
              alt="Yellow checkmark circle"
            />
            <div class="ml-6 text-body-2 lg:text-body-1">2+ year warranty</div>
          </div>

          <div class="flex mb-6">
            <img
              class="max-w-[24px]"
              src="@/assets/icons/plus_circle_yellow.svg"
              alt="Yellow checkmark circle"
            />
            <div class="ml-6 text-body-2 lg:text-body-1">
              Award-winning designs
            </div>
          </div>
          <div class="flex mb-6">
            <img
              class="max-w-[24px]"
              src="@/assets/icons/plus_circle_yellow.svg"
              alt="Yellow checkmark circle"
            />
            <div class="ml-6 text-body-2 lg:text-body-1">
              High quality construction and finishes
            </div>
          </div>
          <div class="flex">
            <img
              class="max-w-[24px]"
              src="@/assets/icons/plus_circle_yellow.svg"
              alt="Yellow checkmark circle"
            />
            <div class="ml-6 text-body-2 lg:text-body-1">
              Trusted by HomeFounder
            </div>
          </div>
        </div>
      </div>

      <LocationInterestModal
        v-if="modalOpened"
        :preselectedHomeModel="houseModel.name"
        @closeLocationInterestModal="toggleLocationInterestModal"
      />

      <Carousel
        v-if="carouselOpened"
        :images="allHouseImages"
        :floorplansImages="allFloorplanImages"
        :forceFullscreen="true"
        :fixedHeight="800"
        :class="[carouselOpened ? '' : 'hidden']"
        :preselectedImageId="preselectedImageId"
        @closeFullscreen="toggleCarousel"
      />
    </div>
  </div>
</template>

<script>
import { PrimaryBaseCta } from "@/components/buttons";
import { LocationInterestModal, Carousel } from "@/components";

export default {
  name: "Interior",
  components: {
    PrimaryBaseCta,
    LocationInterestModal,
    Carousel,
  },
  props: {
    houseModel: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      modalOpened: false,
      carouselOpened: false,
      preselectedImageId: null,
    };
  },
  computed: {
    kitchen() {
      return this.houseModel.interior.images.kitchen[0];
    },
    livingRoom() {
      return this.houseModel.interior.images.livingRoom;
    },
    bedroom() {
      return this.houseModel.interior.images.bedrooms[0];
    },
    bathroom() {
      return this.houseModel.interior.images.bathrooms[0];
    },
    lounge() {
      return this.houseModel.interior.images.lounge[0];
    },
    allHouseImages() {
      return [
        ...this.houseModel.houseImages,
        ...this.houseModel.interior.images.kitchen,
        ...this.houseModel.interior.images.livingRoom,
        ...this.houseModel.interior.images.bedrooms,
        ...this.houseModel.interior.images.bathrooms,
        ...this.houseModel.interior.images.lounge,
      ];
    },
    allFloorplanImages() {
      return this.houseModel.floorplansImages;
    },
    iframeUrl() {
      return `https://my.matterport.com/show/?m=${this.houseModel.matterportId}&search=0&title=0&brand=0`;
    },
  },
  methods: {
    toggleLocationInterestModal() {
      this.modalOpened = !this.modalOpened;
    },
    toggleCarousel() {
      this.carouselOpened = !this.carouselOpened;
    },
    setPreselectedImageId(id) {
      this.preselectedImageId = id;
      this.toggleCarousel();
    },
  },
};
</script>

<style scoped>
img {
  @apply rounded-16 w-full;
}
img:hover {
  @apply cursor-pointer;
}

.description-wrapper {
  @apply flex flex-col lg:flex-row justify-between mt-3;
}
.description-title {
  @apply text-body-2 font-semibold;
}
.description-text {
  @apply text-body-3 lg:text-body-2;
}

hr {
  @apply h-[1px] w-full;
}

.iframe-container {
  @apply overflow-hidden pt-[56.25%] relative rounded-16;
}
.iframe-container iframe {
  @apply absolute top-0 left-0 border-0 w-full h-full;
}
</style>

<template>
  <section class="max-container mt-5 lg:mt-4">
    <div class="main-grid text-body-2 lg:text-body-1 gap-8 lg:gap-24">
      <div class="col-span-full lg:col-span-10 lg:col-start-2">
        <div class="text-body-3 lg:text-body-2 text-right">
          Actual configuration and features may vary.
        </div>
      </div>

      <div class="col-span-full lg:col-span-4 lg:col-start-2 order-1">
        <h3 class="paragraph-title">Land</h3>

        <p class="font-semibold text-body-2 lg:text-body-1 mt-6 lg:mt-8 mb-6">
          {{ houseModel.address }}
        </p>

        <p
          class="mb-6"
          v-for="paragraph in houseModel.exterior.land.paragraphs"
          :key="paragraph"
        >
          {{ paragraph }}
        </p>

        <PrimaryBaseCta
          type="link"
          :text="houseModel.exterior.land.linkText"
          endImage="icons/arrow_right_blue.svg"
          fixedSize="medium"
          @click="goToLocations"
        />
      </div>

      <div class="col-span-full lg:col-span-6 lg:col-start-6 relative order-2">
        <img
          class="rounded-12 object-cover h-full"
          :src="houseModel.lot.bigImage"
          alt="Lot image"
        />

        <div
          class="absolute bottom-5 right-5 flex flex-col justify-center items-center text-center bg-creme-50 py-4 px-6 rounded-12"
        >
          <span class="font-semibold text-body-2 lg:text-body-1">{{
            houseModel.lot.name
          }}</span>
          <span class="text-body-2">{{ houseModel.lot.size }} acres</span>
        </div>
      </div>

      <div
        class="col-span-full lg:col-span-6 lg:col-start-2 text-center border border-black rounded-16 order-4 lg:order-3"
      >
        <Carousel
          :fixedHeight="440"
          :floorplansImages="houseModel.floorplansImages"
          :hideSwiperButton="true"
        />
      </div>

      <div
        class="col-span-full lg:col-span-4 lg:col-start-8 lg:ml-8 order-3 lg:order-4"
      >
        <hr />
        <h3 class="paragraph-title">Floorplans</h3>

        <p
          class="mt-8 mb-6"
          v-for="paragraph in houseModel.exterior.floorplans.paragraphs"
          :key="paragraph"
        >
          {{ paragraph }}
        </p>

        <PrimaryBaseCta
          type="link"
          text="Download brochure"
          fixedSize="medium"
          endImage="icons/arrow_down_blue.svg"
          @click="onClick"
        />
      </div>

      <div class="col-span-full lg:col-span-4 lg:col-start-2 order-5">
        <hr />
        <h3 class="paragraph-title">Exterior</h3>

        <p
          class="mt-8 mb-6"
          v-for="paragraph in houseModel.exterior.paragraphs"
          :key="paragraph"
        >
          {{ paragraph }}
        </p>
      </div>

      <div class="col-span-full lg:col-span-6 lg:col-start-6 order-last">
        <img
          class="mx-auto"
          :src="houseModel.exterior.exteriorImage.src"
          :alt="houseModel.exterior.exteriorImage.alt"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { PrimaryBaseCta, Carousel } from "@/components";

export default {
  name: "Exterior",
  components: {
    PrimaryBaseCta,
    Carousel,
  },
  props: {
    houseModel: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    goToLocations() {
      this.$router.push({ path: "locations/" + this.houseModel.location.slug });
    },
    onClick() {
      console.log("download brochure");
    },
  },
  computed: {},
};
</script>

<style scoped>
.paragraph-title {
  @apply text-h4 lg:text-h3 font-semibold;
}

hr {
  @apply lg:hidden w-full mb-8 h-[1px];
}
</style>

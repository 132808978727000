<template>
  <button
    class="flex flex-row button-container-base"
    v-bind:class="[
      checked ? 'selected' : '',
      `${type}-background`,
      `button-container-base-${fixedSize}`,
      `button-container-${fixedSize}`,
    ]"
    @click="onClickHandler"
    :type="eventName"
  >
    <div
      :class="[
        `button-img-wrapper-${fixedSize}`,
        `button-img-wrapper-start-${fixedSize}`,
      ]"
      v-if="startImage"
    >
      <img v-if="startImage" :src="require('@/assets/' + startImage)" />
    </div>
    <slot>
      <div
        v-if="text"
        class="button-text"
        v-bind:class="[`text-size-${fixedSize}`]"
      >
        {{ text }}
      </div>
    </slot>
    <div
      :class="[
        `button-img-wrapper-${fixedSize}`,
        `button-img-wrapper-end-${fixedSize}`,
      ]"
      v-if="endImage"
    >
      <img v-if="endImage" :src="require('@/assets/' + endImage)" />
    </div>
  </button>
</template>
<script>
export default {
  name: "PrimarySelectorCta",
  props: {
    text: {
      type: String,
      default: "Get Started",
    },
    startImage: {
      type: String,
      default: null,
    },
    endImage: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "primary",
    },
    fixedSize: {
      type: String,
      default: "responsive",
      // small, medium, large, responsive
    },
    eventName: {
      type: String,
      default: "click",
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: this.isSelected,
    };
  },
  methods: {
    onClickHandler() {
      this.checked = !this.checked;
      this.$emit(`${this.eventName}`);
    },
  },
};
</script>
<style scoped>
.button-container-base {
  @apply items-center justify-center cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed;
}
.button-container-base-responsive {
  @apply xl:rounded-16 rounded-12;
}
.button-container-base-small {
  @apply rounded-12;
}
.button-container-base-medium {
  @apply rounded-12;
}
.button-container-base-large {
  @apply rounded-16;
}

.button-container-responsive {
  @apply xl:py-5 xl:px-6 lg:py-4 lg:px-5 py-3 px-4;
}
.button-container-small {
  @apply py-3 px-4;
}
.button-container-medium {
  @apply py-4 px-5;
}
.button-container-large {
  @apply py-5 px-6;
}

.button-img-wrapper-responsive {
  @apply relative xl:w-6 xl:h-6 lg:w-5 lg:h-5 w-4 h-4;
}
.button-img-wrapper-responsive > img {
  @apply absolute xl:h-6 xl:w-6 lg:w-5 lg:h-5 w-4 h-4;
}
.button-img-wrapper-small {
  @apply relative w-4 h-4;
}
.button-img-wrapper-small > img {
  @apply absolute w-4 h-4;
}
.button-img-wrapper-medium {
  @apply relative w-5 h-5;
}
.button-img-wrapper-medium > img {
  @apply absolute w-5 h-5;
}
.button-img-wrapper-large {
  @apply relative w-6 h-6;
}
.button-img-wrapper-large > img {
  @apply absolute h-6 w-6;
}

.button-img-wrapper-start-responsive {
  @apply xl:mr-3 mr-2;
}
.button-img-wrapper-start-small {
  @apply mr-2;
}
.button-img-wrapper-start-medium {
  @apply mr-3;
}
.button-img-wrapper-start-large {
  @apply mr-3;
}

.button-img-wrapper-end-responsive {
  @apply xl:ml-3 ml-2;
}
.button-img-wrapper-end-small {
  @apply ml-2;
}
.button-img-wrapper-end-medium {
  @apply ml-3;
}
.button-img-wrapper-end-large {
  @apply ml-3;
}

.primary-background {
  @apply border border-gray-900 hover:bg-creme-300 active:bg-creme-300;
}

.fiat-background {
  @apply bg-transparent hover:bg-creme-300 active:bg-creme-300;
}

.button-text {
  @apply font-semibold text-gray-900;
}
.text-size-responsive {
  @apply xl:text-h4 lg:text-body-1 text-body-2;
}
.text-size-small {
  @apply text-body-2;
}
.text-size-medium {
  @apply text-body-1;
}
.text-size-large {
  @apply text-h4;
}

.selected {
  @apply bg-yellow-500 hover:bg-yellow-500;
}
</style>

<template>
  <div v-if="isLoading" class="w-full flex items-center justify-center h-96">
    <div
      style="border-top-color: transparent"
      :class="[bgColor, borderType]"
      class="w-14 h-14 border-4 rounded-full animate-spin"
    ></div>
  </div>
</template>

<script>
export default {
  name: "BasicSpinner",
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    bgColor: {
      type: String,
      default: "border-black",
    },
    borderType: {
      type: String,
      default: "",
    },
  },
};
</script>

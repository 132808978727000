const houseModel = {
  id: "ba5c300a-f469-4041-b1e8-23439e38ec2e",
  name: "Gardenia",
  isAvailable: true,
  summary:
    "This new two-story home design will suit households to a T. Included on the first floor are a living room with a fireplace, a breakfast room, a formal dining room and a bedroom that could serve as a guest room. The patio is perfect for sipping sweet tea. Upstairs are spacious bedrooms and a versatile loft.",
  sqfoot: 2980,
  bed: 4,
  bath: 2.5,
  price: 408900,
  homeImages: [
    {
      id: 0,
      src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/shelly_2_front.jpeg",
      alt: "Outside",
      order: 0,
    },
    {
      id: 1,
      src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/shelly_2_great_room.jpeg",
      alt: "Great Room",
      order: 1,
    },
    {
      id: 2,
      src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/shelly_2_foyer.jpg",
      alt: "Foyer",
      order: 2,
    },
    {
      id: 3,
      src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/shelly_2_kitchen.jpg",
      alt: "Kitchen",
      order: 3,
    },
  ],
  floorplansImages: [
    {
      id: 1000,
      floor: 0,
      alt: "Basement",
      src: "/img/gardenia_basement.png",
    },
    {
      id: 2000,
      floor: 1,
      alt: "1st floor",
      src: "/img/gardenia_first_floor.png",
    },
    {
      id: 3000,
      floor: 2,
      alt: "2nd floor",
      src: "/img/gardenia_second_floor.png",
    },
  ],
  features: [
    {
      name: "Tile backsplash",
      description: "Beautiful tile backsplash in your brand new kitchen",
    },
    {
      name: "Lenox air-conditioning",
      description:
        "Top of the line Lenox central air too keep your whole family cool on those hot summer days",
    },
    {
      name: "LED recessed lighting",
      description: "Recessed lighting in all rooms",
    },
    {
      name: "55-gallon electric water heater",
      description:
        "Large tank to accomodate your all your families hot-water needs",
    },
    {
      name: "Timberlake cabinetry",
      description: "",
    },
    {
      name: "Sealed heating and cooling ducts",
      description: "",
    },
    {
      name: "Stainless steel slide-in gas range",
      description: "",
    },
    {
      name: "Whole-home sealant package",
      description: "",
    },
  ],
  kitchen: [
    {
      name: "Noir",
      colorCode: "#6C6958",
      images: [
        {
          src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/shelly_2_noir_1.jpg",
          alt: "Noir - 1",
          order: 0,
        },
        {
          src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/shelly_2_noir_2.jpg",
          alt: "Noir - 2",
          order: 1,
        },
        {
          src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/shelly_2_noir_3.jpg",
          alt: "Noir - 3",
          order: 2,
        },
      ],
    },
    {
      name: "Nuage",
      colorCode: "#EAE4D6",
    },
    {
      name: "Midnight",
      colorCode: "#394244",
    },
  ],
  flooring: [
    {
      name: "Champagne",
      colorCode: "#CBA678",
      images: [],
    },
    {
      name: "Nuage",
      colorCode: "#EAE4D6",
      images: [],
    },
    {
      name: "Charcoal",
      colorCode: "#383532",
      images: [
        {
          src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/shelly_2_floor_charcoal.png",
          alt: "Charcoal - 1",
          order: 0,
        },
      ],
    },
  ],
  lots: [
    { id: 1, name: "Lot 1" },
    { id: 2, name: "Lot 2" },
  ],
  addons: [
    {
      id: 1,
      name: "Screened-in porch",
      price: 3420,
      addedValueToHome: 4760,
      description:
        "A screened-in porch is a good bet as a homeowner, but so are most other outdoor remodeling projects that add usable space to your home, such as a deck or a porch that's not screened in. Screened-in porches are good for areas where bugs, privacy, and rainy weather are concerns.",
      images: [
        {
          src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/shelly_2_front.jpeg",
          alt: "Outside",
        },
        {
          src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/shelly_2_great_room.jpeg",
          alt: "Great Room",
        },
      ],
    },
    {
      id: 2,
      name: "Inground Pool",
      price: 25420,
      addedValueToHome: 31700,
      description:
        "This new two-story home design will suit households to a T. Included on the first floor are a living room with a fireplace, a breakfast room, a formal dining room and a bedroom that could serve as a guest room. The patio is perfect for sipping sweet tea. Upstairs are spacious bedrooms and a versatile loft.",
      images: [
        {
          src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/shelly_2_front.jpeg",
          alt: "Shelly II",
        },
        {
          src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/shelly_2_great_room.jpeg",
          alt: "Shelly II - Great Room",
        },
      ],
    },
  ],
};

const lot1GeoJson = {
  type: "geojson",
  data: {
    type: "FeatureCollection",
    id: "lot1",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-84.49679969804144, 34.1722257717029],
              [-84.49706810386377, 34.17547342955892],
              [-84.49341107453233, 34.17358591712687],
              [-84.49679969804144, 34.1722257717029],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          name: "test1",
        },
        geometry: {
          type: "Point",
          coordinates: [-84.49706810386377, 34.17547342955892],
        },
      },
    ],
  },
};

const lot2GeoJson = {
  type: "geojson",
  data: {
    type: "FeatureCollection",
    id: "lot2",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-84.50734084588301, 34.173173432402734],
              [-84.50734084588301, 34.169479198787045],
              [-84.5167003332656, 34.16834247898993],
              [-84.51558406412836, 34.17409696554047],
              [-84.50734084588301, 34.173173432402734],
            ],
          ],
        },
      },
      {
        type: "Feature",
        geometry: {
          properties: {
            name: "test2",
          },
          type: "Point",
          coordinates: [-84.50734084588301, 34.173173432402734],
        },
      },
    ],
  },
};

const lot3GeoJson = {
  type: "geojson",
  data: {
    type: "FeatureCollection",
    id: "lot3",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-84.50227470133672, 34.17736477087546],
              [-84.50519417446515, 34.18148486679263],
              [-84.49995629620554, 34.17985105972551],
              [-84.50227470133672, 34.17736477087546],
            ],
          ],
        },
      },
      {
        type: "Feature",
        geometry: {
          properties: {
            name: "test3",
          },
          type: "Point",
          coordinates: [-84.50519417446515, 34.18148486679263],
        },
      },
    ],
  },
};

const lot4GeoJson = {
  type: "geojson",
  data: {
    type: "FeatureCollection",
    id: "lot4",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-84.5020171007667, 34.168626660374244],
              [-84.50742671274028, 34.162871800862774],
              [-84.49987042934885, 34.16272970060329],
              [-84.5020171007667, 34.168626660374244],
            ],
          ],
        },
      },
      {
        type: "Feature",
        geometry: {
          properties: {
            name: "test4",
          },
          type: "Point",
          coordinates: [-84.5020171007667, 34.168626660374244],
        },
      },
    ],
  },
};

const lot5GeoJson = {
  type: "geojson",
  data: {
    type: "FeatureCollection",
    id: "lot5",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-84.51386672699384, 34.178856552976626],
              [-84.51051791958207, 34.17914069895839],
              [-84.51438192813445, 34.18120072870548],
              [-84.51386672699384, 34.178856552976626],
            ],
          ],
        },
      },
      {
        type: "Feature",
        geometry: {
          properties: {
            name: "test5",
          },
          type: "Point",
          coordinates: [-84.51438192813445, 34.18120072870548],
        },
      },
    ],
  },
};

const models = [
  {
    id: 1,
    name: "Lot #1",
    shortName: "#1",
    lotArea: "0.5 acres",
    house: houseModel,
    geojson: lot1GeoJson,
    status: "available",
  },
  {
    id: 2,
    name: "Lot #2",
    shortName: "#2",
    lotArea: "0.5 acres",
    house: houseModel,
    geojson: lot2GeoJson,
    status: "available",
  },
  {
    id: 3,
    name: "Lot #3",
    shortName: "#3",
    lotArea: "1 acres",
    house: houseModel,
    geojson: lot3GeoJson,
    status: "available",
  },
  {
    id: 4,
    name: "Lot #4",
    shortName: "#4",
    lotArea: "2.5 acres",
    geojson: lot4GeoJson,
    status: "available",
    house: {
      id: 2,
      name: "Benedict",
      isAvailable: true,
      summary:
        "This new two-story home design will suit households to a T. Included on the first floor are a living room with a fireplace, a breakfast room, a formal dining room and a bedroom that could serve as a guest room. The patio is perfect for sipping sweet tea. Upstairs are spacious bedrooms and a versatile loft.",
      sqfoot: 3200,
      bed: 5,
      bath: 3.5,
      price: 418900,
      homeImages: [
        {
          id: 0,
          src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/shelly_2_front.jpeg",
          alt: "Outside",
          order: 0,
        },
      ],
      features: [],
    },
  },
  {
    id: 5,
    name: "Lot #5",
    shortName: "#5",
    lotArea: "0.8 acres",
    geojson: lot5GeoJson,
    status: "reserved",
    house: {
      id: 3,
      name: "Clara",
      isAvailable: false,
      summary:
        "This new two-story home design will suit households to a T. Included on the first floor are a living room with a fireplace, a breakfast room, a formal dining room and a bedroom that could serve as a guest room. The patio is perfect for sipping sweet tea. Upstairs are spacious bedrooms and a versatile loft.",
      sqfoot: 3500,
      bed: 5,
      bath: 4,
      price: 425900,
      homeImages: [
        {
          id: 0,
          src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/shelly_2_front.jpeg",
          alt: "Outside",
          order: 0,
        },
      ],
    },
  },
];

export const getMapLotArray = () => models;

<template>
  <div
    class="checkbox-wrapper"
    :class="[checked ? 'checkbox-wrapper-checked' : '']"
  >
    <input
      type="checkbox"
      :checked="checked"
      class="checkbox-input checkbox-custom"
      @change="onCheckChanged($event.target.checked)"
    />
  </div>
</template>
<script>
export default {
  name: "RadioButton",
  props: {
    setChecked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: this.setChecked,
    };
  },
  methods: {
    onCheckChanged(value) {
      this.checked = value;
      this.$emit("input", value);
    },
  },
};
</script>
<style scoped>
.checkbox-wrapper {
  @apply rounded-16 w-8 h-8 border border-gray-900 flex items-center justify-center flex-shrink-0 relative;
}
.checkbox-input {
  @apply w-4 h-4 rounded-16 checked:bg-gray-900 appearance-none;
}
.checkbox-custom {
  @apply bg-transparent ring-0 checked:ring-0 border-none shadow-none checked:shadow-none ring-offset-0 checked:bg-none;
}
.checkbox-wrapper-checked {
  @apply bg-yellow-500;
}
</style>

<template>
  <div class="w-full">
    <div class="text-h4 font-semibold text-gray-900">Have questions?</div>
    <div class="flex flex-col xl:flex-row xl:space-x-8 space-x-4 pt-8">
      <div class="flex flex-col xl:w-1/2">
        <div class="hr-line">&nbsp;</div>
        <div
          class="mt-5 mb-5 flex flex-row items-center justify-between cursor-pointer"
          @click="navigateItem('HowItWorks')"
        >
          <div class="text-body-2 text-blue-500 font-semibold">
            How it works?
          </div>
          <img
            class="w-[13px] h-[13px]"
            :src="require('@/assets/ico_circle_arrow.svg')"
            alt="arrow"
          />
        </div>
        <div class="hr-line">&nbsp;</div>
        <div class="mt-5 mb-5 flex flex-col space-y-5">
          <div
            class="w-full flex flex-row items-center justify-between cursor-pointer"
            @click="navigateItem('Faq')"
          >
            <div class="text-body-2 text-blue-500 font-semibold">
              Frequently Asked Questions
            </div>
            <img
              class="w-[13px] h-[13px]"
              :src="require('@/assets/ico_circle_arrow.svg')"
              alt="arrow"
            />
          </div>
          <div
            class="ml-8 flex flex-row items-center justify-between cursor-pointer"
          >
            <div class="text-body-2 text-blue-500 font-semibold">
              What is pre-approval?
            </div>
            <img
              class="w-[13px] h-[13px]"
              :src="require('@/assets/ico_circle_arrow.svg')"
              alt="arrow"
            />
          </div>
          <div
            class="ml-8 flex flex-row items-center justify-between cursor-pointer"
          >
            <div class="text-body-2 text-blue-500 font-semibold">
              How does financing work?
            </div>
            <img
              class="w-[13px] h-[13px]"
              :src="require('@/assets/ico_circle_arrow.svg')"
              alt="arrow"
            />
          </div>
        </div>
        <div class="hr-line">&nbsp;</div>
      </div>
      <div class="flex flex-col xl:w-1/2">
        <div class="hr-line">&nbsp;</div>
        <div
          class="mt-5 mb-5 flex flex-row items-center justify-between cursor-pointer"
          @click="navigateItem('HowItWorks')"
        >
          <div class="text-body-2 text-blue-500 font-semibold">
            Financing partner information
          </div>
          <img
            class="w-[13px] h-[13px]"
            :src="require('@/assets/ico_circle_arrow.svg')"
            alt="arrow"
          />
        </div>
        <div class="hr-line">&nbsp;</div>
        <div
          class="mt-5 mb-5 flex flex-row items-center justify-between cursor-pointer"
          @click="navigateItem('HowItWorks')"
        >
          <div class="text-body-2 text-blue-500 font-semibold">
            Builder information
          </div>
          <img
            class="w-[13px] h-[13px]"
            :src="require('@/assets/ico_circle_arrow.svg')"
            alt="arrow"
          />
        </div>
        <div class="hr-line">&nbsp;</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Questions",
  methods: {
    navigateItem(location) {
      this.$router.push({ name: location });
    },
  },
};
</script>
<style scoped>
.hr-line {
  @apply h-[1px] bg-gray-900 bg-opacity-20;
}
</style>

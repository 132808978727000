<template>
  <div class="main-container">
    <global-section>
      <div class="max-container">
        <div class="text-gray-900">
          <div class="text-h1 font-semibold mb-16">About Us</div>

          <div
            class="flex flex-col lg:flex-row lg:flex-wrap lg:justify-between"
          >
            <div class="w-full lg:w-[63%]">
              <div
                class="flex flex-col lg:flex-row p-12 rounded-16 bg-yellow-500 mb-4 order-1"
              >
                <div class="w-full lg:w-1/2 mr-8 flex lg:flex-col mb-6 lg:mb-0">
                  <div>
                    <span class="badge bg-creme-50"> Our mission </span>
                    <h3
                      class="text-h4 xl:text-h3 font-semibold max-w-[182px] mt-5"
                    >
                      We saw a problem
                    </h3>
                  </div>

                  <div
                    class="w-full h-full flex justify-center lg:justify-start items-center"
                  >
                    <img
                      class="ml-auto lg:ml-0"
                      src="@/assets/icons/lightbulb.svg"
                      alt="Lightbulb icon"
                    />
                  </div>
                </div>
                <div class="w-full text-body-2 flex flex-col justify-between">
                  <p class="mb-6">
                    We believe that incentives in new home construction are
                    totally misaligned, and that consumers pay for it.
                  </p>

                  <p class="mb-6">
                    Over decades of working in land development and
                    homebuilding, we realized that there was a better way. So we
                    created HomeFounder.
                  </p>

                  <p>
                    Our approach is simple - we enable homebuyers to save up to
                    20% off appraisal (by an independent third-party!) by
                    closing on a home before it is built. For a flat fee, we
                    find and coordinate new home construction opportunities.
                  </p>
                </div>
              </div>
              <div
                class="col-span-full lg:col-span-6 xl:col-start-2 xl:col-span-6 flex flex-col p-12 rounded-16 bg-blue-600 text-creme-200 mb-4 h-[min-content] order-2"
              >
                <div class="w-full mr-8 flex mb-6 lg:mb-0">
                  <div>
                    <span class="badge bg-creme-50 text-blue-600">
                      Our team
                    </span>
                    <h3
                      class="text-h4 xl:text-h3 font-semibold max-w-[380px] mt-5 mb-8"
                    >
                      Founded and backed by industry experts
                    </h3>
                  </div>
                </div>

                <p class="mb-6 text-body-2">
                  Jesse Honigberg, the founder and CEO is passionate about
                  making homes in reach of more Americans, after many years
                  working in residential finance. Behind him is a team of
                  industry leaders, driven by the same goal. <br /><br />
                  In addition, we have access to an extensive network of
                  residential housing experts that allows us to create unique
                  saving opportunities for our customers.
                </p>

                <div
                  class="flex flex-col md:flex-row justify-between items-start md:items-end mt-6"
                >
                  <div class="flex flex-col">
                    <div class="text-body-1 font-semibold mt-6">
                      <span class="text-h4">20,000</span> homes/yr
                    </div>
                    <p class="text-body-2">
                      Built and developed by our network
                    </p>
                  </div>

                  <div class="mt-6 lg:mt-0 flex self-end">
                    <img
                      class="max-w-[140px]"
                      src="@/assets/icons/gemini_ventures_logo.svg"
                      alt="Gemini Ventures company logo"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full lg:w-[28%]">
              <div
                class="col-span-full lg:col-span-4 lg:col-start-8 xl:col-span-3 xl:col-start-9 lg:row-span-2 order-last lg:order-2 mt-[-16px] lg:mt-0"
              >
                <hr class="block lg:hidden" />
                <span class="badge bg-yellow-500">Our values</span>

                <h3 class="side-title">Your wins are our wins</h3>
                <p class="side-paragraph">
                  To make sure of it, we aligned our financial incentives with
                  yours. Instead of the percentage-based commission that others
                  often take, we take a small flat fee off every home sold. That
                  means we don't get anything by making you pay more.
                </p>

                <hr />

                <h3 class="side-title">High-quality standards</h3>
                <p class="side-paragraph">
                  We have partnered with a best-in-class builders of
                  single-family homes in Atlanta.
                </p>
                <div class="flex flex-col mt-6">
                  <div class="flex mb-6">
                    <img
                      class="max-w-[24px]"
                      src="@/assets/icons/plus_circle_yellow.svg"
                      alt="Yellow checkmark circle"
                    />
                    <div class="ml-6">2+ year warranty</div>
                  </div>

                  <div class="flex mb-6">
                    <img
                      class="max-w-[24px]"
                      src="@/assets/icons/plus_circle_yellow.svg"
                      alt="Yellow checkmark circle"
                    />
                    <div class="ml-6">Award-winning designs</div>
                  </div>
                  <div class="flex">
                    <img
                      class="max-w-[24px]"
                      src="@/assets/icons/plus_circle_yellow.svg"
                      alt="Yellow checkmark circle"
                    />
                    <div class="ml-6">
                      High quality construction and finishes
                    </div>
                  </div>
                </div>

                <hr />

                <h3 class="side-title">Investing in community</h3>
                <p class="side-paragraph mb-6">
                  Homes are places to live, grow, and bond.
                </p>
                <p class="side-paragraph">
                  That's why we put in place residency requirements to weed out
                  house flippers, and strive to work with builders who have a
                  longtime track of record of giving back to the community
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </global-section>
    <global-section>
      <div class="max-container">
        <div class="relative w-full">
          <div class="flex flex-col items-center">
            <div>
              <h3 class="text-h2 font-semibold text-center">Get in touch</h3>

              <p class="pt-6 text-body-1">
                Fill out a simple form to get in touch with us
              </p>
            </div>

            <PrimaryBaseCta
              text="Contact us"
              endImage="ico_circle_arrow_white.svg"
              class="flex justify-center items-center mt-12"
              @click="onClick"
            />

            <PrimaryBaseCta
              type="link"
              text="Subscribe for updates"
              endImage="icons/arrow_right_blue.svg"
              class="flex justify-center items-center mt-4"
              @click="goToSubscribe"
            />
          </div>
        </div>
      </div>
    </global-section>
  </div>
</template>

<script>
import { PrimaryBaseCta, GlobalSection } from "@/components";

export default {
  name: "About",
  components: {
    PrimaryBaseCta,
    GlobalSection,
  },
  methods: {
    onClick() {
      this.$router.push({ path: "/learn-more" });
    },
    goToSubscribe() {
      this.$router.push({ path: "/learn-more" });
    },
  },
};
</script>

<style scoped>
.badge {
  @apply px-2 py-1 font-extrabold text-body-2 text-center rounded-8 h-[min-content] whitespace-nowrap;
}

.side-title {
  @apply text-h4 mt-5 mb-6 font-semibold;
}
.side-paragraph {
  @apply text-body-2;
}

hr {
  @apply w-full my-12 h-[2px];
}

.info-wrapper {
  @apply flex flex-col w-full lg:w-1/2;
}
.info {
  @apply text-center mb-4 md:max-w-[380px] lg:max-w-[80%] mx-auto;
}
.mail {
  @apply text-body-2 xl:text-body-1 text-center text-blue-500 font-semibold mb-16 break-words;
}

.max-container {
  max-width: 1088px;
}
</style>

<template>
  <div class="flex flex-row gap-x-4">
    <div class="flex flex-col">
      <slot name="timelineCircle"></slot>
      <div :class="[isError ? 'line-error' : 'line']" v-if="!isEnd"></div>
    </div>
    <div class="flex flex-col" :class="[isEnd ? 'pb-0' : 'pb-12']">
      <slot name="timelineContent"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "NextStepSlot",
  props: {
    isEnd: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.timeline-circle {
  @apply min-w-[32px] h-8 rounded-full border border-gray-900 flex items-center justify-center;
}
.timelie-selected-circle {
  @apply w-2 h-2 rounded-full bg-gray-900 align-middle;
}
.timeline-completed {
  background: url("~@/assets/icons/checkmark_black.png");
  @apply bg-no-repeat min-w-[16px] h-4;
}
.timeline-title {
  @apply text-body-2 text-gray-900 font-semibold;
}
.line {
  @apply h-auto w-0 border border-gray-900 mx-auto flex-1;
}
.line-error {
  @apply h-auto w-0 border border-gray-500 mx-auto flex-1;
}
</style>

<template>
  <div class="text-h3 mb-12 text-black">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: "CustomizationHeader",
  props: {
    title: {
      type: String,
      default: "Kitchen Colors",
    },
  },
};
</script>

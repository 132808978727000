<template>
  <MglMap
    class="styled-map"
    :center="center"
    :zoom="zoom"
    :accessToken="accessToken"
    :mapStyle="mapStyle"
    @click="onMapClick"
    @load="onMapLoaded"
    @styledata="onStyleLoaded"
    @styledataloading="onStyleChange"
  >
    <section v-if="mapLoaded">
      <section v-for="lot in lotArray" :key="lot.id">
        <MglGeojsonLayer
          :sourceId="lot.geojson.data.id"
          :source="lot.geojson"
          :layerId="lot.shortName"
          :layer="
            getGeoJsonlayerBottom(
              lot,
              selectedLot ? selectedLot.id : null,
              selectedHouseModel ? selectedHouseModel.id : null
            )
          "
        />
        <MglGeojsonLayer
          :sourceId="lot.geojson.data.id"
          :source="lot.geojson"
          :layerId="lot.name"
          :layer="
            getGeoJsonlayerTop(
              lot,
              selectedLot ? selectedLot.id : null,
              selectedHouseModel ? selectedHouseModel.id : null
            )
          "
        />
        <MglMarker
          :coordinates.sync="lot.geojson.data.features[1].geometry.coordinates"
          :key="selectedLot.id"
          @click="handleMarkerClickEvent($event, lot)"
          @mouseenter="lotPopupStatusList[lot.id] = true"
          @mouseleave="lotPopupStatusList[lot.id] = false"
        >
          <section v-if="isPopupShown(lot)">
            <MglPopup
              :closeButton="false"
              :closeOnClick="false"
              :showed="lotPopupStatusList[lot.id]"
              anchor="bottom"
            >
              <MarkerPopup
                :currentLot="lot"
                :selectedLot="selectedLot"
                :selectedHouseModel="selectedHouseModel"
              />
            </MglPopup>
          </section>
          <CustomMarker
            slot="marker"
            :currentLot="lot"
            :selectedLot="selectedLot"
            :selectedHouseModel="selectedHouseModel"
          />
        </MglMarker>
      </section>
    </section>
  </MglMap>
</template>

<script>
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker, MglPopup, MglGeojsonLayer } from "vue-mapbox";
import { BUILDER_EVENTS } from "@/constants";
import CustomMarker from "./CustomMarker.vue";
import MarkerPopup from "./MarkerPopup.vue";
import {
  getGeoJsonlayerBottom,
  getGeoJsonlayerTop,
} from "@/helpers/lotStatusMapResolver.js";
import { AVAILABLE, SOLD, RESERVED, SELECTED } from "@/constants";

const ZOOM = 14;
const MAP_CENTER = [-84.50145896026513, 34.173716489972406];
const { LOT_SELECTION } = BUILDER_EVENTS;

export default {
  props: {
    mapStyle: String,
    lotArray: Array,
    selectedHouseModel: {
      type: Object,
      default: () => {},
    },
    selectedLot: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    MglMap,
    MglMarker,
    MglPopup,
    MglGeojsonLayer,
    CustomMarker,
    MarkerPopup,
  },
  data() {
    return {
      accessToken: process.env.VUE_APP_ACCESS_TOKEN,
      center: MAP_CENTER,
      zoom: ZOOM,
      markerCoordinates: MAP_CENTER,
      mapLoaded: false,
      lotPopupStatusList: {},
    };
  },

  created() {
    // We need to set mapbox-gl library here in order to use it in template
    this.mapbox = Mapbox;

    // Create a list of lot popup statuses and make it observable
    this.lotArray.forEach((lot) => {
      if (
        [SOLD, RESERVED].indexOf(lot.status) > -1 ||
        (lot.house.id !== this.selectedHouseModel.id &&
          lot.status === AVAILABLE)
      ) {
        this.$set(this.lotPopupStatusList, lot.id, false);
      }
    });
  },
  watch: {
    /* TODO: This will be used in the future to set the panTo function of the map on outer component lot change */
    // selectedLot: function (value) {
    //   this.center = value.geojson.data.features[1].geometry.coordinates;
    //   console.log(this.center);
    // },
    //
  },
  methods: {
    onMapLoaded() {
      this.mapLoaded = true;
      // TODO: Add a better way of saving the map object
      // this.map = event.map;
    },
    onStyleLoaded() {
      this.mapLoaded = true;
    },
    onStyleChange() {
      this.mapLoaded = false;
    },
    onMapClick(e) {
      console.warn(e);
    },
    handleMarkerClickEvent(event, lot) {
      if (this.selectedHouseModel.id === lot.house.id) {
        this.$emit(LOT_SELECTION, lot);
      }
      this.markerCoordinates = event.marker._lngLat;
      event.map.panTo(event.marker._lngLat);
    },
    isPopupShown(lot) {
      // Add logic for showing or hidding popups (fix empty popup showing on marker click)
      // Don't show popup for selected lots and for lots with the same house model
      if (lot.status === SELECTED) return false;
      if (
        lot.status === AVAILABLE &&
        this.selectedHouseModel &&
        lot.house.id === this.selectedHouseModel.id
      )
        return false;

      // For all other lot statuses show the specific popups
      return this.lotPopupStatusList[lot.id];
    },
    getGeoJsonlayerBottom,
    getGeoJsonlayerTop,
  },
};
</script>

<style scoped>
.styled-map >>> .mapboxgl-map {
  @apply rounded-16;
}

.styled-map >>> .mapboxgl-popup {
  @apply top-[-90px];
}

.styled-map >>> .mapboxgl-popup-tip {
  @apply border-t-black;
}

.styled-map >>> .mapboxgl-popup-content {
  @apply bg-black text-creme-50 rounded-16;
}
</style>

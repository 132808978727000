<template>
  <div class="main-container">
    <div class="max-container">
      <div class="flex flex-col items-center">
        <div class="w-full flex flex-col lg:flex-row lg:justify-between">
          <div
            class="flex flex-col w-full lg:max-w-[640px] items-center lg:items-start"
          >
            <div class="flex flex-col items-center lg:items-start px-4 lg:px-0">
              <span
                class="bg-yellow-500 text-body-2 lg:text-h4 font-extrabold mb-4 px-2 rounded-8"
                >How it works</span
              >
              <h2
                class="text-h4 md:text-h3 lg:text-h2 font-semibold text-center lg:text-left lg:mb-8"
              >
                You drive developments from day 1
              </h2>
            </div>

            <div class="flex lg:hidden items-center m-8">
              <img src="@/assets/hiw_vertical.png" />
            </div>

            <p class="text-body-2 lg:text-body-1 mb-8">
              With HomeFounder, homebuyers like you sponsor the development. By
              committing to a home earlier, you save up to 20% on the price of
              your home.
            </p>
            <p class="text-body-2 lg:text-body-1 mb-8">
              First, you tell us where you want to live. Once we get enough
              interest and find land, you’ll be able to put a downpayment on a
              home model and location.... and move in 12-15 months later!
            </p>

            <PrimaryBaseCta
              text="Interested? Let’s chat!"
              endImage="ico_circle_arrow_white.svg"
              class=""
              @click="subscribe"
            />

            <PrimaryBaseCta
              type="link"
              text="Read more about HomeFounder in FAQ"
              endImage="icons/arrow_right_blue.svg"
              class="mt-3"
              @click="faq"
            />
          </div>
          <div
            class="hidden lg:block lg:w-[335px] lg:flex lg:items-center lg:mt-[-32px]"
          >
            <img src="@/assets/hiw_vertical.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCalculatorHomePrices } from "./calculatorData";
import {
  roundNumberToShorthandCurrency,
  currencyNoDecimal,
} from "@/helpers/currencyFormaterHelper.js";
import "swiper/css/swiper.css";
import { PrimaryBaseCta } from "@/components";

export default {
  name: "Calculator",
  components: { PrimaryBaseCta },
  data() {
    return {
      homePrices: getCalculatorHomePrices(),
      activeTab: 1,
      currentHomePrice: {},
      item: {},
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
      },
    };
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
      this.currentHomePrice = this.homePrices.find((item) => item.id === tab);
      console.log(this.currentHomePrice);
    },
    subscribe() {
      this.$router.push({ path: "/learn-more" });
    },
    faq() {
      this.$router.push({ path: "/faq/4" });
    },
    roundNumberToShorthandCurrency,
    currencyNoDecimal,
  },
  mounted() {
    this.setActiveTab(1);
  },
  swiper() {
    return this.$refs.mySwiper.$swiper;
  },
};
</script>

<style scoped>
.calc-infoblock-number {
  @apply font-semibold text-h3;
}
.calc-main-grid {
  @apply col-span-full lg:col-span-10 lg:col-start-2 grid grid-cols-1 grid-rows-3 lg:grid-rows-2 lg:grid-cols-2 gap-8;
  grid-template-rows: repeat(1, minmax(min-content, 1fr)); /* fix this */
  grid-auto-rows: minmax(1fr, auto);
}

.calc-price-button {
  @apply px-5 py-3 sm:px-6 sm:py-4;
}

.active {
  @apply bg-yellow-500 font-semibold rounded-[20px];
}

.swiper-style {
  @apply mx-12 lg:mx-16 text-center flex justify-between items-center flex-row sm:flex-col lg:flex-row overflow-hidden;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: "";
}

.swiper-button-prev,
.swiper-button-next {
  width: 40px;
}

.swiper-button-next {
  right: 0px;
}

.swiper-button-prev {
  left: 0px;
}

.what-will-you-do {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.max-container {
  max-width: 1088px;
}
</style>

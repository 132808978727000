<template>
  <div class="main-container">
    <div class="thank-you-container max-container">
      <div class="flex flex-col items-center">
        <img :src="require('@/assets/bg_thank_you.svg')" />
        <h1 class="mt-16 text-h2 text-center font-semibold text-blue-500">
          Thank you!
        </h1>

        <p class="text-center mt-8 text-body-1 text-gray-900 max-w-[700px]">
          You now have Priority Access! You will be the first to get development
          updates. Plus, you’ll get an exclusive window to vote on locations and
          reserve a spot, once they are available.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThankYouPriority",
};
</script>

<style scoped>
.thank-you-container {
  @apply py-20 lg:py-40 h-full flex justify-center items-center;
}
</style>

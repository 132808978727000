<template>
  <div class="flex flex-row items-center justify-end">
    <div
      class="hidden xl:flex lg:flex flex-row items-center justify-end space-x-12"
    >
      <router-link class="nav-right-text" to="/faq">FAQ</router-link>
      <router-link class="nav-right-text" to="/about">About us</router-link>
      <NavBarCta
        @specialist="navigateItem('/get-notified')"
        @dashboard="navigateItem('/dashboard')"
        @login="navigateItem('/learn-more')"
      />
    </div>
  </div>
</template>
<script>
import NavBarCta from "./NavBarCta.vue";
export default {
  name: "NavDesktopMarketing",
  components: {
    NavBarCta,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    navigateItem(slug) {
      this.$router.push({
        path: `${slug}`,
      });
    },
    login() {
      this.$emit("login");
    },
  },
};
</script>
<style scoped>
.nav-right-text {
  @apply text-gray-900 font-semibold text-body-2 cursor-pointer hover:text-blue-500;
}
.login-btn {
  @apply border border-gray-900 rounded-12 text-blue-500 font-semibold text-body-2 bg-creme-200 text-center cursor-pointer;
}
</style>

<template>
  <div>
    <div
      v-if="
        currentLot.status === AVAILABLE &&
        this.currentLot.house.id !== this.selectedHouseModel.id
      "
      class="popup-content"
    >
      <span
        >“{{ selectedHouseModel.name }}” is not available on this lot.
      </span>
      <span>Available home mode: “{{ currentLot.house.name }}”</span>
    </div>

    <div v-if="currentLot.status === RESERVED" class="popup-content">
      <span>Reserved, pending loan approval.</span>
      <span>Check again in 24 hrs to see if the lot has become available</span>
    </div>

    <div v-if="currentLot.status === SOLD" class="popup-content">
      <span>This lot has been sold.</span>
      <span>Check out available lots.</span>
    </div>
  </div>
</template>

<script>
import { AVAILABLE, SOLD, RESERVED } from "@/constants";

export default {
  props: {
    currentLot: {
      type: Object,
      default: () => [],
    },
    selectedHouseModel: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  data() {
    return { AVAILABLE, SOLD, RESERVED };
  },
};
</script>

<style scoped>
.popup-content {
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.15);
  @apply flex flex-col items-center text-center text-body-3;
}
</style>

<template>
  <div class="w-full">
    <BasicSpinner v-if="loading" class="h-full m-auto" />
    <div v-else>
      <Hero
        v-if="locationModel.location && locationModel.availableFrom"
        :location="locationModel.location"
        :availableFrom="locationModel.availableFrom"
      />

      <div class="bg-creme-50">
        <div class="max-container">
          <div class="main-grid mx-8 md:mx-16 lg:mx-16 pb-8 md:pb-8 lg:pb-0">
            <div class="col-span-full xl:col-span-10 xl:col-start-2">
              <div
                class="flex flex-wrap justify-between text-h4 text-gray-900 pt-4 pb-4 lg:pt-8 lg:pb-8"
              >
                <div
                  class="flex flex-col mb-8 lg:mb-0 mr-4 lg:mr-0 w-full lg:w-auto"
                >
                  <span class="text-body-1 md:text-h4 font-semibold">
                    5 new construction homes
                  </span>
                  <span
                    class="text-body-2 md:text-body-1 font-semibold text-blue-500 mt-2"
                  >
                    <a href="#scroll-homes" class="">Explore homes</a>
                  </span>
                </div>
                <div class="flex flex-col lg:mb-0 mr-4 lg:mr-0">
                  <div
                    class="text-body-1 md:text-h4 font-semibold flex items-center"
                  >
                    $500,000+
                    <span
                      class="hidden md:block text-body-2 bg-yellow-500 font-semibold ml-2 py-1 px-2 rounded-8 h-[fit-content]"
                    >
                      Save $166k+
                    </span>
                  </div>
                  <span class="text-body-2 md:text-body-1 mt-2">
                    Estimated Home Values
                  </span>
                  <span
                    class="md:hidden text-body-2 bg-yellow-500 font-semibold py-1 px-2 rounded-8 h-[fit-content] w-max mt-2"
                  >
                    Save $166k+
                  </span>
                </div>
                <div class="flex flex-col">
                  <span class="text-body-1 md:text-h4 font-semibold"
                    >Dec '22</span
                  >
                  <span class="text-body-2 md:text-body-1 mt-2"
                    >Move-In Estimate</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <global-section>
        <LocationMainSection />
      </global-section>

      <global-section type="300">
        <HomeModels
          v-if="locationModel && locationModel.houseModels"
          :houseModels="locationModel.houseModels.models"
        />
      </global-section>

      <global-section type="200">
        <Neighborhood />
      </global-section>

      <global-section type="50">
        <ImportantDates />
      </global-section>

      <InterestedBanner />
    </div>
  </div>
</template>

<script>
import {
  Hero,
  BasicSpinner,
  LocationMainSection,
  HomeModels,
  Neighborhood,
  ImportantDates,
  InterestedBanner,
  GlobalSection,
} from "@/components";
import { getLocation } from "@/mocks/useLocationsModel.js";

export default {
  name: "Locations",
  components: {
    Hero,
    BasicSpinner,
    LocationMainSection,
    HomeModels,
    Neighborhood,
    ImportantDates,
    InterestedBanner,
    GlobalSection,
  },
  data() {
    return {
      loading: true,
      locationModel: null,
    };
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchLocation",
  },
  methods: {
    async fetchLocation() {
      const locationSlug = this.$route.params.slug;

      if (!locationSlug) {
        // TODO: Handle no location slug case
        console.error("No slug provided!");
      }

      try {
        const data = await getLocation(locationSlug);

        if (data) {
          this.loading = false;
          this.locationModel = data;
          document.title = data.location.name;
        }
      } catch (error) {
        // TODO: Handle error fetching data
        console.error("Error fetching data");
      }
    },
  },
  created() {
    this.fetchLocation();
  },
};
</script>

<style scoped></style>

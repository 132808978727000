<template>
  <button
    type="button"
    :class="[`${bgColor}`]"
    class="font-semibold text-creme-200 text-hfbase px-6 py-2 text-left leading-loose rounded-xl flex align-center"
    @click="click"
  >
    {{ text }}
    <svg
      class="ml-2"
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#F5F0E9"
        d="M15.5 3C22.4036 3 28 8.59644 28 15.5C28 22.4036 22.4036 28 15.5 28C8.59644 28 3 22.4036 3 15.5C3.00758 8.59958 8.59958 3.00757 15.5 3ZM15.5 25.5C20.9932 25.4986 25.4569 21.0664 25.4972 15.5733C25.5375 10.0802 21.1393 5.58305 15.6466 5.50111C10.154 5.41917 5.6236 9.78314 5.5 15.275L5.5 13.0462L5.5 15.5C5.5062 21.0203 9.97972 25.4938 15.5 25.5ZM15.5 9.25L21.75 15.5L15.5 21.75L13.7375 19.9875L16.9625 16.75L9.25 16.75L9.25 14.25L16.9625 14.25L13.7375 11.0125L15.5 9.25Z"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: "CtaButton",
  props: {
    bgColor: {
      type: String,
      default: "bg-darkblue",
    },
    text: {
      type: String,
      default: "Button",
    },
    eventName: {
      type: String,
      default: "ctaClick",
    },
  },
  methods: {
    click() {
      this.$emit(`${this.eventName}_cta`);
    },
  },
};
</script>

<template>
  <div class="max-container">
    <div class="main-grid">
      <div class="col-span-full text-center">
        <h2 class="text-h3 xl:text-h2 font-semibold mb-4 mx-auto">
          Homebuying, simplified.
        </h2>
        <h4 class="font-semibold text-body-2 xl:text-h4 mb-8 xl:mb-10 mx-auto">
          We're fixing this mind-boggling process.
        </h4>
      </div>

      <div class="col-span-full xl:col-start-2 xl:col-span-10 text-center">
        <div
          class="flex flex-col md:flex-row items-center md:justify-center md:ml-[-1rem] mb-2 md:items-stretch"
        >
          <div class="homebuying-card">
            <div>Guaranteed Pricing</div>

            <p>
              Skip the bidding wars! We'll even cover increased costs due to
              supply chains.
            </p>

            <img
              src="@/assets/images/guaranteed_price.png"
              alt="Guaranteed price badge"
            />
          </div>
          <div class="homebuying-card">
            <div>Early Underwriting</div>

            <p>
              Affinity Bank, our exclusive lender, starts underwritng early to
              lock in your price and interest rates ASAP.
            </p>

            <img
              src="@/assets/images/underwriting.png"
              alt="Paper and clock badge"
            />
          </div>
          <div class="homebuying-card">
            <div>Your Personal Coordinator</div>

            <p>
              We coordinate with everyone-- from builders to lenders to the
              HOA-- until you move in.
            </p>

            <img src="@/assets/images/support.png" alt="Headphones badge" />
          </div>
        </div>
      </div>

      <div
        class="col-span-full text-center text-body-2 xl:text-h4 font-semibold"
      >
        <router-link to="/how-it-works" class="text-blue-500">
          Learn more about how it works ->
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Homebuying",
  methods: {
    onClick() {
      this.$router.push({
        path: "/locations/hollysprings",
      });
    },
  },
};
</script>

<style scoped>
.homebuying-card {
  @apply py-6 px-8 rounded-16 bg-creme-300 relative text-left mb-5 mx-0 md:mx-4 w-full md:w-[340px];
}
.homebuying-card div {
  @apply font-semibold text-body-2 xl:text-body-1 mb-4;
}
.homebuying-card p {
  @apply text-body-3 xl:text-body-2 max-w-[75%] lg:max-w-[260px];
}
.homebuying-card img {
  @apply absolute bottom-1/4 right-[-1rem] md:bottom-[-15px] md:right-[-20px] w-20 h-20;
}
</style>

<template>
  <section class="hero">
    <div class="hero-image"></div>

    <div class="max-container mx-auto w-full z-10">
      <div class="hero-content">
        <div
          class="bg-yellow-500 text-body-2 md:text-body-1 font-semibold py-4 text-center rounded-t-16"
        >
          Reservation open until Jun 8
        </div>
        <div
          class="text-h3 md:text-h2 font-semibold mx-8 md:mx-16 lg:mx-12 xl:mx-16 mt-8 text-left"
        >
          {{ location.name }}
        </div>
        <div
          class="text-body-1 md:text-h4 font-semibold mx-8 md:mx-16 lg:mx-12 xl:mx-16 my-4 text-left"
        >
          {{ cityWithState }}
        </div>
        <PrimaryBaseCta
          text="I'm interested"
          class="my-4 ml-8 md:ml-16 lg:mx-12 xl:mx-16 self-start"
          endImage="ico_circle_arrow_white.svg"
          @click="toggleModal"
        />
      </div>
    </div>

    <LocationInterestModal
      v-if="modalOpened"
      @closeLocationInterestModal="toggleModal()"
    />
  </section>
</template>

<script>
import { PrimaryBaseCta } from "@/components";
import { trackEvent } from "@/helpers/events.js";

export default {
  name: "Hero",
  components: {
    PrimaryBaseCta,
    // I don't know why normal importing does not work, so we async import it
    LocationInterestModal: () => import("../modals/LocationInterestModal.vue"),
  },
  props: {
    location: {
      type: Object,
      default: function () {
        return {};
      },
    },
    availableFrom: {
      type: String,
      default: "2022",
    },
  },
  data() {
    return {
      modalOpened: false,
    };
  },
  computed: {
    cityWithState: function () {
      return `${this.location.city}, ${this.location.stateAbbreviation}`;
    },
  },
  methods: {
    toggleModal() {
      this.modalOpened = !this.modalOpened;
      if (this.modalOpened) {
        document.querySelector("html").classList.add("overflow-hidden");
        trackEvent("Button", "Interested", "Holly Springs");
      } else document.querySelector("html").classList.remove("overflow-hidden");
    },
  },
};
</script>

<style scoped>
.hero {
  @apply w-full min-h-[560px] relative flex flex-col justify-end;
}

.hero-image {
  background: url("~@/assets/images/np_hero.jpg");
  @apply absolute top-0 bottom-0 left-0 right-0 bg-no-repeat bg-center bg-cover;
}

.hero-content {
  @apply rounded-t-16 flex flex-col lg:ml-4 xl:ml-24;
  @apply w-full lg:w-[500px] h-full lg:h-[335px] bg-creme-50 text-gray-900 text-h4 font-extrabold;
}
</style>

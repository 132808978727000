<template>
  <Modal @closeModal="internalClose">
    <h1 class="title">
      I'm interested in
      <span class="text-blue-500 font-semibold">{{ neighborhood }}</span
      >.
    </h1>

    <div class="mb-8 xl:mb-10">
      <p class="sub-title">
        A Home Specialist will reach out via email to set up a phone call to
        walk through pre-approval and reservation, and answer any of your
        questions.
      </p>
      <p class="sub-title">
        We're excited to help you start your HomeFounder journey.
      </p>
    </div>

    <form @submit.prevent="submit" class="column-def xl:mt-16 mt-8">
      <div class="field-wrapper">
        <div class="mr-8">
          <div class="field-label">First name</div>
          <TextInput v-model="firstName" :required="true" />
        </div>
        <div class="xl:mt-0">
          <div class="field-label">Last name</div>
          <TextInput v-model="lastName" :required="true" />
        </div>
      </div>
      <div class="flex flex-col mt-6">
        <div>
          <div class="field-label">Email</div>
          <TextInput inputType="email" :required="true" v-model="email" />
        </div>
        <div class="mt-6">
          <div class="field-label">My top choice home model is...</div>
          <Dropdown
            :choices="homeModels"
            :oneChoiceAllowed="true"
            v-model="chosenHomeModel"
            :preselectedChoice="preselectedHomeModel"
          />
        </div>
        <div class="mt-6">
          <div class="field-label">When is your ideal move date?</div>
          <Dropdown
            :choices="moveDates"
            :oneChoiceAllowed="true"
            v-model="idealMoveDate"
            placeholder="(Optional)"
          />
        </div>
      </div>

      <div class="xl:mt-16 mt-8">
        <div class="flex flex-row items-center align-middle">
          <Checkbox v-model="isAgent" ref="chk" />
          <div class="ml-6 text-body-2 xl:text-body-1 text-gray-900">
            I am a real estate agent.
          </div>
        </div>
      </div>

      <div
        class="xl:mt-16 mt-8 font-semibold text-body-2 xl:text-body-1 text-red-500"
        v-if="errorText"
      >
        {{ errorText }}
      </div>

      <div class="flex justify-end mt-8">
        <PrimaryBaseCta
          text="Contact our team"
          fixedSize="medium"
          endImage="ico_circle_arrow_white.svg"
          @click="submit"
          eventName="submit"
          :disabled="disabled"
        />
      </div>
    </form>
  </Modal>
</template>

<script>
import Modal from "./Modal.vue";
import { PrimaryBaseCta, TextInput, Dropdown, Checkbox } from "@/components";
import { HUBSPOT_PAGES, MOVE_IN_DATES_ARRAY } from "@/constants";
import { postToHubspot } from "@/helpers/postToHubspot.js";

export default {
  name: "LocationInterestModal",
  components: {
    Modal,
    PrimaryBaseCta,
    TextInput,
    Dropdown,
    Checkbox,
  },
  props: {
    neighborhood: {
      type: String,
      default: "Holly Springs",
    },
    preselectedHomeModel: {
      type: String,
      default: "No Preference",
    },
  },
  data() {
    return {
      idealMoveDate: null,
      firstName: null,
      lastName: null,
      email: null,
      isAgent: false,
      disabled: false,
      errorText: null,
      chosenHomeModel: null,
      moveDates: MOVE_IN_DATES_ARRAY,
      homeModels: [
        { name: "No Preference", value: "No Preference" },
        { name: "Jasmine", value: "Jasmine" },
        { name: "Chelsea", value: "Chelsea" },
        { name: "Gardenia", value: "Gardenia" },
        { name: "Cobalt", value: "Cobalt" },
      ],
    };
  },
  methods: {
    internalClose() {
      this.$emit("closeLocationInterestModal");
    },
    submit() {
      this.disabled = true;
      this.errorText = null;

      const data = {
        firstname: this.firstName,
        lastname: this.lastName,
        email: this.email,
        ideal_move_date: this.idealMoveDate,
        is_agent: this.isAgent,
        home_model_interest: this.chosenHomeModel,
        is_fthb: this.isFirstTimeBuyer,
      };

      postToHubspot(HUBSPOT_PAGES.HOLLYSPRINGS, data)
        .then((res) => {
          if (res.success) {
            this.$router.push({
              path: "/thanks-holly",
            });
          }
        })
        .catch((error) => (this.errorText = error));

      this.disabled = false;
    },
  },
};
</script>

<style scoped>
.title {
  @apply text-h4 xl:text-h3 max-w-[400px] mb-8 xl:mb-12;
}
.sub-title {
  @apply text-body-2 xl:text-body-1 mb-6 xl:mb-8;
}
.field-wrapper {
  @apply flex flex-row mt-6;
}
.field-wrapper > div {
  @apply xl:w-1/2 w-full;
}
.field-label {
  @apply text-body-2 xl:text-body-1 font-semibold text-gray-900 mb-3;
}
</style>

import moment from "moment";
import Vue from "vue";

const RELEASE_DATE = moment.utc(process.env.VUE_APP_RELEASE_DATE);
const CURRENT_DATE = moment.utc();

const store = Vue.observable({ isPreRelease: true });

const actions = {
  isPreRelease() {
    return store.isPreRelease;
  },
};

//set it initially then check every minute
store.isPreRelease = CURRENT_DATE.isBefore(RELEASE_DATE);

setInterval(() => {
  store.isPreRelease = CURRENT_DATE.isBefore(RELEASE_DATE);
}, 1000 * 60); //Check every minute

export default actions;

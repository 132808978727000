<template>
  <div class="max-container">
    <div class="main-grid">
      <div class="col-span-full xl:col-span-6 xl:col-start-4 text-center">
        <h2 class="text-h3 lg:text-h2 font-semibold mb-4 mx-auto">
          Upcoming Locations
        </h2>
        <h2 class="font-semibold text-body-2 lg:text-h4 mb-12 lg:mb-24 mx-auto">
          Open for reservation in May 2022
        </h2>
      </div>
      <div
        class="col-span-full lg:col-span-6 lg:col-start-2 text-body-1 lg:text-h4"
      >
        <p class="font-extrabold">Douglasville, GA</p>
        <h3
          class="text-h3 lg:text-h2 font-semibold mb-7 max-w-[350px] lg:max-w-full"
        >
          Holly Springs subdivision
        </h3>
        <p class="mb-8 text-body-2">
          Holly Springs is a well-established subdivision in Douglasville, GA
          (not to be confused the city, Holly Springs, GA). With easy access to
          both downtown and the state park, it's the perfect balance of space
          and convenience.
        </p>
        <img
          class="rounded-32 mx-auto mb-8 object-contain"
          src="@/assets/images/hp_neighborhood.png"
          alt=""
        />
        <img
          class="rounded-16 mx-auto mb-8 border border-gray-900 object-contain"
          src="@/assets/images/hp_map.png"
          alt=""
        />
      </div>
      <div class="col-span-full lg:col-span-3 lg:col-start-9">
        <div class="grid gap-8 lg:gap-12 grid-cols-1">
          <div>
            <p class="upcoming-locations-title">5</p>
            <p class="upcoming-locations-subtitle">Homes available</p>
          </div>

          <div>
            <p class="upcoming-locations-title">$500,000+</p>
            <p class="upcoming-locations-subtitle mb-3">
              Appraised Home Values
            </p>
            <p class="upcoming-locations-subtitle"></p>
            <span
              class="px-2 py-1 bg-yellow-500 rounded-8 text-body-1 lg:text-h4 font-semibold"
            >
              <router-link to="/home#calculator"> Save $166k+ </router-link>
            </span>
          </div>
          <div>
            <p class="upcoming-locations-title">Dec '22</p>
            <p class="upcoming-locations-subtitle">Move-In Estimate</p>
          </div>

          <hr />

          <div>
            <p class="upcoming-locations-title">30min</p>
            <p class="upcoming-locations-subtitle">drive to downtown Atlanta</p>
          </div>
          <div>
            <p class="upcoming-locations-title">7.6/10 (Good)</p>
            <p class="upcoming-locations-subtitle">average school rating</p>
          </div>
        </div>
      </div>

      <div class="col-span-full xl:col-span-10 xl:col-start-2">
        <PrimaryBaseCta
          class="mx-auto mt-12 mb-8 xl:mt-0 xl:mb-0"
          text="Explore the community"
          fixedSize="large"
          endImage="ico_circle_arrow_white.svg"
          @click="onClick"
        />
      </div>
      <div
        class="col-span-full xl:col-span-10 xl:col-start-2 text-center xl:mt-16"
      >
        <div class="text-body-1 sm:text-h4">
          Interested in a different area? <br class="xl:hidden" />
          <router-link to="/locations">
            <span class="text-blue-500 font-semibold">
              Get notified about other locations
            </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PrimaryBaseCta } from "@/components";
export default {
  name: "UpcomingLocations",
  components: {
    PrimaryBaseCta,
  },
  methods: {
    onClick() {
      this.$router.push({
        path: "/locations/hollysprings",
      });
    },
  },
};
</script>

<style scoped>
.upcoming-locations-title {
  @apply text-h4 sm:text-h3 font-semibold mb-1;
}
.upcoming-locations-subtitle {
  @apply text-body-1 sm:text-h4;
}
</style>

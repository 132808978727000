var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MglMap',{staticClass:"styled-map",attrs:{"center":_vm.center,"zoom":_vm.zoom,"accessToken":_vm.accessToken,"mapStyle":_vm.mapStyle},on:{"click":_vm.onMapClick,"load":_vm.onMapLoaded,"styledata":_vm.onStyleLoaded,"styledataloading":_vm.onStyleChange}},[(_vm.mapLoaded)?_c('section',_vm._l((_vm.lotArray),function(lot){return _c('section',{key:lot.id},[_c('MglGeojsonLayer',{attrs:{"sourceId":lot.geojson.data.id,"source":lot.geojson,"layerId":lot.shortName,"layer":_vm.getGeoJsonlayerBottom(
            lot,
            _vm.selectedLot ? _vm.selectedLot.id : null,
            _vm.selectedHouseModel ? _vm.selectedHouseModel.id : null
          )}}),_c('MglGeojsonLayer',{attrs:{"sourceId":lot.geojson.data.id,"source":lot.geojson,"layerId":lot.name,"layer":_vm.getGeoJsonlayerTop(
            lot,
            _vm.selectedLot ? _vm.selectedLot.id : null,
            _vm.selectedHouseModel ? _vm.selectedHouseModel.id : null
          )}}),_c('MglMarker',{key:_vm.selectedLot.id,attrs:{"coordinates":lot.geojson.data.features[1].geometry.coordinates},on:{"update:coordinates":function($event){return _vm.$set(lot.geojson.data.features[1].geometry, "coordinates", $event)},"click":function($event){return _vm.handleMarkerClickEvent($event, lot)},"mouseenter":function($event){_vm.lotPopupStatusList[lot.id] = true},"mouseleave":function($event){_vm.lotPopupStatusList[lot.id] = false}}},[(_vm.isPopupShown(lot))?_c('section',[_c('MglPopup',{attrs:{"closeButton":false,"closeOnClick":false,"showed":_vm.lotPopupStatusList[lot.id],"anchor":"bottom"}},[_c('MarkerPopup',{attrs:{"currentLot":lot,"selectedLot":_vm.selectedLot,"selectedHouseModel":_vm.selectedHouseModel}})],1)],1):_vm._e(),_c('CustomMarker',{attrs:{"slot":"marker","currentLot":lot,"selectedLot":_vm.selectedLot,"selectedHouseModel":_vm.selectedHouseModel},slot:"marker"})],1)],1)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
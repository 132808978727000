const models = [
  {
    id: 1,
    name: 550000,
    upfrontHomeSavings: 82500,
    appraisedHomeValue: 550000,
    homeFounderPrice: 467500,
    monthlyMortageSaving: 378,
    totalInterestSaving: 53580,
    extraMoney: 136080,
    kidsCollege: 4,
    newCars: 5,
    exoticVacations: 27,
    land: 60000,
    landPreparation: 70000,
    construction: 327000,
    homeFounderFee: 10000,
  },
  {
    id: 2,
    name: 450000,
    upfrontHomeSavings: 67500,
    appraisedHomeValue: 450000,
    homeFounderPrice: 382500,
    monthlyMortageSaving: 310,
    totalInterestSaving: 44100,
    extraMoney: 111600.0,
    kidsCollege: 3,
    newCars: 4,
    exoticVacations: 22,
    land: 51500,
    landPreparation: 60000,
    construction: 261000,
    homeFounderFee: 10000,
  },
  {
    id: 3,
    name: 350000,
    upfrontHomeSavings: 52500,
    appraisedHomeValue: 350000,
    homeFounderPrice: 297500,
    monthlyMortageSaving: 241,
    totalInterestSaving: 34260,
    extraMoney: 86760.0,
    kidsCollege: 2,
    newCars: 3,
    exoticVacations: 17,
    land: 40000,
    landPreparation: 50000,
    construction: 197500,
    homeFounderFee: 10000,
  },
];

export const getCalculatorHomePrices = () => models;

import { AVAILABLE, RESERVED, SOLD, UNDER_CONTRACT } from "@/constants";
import { API } from "aws-amplify";

const locations = [
  {
    id: 1,
    slug: "hollysprings",
    location: {
      name: "Holly Springs",
      city: "Douglasville",
      state: "Georgia",
      stateAbbreviation: "GA",
    },
    homesites: {
      numAvailable: 5,
    },
    houseModels: {
      numOptions: 5,
      models: [
        {
          id: 266,
          availability: AVAILABLE,
          name: "Cobalt",
          houseSlug: "cobalt",
          address: "47 Address dr.",
          location: {
            name: "Holly Springs",
            city: "Douglasville",
            state: "Georgia",
            stateAbbreviation: "GA",
            slug: "hollysprings",
          },
          matterportId: "tM4ZVRdtmu4",
          exterior: {
            land: {
              paragraphs: [
                "The home will be built on a spacious 0.3 acres lot in the heart of The Estates at Holly Springs.",
                "This beautiful community has extensive amenities and is conveniently located just 30 minutes away from downtown Atlanta.",
              ],
              linkText: "The Estates at Holly Springs",
            },
            floorplans: {
              paragraphs: [
                "This two-story home features four luxuriously-sized bedrooms, a two-car garage, a covered porch, and a beautiful sunlit lounge overlooking the the first floor. There is a utility or laundry room on each floor.",
              ],
            },
            paragraphs: [
              "Roof peaks, window shingles and brick accents come together to create a traditional exterior with elegant appeal.",
            ],
            exteriorImage: {
              id: 111,
              src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/exterior_image.png",
              alt: "Exterior image",
            },
          },
          interior: {
            images: {
              kitchen: [
                {
                  id: 1,
                  src: "/img/cobalt_kitchen.jpg",
                  alt: "Kitchen",
                  floor: "1st floor",
                  description:
                    "The sunny kitchen features a breakfast nook and a kitchen island, which serious and hobby cooks alike will find indispensable. A pantry helps keep things organized.",
                },
              ],
              livingRoom: [
                {
                  id: 2,
                  src: "/img/cobalt_family_room_1.jpg",
                  alt: "Family room",
                  floor: "1st floor",
                  description: "",
                },
                {
                  id: 3,
                  src: "/img/cobalt_family_room_2.jpg",
                  alt: "Family room",
                  floor: "1st floor",
                  description: "",
                },
              ],
              bedrooms: [
                {
                  id: 11,
                  src: "/img/cobalt_master_bedroom.jpg",
                  alt: "Master bedroom",
                  floor: "1st floor",
                  description: "",
                },
              ],
              bathrooms: [
                {
                  id: 12,
                  src: "/img/cobalt_master_bathroom.jpg",
                  alt: "Master bathroom",
                  floor: "1st floor",
                  description: "1st floor - Description",
                },
              ],
              lounge: [
                {
                  id: 13,
                  src: "/img/cobalt_lounge.jpg",
                  alt: "Lounge",
                  floor: "2nd floor",
                  description: "",
                },
              ],
            },
          },
          houseImages: [
            { id: 1001, alt: "Outside", src: "/img/h_cobalt_main.jpg" },
          ],
          floorplansImages: [
            {
              id: 1000,
              floor: 0,
              alt: "Basement",
              src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/gardenia_basement.png",
            },
            {
              id: 2000,
              floor: 1,
              alt: "1st floor",
              src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/gardenia_first_floor.png",
            },
            {
              id: 3000,
              floor: 2,
              alt: "2nd floor",
              src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/gardenia_second_floor.png",
            },
          ],
          bedrooms: 6,
          bathrooms: 4,
          carGarage: 3,
          costs: {
            price: 491131,
            value: 532241,
            homefounderFee: 11000,
            construction: {
              landDevelopmentPrice: 63000,
              price: 288936,
            },
            requiredDownpayment: {
              percentage: 20,
              price: 89340,
            },
            estimatedMonthlyPayment: {
              numberOfYears: 15,
              amount: 2953,
            },
            closingCosts: {
              independent: {
                percentage: 0.5,
                amount: 2395,
              },
              agent: {
                percentage: 3.5,
                amount: 16764,
              },
            },
            annualCosts: {
              propertyTax: 4519,
              propertyInsurance: 1265,
              hoaDues: 550,
            },
          },
          squareFootage: 3654,
          summary:
            "First floor living room, large kitchen, master suite, and 2-story great room. Additional 3 bedrooms and 2 bathrooms upstairs.",
          moreInfo:
            "Options to add a bonus room, garage space, and rear patio or porch.",
          features: [
            { id: 1, name: "Tile backsplash" },
            { id: 2, name: "Lenox air-conditioning" },
            { id: 3, name: "LED recessed lighting" },
            { id: 4, name: "55-gallon electric water heater" },
            { id: 5, name: "Timberlake&trade; cabinetry" },
            { id: 6, name: "Stainless steel slide-in gas range" },
          ],
          lot: {
            name: "Lot #1",
            size: 0.5,
            image: "/img/lot1.png",
            price: 32000,
            bigImage:
              "https://hf-images-hosted.s3.us-west-2.amazonaws.com/lot1.jpg",
          },
          banners: {
            bottom: {
              id: 11123,
              src: "/img/cobalt_banner.jpg",
              alt: "Cobalt house banner",
            },
          },
        },
        {
          id: 165,
          availability: RESERVED,
          name: "Chelsea",
          houseSlug: "chelsea",
          address: "47 Address dr.",
          location: {
            name: "Holly Springs",
            city: "Douglasville",
            state: "Georgia",
            stateAbbreviation: "GA",
            slug: "hollysprings",
          },
          matterportId: "tM4ZVRdtmu4",
          exterior: {
            land: {
              paragraphs: [
                "The home will be built on a spacious 0.3 acres lot in the heart of The Estates at Holly Springs.",
                "This beautiful community has extensive amenities and is conveniently located just 30 minutes away from downtown Atlanta.",
              ],
              linkText: "The Estates at Holly Springs",
            },
            floorplans: {
              paragraphs: [
                "This two-story home features four luxuriously-sized bedrooms, a two-car garage, a covered porch, and a beautiful sunlit lounge overlooking the the first floor. There is a utility or laundry room on each floor.",
              ],
            },
            paragraphs: [
              "Roof peaks, window shingles and brick accents come together to create a traditional exterior with elegant appeal.",
            ],
            exteriorImage: {
              id: 111,
              src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/exterior_image.png",
              alt: "Exterior image",
            },
          },
          interior: {
            images: {
              kitchen: [
                {
                  id: 1,
                  src: "/img/cobalt_kitchen.jpg",
                  alt: "Kitchen",
                  floor: "1st floor",
                  description:
                    "The sunny kitchen features a breakfast nook and a kitchen island, which serious and hobby cooks alike will find indispensable. A pantry helps keep things organized.",
                },
              ],
              livingRoom: [
                {
                  id: 2,
                  src: "/img/cobalt_family_room_1.jpg",
                  alt: "Family room",
                  floor: "1st floor",
                  description: "",
                },
                {
                  id: 3,
                  src: "/img/cobalt_family_room_2.jpg",
                  alt: "Family room",
                  floor: "1st floor",
                  description: "",
                },
              ],
              bedrooms: [
                {
                  id: 11,
                  src: "/img/cobalt_master_bedroom.jpg",
                  alt: "Master bedroom",
                  floor: "1st floor",
                  description: "",
                },
              ],
              bathrooms: [
                {
                  id: 12,
                  src: "/img/cobalt_master_bathroom.jpg",
                  alt: "Master bathroom",
                  floor: "1st floor",
                  description: "1st floor - Description",
                },
              ],
              lounge: [
                {
                  id: 13,
                  src: "/img/cobalt_lounge.jpg",
                  alt: "Lounge",
                  floor: "2nd floor",
                  description: "",
                },
              ],
            },
          },
          houseImages: [
            { id: 100, name: "Outside", src: "/img/h_chelsea_main.jpg" },
          ],
          floorplansImages: [
            {
              id: 1000,
              floor: 0,
              alt: "Basement",
              src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/gardenia_basement.png",
            },
            {
              id: 2000,
              floor: 1,
              alt: "1st floor",
              src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/gardenia_first_floor.png",
            },
            {
              id: 3000,
              floor: 2,
              alt: "2nd floor",
              src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/gardenia_second_floor.png",
            },
          ],
          bedrooms: 5,
          bathrooms: 4,
          carGarage: 1,
          costs: {
            price: 491131,
            value: 532241,
            homefounderFee: 11000,
            construction: {
              landDevelopmentPrice: 63000,
              price: 288936,
            },
            requiredDownpayment: {
              percentage: 20,
              price: 89340,
            },
            estimatedMonthlyPayment: {
              numberOfYears: 15,
              amount: 2953,
            },
            closingCosts: {
              independent: {
                percentage: 0.5,
                amount: 2395,
              },
              agent: {
                percentage: 3.5,
                amount: 16764,
              },
            },
            annualCosts: {
              propertyTax: 4519,
              propertyInsurance: 1265,
              hoaDues: 550,
            },
          },
          squareFootage: 2976,
          summary:
            "First floor living room, large kitchen, master suite, and 2-story great room. Additional 3 bedrooms and 2 bathrooms upstairs.",
          moreInfo:
            "Options to add a bonus room, garage space, and rear patio or porch.",
          features: [
            { id: 1, name: "Tile backsplash" },
            { id: 2, name: "Lenox air-conditioning" },
            { id: 3, name: "LED recessed lighting" },
            { id: 4, name: "55-gallon electric water heater" },
            { id: 5, name: "Timberlake&trade; cabinetry" },
            { id: 6, name: "Stainless steel slide-in gas range" },
          ],
          lot: {
            name: "Lot #2",
            size: 0.8,
            image: "/img/lot2.png",
            price: 32000,
            bigImage:
              "https://hf-images-hosted.s3.us-west-2.amazonaws.com/lot1.jpg",
          },
          banners: {
            bottom: {
              id: 11123,
              src: "/img/cobalt_banner.jpg",
              alt: "Cobalt house banner",
            },
          },
        },
        {
          id: 261,
          availability: AVAILABLE,
          name: "Gardenia",
          houseSlug: "gardenia",
          address: "47 Address dr.",
          location: {
            name: "Holly Springs",
            city: "Douglasville",
            state: "Georgia",
            stateAbbreviation: "GA",
            slug: "hollysprings",
          },
          matterportId: "tM4ZVRdtmu4",
          exterior: {
            land: {
              paragraphs: [
                "The home will be built on a spacious 0.3 acres lot in the heart of The Estates at Holly Springs.",
                "This beautiful community has extensive amenities and is conveniently located just 30 minutes away from downtown Atlanta.",
              ],
              linkText: "The Estates at Holly Springs",
            },
            floorplans: {
              paragraphs: [
                "This two-story home features four luxuriously-sized bedrooms, a two-car garage, a covered porch, and a beautiful sunlit lounge overlooking the the first floor. There is a utility or laundry room on each floor.",
              ],
            },
            paragraphs: [
              "Roof peaks, window shingles and brick accents come together to create a traditional exterior with elegant appeal.",
            ],
            exteriorImage: {
              id: 111,
              src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/exterior_image.png",
              alt: "Exterior image",
            },
          },
          interior: {
            images: {
              kitchen: [
                {
                  id: 1,
                  src: "/img/cobalt_kitchen.jpg",
                  alt: "Kitchen",
                  floor: "1st floor",
                  description:
                    "The sunny kitchen features a breakfast nook and a kitchen island, which serious and hobby cooks alike will find indispensable. A pantry helps keep things organized.",
                },
              ],
              livingRoom: [
                {
                  id: 2,
                  src: "/img/cobalt_family_room_1.jpg",
                  alt: "Family room",
                  floor: "1st floor",
                  description: "",
                },
                {
                  id: 3,
                  src: "/img/cobalt_family_room_2.jpg",
                  alt: "Family room",
                  floor: "1st floor",
                  description: "",
                },
              ],
              bedrooms: [
                {
                  id: 11,
                  src: "/img/cobalt_master_bedroom.jpg",
                  alt: "Master bedroom",
                  floor: "1st floor",
                  description: "",
                },
              ],
              bathrooms: [
                {
                  id: 12,
                  src: "/img/cobalt_master_bathroom.jpg",
                  alt: "Master bathroom",
                  floor: "1st floor",
                  description: "1st floor - Description",
                },
              ],
              lounge: [
                {
                  id: 13,
                  src: "/img/cobalt_lounge.jpg",
                  alt: "Lounge",
                  floor: "2nd floor",
                  description: "",
                },
              ],
            },
          },
          houseImages: [
            { id: 100, name: "Outside", src: "/img/h_gardenia_main.jpg" },
          ],
          floorplansImages: [
            {
              id: 1000,
              floor: 0,
              alt: "Basement",
              src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/gardenia_basement.png",
            },
            {
              id: 2000,
              floor: 1,
              alt: "1st floor",
              src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/gardenia_first_floor.png",
            },
            {
              id: 3000,
              floor: 2,
              alt: "2nd floor",
              src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/gardenia_second_floor.png",
            },
          ],
          bedrooms: 5,
          bathrooms: 3.5,
          carGarage: 2,
          costs: {
            price: 491131,
            value: 532241,
            homefounderFee: 11000,
            construction: {
              landDevelopmentPrice: 63000,
              price: 288936,
            },
            requiredDownpayment: {
              percentage: 20,
              price: 89340,
            },
            estimatedMonthlyPayment: {
              numberOfYears: 15,
              amount: 2953,
            },
            closingCosts: {
              independent: {
                percentage: 0.5,
                amount: 2395,
              },
              agent: {
                percentage: 3.5,
                amount: 16764,
              },
            },
            annualCosts: {
              propertyTax: 4519,
              propertyInsurance: 1265,
              hoaDues: 550,
            },
          },
          squareFootage: 3432,
          summary:
            "First floor living room, large kitchen, master suite, and 2-story great room. Additional 3 bedrooms and 2 bathrooms upstairs.",
          moreInfo:
            "Options to add a bonus room, garage space, and rear patio or porch.",
          features: [
            { id: 1, name: "Tile backsplash" },
            { id: 2, name: "Lenox air-conditioning" },
            { id: 3, name: "LED recessed lighting" },
            { id: 4, name: "55-gallon electric water heater" },
            { id: 5, name: "Timberlake&trade; cabinetry" },
            { id: 6, name: "Stainless steel slide-in gas range" },
          ],
          lot: {
            name: "Lot #3",
            size: 0.2,
            image: "/img/lot3.png",
            price: 32000,
            bigImage:
              "https://hf-images-hosted.s3.us-west-2.amazonaws.com/lot1.jpg",
          },
          banners: {
            bottom: {
              id: 11123,
              src: "/img/cobalt_banner.jpg",
              alt: "Cobalt house banner",
            },
          },
        },
        {
          id: 137,
          availability: SOLD,
          name: "Jasmine",
          houseSlug: "jasmine",
          address: "47 Address dr.",
          location: {
            name: "Holly Springs",
            city: "Douglasville",
            state: "Georgia",
            stateAbbreviation: "GA",
            slug: "hollysprings",
          },
          matterportId: "tM4ZVRdtmu4",
          exterior: {
            land: {
              paragraphs: [
                "The home will be built on a spacious 0.3 acres lot in the heart of The Estates at Holly Springs.",
                "This beautiful community has extensive amenities and is conveniently located just 30 minutes away from downtown Atlanta.",
              ],
              linkText: "The Estates at Holly Springs",
            },
            floorplans: {
              paragraphs: [
                "This two-story home features four luxuriously-sized bedrooms, a two-car garage, a covered porch, and a beautiful sunlit lounge overlooking the the first floor. There is a utility or laundry room on each floor.",
              ],
            },
            paragraphs: [
              "Roof peaks, window shingles and brick accents come together to create a traditional exterior with elegant appeal.",
            ],
            exteriorImage: {
              id: 111,
              src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/exterior_image.png",
              alt: "Exterior image",
            },
          },
          interior: {
            images: {
              kitchen: [
                {
                  id: 1,
                  src: "/img/cobalt_kitchen.jpg",
                  alt: "Kitchen",
                  floor: "1st floor",
                  description:
                    "The sunny kitchen features a breakfast nook and a kitchen island, which serious and hobby cooks alike will find indispensable. A pantry helps keep things organized.",
                },
              ],
              livingRoom: [
                {
                  id: 2,
                  src: "/img/cobalt_family_room_1.jpg",
                  alt: "Family room",
                  floor: "1st floor",
                  description: "",
                },
                {
                  id: 3,
                  src: "/img/cobalt_family_room_2.jpg",
                  alt: "Family room",
                  floor: "1st floor",
                  description: "",
                },
              ],
              bedrooms: [
                {
                  id: 11,
                  src: "/img/cobalt_master_bedroom.jpg",
                  alt: "Master bedroom",
                  floor: "1st floor",
                  description: "",
                },
              ],
              bathrooms: [
                {
                  id: 12,
                  src: "/img/cobalt_master_bathroom.jpg",
                  alt: "Master bathroom",
                  floor: "1st floor",
                  description: "1st floor - Description",
                },
              ],
              lounge: [
                {
                  id: 13,
                  src: "/img/cobalt_lounge.jpg",
                  alt: "Lounge",
                  floor: "2nd floor",
                  description: "",
                },
              ],
            },
          },
          houseImages: [
            { id: 100, name: "Outside", src: "/img/h_jasmine_main.jpg" },
          ],
          floorplansImages: [
            {
              id: 1000,
              floor: 0,
              alt: "Basement",
              src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/gardenia_basement.png",
            },
            {
              id: 2000,
              floor: 1,
              alt: "1st floor",
              src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/gardenia_first_floor.png",
            },
            {
              id: 3000,
              floor: 2,
              alt: "2nd floor",
              src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/gardenia_second_floor.png",
            },
          ],
          bedrooms: 5,
          bathrooms: 4,
          carGarage: 2,
          costs: {
            price: 491131,
            value: 532241,
            homefounderFee: 11000,
            construction: {
              landDevelopmentPrice: 63000,
              price: 288936,
            },
            requiredDownpayment: {
              percentage: 20,
              price: 89340,
            },
            estimatedMonthlyPayment: {
              numberOfYears: 15,
              amount: 2953,
            },
            closingCosts: {
              independent: {
                percentage: 0.5,
                amount: 2395,
              },
              agent: {
                percentage: 3.5,
                amount: 16764,
              },
            },
            annualCosts: {
              propertyTax: 4519,
              propertyInsurance: 1265,
              hoaDues: 550,
            },
          },
          squareFootage: 3037,
          summary:
            "First floor living room, large kitchen, master suite, and 2-story great room. Additional 3 bedrooms and 2 bathrooms upstairs.",
          moreInfo:
            "Options to add a bonus room, garage space, and rear patio or porch.",
          features: [
            { id: 1, name: "Tile backsplash" },
            { id: 2, name: "Lenox air-conditioning" },
            { id: 3, name: "LED recessed lighting" },
            { id: 4, name: "55-gallon electric water heater" },
            { id: 5, name: "Timberlake&trade; cabinetry" },
            { id: 6, name: "Stainless steel slide-in gas range" },
          ],
          lot: {
            name: "Lot #4",
            size: 0.4,
            image: "/img/lot4.png",
            price: 32000,
            bigImage:
              "https://hf-images-hosted.s3.us-west-2.amazonaws.com/lot1.jpg",
          },
          banners: {
            bottom: {
              id: 11123,
              src: "/img/cobalt_banner.jpg",
              alt: "Cobalt house banner",
            },
          },
        },
        {
          id: 267,
          availability: AVAILABLE,
          name: "Marshall",
          houseSlug: "marshall",
          address: "47 Address dr.",
          location: {
            name: "Holly Springs",
            city: "Douglasville",
            state: "Georgia",
            stateAbbreviation: "GA",
            slug: "hollysprings",
          },
          matterportId: "tM4ZVRdtmu4",
          exterior: {
            land: {
              paragraphs: [
                "The home will be built on a spacious 0.3 acres lot in the heart of The Estates at Holly Springs.",
                "This beautiful community has extensive amenities and is conveniently located just 30 minutes away from downtown Atlanta.",
              ],
              linkText: "The Estates at Holly Springs",
            },
            floorplans: {
              paragraphs: [
                "This two-story home features four luxuriously-sized bedrooms, a two-car garage, a covered porch, and a beautiful sunlit lounge overlooking the the first floor. There is a utility or laundry room on each floor.",
              ],
            },
            paragraphs: [
              "Roof peaks, window shingles and brick accents come together to create a traditional exterior with elegant appeal.",
            ],
            exteriorImage: {
              id: 111,
              src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/exterior_image.png",
              alt: "Exterior image",
            },
          },
          interior: {
            images: {
              kitchen: [
                {
                  id: 1,
                  src: "/img/cobalt_kitchen.jpg",
                  alt: "Kitchen",
                  floor: "1st floor",
                  description:
                    "The sunny kitchen features a breakfast nook and a kitchen island, which serious and hobby cooks alike will find indispensable. A pantry helps keep things organized.",
                },
              ],
              livingRoom: [
                {
                  id: 2,
                  src: "/img/cobalt_family_room_1.jpg",
                  alt: "Family room",
                  floor: "1st floor",
                  description: "",
                },
                {
                  id: 3,
                  src: "/img/cobalt_family_room_2.jpg",
                  alt: "Family room",
                  floor: "1st floor",
                  description: "",
                },
              ],
              bedrooms: [
                {
                  id: 11,
                  src: "/img/cobalt_master_bedroom.jpg",
                  alt: "Master bedroom",
                  floor: "1st floor",
                  description: "",
                },
              ],
              bathrooms: [
                {
                  id: 12,
                  src: "/img/cobalt_master_bathroom.jpg",
                  alt: "Master bathroom",
                  floor: "1st floor",
                  description: "1st floor - Description",
                },
              ],
              lounge: [
                {
                  id: 13,
                  src: "/img/cobalt_lounge.jpg",
                  alt: "Lounge",
                  floor: "2nd floor",
                  description: "",
                },
              ],
            },
          },
          houseImages: [
            { id: 100, name: "Outside", src: "/img/h_marshal_main.jpg" },
          ],
          floorplansImages: [
            {
              id: 1000,
              floor: 0,
              alt: "Basement",
              src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/gardenia_basement.png",
            },
            {
              id: 2000,
              floor: 1,
              alt: "1st floor",
              src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/gardenia_first_floor.png",
            },
            {
              id: 3000,
              floor: 2,
              alt: "2nd floor",
              src: "https://hf-images-hosted.s3.us-west-2.amazonaws.com/gardenia_second_floor.png",
            },
          ],
          bedrooms: 5,
          bathrooms: 3.5,
          carGarage: 2,
          costs: {
            price: 491131,
            value: 532241,
            homefounderFee: 11000,
            construction: {
              landDevelopmentPrice: 63000,
              price: 288936,
            },
            requiredDownpayment: {
              percentage: 20,
              price: 89340,
            },
            estimatedMonthlyPayment: {
              numberOfYears: 15,
              amount: 2953,
            },
            closingCosts: {
              independent: {
                percentage: 0.5,
                amount: 2395,
              },
              agent: {
                percentage: 3.5,
                amount: 16764,
              },
            },
            annualCosts: {
              propertyTax: 4519,
              propertyInsurance: 1265,
              hoaDues: 550,
            },
          },
          squareFootage: 4000,
          summary:
            "First floor living room, large kitchen, master suite, and 2-story great room. Additional 3 bedrooms and 2 bathrooms upstairs.",
          moreInfo:
            "Options to add a bonus room, garage space, and rear patio or porch.",
          features: [
            { id: 1, name: "Tile backsplash" },
            { id: 2, name: "Lenox air-conditioning" },
            { id: 3, name: "LED recessed lighting" },
            { id: 4, name: "55-gallon electric water heater" },
            { id: 5, name: "Timberlake&trade; cabinetry" },
            { id: 6, name: "Stainless steel slide-in gas range" },
          ],
          lot: {
            name: "Lot #5",
            size: 0.3,
            image: "/img/lot5.png",
            price: 32000,
            bigImage:
              "https://hf-images-hosted.s3.us-west-2.amazonaws.com/lot1.jpg",
          },
          banners: {
            bottom: {
              id: 11123,
              src: "/img/cobalt_banner.jpg",
              alt: "Cobalt house banner",
            },
          },
        },
      ],
    },
    isAvailable: true,
    availableFrom: "April 2022",
    moveInEstimate: "Dec '22",
  },
  {
    id: 2,
    slug: "",
    name: "",
  },
  {
    id: 3,
    slug: "",
    name: "",
  },
];

const delayData = (time, value) => {
  return new Promise((resolve) => {
    setTimeout(resolve.bind(null, value), time);
  });
};

const getHubspotProducts = async () => {
  try {
    const response = await API.get("hubspotApi", "/hubspot/products", {
      headers: {},
    });
    return response;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

const applyAvailbility = async (location) => {
  const products = await getHubspotProducts();
  if (products && products.results) {
    for (var id in products.results) {
      const product = products.results[id];
      for (var index in location.houseModels.models) {
        var houseModel = location.houseModels.models[index];
        if (
          product.properties.hs_sku &&
          parseInt(product.properties.hs_sku) == houseModel.id
        ) {
          if (product.properties.availability == "available") {
            houseModel.availability = AVAILABLE;
          } else if (product.properties.availability == "sold") {
            houseModel.availability = SOLD;
          } else if (product.properties.availability == "reserved") {
            houseModel.availability = RESERVED;
          } else if (product.properties.availability == "under_contract") {
            houseModel.availability = UNDER_CONTRACT;
          }
        }
      }
    }
  }
  return location;
};

export const getLocation = async (slug) => {
  var location = locations.find((model) => model.slug === slug);
  if (location) {
    location = await applyAvailbility(location);
  }
  return location;
};

export const getHouseModel = async (slug, houseSlug) => {
  const location = await getLocation(slug);
  if (!location) throw new Error("Location not found!");

  const houseModel = location.houseModels.models.find(
    (model) => model.houseSlug === houseSlug
  );
  if (!houseModel) throw new Error("House model not found!");

  return delayData(400, { location, houseModel });
};

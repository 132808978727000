<template>
  <div
    id="style-menu"
    class="style-menu"
    :class="
      fullscreenEnabled
        ? 'bottom-[20rem] left-[50%] translate-x-[-50%] sm:translate-x-0 sm:bottom-40 sm:left-12'
        : 'hidden sm:block sm:bottom-6 sm:right-6'
    "
  >
    <button
      class="style-menu-button mr-4"
      :class="[
        currentMapStyleValue === SATELLITE ? 'style-menu-button-active' : '',
      ]"
      @click="currentMapStyleValue = SATELLITE"
    >
      Satellite
    </button>
    <button
      class="style-menu-button"
      :class="[currentMapStyleValue === MAP ? 'style-menu-button-active' : '']"
      @click="currentMapStyleValue = MAP"
    >
      Map
    </button>
  </div>
</template>

<script>
import { SATELLITE, MAP } from "@/constants";

export default {
  props: {
    mapStyle: String,
    fullscreenEnabled: Boolean,
  },
  data() {
    return {
      currentMapStyleValue: this.mapStyle,
      MAP,
      SATELLITE,
    };
  },
  watch: {
    currentMapStyleValue: function (value) {
      this.$emit("StyleChanged", value);
    },
  },
  created() {
    this.$emit("StyleChanged", this.currentMapStyleValue);
  },
};
</script>

<style scoped>
.style-menu {
  @apply bg-creme-200 absolute z-10 border border-maintextcolor rounded-32 py-2 px-3;
}

.style-menu-button {
  @apply px-4 py-2 font-semibold text-body-2;
}
.style-menu-button-active {
  @apply bg-creme-300 rounded-16;
}
</style>

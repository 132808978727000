<template>
  <section class="hero">
    <img class="hero-image" :src="image.src" :alt="image.alt" />

    <div class="max-container mx-auto w-full z-10">
      <div class="hero-content">
        <div
          class="bg-blue-100 text-blue-500 text-body-2 md:text-body-1 font-semibold py-4 text-center rounded-t-16"
        >
          <router-link to="/locations/hollysprings">
            The Estates at {{ location.name }}
          </router-link>
        </div>
        <div class="mx-8 md:mx-16 lg:mx-12 xl:mx-12 py-8">
          <div class="text-h3 md:text-h2 font-semibold text-left">
            {{ name }} ({{ lot.name }})
          </div>

          <div class="text-body-1 font-regular mt-4 mb-8 text-left">
            Upcoming construction
          </div>

          <div v-if="availability === AVAILABLE">
            <PrimaryBaseCta
              text="I'm interested"
              fixedSize="responsive"
              class="max-w-[243px]"
              endImage="ico_circle_arrow_white.svg"
              @click="toggleModal"
            />
          </div>

          <div v-if="availability === RESERVED" class="flex items-center">
            <span class="badge reserved">RESERVED</span>
            <PrimaryBaseCta
              type="link"
              text="Contact us"
              fixedSize="responsive"
              @click="toggleModal"
            />
          </div>
          <div v-if="availability === SOLD" class="flex items-center">
            <span class="badge sold">SOLD</span>
            <PrimaryBaseCta
              type="link"
              text="Contact us"
              fixedSize="responsive"
              @click="toggleModal"
            />
          </div>
        </div>
      </div>
    </div>

    <LocationInterestModal
      v-if="modalOpened"
      :preselectedHomeModel="houseName"
      @closeLocationInterestModal="toggleModal()"
    />
  </section>
</template>

<script>
import { PrimaryBaseCta } from "@/components";
import { AVAILABLE, RESERVED, SOLD } from "@/constants";

export default {
  name: "HouseHero",
  components: {
    PrimaryBaseCta,
    // I don't know why normal importing does not work, so we async import it
    LocationInterestModal: () => import("../modals/LocationInterestModal.vue"),
  },
  props: {
    image: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: "",
    },
    location: {
      type: Object,
      default: () => {},
    },
    lot: {
      type: Object,
      default: () => {},
    },
    availability: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalOpened: false,
      AVAILABLE,
      RESERVED,
      SOLD,
    };
  },
  computed: {
    cityWithState() {
      return `${this.location.city}, ${this.location.stateAbbreviation}`;
    },
    houseName() {
      return this.availability === AVAILABLE ? this.name : "";
    },
  },
  methods: {
    toggleModal() {
      this.modalOpened = !this.modalOpened;

      if (this.modalOpened) {
        document.querySelector("html").classList.add("overflow-hidden");
      } else document.querySelector("html").classList.remove("overflow-hidden");
    },
  },
};
</script>

<style scoped>
.hero {
  @apply w-full min-h-[620px] relative flex flex-col justify-end;
}

.hero-image {
  @apply absolute top-0 h-[55%] sm:h-full w-full object-cover;
}

.hero-content {
  @apply rounded-t-16 flex flex-col lg:ml-4 lg:ml-28;
  @apply w-full lg:w-[500px] h-full bg-creme-50 text-gray-900 text-h4 font-extrabold;
}

.badge {
  @apply py-2 px-4 text-body-1 lg:text-h4 font-semibold uppercase rounded-8 mr-4 lg:mr-8;
}
.reserved {
  @apply bg-gray-900 text-creme-50;
}
.sold {
  @apply bg-yellow-500;
}
</style>

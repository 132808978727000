const items = [
  {
    title: "Holly Springs",
    subtitle: "Douglasville, GA",
    selection: {
      text: "Available June 2022",
    },
    slug: "/locations/hollysprings",
  },
];

export const getNavLocations = () => items;

<template>
  <section class="banner">
    <div class="max-container flex z-10 lg:w-full">
      <div class="banner-content lg:ml-32 lg:mt-32">
        <div
          class="text-h3 lg:text-h2 font-semibold mb-8 text-left min-w-[280px] lg:min-w-[330px]"
        >
          Interested in <br />
          <span class="text-blue-500"> {{ location.name }}?</span>
        </div>

        <div
          class="text-body-2 lg:text-h4 mb-10 max-w-[205px] lg:max-w-[320px]"
        >
          Contact our team of Home Specialists to get started
        </div>

        <PrimaryBaseCta
          text="I'm interested"
          class="mb-6 self-start lg:self-center lg:w-full"
          endImage="ico_circle_arrow_white.svg"
          @click="toggleModal"
        />

        <router-link
          to="/locations"
          class="text-blue-500 text-left lg:text-center font-semibold text-body-2 lg:text-h4"
        >
          Explore other locations
        </router-link>
      </div>
    </div>

    <LocationInterestModal
      v-if="modalOpened"
      @closeLocationInterestModal="toggleModal"
    />
  </section>
</template>

<script>
import { PrimaryBaseCta } from "@/components";
import { trackEvent } from "@/helpers/events.js";

export default {
  components: {
    PrimaryBaseCta,
    LocationInterestModal: () => import("../modals/LocationInterestModal.vue"),
  },
  data() {
    return {
      modalOpened: false,
    };
  },
  props: {
    location: {
      type: Object,
      default: function () {
        return {
          name: "Holly Springs",
        };
      },
    },
  },
  methods: {
    toggleModal() {
      this.modalOpened = !this.modalOpened;
      if (this.modalOpened) {
        trackEvent("Button", "Interested", "Holly Springs");
      }
    },
  },
};
</script>

<style scoped>
.banner {
  background: url("~@/assets/images/hollysprings_banner.jpg");
  @apply w-full lg:mx-auto min-h-[500px] md:min-h-[700px] lg:min-h-[950px] flex flex-grow flex-col relative bg-no-repeat bg-center bg-cover justify-center lg:justify-start;
}

.banner-content {
  @apply rounded-16 flex flex-col p-8 lg:p-12 self-center lg:justify-start;
  @apply bg-creme-50 text-gray-900 text-h4;
}
</style>

<template>
  <div>
    <div class="py-6 border-b border-gray-900 border-opacity-20 xl:border-none">
      <router-link class="nav-right-text" to="/faq" @click.native="closeAll()"
        >FAQ</router-link
      >
    </div>
    <div class="py-6 border-b border-gray-900 border-opacity-20 xl:border-none">
      <router-link class="nav-right-text" to="/about" @click.native="closeAll()"
        >About us</router-link
      >
    </div>
    <div class="flex justify-center py-6 border-none">
      <NavBarCta
        @specialist="navigateItem('/get-notified')"
        @dashboard="navigateItem('/dashboard')"
        @login="navigateItem('/learn-more')"
      />
    </div>
  </div>
</template>
<script>
import NavBarCta from "./NavBarCta.vue";
export default {
  name: "NavMobileMarketing",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    NavBarCta,
  },
  data() {
    return {
      isMobileLocationOpen: false,
    };
  },
  methods: {
    toggleMobileLocation() {
      this.isMobileLocationOpen = !this.isMobileLocationOpen;
    },
    navigateItem(slug) {
      this.$router.push({
        path: `${slug}`,
      });
      this.isMobileLocationOpen = false;
      const _this = this;
      setTimeout(() => {
        _this.closeAll();
      }, 100);
    },
    closeAll() {
      this.isMobileLocationOpen = false;
      const _this = this;
      setTimeout(() => {
        _this.$emit("closeMobile");
      }, 100);
    },
    login() {
      this.$emit("login");
    },
  },
};
</script>
<style scoped>
.nav-right-text {
  @apply text-gray-900 font-semibold text-body-2 cursor-pointer hover:text-blue-500;
}
.login-btn {
  @apply border border-gray-900 rounded-12 text-blue-500 font-semibold text-body-2 bg-creme-200 text-center cursor-pointer;
}
</style>

<template>
  <div class="max-container">
    <div class="w-full mb-16 lg:mb-24">
      <h2 class="text-h3 lg:text-h2 font-semibold text-center">
        The neighborhood
      </h2>
    </div>

    <div class="main-grid">
      <div class="col-span-full lg:col-span-4">
        <div class="city">
          <h3 class="text-h4 lg:text-h3 font-semibold mb-12">
            Douglasville, Georgia
          </h3>

          <p class="text-body-2 lg:text-body-1">
            A historic city only 30-minutes outside of Atlanta that is the
            darling of Hollywood film studios.
          </p>

          <div class="carousel">
            <Carousel :images="images" :fixedHeight="300" />
          </div>

          <hr />
        </div>
        <div class="convenience">
          <h3 class="text-h4 font-semibold mb-2">Convenience</h3>

          <p class="text-body-2 mb-9">
            10 min drive to shops, dining, and parks
          </p>

          <div class="flex justify-between text-body-2 mb-6">
            <div class="flex flex-col justify-center mr-4 max-w-[280px]">
              <div class="mb-1">Downtown Douglasville</div>
              <div class="text-body-3">
                Bustling main street with an old-town feel
              </div>
            </div>
            <div class="font-semibold flex items-center">6.0 mi</div>
          </div>

          <div class="flex justify-between text-body-2 mb-6">
            <div class="flex flex-col justify-center mr-4 max-w-[280px]">
              <div class="mb-1">Arbor Place Mall</div>
              <div class="text-body-3">
                Regional mall with over 1 million sq ft of shops and restaurants
              </div>
            </div>
            <div class="font-semibold flex items-center">7.0 mi</div>
          </div>

          <div class="flex justify-between text-body-2">
            <div class="flex flex-col justify-center mr-4 max-w-[280px]">
              <div class="mb-1">Sweetwater Creek State Park</div>
              <div class="text-body-3">Hiking, boating, fishing, and more</div>
            </div>
            <div class="font-semibold flex items-center">6.0 mi</div>
          </div>

          <hr />
        </div>

        <div class="lg:hidden lg:mb-8">
          <div class="w-full flex justify-center">
            <img
              class="h-full my-auto rounded-16 map-image"
              src="@/assets/images/np_doug_map.jpg"
              alt="Area map"
            />
          </div>
          <hr class="lg:hidden" />
        </div>

        <div class="schools">
          <h3 class="text-h4 font-semibold mb-2">Great Schools</h3>

          <p class="text-body-2 mb-9">
            Ratings from
            <a href="greatchools.org" class="text-blue-500 font-semibold">
              GreatSchools.org
            </a>
          </p>

          <div class="flex justify-between text-body-2 mb-6">
            <div class="flex max-w-[280px]">
              <div class="flex items-start">
                <span
                  class="bg-yellow-500 px-2 py-1 font-semibold rounded-100 w-max mr-4"
                >
                  8 / 10
                </span>
              </div>
              <div class="mb-1">Holly Springs Elementary School</div>
            </div>
            <div class="font-semibold">1.7 mi</div>
          </div>

          <div class="flex justify-between text-body-2 mb-6">
            <div class="flex max-w-[280px]">
              <div class="flex items-start">
                <span
                  class="bg-yellow-500 px-2 py-1 font-semibold rounded-100 w-max mr-4"
                >
                  8 / 10
                </span>
              </div>
              <div class="mb-1">Chapel Hill Middle School</div>
            </div>
            <div class="font-semibold">1.7 mi</div>
          </div>

          <div class="flex justify-between text-body-2 mb-6">
            <div class="flex max-w-[280px]">
              <div class="flex items-start">
                <span
                  class="bg-yellow-500 px-2 py-1 font-semibold rounded-100 w-max mr-4"
                >
                  7 / 10
                </span>
              </div>
              <div class="mb-1">
                <div>Chapel Hill High School</div>
                <span
                  class="bg-creme-500 px-2 py-1 mt-2 rounded-100 whitespace-nowrap text-body-3"
                >
                  College Success Award - Gold
                </span>
              </div>
            </div>
            <div class="font-semibold">1.7 mi</div>
          </div>

          <hr />
        </div>
        <div class="brochure">
          <div class="mb-0 lg:mb-8">
            <a href="#" class="flex w-max">
              Download brochure
              <img
                src="@/assets/icons/arrow_down_blue.svg"
                alt="Arrow down icon"
                class="ml-4"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="col-span-full lg:col-span-7 lg:col-start-6 hidden lg:block">
        <div class="map lg:mb-8">
          <div class="w-full flex justify-center">
            <img
              class="h-full my-auto rounded-16 map-image"
              src="@/assets/images/np_doug_map.jpg"
              alt="Area map"
            />
          </div>
          <hr class="lg:hidden" />
        </div>
        <div class="flex flex-row flex-wrap justify-between">
          <div class="plaza mb-8">
            <img
              src="@/assets/images/np_doug_plaza.jpg"
              class="rounded-16"
              alt="Plaza image"
            />
            <div class="text-right text-body-3 mt-3">
              Douglasville central plaza <br />
              Performance & festivals venue
            </div>
          </div>
          <div class="center mb-8">
            <img
              src="@/assets/images/np_doug_conf.jpg"
              class="rounded-16"
              alt="Conference center"
            />
            <div class="text-right text-body-3 mt-3">
              Douglasville <br />
              conference center
            </div>
          </div>

          <div class="mall">
            <img
              src="@/assets/images/np_doug_arbor.jpg"
              alt="Arbor place mall"
              class="rounded-16"
            />
            <div class="text-right text-body-3 mt-3">Arbor Place Mall</div>
          </div>

          <div class="park">
            <img
              src="@/assets/images/np_doug_creek.jpg"
              alt="Creek state park"
              class="rounded-16"
            />
            <div class="text-right text-body-3 mt-3">
              Sweetwater Creek State Park
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel } from "@/components/carousels";

export default {
  name: "Neighborhood",
  components: {
    Carousel,
  },
  data() {
    return {
      images: [
        {
          id: 1,
          src: "/img/np_doug_plaza.jpg",
          alt: "Douglasville central plaza",
          text: `Douglasville central plaza <br /> Performance & festivals venue`,
        },
        {
          id: 2,
          src: "/img/np_doug_conf.jpg",
          alt: "Douglasville conference center",
          text: `Douglasville <br /> conference center`,
        },
        {
          id: 3,
          src: "/img/np_doug_arbor.jpg",
          alt: "Arbor place mall",
          text: `Arbor place mall`,
        },
        {
          id: 4,
          src: "/img/np_doug_creek.jpg",
          alt: "Sweetwater Creek State Park",
          text: `Sweetwater Creek State Park`,
        },
      ],
    };
  },
  methods: {
    onClick() {
      this.$router.push({
        path: "/locations/hollysprings/interest",
      });
    },
  },
};
</script>

<style scoped>
hr {
  @apply my-8 lg:my-12 h-[1px];
}

.plaza,
.center,
.mall,
.park {
}

.city {
}
.convenience {
}
.carousel {
  @apply lg:hidden mt-8;
}
.map {
  grid-area: map;
  /* @apply lg:min-h-[546px]; */
}
.map-image {
  max-height: 600px;
}

.schools {
  grid-area: schools;
}

.plaza {
  flex-basis: 65%;
  @apply rounded-16;
}

.center {
  flex-basis: 30%;
  @apply rounded-16;
}

.mall {
  flex-basis: 30%;
  @apply rounded-16;
}

.park {
  flex-basis: 65%;
  @apply rounded-16;
}

.brochure {
  grid-area: brochure;
  @apply text-blue-500 font-semibold text-body-1;
}

.city,
.convenience,
.schools,
.brochure,
hr {
  @apply w-full  mx-auto;
}
</style>

<template>
  <div class="main-container">
    <div class="max-container">
      <div
        class="flex flex-col lg:flex-row-reverse justify-center lg:justify-between"
      >
        <div class="w-full lg:w-[48%] text-center lg:text-left">
          <h2 class="text-h3 xl:text-h2 font-semibold">
            Welcome to a <span class="text-blue-500">new era</span>
            <br class="hidden lg:block" />
            in homebuying.
          </h2>
          <p class="text-h4 mt-6">And soon, welcome home.</p>

          <div class="flex flex-col items-center lg:items-start">
            <PrimaryBaseCta
              text="Interested? Let’s chat!"
              endImage="ico_circle_arrow_white.svg"
              class="mb-12 mt-6 lg:mt-12"
              @click="onClick()"
            />
          </div>
        </div>
        <div class="w-full lg:w-[48%] flex items-end lg:pr-8">
          <img
            class="mx-auto pt-4 billboard-image"
            src="@/assets/images/home_page_welcome_billboard_HQ.png"
            alt="Welcome billboard"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PrimaryBaseCta } from "@/components";
export default {
  name: "FounderPage",
  components: {
    PrimaryBaseCta,
  },
  methods: {
    onClick() {
      this.$router.push({
        path: "/learn-more",
      });
    },
  },
};
</script>
<style scoped>
.background-gradient {
  background: linear-gradient(180deg, #fcfbf8 0%, #f5f0e9 100%);
}
.billboard-image {
  max-width: 800px;
  width: 100%;
}

.max-container {
  max-width: 1088px;
}
</style>

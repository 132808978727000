<template>
  <div class="max-container">
    <global-section>
      <div class="main-grid">
        <div class="layout-grid-row text-h3 lg:text-h1 font-semibold">
          <h1>Questions?</h1>
          <h2>We're here to help.</h2>
        </div>

        <div class="layout-grid-row">
          <MainSection />
        </div>

        <div class="layout-grid-row">
          <MoreQuestions />
        </div>
      </div>
    </global-section>
  </div>
</template>

<script>
import { MoreQuestions, MainSection, GlobalSection } from "@/components";
export default {
  name: "Faq",
  components: {
    MoreQuestions,
    MainSection,
    GlobalSection,
  },
};
</script>

<style scoped>
.layout-grid-row {
  @apply col-span-full;
}
</style>

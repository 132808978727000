<template>
  <div>
    <CustomizationHeader :title="title" />

    <div v-if="customization">
      <ButtonGroup
        :selections="customization"
        @buttonGroupClicked="buttonGroupClick"
      />
      <Carousel
        v-if="selection && selection.images"
        :id="imageId"
        :images="selection.images.items"
        :fixedHeight="500"
      />
    </div>

    <hr class="mt-8 border bg-black opacity-20" />
  </div>
</template>

<script>
import CustomizationHeader from "@/components/text/CustomizationHeader";
import { Carousel } from "@/components/carousels";
import ButtonGroup from "@/components/buttons/ButtonGroup";
import { BUILDER_EVENTS } from "@/constants";

export default {
  name: "Customization",
  components: {
    CustomizationHeader,
    ButtonGroup,
    Carousel,
  },
  props: ["imageId", "customType", "title", "customization"],
  data() {
    return {
      selection: null,
    };
  },
  methods: {
    buttonGroupClick(val) {
      this.selection = val;
      this.$root.$emit(
        this.customType === "kitchen"
          ? BUILDER_EVENTS.KITCHEN_SELECTION
          : BUILDER_EVENTS.FLOORING_SELECTION,
        val
      );
    },
  },
};
</script>

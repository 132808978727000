<template>
  <div class="main-container">
    <global-section class="pt-0">
      <div class="max-container">
        <h1 class="column-def font-semibold text-h3 lg:text-h2">
          Interested? Let's chat!
        </h1>
        <p
          class="hidden column-def text-body-2 lg:text-body-1 mt-4 mb-0 lg:my-6"
        >
          Curious about HomeFounder? Excited? Too good to be true? We promise,
          it’s not!<br />Tell us what you’re looking for, and we’re happy to
          answer any questions you have.
        </p>
        <form @submit.prevent="submit" class="column-def mt-8 lg:mt-16">
          <div
            class="flex flex-row flex-wrap lg:flex-nowrap lg:justify-between"
          >
            <div class="lg:w-[32%] w-full mb-8 lg:mb-0">
              <div class="field-label">First name</div>
              <TextInput
                v-model="firstname"
                :invalid="errors['firstname']"
                ref="firstname"
              />
            </div>
            <div class="lg:w-[32%] w-full mb-8 lg:mb-0">
              <div class="field-label">Last name</div>
              <TextInput
                v-model="lastname"
                :invalid="errors['lastname']"
                ref="lastname"
              />
            </div>
            <div class="lg:w-[32%] w-full">
              <div class="field-label">Email</div>
              <TextInput
                inputType="email"
                v-model="email"
                :invalid="errors['email']"
                ref="email"
              />
            </div>
          </div>

          <div class="mt-8">
            <div class="field-label">
              Anything else you’d like to share about your home ownership
              journey?
            </div>
            <div class="mt-3">
              <TextArea
                placeholder="(Optional) Tell us about your timeline and location preferences"
                v-model="lead_form_comments"
              />
            </div>
          </div>

          <div
            class="flex flex-col lg:flex-row justify-center lg:justify-end items-center mt-8 lg:mt-16 w-full"
          >
            <div
              class="text-body-1 mr-8 mb-8 lg:mb-0 text-center lg:text-right"
            >
              <span
                class="lg:mt-16 mt-8 font-semibold text-body-2 text-red-500"
                v-if="errorText"
              >
                {{ errorText }}
              </span>
              <span v-else>
                {{ friendlyValidationMessage() }}
              </span>
            </div>
            <PrimaryBaseCta
              text="Let's chat"
              fixedSize="large"
              endImage="ico_circle_arrow_white.svg"
              @click="submit"
              eventName="submit"
              :disabled="this.disabled"
            />
          </div>
        </form>
      </div>
    </global-section>
  </div>
</template>

<script>
import {
  TextInput,
  PrimaryBaseCta,
  GlobalSection,
  TextArea,
} from "@/components";
import { HUBSPOT_PAGES } from "@/constants";
import { postToHubspot } from "@/helpers/postToHubspot.js";

export default {
  name: "Subscribe",
  components: {
    TextInput,
    PrimaryBaseCta,
    GlobalSection,
    TextArea,
  },
  data() {
    return {
      firstname: null,
      lastname: null,
      email: null,
      lead_form_comments: null,
      errors: {},
      disabled: false,
      errorText: null,
    };
  },
  watch: {
    firstname() {
      this.validateFieldsInRealTime();
    },
    lastname() {
      this.validateFieldsInRealTime();
    },
    email() {
      this.validateFieldsInRealTime();
    },
  },
  updated() {
    this.validateFieldsInRealTime();
  },
  methods: {
    getFormFields() {
      var fields = ["firstname", "lastname", "email", "lead_form_comments"];
      return fields;
    },
    friendlyValidationMessage() {
      let fields = this.getFormFields();
      var missing_fields = 0;
      for (var i in fields) {
        let field = fields[i];
        if (field == null || field == "") {
          missing_fields = missing_fields + 1;
        }
      }

      if (missing_fields > 3) {
        return "Complete all input fields to submit";
      } else if (missing_fields > 0) {
        return (
          "Almost there! Only " +
          missing_fields +
          " field" +
          (missing_fields != 1 ? "s" : "") +
          " left"
        );
      }

      return "";
    },
    validateFieldsInRealTime() {
      if (this.errorText) {
        let count = this.validateFields();
        if (count == 0) {
          this.errorText = null;
        } else {
          this.errorText = "All fields required";
        }
      }
    },
    validateFields() {
      var errorCount = 0;
      let fields = this.getFormFields();
      for (var i in fields) {
        let fieldName = fields[i];
        let field = this[fieldName];
        var fieldEmpty = false;
        if (fieldName != "lead_form_comments") {
          fieldEmpty = field == null || field == "";
        }

        if (fieldEmpty) {
          errorCount = errorCount + 1;
          this.errors[fieldName] = true;
          if (errorCount == 1) {
            if (this.$refs[fieldName]) {
              let element = this.$refs[fieldName].$el;
              if (element) {
                element.scrollIntoView({
                  block: "start",
                  inline: "nearest",
                  behavior: "smooth",
                });
              }
            }
          }
        } else {
          this.errors[fieldName] = false;
        }
      }
      return errorCount;
    },
    validate() {
      var errorCount = this.validateFields();

      if (errorCount > 0) {
        return false;
      }

      return true;
    },
    submit() {
      this.errorText = null;
      if (!this.validate()) {
        this.errorText = "All fields are required";
        this.disabled = false;
      } else {
        this.disabled = true;
        var data = {
          priorityAccess: this.priorityAccess,
        };

        let fields = this.getFormFields();
        for (var i in fields) {
          let field = fields[i];
          data[field] = this[field];
        }

        postToHubspot(HUBSPOT_PAGES.LEARN_MORE, data)
          .then((res) => {
            this.disabled = false;
            if (res.success) {
              this.$router.push({
                path: "/subscribe/subscribe-thankyou",
              });
            }
          })
          .catch((error) => {
            this.errorText = error;
            console.error(error);
            this.disabled = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.max-container {
  max-width: 1088px;
}

.required {
  @apply text-gray-900 pt-8 text-body-2 font-regular;
}
.field-label {
  @apply text-body-2 font-semibold text-gray-900 mb-3;
}

.title {
  margin: 0;
  @apply text-gray-900 text-h2 font-semibold;
}
.sub-title {
  @apply text-gray-900 pt-8 text-body-1 font-regular max-w-[730px];
}

#priority-access {
  background-color: #eae4d6;
  @apply border  border-gray-500;
}

#priority-access.application-open {
  @apply bg-white;
}

#priority-access.application-open .main {
  @apply border-b border-gray-900 border-opacity-20;
}

#priority-access.application-open .field-label {
  @apply font-regular;
}

#priority-access .priority-access-form {
  display: none;
}

#priority-access.application-open .priority-access-form {
  display: flex;
  flex-direction: column;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>

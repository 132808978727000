export const questionFilters = [
  { id: 0, text: "All FAQs", active: true },
  { id: 1, text: "How does HomeFounder work?", active: false },
  { id: 2, text: "Costs & Financing", active: false },
  { id: 3, text: "Choosing a Home", active: false }, //previously Reserving, Contracting, and Closing
  //{ id: 4, text: "Costs", active: false }, //not in use, merged with Financing
  { id: 5, text: "Construction", active: false },
  //{ id: 6, text: "Contact information", active: false }, //not in use
];

/* Relationship between filters and question-answer pairs
 * is defined with questionFiltersId property
 */
export const faqArray = [
  {
    id: 0,
    questionFiltersId: 1,
    question: "What is HomeFounder exactly?",
    answer:
      "HomeFounder is a new platform that helps aspiring homeowners identify and develop new homes and communities. <br><br>We are not a broker, developer, builder, or bank— but we work directly with the best to create unheard-of deals for buyers like you. HomeFounder’s real estate experts identify lots in desirable locations, gauge interest from homebuyers, and contract with reputable developers and builders on your behalf to make these new developments a reality. <br><br>By working directly with us before land is developed, you get a say in the homes getting built AND save up to 20% off the appraised value of your home (plus, even more in interest).",
    active: false,
  },
  {
    id: 1,
    questionFiltersId: 1,
    question: "Who is HomeFounder for?",
    answer:
      "HomeFounder serves homebuyers looking to purchase a primary residence. We are building strong communities and as such, we do not sell to flippers, landlords, or vacationers.",
    active: false,
  },
  {
    id: 2,
    questionFiltersId: 1,
    question:
      "How is it possible for HomeFounder to sell homes below appraised value?",
    answer:
      "By identifying purchasers earlier in the process, HomeFounder is able to sell to customers below appraised market value.<br><br>Why does this work? In typical residential developments, investors fund the upfront costs of development and construction, but add a big margin. HomeFounder removes traditional investors from the development process and passes on those savings to our customers.",
    active: false,
  },
  {
    id: 3,
    questionFiltersId: 1,
    question: "Why is HomeFounder willing to sell homes so affordably?",
    answer:
      "We believe the homebuilding industry fundamentally needs to serve homebuyers better. By taking a fixed fee we can pass on the maximum amount of savings to our buyers. And the more homebuyers who use HomeFounder, the better we can negotiate and continue doing business.",
    active: false,
  },
  /*
  {
    id: 4,
    questionFiltersId: 1,
    question:
      "If I invest early, am I taking on the risk that the development might fall through? How do you protect me?",
    answer:
      "We work with established builders for a fixed fee on guaranteed max price contracts. These contracts come with a completion guarantee, so you can rest assured that the homes will be built.<br><br>Additionally, your mortgage rate is locked in when you sign a Purchase & Sale agreement, so you can budget with confidence in your monthly payment. HomeFounder builds substantial additional contingencies into development to account for any unforeseen cost increases or delays.",
    active: false,
  },
  */
  {
    id: 5,
    questionFiltersId: 1,
    question: "Where does HomeFounder operate?",
    answer:
      "We currently operate in the Atlanta metropolitan area, but will be expanding soon. Want us to come to another location? <a href='/subscribe' class='text-blue-500'>Let us know</a>.",
    active: false,
  },
  {
    id: 6,
    questionFiltersId: 1,
    question: "How do you estimate what homes are worth?",
    answer:
      "As we shop for land opportunities, the neighborhood informs our estimated home values and home models. Once we finalize a location and home models (with your help!), we require a bank-initiated, independent appraisal to get an estimated value for every home model and lot.",
    active: false,
  },
  /*
  {
    id: 7,
    questionFiltersId: 1,
    question: "Do I need a real estate agent to buy new construction?",
    answer:
      "No, you do not need an agent to purchase a new home with HomeFounder. You are welcome to work with one if you choose but will be responsible for paying their fees.",
    active: false,
  },
*/
  {
    id: 8,
    questionFiltersId: 2,
    question: "Do I still get a normal mortgage?",
    answer:
      "Yes. Once your home is constructed, the loan becomes a standard 30-year fixed mortgage. We work with lenders to ensure a competitive interest rate. All of the deposits and payments made by our buyers go directly to the principal value of the home.",
    active: false,
  },
  /*
  {
    id: 9,
    questionFiltersId: 2,
    question: "What terms are supported by the lender?",
    answer:
      "Our financing partner, Affinity Bank, offers construction-to-perm 15-year fixed and 5/1 and 7/1 adjustable rate mortgages.",
    active: false,
  },
  {
    id: 10,
    questionFiltersId: 2,
    question: "What is a single-close construction to perm loan?",
    answer:
      "Construction-to-permanent loans allow you to finance construction, along with your completed home. When construction concludes, it automatically converts into a permanent mortgage.<br><br>Under this structure, you’ll only have to pay one set of closing costs and your interest rate will be locked in from the point of application.",
    active: false,
  },

  {
    id: 11,
    questionFiltersId: 3,
    question: "Why is there a short reservation window?",
    answer:
      "Every community has a limited-time home site reservation & closing period. This is the maximum time that HomeFounder can guarantee your home price, due to fluctuating construction and material costs. ",
    active: false,
  },
  {
    id: 12,
    questionFiltersId: 3,
    question:
      "What’s the difference between the Purchase & Sale agreement and what happens at closing?",
    answer:
      "A Purchase & Sale Agreement (PSA) is a binding contract executed between you and the builder. It defines the terms of the transaction.<br><br>On closing day, the transaction will be finalized and all necessary documentation will be executed by you, the lender, and the closing attorney. Your downpayment will be due at closing.",
    active: false,
  },
  {
    id: 13,
    questionFiltersId: 3,
    question: "Can I see my home before closing?",
    answer:
      "When you close, the home won’t be built yet, so there isn’t anything to see! All prospective homeowners will receive regular updates throughout the construction process. If homeowners wish they can schedule in-person visit to see their home ‘s progress.",
    active: false,
  },
*/
  {
    id: 14,
    questionFiltersId: 2,
    question: "What do I pay at closing?",
    answer:
      "At closing, you will pay the downpayment and additional closing costs, if applicable. While 20% down on the HomeFounder price is encouraged, we know that is often not possible and are exploring downpayment assistance options. Still reach out to us if you're interested, and would want downpayment assistance.",
    active: false,
  },
  {
    id: 15,
    questionFiltersId: 2,
    question: "What is the HomeFounder fee?",
    answer: "HomeFounder charges a flat rate of $10,000 per home.",
    active: false,
  },
  /*
  {
    id: 16,
    questionFiltersId: 4,
    question: "How is the home value estimated?",
    answer:
      "The estimated home value displayed on the website is based on a neutral third party appraisal.",
    active: false,
  },
  */
  {
    id: 17,
    questionFiltersId: 2,
    question: "How do you guarantee purchase price given market volatility?",
    answer:
      "We work with a third party appraiser to get a realistic idea of the value of each of our developments. Additionally, we build in a contingency and additional buffers to mitigate the risk of rising costs. If, in the unlikely scenario we run over these costs, your price is still guaranteed.",
    active: false,
  },
  {
    id: 22,
    questionFiltersId: 3,
    question: "What should I do if I’m interested in an upcoming location?",
    answer:
      "The first step is to reach out to us! For now, <a href='/subscribe' class='text-blue-500'>sign up for updates and priority access</a>.When a location is finalized, we’ll open up a reservation period. To reserve your spot, talk to a Home Specialist to learn more and get pre-approved. <br><br>Once you’re pre-approved, you’ll design your home and sign a Purchase & Sale Agreement with the builder. <br><br>Within a month, you’ll close on your dream home! (Don’t worry, we’ll help coordinate your closing). This is the maximum time that HomeFounder can guarantee your home price, due to fluctuating construction and material costs.",
    active: false,
  },
  {
    id: 18,
    questionFiltersId: 5,
    question: "Who is building my home? How do you vet them?",
    answer:
      "We only partner with reputable local and national builders. These are award-winning teams, known for high-quality builds. As industry experts, we can vet them by their track record and their reputation among insiders.",
    active: false,
  },
  /*
  {
    id: 19,
    questionFiltersId: 5,
    question: "Can I check on construction as my home is built?",
    answer: "TO FILL",
    active: false,
  },
  */
  {
    id: 20,
    questionFiltersId: 5,
    question: "What if there’s an unexpected complication?",
    answer:
      "While unexpected issues arise, our Home Specialists are a resource for you throughout the construction process. Reach out via email and we’ll work with you and the builder to resolve the issue.<br><br>Once the home is built, all HomeFounder homes are covered by a third party warranty.",
    active: false,
  },

  /*
  {
    id: 21,
    questionFiltersId: 6,
    question: "Who do I contact with questions?",
    answer:
      'Your local Home Specialist is the best person to direct questions to. They can also coordinate questions with our lender or builder, as needed. Reach out to <a class="text-body-2 text-blue-500 font-semibold" href="mailto:homespecialists@homefounder.com">homespecialists@homefounder.com</a>',
    active: false,
  },
*/
];

<template>
  <div
    class="relative"
    :class="[showFullscreenCarousel ? 'fullscreen-carousel' : '']"
  >
    <PrimaryImageOnlyBaseCta
      v-if="showFullscreenCarousel"
      type="tertiary"
      image="icons/close_white.svg"
      :dark="true"
      class="absolute left-6 top-6 z-10 border-white w-10 h-10"
      @click="toggleFullscreenCarousel"
    />

    <PrimaryImageOnlyBaseCta
      v-if="!showFullscreenCarousel"
      type="tertiary"
      image="icons/fullscreen_arrows.svg"
      class="absolute right-6 top-6 z-10 w-10 h-10"
      v-on:click="toggleFullscreenCarousel"
    />

    <Swiper
      ref="mySwiper"
      :options="swiperOptions"
      :style="
        fixedHeight && !showFullscreenCarousel ? `height: ${fixedHeight}px` : ''
      "
      :class="[
        showFullscreenCarousel ? 'fullscreen-swiper' : '',
        activeTab === 'photos' && !showFullscreenCarousel
          ? 'linear-gradient'
          : '',
      ]"
    >
      <SwiperSlide :key="image.id" v-for="image in allImages">
        <img
          class="swiper-lazy rounded-16 h-full w-full object-center"
          :class="[
            !showFullscreenCarousel && !hasOnlyFloorplansImages
              ? 'object-cover'
              : 'object-contain',
            activeTab === 'floorplans' ? 'h-full' : '',
          ]"
          :src="image.src"
          :alt="image.alt"
        />
        <div class="swiper-lazy-preloader"></div>
      </SwiperSlide>
      <div class="swiper-pagination text-body-2" slot="pagination" />

      <div
        class="swiper-button-prev"
        :class="[
          paginationDotsOnly ? 'hidden' : '',
          hideSwiperButton ? 'hidden' : '',
        ]"
        slot="button-prev"
      />
      <div
        class="swiper-button-next"
        :class="[
          paginationDotsOnly ? 'hidden' : '',
          hideSwiperButton ? 'hidden' : '',
        ]"
        slot="button-next"
      />
    </Swiper>

    <div
      v-if="floorplansImages.length && images.length"
      class="absolute bottom-4 right-0 z-10 hidden xl:block"
    >
      <div class="flex flex-row">
        <div
          class="mr-5 py-2 px-4 cursor-pointer rounded-32"
          :class="[
            this.activeTab === 'photos'
              ? 'text-creme-200 bg-creme-300 bg-opacity-20'
              : '',
            this.showFullscreenCarousel ? 'text-creme-200' : '',
          ]"
          @click="swipeToIndex(0)"
        >
          Photos
        </div>
        <div
          class="mr-5 py-2 px-4 cursor-pointer rounded-32"
          :class="[
            this.activeTab === 'floorplans'
              ? 'bg-creme-300 font-semibold'
              : 'text-creme-200',
            this.showFullscreenCarousel ? 'text-creme-200 bg-opacity-20' : '',
          ]"
          @click="swipeToIndex(images.length)"
        >
          Floorplans
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PrimaryImageOnlyBaseCta } from "@/components/buttons";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "Carousel",
  components: {
    PrimaryImageOnlyBaseCta,
    Swiper,
    SwiperSlide,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    floorplansImages: {
      type: Array,
      default: () => [],
    },
    paginationDotsOnly: {
      type: Boolean,
      default: false,
    },
    fixedHeight: {
      type: Number,
    },
    hideSwiperButton: {
      type: Boolean,
      default: false,
    },
    forceFullscreen: {
      type: Boolean,
      default: false,
    },
    preselectedImageId: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      currentImage: 0,
      activeTab: "photos",
      showFullscreenCarousel: this.forceFullscreen,
      swiperOptions: {
        autoHeight: !this.fixedHeight,
        lazy: true,
        zoom: true,
        pagination: {
          el: ".swiper-pagination",
          type: "custom",
          clickable: true,
          renderCustom: (swiper, current, total) => {
            this.setCurrentImage(current - 1);

            // TODO - This below is a mess and needs to be optimized for an easier maintenance
            let textHtml =
              "<div class='flex flex-row items-center justify-center xl:justify-between w-full mb-3'>";

            if (this.floorplansImages.length) {
              textHtml +=
                "<div class='flex flex-row justify-between items-center xl:min-w-[180px]'>";
            } else if (!this.paginationDotsOnly) {
              textHtml +=
                "<div class='flex flex-row-reverse justify-between items-center w-full'>";
            } else
              textHtml +=
                "<div class='flex flex-row justify-center items-center w-full'>";

            if (this.allImageNames.length && !this.paginationDotsOnly) {
              textHtml += `<div class='mx-6 text-body-3 ${
                this.activeTab === "photos" ? "text-creme-300" : "text-black"
              } ${this.showFullscreenCarousel ? "text-creme-300" : ""}'>${
                this.allImageNames[current - 1]
              }</div>`;
            }

            textHtml += `<div class='flex flex-row mx-6'>`;
            for (let index = 0; index < total; index++) {
              textHtml += `
              ${
                !this.showFullscreenCarousel
                  ? `<div class="${
                      this.activeTab === "photos"
                        ? "swiper-pagination-bullet"
                        : "swiper-pagination-bullet-black"
                    } ${
                      index === current - 1
                        ? `${
                            this.activeTab === "photos"
                              ? "swiper-pagination-bullet-active"
                              : "swiper-pagination-bullet-active-black"
                          }`
                        : ""
                    }"></div>`
                  : `<div class="swiper-pagination-bullet
                   ${
                     index === current - 1
                       ? "swiper-pagination-bullet-active"
                       : ""
                   }"></div>`
              }`;
            }
            textHtml += "</div>";

            textHtml += "</div>";

            textHtml += "</div>";
            return textHtml;
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    allImages() {
      return [...this.images, ...this.floorplansImages];
    },
    allImageNames() {
      return [
        ...this.images.map((img) => img.alt),
        ...this.floorplansImages.map((img) => img.alt),
      ];
    },
    hasOnlyFloorplansImages() {
      return !this.images.length && this.floorplansImages.length;
    },
  },
  methods: {
    setCurrentImage(index) {
      this.currentImage = index;
      this.setActiveTab(index);
    },
    setActiveTab(index) {
      if (index >= this.images.length) {
        this.activeTab = "floorplans";
      } else this.activeTab = "photos";
    },
    swipeToIndex(index = 0) {
      // images.length is the starting INDEX of floorplan images
      // which are spread just after the house images
      this.setActiveTab(index);

      if (this.swiper && this.swiper.slideTo) {
        this.swiper.slideTo(index, 500, false);
      }
    },
    toggleFullscreenCarousel() {
      if (this.showFullscreenCarousel) {
        this.$emit("closeFullscreen");
      }

      this.showFullscreenCarousel = !this.showFullscreenCarousel;

      if (this.showFullscreenCarousel) {
        document.querySelector("html").classList.add("overflow-hidden");
      } else document.querySelector("html").classList.remove("overflow-hidden");
    },
    setInitialSlideNumber() {
      if (this.preselectedImageId >= 0) {
        const foundImageIndex = this.allImages.findIndex(
          (image) => image.id === this.preselectedImageId
        );

        if (foundImageIndex >= 0) {
          this.setCurrentImage(foundImageIndex);
          this.swiper.slideTo(foundImageIndex, 0);
        }
      }
    },
  },
  mounted() {
    this.setInitialSlideNumber();
  },
};
</script>

<style scoped>
.swiper-container {
  @apply rounded-16;
}
.linear-gradient::after {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 68.8%,
    rgba(0, 0, 0, 0.4) 86.41%,
    rgba(0, 0, 0, 0.6) 100%
  );
}

.swiper-pagination >>> .swiper-pagination-bullet {
  /* Note:
   * !important is put here because Swiper.js overrides classes
   * by applying style="" tag on .swiper-pagination-bullet element */
  @apply w-3 h-3 mr-2 opacity-100 border border-creme-300 cursor-pointer bg-transparent !important;
}
.swiper-pagination >>> .swiper-pagination-bullet-active {
  /* Note:
   * !important is put here because Swiper.js overrides classes
   * by applying style="" tag on .swiper-pagination-bullet-active element */
  @apply bg-creme-300 !important;
}

.swiper-pagination >>> .swiper-pagination-bullet-black {
  @apply w-3 h-3 mr-2 opacity-100 rounded-100 cursor-pointer border border-black bg-transparent !important;
}
.swiper-pagination >>> .swiper-pagination-bullet-active-black {
  @apply bg-black !important;
}

.object-unset {
  object-fit: unset;
}

.images-button-active {
  @apply text-creme-200 bg-creme-300 bg-opacity-20 rounded-32;
}

.fullscreen-carousel {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background: black;
  z-index: 1000;
}
.fullscreen-swiper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fullscreen-swiper >>> .swiper-slide {
  height: 75%;
  margin: auto;
  display: flex;
}

.swiper-button-prev {
  @apply w-20 h-20 absolute left-0 top-[50%] bg-black bg-opacity-40 hover:bg-opacity-100 rounded-tr-16 rounded-br-16;
}
.swiper-button-prev::after {
  background: url("~@/assets/icons/chevron_big_left_white.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  @apply text-transparent;
}
.swiper-button-next {
  @apply w-20 h-20 absolute right-0 top-[50%] bg-black bg-opacity-40 hover:bg-opacity-100 rounded-tl-16 rounded-bl-16;
}
.swiper-button-next::after {
  background: url("~@/assets/icons/chevron_big_right_white.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  @apply text-transparent;
}
</style>

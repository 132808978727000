<template>
  <div class="max-container">
    <div class="main-grid mx-8 md:mx-16 lg:mx-12 xl:mx-16">
      <div class="col-span-full lg:col-span-10 lg:col-start-2">
        <div class="flex flex-wrap text-h4 text-gray-900 pt-4 md:pt-8 pb-8">
          <div class="flex flex-col flex-grow mr-4 lg:mr-0 mb-4 lg:mb-0">
            <span class="text-body-1 md:text-h4 font-semibold">
              {{ houseModel.lot.size }}
            </span>
            <span class="text-body-2 md:text-body-1 mt-2"> Acres </span>
          </div>

          <div class="flex flex-col flex-grow mr-4 lg:mr-0 mb-4 lg:mb-0">
            <span class="text-body-1 md:text-h4 font-semibold">
              {{ houseModel.squareFootage }}
            </span>
            <span class="text-body-2 md:text-body-1 mt-2"> Sq. ft. </span>
          </div>

          <div class="flex flex-col flex-grow mr-4 lg:mr-0 mb-4 lg:mb-0">
            <span class="text-body-1 md:text-h4 font-semibold">
              {{ houseModel.bedrooms }}
            </span>
            <span class="text-body-2 md:text-body-1 mt-2"> Beds </span>
          </div>

          <div class="flex flex-col flex-grow mr-4 lg:mr-0 mb-4 lg:mb-0">
            <span class="text-body-1 md:text-h4 font-semibold">
              {{ houseModel.bathrooms }}
            </span>
            <span class="text-body-2 md:text-body-1 mt-2"> Baths </span>
          </div>

          <div
            class="flex flex-col md:flex-row md:flex-wrap md:max-w-[288px] mr-8 lg:mr-0"
          >
            <div class="text-body-1 md:text-h4 font-semibold order-1 w-max">
              {{ $currencyNoDecimal.format(houseModel.costs.price) }}
            </div>
            <div
              class="text-body-2 bg-yellow-500 font-semibold order-last md:order-2 md:ml-2 mt-2 md:mt-0 py-1 px-2 rounded-8 h-[fit-content] w-min whitespace-nowrap"
            >
              {{
                roundNumberToShorthandCurrency(
                  houseModel.costs.value - houseModel.costs.price
                )
              }}
              below appraisal
            </div>

            <span
              class="cursor-pointer text-body-2 md:text-body-1 font-semibold order-3 md:order-2 text-blue-500 mt-2"
              @click="scrollToSection()"
            >
              Guaranteed price
            </span>
          </div>
          <div class="flex flex-col">
            <span class="text-body-1 md:text-h4 font-semibold">
              {{ moveInEstimate }}
            </span>
            <span class="text-body-2 md:text-body-1 mt-2"> Est. move-in </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { roundNumberToShorthandCurrency } from "@/helpers/currencyFormaterHelper.js";

export default {
  name: "QuickInfo",
  props: {
    houseModel: {
      type: Object,
      default: () => {},
    },
    moveInEstimate: {
      type: String,
      default: "",
    },
  },
  methods: {
    roundNumberToShorthandCurrency,
    scrollToSection() {
      const section = document.getElementById("guaranteed-pricing");
      section.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

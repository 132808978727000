<template>
  <section>
    <div id="lot-list-wrapper" class="hidden sm:block">
      <div id="available-lots-section" class="px-8">
        <div class="py-8 font-semibold text-body-2 text-left">
          Home model “{{ $get(selectedLot.house, "name") }}” is available on
          {{ lotsWithSameHouseAvailability }}
          lots.
        </div>

        <div
          v-for="lot in availablePlotsArray"
          :key="lot.id"
          class="grid-display"
          id="lot-item"
        >
          <div class="col-span-1">
            <div class="house-icon flexible-image" />
          </div>
          <div class="col-span-3">
            <div class="font-semibold text-body-1">{{ lot.name }}</div>
            <div class="text-body-3">
              {{ lot.house.sqfoot }} sq.ft
              <span class="ml-4">
                {{ roundNumberToShorthandCurrency(lot.house.price) }}
              </span>
            </div>
            <div class="font-semibold text-blue-500 text-body-3">
              HollySprings Rd. 53, GA
            </div>
          </div>
          <div class="col-span-2">
            <PrimaryBaseCta
              class="max-h-[40px] ml-auto"
              :class="selectedLot.id === lot.id ? 'active' : ''"
              type="tertiary"
              fixedSize="small"
              :text="selectedLot.id === lot.id ? 'Selected' : 'Select'"
              @click="onClickHandler(lot)"
            />
          </div>
          <hr class="mt-2" />
        </div>
      </div>

      <div id="unavailable-lots-section" class="px-8">
        <div class="bg-creme-300">
          <h2 class="mb-0">
            <button
              class="font-semibold flex justify-between items-center w-full pt-8 pb-6 px-0 bg-creme-300"
              type="button"
              @click="toggleAccordionModal"
            >
              <span>
                Lots with other home models

                <span>{{ ` (${lotsWithoutSameHouseAvailability})` }} </span>
              </span>

              <span
                class="my-accordion-button"
                :class="accordionOpened ? 'show-accordion' : ''"
              ></span>
            </button>
          </h2>

          <div
            class="my-accordion-body"
            :class="accordionOpened ? 'show-accordion' : ''"
          >
            <div v-if="accordionOpened">
              <div
                v-for="lot in unavailablePlotsArray"
                :key="lot.id"
                class="grid-display col-span-full pt-8"
                id="lot-item"
              >
                <div class="col-span-1">
                  <div class="house-icon flexible-image" />
                </div>
                <div class="col-span-3">
                  <div class="font-semibold text-body-1">{{ lot.name }}</div>
                  <div class="text-body-3">
                    {{ lot.house.sqfoot }} sq.ft
                    <span class="ml-4">
                      {{ roundNumberToShorthandCurrency(lot.house.price) }}
                    </span>
                  </div>
                  <div class="font-semibold text-blue-500 text-body-3">
                    HollySprings Rd. 53, GA
                  </div>
                </div>
                <div class="col-span-2 text-body-3 text-right">
                  Home model:

                  <div>"{{ lot.house.name }}"</div>
                </div>
                <hr class="col-span-full bg-black opacity-20 mt-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="lot-list-wrapper-mobile" class="block sm:hidden">
      <Swiper :options="swiperOptions" class="flex flex-row">
        <SwiperSlide
          id="swiper-slide"
          v-for="(lot, index) in lotArray"
          :key="lot.id"
        >
          <div
            v-if="isCurrentHouseModel(lot.house.id)"
            id="available-lots-section-mobile"
          >
            <div
              id="test"
              :class="
                index + 1 === availablePlotsArray.length
                  ? ''
                  : 'border-r border-opacity-20 border-black'
              "
            >
              <div class="font-semibold text-body-1">
                {{ lot.name }}
              </div>
              <div class="text-body-2 mt-2">
                {{ lot.house.sqfoot }} sq.ft
                <span class="ml-4">
                  {{ roundNumberToShorthandCurrency(lot.house.price) }} total
                </span>
              </div>
              <div class="font-semibold text-blue-500 text-body-3 mt-2">
                Address, HollySprings Rd. 53, GA
              </div>
              <PrimaryBaseCta
                class="max-h-[40px] mt-6"
                :class="selectedLot.id === lot.id ? 'active' : ''"
                type="tertiary"
                fixedSize="small"
                :text="selectedLot.id === lot.id ? 'Selected' : 'Select'"
                @click="onClickHandler(lot)"
              />
            </div>
          </div>
          <div
            v-if="!isCurrentHouseModel(lot.house.id)"
            id="unavailable-lots-section-mobile"
          >
            <div
              :class="
                index + 1 ===
                availablePlotsArray.length + unavailablePlotsArray.length
                  ? ''
                  : 'border-r border-opacity-20 border-black'
              "
            >
              <div class="font-semibold text-body-1">
                {{ lot.name }}
              </div>
              <div class="text-body-3 mt-2">
                {{ lot.house.sqfoot }} sq.ft
                <span class="ml-4">
                  {{ roundNumberToShorthandCurrency(lot.house.price) }} total
                </span>
              </div>
              <div class="font-semibold text-blue-500 text-body-3">
                HollySprings Rd. 53, GA
              </div>
              <div class="text-body-3 text-left pt-6">
                Home model:

                <div>"{{ lot.house.name }}"</div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  </section>
</template>

<script>
import { roundNumberToShorthandCurrency } from "@/helpers/currencyFormaterHelper.js";
import { PrimaryBaseCta } from "@/components/buttons";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BUILDER_EVENTS } from "@/constants";

const { LOT_SELECTION } = BUILDER_EVENTS;

export default {
  name: "FullscreenMapLotList",
  components: { PrimaryBaseCta, Swiper, SwiperSlide },
  props: {
    selectedLot: {
      type: Object,
      default: () => {},
    },
    lotArray: Array,
    selectedHouseModel: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      accordionOpened: false,
      swiperOptions: {
        slidesPerView: "auto",
        freeMode: true,
      },
    };
  },
  mounted() {},
  computed: {
    availablePlotsArray() {
      return this.lotArray.filter(
        (lot) => lot.house.id === this.selectedHouseModel.id
      );
    },
    unavailablePlotsArray() {
      return this.lotArray.filter(
        (lot) => lot.house.id !== this.selectedHouseModel.id
      );
    },
    lotsWithSameHouseAvailability() {
      return this.availablePlotsArray.length;
    },
    lotsWithoutSameHouseAvailability() {
      return this.unavailablePlotsArray.length;
    },
  },
  methods: {
    roundNumberToShorthandCurrency,
    isCurrentHouseModel(houseId) {
      return houseId === this.selectedHouseModel.id;
    },
    toggleAccordionModal() {
      this.accordionOpened = !this.accordionOpened;
    },
    onClickHandler(lot) {
      this.$emit(LOT_SELECTION, lot);
    },
  },
};
</script>

<style scoped>
#lot-list-wrapper {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  @apply absolute top-8 right-8;
}

#available-lots-section {
  @apply bg-creme-200 rounded-t-16;
}

#unavailable-lots-section {
  @apply bg-creme-300 rounded-b-16;
}

#lot-list-wrapper-mobile {
  @apply bg-creme-200 absolute w-full bottom-24 rounded-t-16 overflow-hidden;
}

#available-lots-section-mobile {
  @apply bg-creme-200 py-8 pl-8 pr-0;
}

#unavailable-lots-section-mobile {
  @apply bg-creme-300 pt-8 pb-12 pl-8;
}

.grid-display {
  @apply grid grid-cols-6 gap-4;
}

.house-icon {
  background: url("~@/assets/images/gardenia.png");
  @apply bg-no-repeat bg-center bg-cover border border-black rounded-8 w-12 h-12;
}

.active {
  box-shadow: 0px 4px 12px 0px #0000001a;
  @apply bg-yellow-500;
}

#available-lots-section div:last-child hr {
  @apply hidden;
}

#available-lots-section #lot-item:last-child {
  @apply pb-8;
}

#unavailable-lots-section #lot-item:last-child {
  @apply pb-8;
}

.my-accordion-body {
  height: 0px;
  will-change: height;
  transition: height 0.3s ease-in-out;
}

.my-accordion-body div:first-child {
  @apply pt-0;
}

.my-accordion-body div:last-child hr {
  @apply hidden;
}

.show-accordion {
  height: 100%;
  transition: height 0.3s ease-in-out;
}

.my-accordion-button:not(.show-accordion) {
  background: url("~@/assets/icons/chevron_down_sm.svg");
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
  background-size: 16px;
  @apply w-4 h-2;
}

.my-accordion-button {
  background: url("~@/assets/icons/chevron_down_sm.svg");

  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
  background-size: 16px;
  @apply w-4 h-2;
}

.swiper-slide {
  max-width: 250px;
  width: 60%;
}
</style>

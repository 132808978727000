<template>
  <div :id="containerId" class="mt-32">
    <div class="relative overflow-auto">
      <BuilderHeader
        title="Choose your lot"
        class="transition-opacity duration-1000 ease-out"
      />
      <MapboxWrapper :selectedHouseModel="selectedHouseModel" />
      <!-- TODO loading -->
    </div>
  </div>
</template>

<script>
import BuilderHeader from "@/components/text/BuilderHeader";
import { BUILDER_COMPONENTS_IDS } from "@/constants";
import { MapboxWrapper } from "@/components/map";

export default {
  name: "LotMap",
  components: {
    BuilderHeader,
    MapboxWrapper,
  },
  props: {
    lots: {
      type: Array,
      default: () => [],
    },
    selectedHouseModel: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: true,
      containerId: BUILDER_COMPONENTS_IDS.LOT_MAP,
    };
  },
  mounted() {
    if (this.observer != null) {
      this.observer.observe(this.$el);
    }
    const _this = this;
    setTimeout(() => {
      _this.isLoading = false;
    }, 5000);
  },
};
</script>

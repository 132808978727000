<template>
  <button
    class="flex flex-row button-container-base"
    v-bind:class="[
      `${type}-background`,
      `button-container-base-${fixedSize}`,
      `button-container-${fixedSize}`,
    ]"
    @click="onClickHandler"
    :type="eventName"
  >
    <div
      :class="[
        `button-img-wrapper-${fixedSize}`,
        `button-img-wrapper-start-${fixedSize}`,
      ]"
      v-if="startImage"
    >
      <img v-if="startImage" :src="require('@/assets/' + startImage)" />
    </div>
    <div
      v-if="text"
      class="button-text"
      v-bind:class="[`${type}-text-color`, `text-size-${fixedSize}`]"
    >
      {{ text }}
    </div>
    <div
      :class="[
        `button-img-wrapper-${fixedSize}`,
        `button-img-wrapper-end-${fixedSize}`,
      ]"
      v-if="endImage"
    >
      <img v-if="endImage" :src="require('@/assets/' + endImage)" />
    </div>
  </button>
</template>

<script>
export default {
  name: "",
  props: {
    text: {
      type: String,
      default: "Get Priority Access",
    },
    startImage: {
      type: String,
      default: null,
    },
    endImage: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "primary",
    },
    fixedSize: {
      type: String,
      default: "responsive",
      // small, medium, large, responsive
    },
    eventName: {
      type: String,
      default: "click",
    },
  },
  methods: {
    onClickHandler() {
      this.$emit(`${this.eventName}`);
    },
  },
};
</script>
<style scoped>
.button-container-base {
  @apply items-center justify-center cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed;
}
.button-container-base-responsive {
  @apply lg:rounded-16 rounded-12;
}
.button-container-base-small {
  @apply rounded-12;
}
.button-container-base-medium {
  @apply rounded-12;
}
.button-container-base-large {
  @apply lg:rounded-16 rounded-12;
}

.button-container-responsive {
  @apply xl:px-6 xl:py-4 xl:min-h-[64px] lg:px-5 lg:py-3 lg:min-h-[52px] px-4 min-h-[40px];
}
.button-container-small {
  @apply px-4 py-2 min-h-[40px];
}
.button-container-medium {
  @apply lg:px-5 lg:py-3 lg:min-h-[52px] px-4 py-2 min-h-[40px];
}
.button-container-large {
  @apply xl:px-6 xl:py-4 xl:min-h-[64px] lg:px-5 lg:py-3 lg:min-h-[52px] px-4 min-h-[40px];
}

.button-img-wrapper-responsive {
  @apply relative xl:w-6 xl:h-6 lg:w-5 lg:h-5 w-4 h-4;
}
.button-img-wrapper-responsive > img {
  @apply absolute xl:h-6 xl:w-6 lg:w-5 lg:h-5 w-4 h-4;
}
.button-img-wrapper-small {
  @apply relative w-4 h-4;
}
.button-img-wrapper-small > img {
  @apply absolute w-4 h-4;
}
.button-img-wrapper-medium {
  @apply relative w-5 h-5;
}
.button-img-wrapper-medium > img {
  @apply absolute w-5 h-5;
}
.button-img-wrapper-large {
  @apply relative w-6 h-6;
}
.button-img-wrapper-large > img {
  @apply absolute h-6 w-6;
}

.button-img-wrapper-start-responsive {
  @apply xl:mr-3 mr-2;
}
.button-img-wrapper-start-small {
  @apply mr-2 flex-shrink-0;
}
.button-img-wrapper-start-medium {
  @apply mr-3;
}
.button-img-wrapper-start-large {
  @apply mr-3;
}

.button-img-wrapper-end-responsive {
  @apply xl:ml-3 ml-2;
}
.button-img-wrapper-end-small {
  @apply ml-2;
}
.button-img-wrapper-end-medium {
  @apply ml-3;
}
.button-img-wrapper-end-large {
  @apply ml-3;
}

.button-text {
  @apply font-semibold;
}

.primary-background {
  @apply bg-blue-500 shadow-primary-cta hover:shadow-primary-hover-cta hover:bg-gradient-to-t hover:from-hovered-0.1 hover:to-blue-500 active:bg-gradient-to-t active:from-hovered-0.1 active:to-blue-500 active:shadow-primary-cta disabled:shadow-primary-cta;
}
.primary-text-color {
  @apply text-creme-50;
}

.secondary-background {
  @apply bg-transparent border border-blue-500 xl:hover:bg-blue-100 active:bg-blue-200;
}
.secondary-text-color {
  @apply text-blue-500 xl:text-button-large lg:text-button-medium text-button-small;
}

.tertiary-background {
  @apply bg-creme-200 border border-gray-900 xl:hover:bg-creme-300 active:bg-creme-300;
}
.tertiary-text-color {
  @apply text-gray-900 xl:text-button-large lg:text-button-medium text-button-small;
}

.link-background {
  @apply bg-transparent xl:hover:bg-blue-100 rounded-none py-0 px-0 active:bg-blue-200;
}
.link-text-color {
  @apply text-blue-500 xl:text-button-large lg:text-button-medium text-button-small;
}

.text-size-responsive {
  @apply xl:text-button-large lg:text-button-medium text-button-small;
}
.text-size-small {
  @apply text-button-small;
}
.text-size-medium {
  @apply lg:text-button-medium text-button-small;
}
.text-size-large {
  @apply xl:text-button-large lg:text-button-medium text-button-small;
}
</style>

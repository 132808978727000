<template>
  <div class="max-container overflow-x-clip">
    <BasicSpinner v-if="loading" class="h-full m-auto" />

    <div v-else class="main-grid mt-12 xl:my-12">
      <div class="col-span-full xl:col-span-4 sticky top-16 z-30 bg-creme-200">
        <Summary />
      </div>
      <div class="col-span-full xl:col-start-5 xl:col-span-auto">
        <HomeModel
          v-if="selectedModel"
          :homeModels="homeModels"
          :selectedModel="selectedModel"
        />
        <LotMap :selectedHouseModel="selectedModel" />
        <HomeCustomizations :customizations="customizations" />
        <HomeAddons :addons="selectedModel.addons.items" />
        <SaveHome />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Summary,
  LotMap,
  HomeModel,
  HomeCustomizations,
  HomeAddons,
  SaveHome,
  BasicSpinner,
} from "@/components";
import { BUILDER_EVENTS, HOUSE_NAMES } from "@/constants";
import { API } from "aws-amplify";
import { listHouses } from "@/graphql/queries";

export default {
  name: "HomeBuilder",
  components: {
    Summary,
    LotMap,
    HomeModel,
    HomeCustomizations,
    HomeAddons,
    SaveHome,
    BasicSpinner,
  },
  data() {
    return {
      loading: true,
      homeModels: [],
      selectedModel: {},
    };
  },
  computed: {
    customizations() {
      if (this.selectedModel?.allCustomizations)
        return {
          kitchen: this.selectedModel?.allCustomizations?.kitchen?.items,
          flooring: this.selectedModel?.allCustomizations?.flooring?.items,
        };
      else return null;
    },
  },
  methods: {
    async fetchHomeModels() {
      try {
        const graphqlResponse = await API.graphql({ query: listHouses });
        const housesArray = graphqlResponse?.data?.listHouses?.items
          ? graphqlResponse?.data?.listHouses?.items
          : null;

        if (housesArray && housesArray.length) {
          this.loading = false;
          this.homeModels = housesArray;
          this.selectedModel = this.homeModels?.length
            ? this.homeModels.filter(
                (model) => model.name === HOUSE_NAMES.GARDENIA
              )[0]
            : null;
        }
      } catch (error) {
        // TODO: Handle error fetching data
        console.error("Error fetching data");
      }
    },
  },
  mounted() {
    this.fetchHomeModels();

    this.$root.$on(
      BUILDER_EVENTS.MODEL_SELECTION,
      (val) => (this.selectedModel = val)
    );
  },
};
</script>

<style scoped>
.overflow-x-clip {
  overflow-x: clip;
}
</style>

<template>
  <section class="max-container">
    <div class="main-grid">
      <div class="col-span-full lg:col-span-5 xl:col-span-4 xl:col-start-2">
        <div
          class="flex flex-col justify-center text-gray-900 text-body-2 lg:text-body-1 lg:max-w-[380px]"
        >
          <p>
            The Estates at Holly Springs is our first HomeFounder community. A
            beautiful community in Douglasville, GA, it has extensive amenities
            and is conveniently located just 30 minutes away from downtown
            Atlanta.
          </p>
          <p class="my-6 lg:mb-0">
            With wide streets and large yards, Holly Springs is a quiet commuter
            neighborhood with room to spread out.
          </p>
          <hr class="lg:block" />
        </div>
      </div>

      <div class="col-span-full lg:hidden">
        <div class="mx-auto">
          <Carousel :images="images" :fixedHeight="300" />
        </div>
      </div>

      <div
        class="col-span-full lg:col-span-7 lg:col-start-6 lg:justify-between hidden lg:flex"
      >
        <div class="h-[90%] lg:w-full mr-4">
          <div class="relative h-[256px] w-[256px] lg:h-full lg:w-full">
            <div class="amenities-image flexible-image rounded-16"></div>
          </div>
          <div class="text-right mt-3 text-body-3">Amenities</div>
        </div>

        <div class="h-[90%] lg:w-full ml-4">
          <div class="relative h-[256px] w-[256px] lg:h-full lg:w-full">
            <div class="sign-image flexible-image rounded-16"></div>
          </div>
          <div class="text-right mt-3 text-body-3">Community street photo</div>
        </div>
      </div>

      <div
        class="col-span-full lg:col-span-5 xl:col-start-2 xl:col-span-4 xl:max-w-[380px]"
      >
        <hr class="lg:hidden" />
        <div class="flex flex-col">
          <div>
            <h3 class="text-h4 font-semibold">Amenities</h3>
            <p class="text-body-2 mt-2">
              Leisure &amp; recreation for everyone
            </p>
          </div>
          <div class="min-h-ful mt-8">
            <div class="icons-grid">
              <div>
                <img src="@/assets/icons/pool.svg" alt="Swimming pool icon" />
                <span> Swimming pool </span>
              </div>
              <div>
                <img src="@/assets/icons/clubhouse.svg" alt="House icon" />
                <span> Clubhouse </span>
              </div>
              <div>
                <img src="@/assets/icons/tennis.svg" alt="Tennis ball icon" />
                <span> Tennis courts </span>
              </div>
              <div>
                <img
                  src="@/assets/icons/basketball.svg"
                  alt="Basketball court icon"
                />
                <span> Basketball court </span>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div class="hidden lg:block text-blue-500 font-semibold text-body-1">
          <div class="mb-8">
            <a href="#" class="flex w-max">
              Download brochure
              <img
                src="@/assets/icons/arrow_down_blue.svg"
                alt="Arrow down icon"
                class="ml-4"
              />
            </a>
          </div>
          <div>
            <a href="#" class="flex w-max">
              Drive through in Google Maps
              <img
                src="@/assets/icons/arrow_right_blue.svg"
                alt="Arrow right icon"
                class="ml-4"
              />
            </a>
          </div>
        </div>
      </div>

      <div class="col-span-full lg:col-span-7 lg:col-start-6 flex">
        <div class="w-full relative">
          <img
            class="w-full rounded-16"
            src="@/assets/images/ammenities_map.jpg"
            alt="Ammenities map"
          />
          <div class="badge">5 available home lots</div>
        </div>
      </div>

      <div
        class="col-span-full lg:hidden text-blue-500 font-semibold text-body-1"
      >
        <hr />
        <div class="mb-8">
          <a href="#" class="flex w-max">
            Download brochure
            <img
              src="@/assets/icons/arrow_down_blue.svg"
              alt="Arrow down icon"
              class="ml-4"
            />
          </a>
        </div>
        <div>
          <a href="#" class="flex w-max">
            Drive through in Google Maps
            <img
              src="@/assets/icons/arrow_right_blue.svg"
              alt="Arrow right icon"
              class="ml-4"
            />
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel } from "@/components/carousels";

export default {
  components: {
    Carousel,
  },
  data() {
    return {
      images: [
        { id: 0, src: "/img/amenities.jpg", alt: "Ammenities" },
        {
          id: 1,
          src: "/img/np_welcome-sign.jpg",
          alt: "Community street photo",
        },
      ],
    };
  },
};
</script>

<style scoped>
.icons-grid {
  @apply h-full grid grid-cols-2 grid-rows-2;
}

.icons-grid img {
  @apply w-14;
}
.icons-grid div {
  @apply w-full flex col-span-full;
}
.icons-grid span {
  @apply flex text-left items-center text-body-1 m-4;
}

.amenities-image {
  background: url("~@/assets/images/amenities.jpg");
  @apply bg-no-repeat bg-center bg-cover;
}
.sign-image {
  background: url("~@/assets/images/np_welcome-sign.jpg");
  @apply bg-no-repeat bg-center bg-cover;
}

.static-map-image {
  background: url("~@/assets/images/ammenities_map.jpg");
  @apply bg-no-repeat bg-center bg-cover;
}

.flexible-image {
  @apply absolute top-0 bottom-0 left-0 right-0;
}
.town-hall-image {
  background: url("~@/assets/images/convenience.jpg");
  @apply bg-no-repeat bg-center bg-cover;
}
.school-image {
  background: url("~@/assets/images/school.jpg");
  @apply bg-no-repeat bg-center bg-cover;
}

.badge {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  @apply p-6 w-36 h-[max-content] absolute top-4 left-[-16px] md:left-[-32px] bg-creme-50 font-bold rounded-12 text-center text-body-2;
}

hr {
  @apply w-full my-8 h-[2px];
}
</style>

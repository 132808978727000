<template>
  <div class="w-full">
    <BasicSpinner v-if="loading" class="h-full m-auto" />
    <div v-else>
      <HouseHero
        v-if="houseModel.houseImages && houseModel.houseImages.length"
        :image="houseModel.houseImages[0]"
        :name="houseModel.name"
        :location="houseModel.location"
        :lot="houseModel.lot"
        :availability="houseModel.availability"
      />

      <div class="bg-creme-50">
        <QuickInfo
          :houseModel="houseModel"
          :moveInEstimate="location.moveInEstimate"
        />
      </div>

      <global-section type="bottom-only">
        <Exterior :houseModel="houseModel" />
      </global-section>

      <global-section type="300">
        <Interior :houseModel="houseModel" />
      </global-section>

      <global-section type="200">
        <Pricing :houseModel="houseModel" />
      </global-section>

      <global-section type="50">
        <ImportantDates
          :houseName="houseModel.name"
          :availability="houseModel.availability"
        />
      </global-section>

      <global-section type="200">
        <Explore :slug="location.slug" :houseName="houseModel.name" />
      </global-section>

      <ReserveBy
        :banner="houseModel.banners.bottom"
        :houseName="houseModel.name"
      />
    </div>
  </div>
</template>

<script>
import { BasicSpinner, ImportantDates, GlobalSection } from "@/components";
import {
  QuickInfo,
  HouseHero,
  Exterior,
  Interior,
  Pricing,
  Explore,
  ReserveBy,
} from "@/components/listing";
import { getHouseModel } from "@/mocks/useLocationsModel.js";

export default {
  name: "HouseListing",
  components: {
    HouseHero,
    QuickInfo,
    BasicSpinner,
    Exterior,
    Interior,
    Pricing,
    ImportantDates,
    Explore,
    ReserveBy,
    GlobalSection,
  },
  data() {
    return {
      loading: true,
      houseModel: null,
      location: null,
    };
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchLocation",
  },
  methods: {
    async fetchLocation() {
      const locationSlug = this.$route.params.slug;
      const houseSlug = this.$route.params.houseslug;

      if (!locationSlug) {
        // TODO: Handle no location slug case
        console.error("No slug provided!");
      }

      try {
        const { houseModel, location } = await getHouseModel(
          locationSlug,
          houseSlug
        );

        if (houseModel && location) {
          this.loading = false;
          this.houseModel = houseModel;
          this.location = location;
          document.title = houseModel.name;
        }
      } catch (error) {
        // TODO: Handle error fetching data
        console.error("Error fetching data");
      }
    },
  },
  created() {
    this.fetchLocation();
  },
};
</script>

<style scoped></style>

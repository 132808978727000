import {
  AVAILABLE,
  SELECTED,
  UNAVAILABLE,
  SOLD,
  RESERVED,
  SELF_RESERVED,
} from "@/constants";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config.js";

const fullConfig = resolveConfig(tailwindConfig);
const {
  theme: { colors },
} = fullConfig;

const BOTTOM_LAYER = "bottom";
const TOP_LAYER = "top";

const getLayerStyleSpecificationObject = (id, lotStatus, styleName) => {
  return {
    id: `${id + "-" + styleName}-line`,
    type: "line",
    layout: {},
    paint: getLayoutPaintOptions(lotStatus, styleName),
  };
};

const getLayoutPaintOptions = (lotStatus, styleName) => {
  if (styleName === BOTTOM_LAYER) {
    switch (lotStatus) {
      // case SELECTED:
      // For now the same as AVAILABLE
      // return { "line-width": 8, "line-color": colors.yellow[500] };
      case SELECTED:
      case AVAILABLE:
        return { "line-width": 8, "line-color": colors.creme[50] };
      case UNAVAILABLE:
        return { "line-width": 6, "line-color": colors.creme[500] };
      case SOLD:
      case RESERVED:
      case SELF_RESERVED:
        return { "line-width": 6, "line-color": colors.gray[900] };
      default:
        // return UNAVAILABLE
        return {
          "line-width": 6,
          "line-color": colors.creme[500],
        };
    }
  } else if (styleName === TOP_LAYER) {
    switch (lotStatus) {
      case AVAILABLE:
      case SELECTED:
        return {
          "line-color": colors.gray[900],
          "line-width": 2,
        };
      case UNAVAILABLE:
        return {
          "line-color": colors.gray[900],
          "line-width": 2,
          "line-dasharray": [2, 2],
        };
      case SOLD:
      case RESERVED:
      case SELF_RESERVED:
        return {
          "line-color": colors.creme[200],
          "line-width": 2,
          "line-dasharray": [2, 2],
        };
      default:
        // return UNAVAILABLE
        return {
          "line-color": colors.gray[900],
          "line-width": 2,
          "line-dasharray": [2, 2],
        };
    }
  }
  return {};
};

export function getLotStatus(lot, selectedLotId, selectedHouseId) {
  switch (lot.status) {
    case AVAILABLE:
      if (lot.id === selectedLotId) return SELECTED;
      if (lot.house.id !== selectedHouseId) return UNAVAILABLE;
      return AVAILABLE;
    case SOLD:
      return SOLD;
    case RESERVED:
      return RESERVED;
    case SELF_RESERVED:
      return SELF_RESERVED;
    default:
      return UNAVAILABLE;
  }
}

export function getGeoJsonlayerBottom(lot, selectedLotId, selectedHouseId) {
  const status = getLotStatus(lot, selectedLotId, selectedHouseId);
  return getLayerStyleSpecificationObject(lot.id, status, BOTTOM_LAYER);
}

export function getGeoJsonlayerTop(lot, selectedLotId, selectedHouseId) {
  const status = getLotStatus(lot, selectedLotId, selectedHouseId);

  return getLayerStyleSpecificationObject(lot.id, status, TOP_LAYER);
}

<template>
  <div class="main-container">
    <div class="max-container flex flex-col items-center">
      <h2 class="text-h4 lg:text-h2 font-semibold text-center">
        Coming soon to Atlanta!
      </h2>

      <div
        class="mt-8 lg:mt-16 flex flex-col lg:flex-row lg:justify-between lg:bottom-border items-center max-w-[864px] pb-4 md:pb-12 lg:pr-8"
      >
        <div class="w-full lg:w-1/2 lg:pr-16 self-start flex-grow relative">
          <img
            :src="require('@/assets/images/np_hero.jpg')"
            class="rounded-16 w-full lg:h-[240px] object-cover"
          />
        </div>
        <div class="w-full lg:w-1/2">
          <p
            class="text-body-2 lg:text-body-1 mb-4 text-left lg:text-center mt-8 lg:mt-0"
          >
            We’re exploring options for $325-450K homes in the following
            neighborhoods:
          </p>
          <p class="hidden text-body-3 mt-0 mb-12">
            Market value estimate of final homes: $382K - 530K
          </p>
          <div
            class="mt-8 flex flex-row flex-wrap items-center justify-around lg:flex-nowrap"
          >
            <div class="flex flex-col items-center">
              <img
                src="@/assets/images/City1.png"
                class="rounded-full max-w-[80px]"
              />
              <div class="text-body-2 mt-3">Alpharetta</div>
              <div class="text-body-3 mt-1">Fulton County</div>
            </div>
            <div class="flex flex-col items-center text-center">
              <img
                src="@/assets/images/City3.png"
                class="rounded-full max-w-[80px] inline-block"
              />
              <div class="text-body-2 mt-3">Sandy Springs</div>
              <div class="text-body-3 mt-1">Fulton County</div>
            </div>
            <div class="flex flex-col items-center">
              <img
                src="@/assets/images/City4.png"
                class="rounded-full max-w-[80px]"
              />
              <div class="text-body-2 mt-3 text-center">and more</div>
              <div class="text-body-3 mt-1">Atlanta, GA</div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex flex-col items-center text-body-1 mt-8 lg:mt-16 text-center lg:text-left"
      >
        <PrimaryBaseCta
          text="Interested? Let’s chat!"
          endImage="ico_circle_arrow_white.svg"
          class=""
          @click="subscribe"
        />

        <PrimaryBaseCta
          type="link"
          text="Other locations? Tell us!"
          endImage="icons/arrow_right_blue.svg"
          class="mt-3"
          @click="subscribe"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { PrimaryBaseCta } from "@/components";
export default {
  name: "ComingSoon",
  components: {
    PrimaryBaseCta,
  },
  methods: {
    subscribe() {
      this.$router.push({
        path: "/learn-more",
      });
    },
  },
};
</script>
<style scoped>
.max-container {
  max-width: 1088px;
}

.bottom-border {
  @apply border-b border-gray-900 border-opacity-20;
}
</style>

<template>
  <div id="guaranteed-pricing" class="max-container">
    <div class="main-grid">
      <div class="col-span-full">
        <h3 class="text-h3 lg:text-h2 font-semibold text-center mb-20">
          Guaranteed pricing
        </h3>
      </div>

      <div class="col-span-full lg:col-span-4 lg:col-start-2">
        <h4 class="section-title">No bidding wars</h4>
        <p class="section-paragraph">
          Skip the bidding wars and unexpected price hikes! Lock in your price
          at closing, before breaking ground.
        </p>
        <hr class="lg:max-w-[345px] my-12" />

        <h4 class="section-title">Built in equity on day 1</h4>
        <p class="section-paragraph">
          With a home that appraises for more than your ticket price, you'll be
          moving in with more home equity.
        </p>
        <hr class="lg:max-w-[345px] my-12" />

        <h4 class="section-title">Flat fee</h4>
        <p class="section-paragraph">
          HomeFounder charges a flat service fee, rather than a percentage-based
          commission. So we have zero incentive to inflate your price.
        </p>
        <hr class="lg:max-w-[345px] my-12" />

        <PrimaryBaseCta
          type="link"
          text="Learn more in FAQ"
          endImage="icons/arrow_right_blue.svg"
          @click="goToFaq(17)"
        />
      </div>

      <div class="col-span-full lg:col-span-6 lg:col-start-6 mt-4 lg:mt-0">
        <div class="p-12 bg-yellow-500 rounded-t-16">
          <div class="flex flex-wrap">
            <div class="flex flex-col mr-12">
              <div class="text-body-2 lg:text-body-1 mb-2">
                Guaranteed price
              </div>
              <span class="font-semibold text-body-1 lg:text-h3">{{
                $currencyNoDecimal.format(houseModel.costs.price)
              }}</span>
            </div>

            <div class="flex flex-col justify-center">
              <div class="text-body-2 lg:text-body-1 mb-2">
                Independent appraisal*
              </div>
              <div
                class="font-semibold text-body-1 lg:text-h3 flex items-center"
              >
                {{ $currencyNoDecimal.format(houseModel.costs.value) }}
                <span class="badge">
                  Save
                  {{
                    roundNumberToShorthandCurrency(
                      houseModel.costs.value - houseModel.costs.price
                    )
                  }}+
                </span>
              </div>
            </div>
          </div>

          <div class="flex flex-col mt-12">
            <div class="price-title hidden lg:block">
              How are prices calculated?
            </div>

            <hr class="lg:hidden my-6" />

            <div class="price-breakdown">
              <div>Home construction ({{ houseModel.name }} model)</div>
              <div>
                {{
                  $currencyNoDecimal.format(houseModel.costs.construction.price)
                }}
              </div>
            </div>
            <div class="price-breakdown">
              <div>Land ({{ houseModel.lot.name }})</div>
              <div>
                {{ $currencyNoDecimal.format(houseModel.lot.price) }}
              </div>
            </div>
            <div class="price-breakdown">
              <div>Land development</div>
              <div>
                {{
                  $currencyNoDecimal.format(
                    houseModel.costs.construction.landDevelopmentPrice
                  )
                }}
              </div>
            </div>
            <div class="price-breakdown">
              <div>HomeFounder flat fee</div>
              <div>
                {{ $currencyNoDecimal.format(houseModel.costs.homefounderFee) }}
              </div>
            </div>
          </div>
        </div>

        <div class="p-12 bg-creme-50 rounded-b-16">
          <div class="flex flex-col">
            <div class="price-title">Loan information</div>
            <div class="price-breakdown">
              <div>
                Required downpayment ({{
                  houseModel.costs.requiredDownpayment.percentage
                }}%)
              </div>
              <div>
                {{
                  $currencyNoDecimal.format(
                    houseModel.costs.requiredDownpayment.price
                  )
                }}
              </div>
            </div>
            <div class="price-breakdown">
              <div>
                Estimated monthly payment ({{
                  houseModel.costs.estimatedMonthlyPayment.numberOfYears
                }}
                yr)
              </div>
              <div>
                {{
                  $currencyNoDecimal.format(
                    houseModel.costs.estimatedMonthlyPayment.amount
                  )
                }}
              </div>
            </div>
            <p class="text-body-3 lg:text-body-2">
              Loan options are 15-year fixed-rate mortgage or longer-term
              amortizations with 5/1 or 7/1 ARM. Interest rates subject to
              change.
            </p>
            <hr class="my-6 lg:my-8" />

            <div class="price-title">Estimated Closing Costs</div>
            <div class="price-breakdown">
              <div>
                As an independent buyer (~{{
                  houseModel.costs.closingCosts.independent.percentage
                }}%)
              </div>
              <div>
                {{
                  $currencyNoDecimal.format(
                    houseModel.costs.closingCosts.independent.amount
                  )
                }}
              </div>
            </div>
            <div class="price-breakdown">
              <div>
                With a buyer agent commission (~{{
                  houseModel.costs.closingCosts.agent.percentage
                }}%)
              </div>
              <div>
                {{
                  $currencyNoDecimal.format(
                    houseModel.costs.closingCosts.agent.amount
                  )
                }}
              </div>
            </div>
            <hr class="my-6 lg:my-8" />

            <div class="price-title">Recurring annual costs</div>
            <div class="price-breakdown">
              <div>Property Tax</div>
              <div>
                {{
                  $currencyNoDecimal.format(
                    houseModel.costs.annualCosts.propertyTax
                  )
                }}
              </div>
            </div>
            <div class="price-breakdown">
              <div>Property Insurance</div>
              <div>
                {{
                  $currencyNoDecimal.format(
                    houseModel.costs.annualCosts.propertyTax
                  )
                }}
              </div>
            </div>
            <div class="price-breakdown">
              <div>HOA dues</div>
              <div>
                {{
                  $currencyNoDecimal.format(
                    houseModel.costs.annualCosts.hoaDues
                  )
                }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-span-full lg:col-span-4 lg: lg:col-start-8">
        <p class="text-center lg:text-right mb-16">
          *As evaluated by a third-party appraiser. <br />
          Homes will be re-appraised during underwriting.
        </p>
      </div>

      <div class="col-span-full">
        <PrimaryBaseCta
          type="primary"
          :text="buttonText"
          fixedSize="medium"
          endImage="ico_circle_arrow_white.svg"
          class="mx-auto"
          @click="toggleModal"
        />
      </div>

      <LocationInterestModal
        v-if="modalOpened"
        :preselectedHomeModel="houseName"
        @closeLocationInterestModal="toggleModal"
      />
    </div>
  </div>
</template>

<script>
import { PrimaryBaseCta } from "@/components/buttons";
import { roundNumberToShorthandCurrency } from "@/helpers/currencyFormaterHelper.js";
import { LocationInterestModal } from "@/components/modals";
import { AVAILABLE } from "@/constants";

export default {
  name: "Pricing",
  components: {
    PrimaryBaseCta,
    LocationInterestModal,
  },
  props: {
    houseModel: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      modalOpened: false,
    };
  },
  computed: {
    buttonText() {
      return this.houseModel.availability === AVAILABLE
        ? "Interested? Contact us"
        : "Contact us";
    },
    houseName() {
      return this.houseModel.availability === AVAILABLE
        ? this.houseModel.name
        : "";
    },
  },
  methods: {
    roundNumberToShorthandCurrency,
    toggleModal() {
      this.modalOpened = !this.modalOpened;
    },
    goToFaq(n) {
      this.$router.push({ path: "/faq/" + (n ? n : "") });
    },
  },
};
</script>

<style scoped>
hr {
  @apply h-[2px] w-full;
}

.section-title {
  @apply text-body-1 lg:text-h4 font-semibold mb-2;
}
.section-paragraph {
  @apply text-body-2 lg:text-body-1 lg:max-w-[345px];
}
.badge {
  @apply ml-2 py-1 px-2 bg-creme-200 font-semibold text-body-2 lg:text-body-1 rounded-8 whitespace-nowrap;
}

.price-title {
  @apply text-body-2 lg:text-body-1 font-semibold mb-6;
}
.price-breakdown {
  @apply text-body-2 lg:text-body-1 flex justify-between mb-4;
}
</style>

<template>
  <global-section type="top-only">
    <div class="relative w-full">
      <div class="flex flex-col items-center">
        <div>
          <h3 class="text-h2 font-semibold">More questions?</h3>

          <p class="pt-6 text-body-1">
            Fill out a simple form to get in touch with us
          </p>
        </div>

        <PrimaryBaseCta
          text="Contact us"
          endImage="ico_circle_arrow_white.svg"
          class="flex justify-center items-center mt-12"
          @click="goToContact"
        />

        <PrimaryBaseCta
          type="link"
          text="Subscribe for updates"
          endImage="icons/arrow_right_blue.svg"
          class="flex justify-center items-center mt-4"
          @click="goToSubscribe"
        />
      </div>
    </div>
  </global-section>
</template>

<script>
import { PrimaryBaseCta, GlobalSection } from "@/components";

export default {
  name: "MoreQuestions",
  components: {
    PrimaryBaseCta,
    GlobalSection,
  },
  methods: {
    goToContact() {
      this.$router.push({ path: "/learn-more" });
    },
    goToSubscribe() {
      this.$router.push({ path: "/learn-more" });
    },
  },
};
</script>

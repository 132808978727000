<template>
  <div
    ref="modal"
    class="modal overlay"
    :class="{
      'modal-intro-animation': startModalIntroAnimation,
      'show-full-modal': showFullModal,
    }"
  >
    <hr class="pull-line" />
    <PrimaryImageOnlyBaseCta
      image="icons/close_black.svg"
      type="tertiary"
      class="hidden md:block absolute top-[62px] right-12"
      @click="internalClose"
    />
    <div class="min-h-[200px]">
      <slot />
    </div>
  </div>
</template>

<script>
import { PrimaryImageOnlyBaseCta } from "@/components/buttons";

export default {
  name: "DetailsModal",
  components: {
    PrimaryImageOnlyBaseCta,
  },
  data() {
    return {
      touchstartY: 0,
      touchendY: 0,
      startModalIntroAnimation: false,
      showFullModal: false,
    };
  },
  methods: {
    internalClose() {
      this.showFullModal = false;
      this.startModalIntroAnimation = false;

      setTimeout(() => {
        this.$emit("close");
      }, 200);
    },
    handleSwipe() {
      // To avoid false micro swipes
      const SWIPE_THRESHOLD = 10;
      const swippingAmount = this.touchstartY - this.touchendY;

      if (swippingAmount - SWIPE_THRESHOLD > 0) {
        this.showFullModal = true;
      }

      if (swippingAmount + SWIPE_THRESHOLD < 0) {
        this.internalClose();
      }
    },
    setTouchListeners() {
      this.$refs.modal.addEventListener(
        "touchstart",
        (event) => {
          this.touchstartY = parseInt(event.changedTouches[0].screenY);
        },
        false
      );
      this.$refs.modal.addEventListener(
        "touchend",
        (event) => {
          this.touchendY = parseInt(event.changedTouches[0].screenY);
          this.handleSwipe();
        },
        false
      );
      this.$refs.modal.addEventListener(
        "mousedown",
        (event) => {
          this.touchstartY = parseInt(event.y);
        },
        false
      );
      this.$refs.modal.addEventListener(
        "mouseup",
        (event) => {
          this.touchendY = parseInt(event.y);
          this.handleSwipe();
        },
        false
      );
    },
  },
  mounted() {
    this.setTouchListeners();

    setTimeout(() => {
      this.startModalIntroAnimation = true;
    });
  },
};
</script>

<style scoped>
.modal {
  transition: all 0.3s ease-in-out;
  @apply bg-creme-200 rounded-t-16 md:rounded-32 h-[85vh] md:h-auto md:min-h-[max-content] md:w-[640px]  absolute left-0 right-0 top-full md:left-1/2 md:top-1/2 md:translate-x-[-50%] md:translate-y-[-50%] p-8 md:p-12 text-black md:max-h-[90vh] overflow-y-scroll;
}

.modal-intro-animation {
  @apply top-[83%] md:top-1/2;
}

.show-full-modal {
  outline: 9999px solid rgba(0, 0, 0, 0.5);
  @apply top-[15%] overflow-y-scroll md:translate-x-[-50%] md:translate-y-[-50%];
}

.overlay {
  outline: 9999px solid rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 768px) {
  .overlay {
    @apply outline-none;
  }
}

.pull-line {
  @apply block md:hidden w-8 h-1 bg-black rounded-16 mx-auto;
}
</style>

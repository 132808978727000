<template>
  <div class="flex items-center edge-to-edge-mobile smooth-scroll-n-hide mb-6">
    <div class="inline-flex py-1" role="group">
      <button
        v-for="selection in selections"
        :key="selection.name"
        class="text-black px-6 py-3 mr-6 border border-black rounded-16 flex align-center"
        :class="[
          selection.name === $get(selected, 'name', '')
            ? 'bg-yellow-500 elevated-button'
            : 'bg-creme-200',
        ]"
        @click="select(selection)"
      >
        {{ selection.name }}
        <svg
          v-if="selection.colorCode"
          class="ml-2"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="21"
            height="21"
            rx="7.5"
            :fill="selection.colorCode"
            stroke="#2C2525"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonGroup",
  props: {
    selections: {
      type: Array,
      default: () => [
        {
          name: "Noir",
          colorCode: "#6C6958",
        },
        {
          name: "Nuage",
          colorCode: "#EAE4D6",
        },
      ],
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  methods: {
    select(val) {
      this.selected = val;
      this.$emit("buttonGroupClicked", val);
    },
  },
};
</script>

<style scoped>
.edge-to-edge-mobile {
  @apply w-screen xl:w-full ml-[-32px] pl-[32px] xl:ml-0 xl:pl-0 overflow-scroll;
}

.smooth-scroll-n-hide {
  scroll-behavior: smooth;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.smooth-scroll-n-hide::-webkit-scrollbar {
  display: none;
}

.elevated-button {
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0px 4px 12px 0px #0000001a;
}
</style>

<template>
  <div class="overlay">
    <div class="modal">
      <PrimaryImageOnlyBaseCta
        image="icons/close_black.svg"
        type="tertiary"
        class="absolute right-6 md:right-12"
        @click="internalClose()"
      />
      <div class="min-h-[200px]">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { PrimaryImageOnlyBaseCta } from "@/components/buttons";

export default {
  name: "Modal",
  components: {
    PrimaryImageOnlyBaseCta,
  },
  methods: {
    internalClose() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped>
.modal {
  @apply relative bg-creme-200 rounded-16 md:rounded-32 min-h-[max-content] w-[90%] md:w-[640px] p-8 md:p-12 text-gray-900 my-24 mx-auto;
}

.overlay {
  @apply fixed top-0 left-0 right-0 bottom-0 z-50 bg-black bg-opacity-50 overflow-x-scroll;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"flex flex-row button-container-base",class:[
    (_vm.type + "-background"),
    ("button-container-base-" + _vm.fixedSize),
    ("button-container-" + _vm.fixedSize) ],attrs:{"type":_vm.eventName},on:{"click":_vm.onClickHandler}},[(_vm.startImage)?_c('div',{class:[
      ("button-img-wrapper-" + _vm.fixedSize),
      ("button-img-wrapper-start-" + _vm.fixedSize) ]},[(_vm.startImage)?_c('img',{attrs:{"src":require('@/assets/' + _vm.startImage)}}):_vm._e()]):_vm._e(),(_vm.text)?_c('div',{staticClass:"button-text",class:[(_vm.type + "-text-color"), ("text-size-" + _vm.fixedSize)]},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e(),(_vm.endImage)?_c('div',{class:[
      ("button-img-wrapper-" + _vm.fixedSize),
      ("button-img-wrapper-end-" + _vm.fixedSize) ]},[(_vm.endImage)?_c('img',{attrs:{"src":require('@/assets/' + _vm.endImage)}}):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
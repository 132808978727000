<template>
  <global-section type="bottom-only">
    <div class="max-container">
      <div class="main-grid xl:gap-y-24 relative xl:mt-[-60px]">
        <div class="col-start-1 col-span-1 xl:col-start-2">
          <div class="flex justify-center mt-[-60px] xl:mt-0">
            <hr />
          </div>
        </div>

        <div class="col-start-1 col-span-1 xl:col-start-2">
          <div class="number-wrapper">
            <div class="number-circle">
              <div class="number">01</div>
            </div>
          </div>
        </div>

        <div class="timeline-title">Preview period is open</div>
        <div class="timeline-content">
          <div class="mb-5">
            Take advantage of the preview period to explore the neighborhood,
            home options, and get pre-approved. You can also coordinate an
            in-person visit to the raw land.
          </div>

          <div>
            A local Home Specialist will guide you through every step, even past
            closing.
          </div>

          <PrimaryBaseCta
            @click="openContactPage"
            fixedSize="small"
            class="mt-5"
            text="Schedule a call"
            endImage="ico_circle_arrow_white.svg"
          />
        </div>

        <div class="col-start-1 col-span-1 xl:col-start-2">
          <div class="number-wrapper">
            <div class="number-circle">
              <div class="number">02</div>
            </div>
          </div>
        </div>

        <div class="timeline-title">
          <a id="pre-approval" name="pre-approval">Get pre-approved</a>
        </div>
        <div class="timeline-content">
          <div class="mb-5">
            Go through a quick pre-approval process with our local financing
            partner, Affinity Bank. This will streamline your closing later, and
            they can help you understand your financing options.
          </div>
          <div class="mb-5">
            Due to our partnership, they are uniquely able to understand our
            process and fast-track your application.
          </div>
          <div>
            For new construction, the industry standard is a
            construction-to-perm loan. That means you only pay for construction
            first; it'll convert to a normal mortgage once construction is
            complete.
          </div>

          <PrimaryBaseCta
            @click="faq(10)"
            fixedSize="small"
            type="link"
            class="my-6"
            text="What is a construction-to-perm loan?"
            startImage="icons/arrow_right_blue.svg"
          />
          <PrimaryBaseCta
            @click="faq(9)"
            fixedSize="small"
            type="link"
            text="What are typical terms with your lender?"
            startImage="icons/arrow_right_blue.svg"
          />
        </div>

        <div class="col-start-1 col-span-1 xl:col-start-2">
          <div class="number-wrapper">
            <div class="number-circle">
              <div class="number">03</div>
            </div>
          </div>
        </div>

        <div class="timeline-title">
          <a id="reservation" name="reservation">Reserve your home</a>
        </div>
        <div class="timeline-content">
          <div>
            When you're ready, tell your Home Specialist which model you're
            interested in and they'll send you a Purchase & Sale Agreement.
            They'll also lock it for 48 hours, so you can rest in peace that no
            one will buy it from under you.
          </div>

          <PrimaryBaseCta
            @click="hollysprings()"
            fixedSize="small"
            type="link"
            class="my-6"
            text="See homes available in our latest subdivision"
            startImage="icons/arrow_right_blue.svg"
          />
        </div>

        <div class="col-start-1 col-span-1 xl:col-start-2">
          <div class="number-wrapper">
            <div class="number-circle">
              <div class="number">04</div>
            </div>
          </div>
        </div>

        <div class="timeline-title">Sign Purchase & Sale Agreement</div>
        <div class="timeline-content">
          <div class="mb-6">
            As soon as you sign, our lending partner will order an appraisal to
            confirm your estimated home value. If all looks good, you'll close
            within days afterwards.
          </div>

          <div>
            During this time, you'll finalize your interior design choices like
            paint and flooring.
          </div>

          <PrimaryBaseCta
            @click="faq(12)"
            fixedSize="small"
            type="link"
            class="my-6"
            text="What is in a Purchase & Sale Agreement?"
            startImage="icons/arrow_right_blue.svg"
          />
        </div>

        <div class="col-start-1 col-span-1 xl:col-start-2">
          <div class="number-wrapper">
            <div class="number-circle">
              <div class="number">05</div>
            </div>
          </div>
        </div>

        <div class="timeline-title">Close on your home</div>
        <div class="timeline-content">
          <div class="mb-6">
            Our “digital-hybrid” closing means you can e-sign from anywhere. A
            mobile attorney will meet you at your home, or wherever is
            convenient.
          </div>

          <div>
            Don't worry about making mortgage payments yet! Your closing fees,
            HomeFounder fee, and construction costs will draw on your
            downpayment first. You'll only pay interest on any withdrawals above
            that until construction is complete.
          </div>

          <PrimaryBaseCta
            @click="faq(12)"
            fixedSize="small"
            type="link"
            class="my-6"
            text="What is hybrid closing?"
            startImage="icons/arrow_right_blue.svg"
          />
          <PrimaryBaseCta
            @click="faq(14)"
            fixedSize="small"
            type="link"
            class="mb-6"
            text="What do I pay at closing?"
            startImage="icons/arrow_right_blue.svg"
          />
          <PrimaryBaseCta
            @click="faq(4)"
            fixedSize="small"
            type="link"
            class="mb-6 text-left"
            text="Do I need to carry 2 mortgages (or rent and a mortgage) while this new home is under construction?"
            startImage="icons/arrow_right_blue.svg"
          />
        </div>

        <div class="col-start-1 col-span-1 xl:col-start-2">
          <div class="number-wrapper">
            <div class="number-circle">
              <div class="number">06</div>
            </div>
          </div>
        </div>

        <div class="timeline-title">Contruction begins</div>
        <div class="timeline-content">
          <div class="mb-6">
            Our vetted, award winning builders will start working on your future
            home.
          </div>

          <div>
            During construction you will be able to track the progress and get
            updates from your home specialist. If you're not satisfied with your
            build, reach out to us and we'll work with the builder to resolve
            any questions.
          </div>

          <PrimaryBaseCta
            @click="faq(13)"
            fixedSize="small"
            type="link"
            class="my-6"
            text="Can I visit during construction?"
            startImage="icons/arrow_right_blue.svg"
          />
        </div>

        <div class="col-start-1 col-span-1 xl:col-start-2">
          <div class="number-wrapper">
            <div class="number-circle">
              <div class="number">07</div>
            </div>
          </div>
        </div>

        <div class="timeline-title">Move in!</div>
        <div class="timeline-content">
          <div>Est. 6-12 months after closing</div>
        </div>
      </div>
    </div>
  </global-section>
</template>
<script>
import { PrimaryBaseCta, GlobalSection } from "@/components";
export default {
  name: "HiwTimeline",
  components: {
    PrimaryBaseCta,
    GlobalSection,
  },
  methods: {
    nextLocation() {
      this.$router.push({
        path: "/locations/hollysprings",
      });
    },
    openContactPage() {
      this.$router.push({ name: "Contact" });
    },
    faq(id) {
      this.$router.push({
        name: "Faq",
        params: { id },
      });
    },
    hollysprings() {
      this.$router.push({
        path: "/locations/hollysprings",
      });
    },
  },
};
</script>
<style scoped>
.timeline-title {
  @apply text-body-1 xl:text-h3 font-semibold col-start-2 col-span-full xl:col-span-4 xl:h-16 flex items-center;
}
.timeline-content {
  @apply text-body-2 xl:text-body-1 col-start-2 col-span-full xl:col-start-7 xl:col-span-5 xl:pt-4 mb-12 xl:mb-0;
}
.timeline-content button {
  @apply text-left;
}

.number-circle {
  @apply w-6 h-6 xl:w-16 xl:h-16 bg-yellow-500 rounded-100 flex justify-center items-center z-10 flex-shrink-0;
}
.number {
  @apply hidden xl:block text-h3 text-gray-900 font-semibold text-center;
}

.number-wrapper {
  @apply h-full w-full flex justify-center relative;
}
hr {
  height: calc(100% - 32px);
  @apply w-[1px] absolute bg-gray-900 opacity-20;
}

.internal-tooltip {
  @apply absolute xl:bottom-56 lg:bottom-48 md:bottom-48 sm:bottom-24 bottom-16;
  @apply xl:-left-12 lg:-left-16 lg:right-auto md:-left-8 md:right-auto sm:-left-8 sm:right-auto -left-16 right-auto;
}
.image-overlay {
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.15);
}
</style>

import { render, staticRenderFns } from "./ReserveBy.vue?vue&type=template&id=49b38a5d&scoped=true&"
import script from "./ReserveBy.vue?vue&type=script&lang=js&"
export * from "./ReserveBy.vue?vue&type=script&lang=js&"
import style0 from "./ReserveBy.vue?vue&type=style&index=0&id=49b38a5d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49b38a5d",
  null
  
)

export default component.exports
<template>
  <div class="max-container" id="scroll-homes">
    <div class="main-grid">
      <h2
        class="col-span-full xl:col-start-2 xl:col-span-10 text-h3 lg:text-h2 font-semibold text-center"
      >
        5 new construction homes
      </h2>

      <p
        class="col-span-full xl:col-start-2 xl:col-span-10 text-body-1 lg:text-h4 text-center font-semibold mb-16"
      >
        Move in: Dec 2022 (est.)
      </p>

      <div v-for="house in houseModels" :key="house.id" class="house-card">
        <div class="w-full cursor-pointer" @click="goToHouse(house.houseSlug)">
          <div
            class="rounded-t-16 h-[200px] sm:h-[280px] md:h-[320px] lg:h-[360px] overflow-hidden relative"
          >
            <img
              v-if="house.houseImages.length"
              :src="house.houseImages[0].src"
              :alt="house.houseImages[0].alt"
              class="h-full w-full object-cover"
            />
            <div v-if="house.availability === RESERVED" class="reserved-badge">
              Reserved
            </div>
            <div v-if="house.availability === SOLD" class="sold-badge">
              Sold
            </div>
          </div>

          <div class="p-8 w-full">
            <div class="flex flex-row justify-between relative w-full">
              <div class="text-body-2">
                <div class="text-body-1 xl:text-h3 font-semibold mb-2">
                  {{ house.name }} ({{ house.lot.name }})
                </div>
                <div>{{ house.address }}</div>
                <div class="mt-6">
                  <span class="pr-6 xl:pr-4">
                    <b>{{ house.lot.size }}</b> ac
                  </span>
                  <span class="pr-6 xl:pr-4">
                    <b>{{ house.squareFootage }}</b> sqft
                  </span>
                  <span class="pr-6 xl:pr-4">
                    <b>{{ house.bedrooms }}</b> bds
                  </span>
                  <span class="pr-6 xl:pr-4">
                    <b>{{ house.bathrooms }}</b> ba
                  </span>
                </div>
              </div>

              <img
                class="rounded-16 w-16 h-16 md:w-24 md:h-24 lg:w-32 lg:h-32"
                :src="house.lot.image"
                alt="House lot image"
              />
            </div>
            <div class="mt-12 flex flex-row justify-between">
              <div class="flex flex-row">
                <div v-if="house.availability != RESERVED" class="mr-6 xl:mr-8">
                  <div class="text-body-3 xl:text-body-2">Guaranteed price</div>
                  <span class="font-semibold text-body-2 xl:text-h4">{{
                    $currencyNoDecimal.format(house.costs.price)
                  }}</span>
                </div>
                <div>
                  <div class="text-body-3 xl:text-body-2">Home value est.*</div>
                  <div>
                    <span
                      class="text-body-2 xl:text-h4 font-semibold mr-2 mb-3"
                    >
                      {{ $currencyNoDecimal.format(house.costs.value) }}</span
                    >
                    <span
                      class="text-body-3 xl:text-body-2 bg-yellow-500 font-semibold py-1 px-2 rounded-8 h-[fit-content] whitespace-nowrap"
                    >
                      Save
                      {{
                        roundNumberToShorthandCurrency(
                          house.costs.value - house.costs.price
                        )
                      }}+
                    </span>
                  </div>
                </div>
              </div>
              <div
                v-if="house.availability === RESERVED"
                class="mr-6 xl:mr-8 self-center flex-grow text-right"
              >
                This home has been reserved
              </div>
              <PrimaryImageOnlyBaseCta
                image="icons/chevron_big_right.svg"
                type="secondary"
                class="hidden md:flex w-14 h-14"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="last-house-card">
        <h3 class="text-h4 xl:text-h3 font-semibold mb-8">
          Guaranteed pricing
        </h3>

        <p class="text-body-2 xl:text-body-1">
          Skip the bidding wars! We'll even cover any increases in the material
          or construction costs for you.
          <a href="/faq" class="font-semibold text-blue-500">Learn more</a>
        </p>

        <hr />

        <h4 class="text-h4 xl:text-h3 font-semibold">
          Vetted, award-winning builders
        </h4>

        <div class="mt-8">
          <div class="flex items-center mb-7">
            <img
              src="@/assets/icons/plus_circle_yellow.svg"
              alt="Yellow plus circle"
              class="mr-6"
            />

            <div>2+ year Warranty</div>
          </div>
          <div class="flex items-center mb-7">
            <img
              src="@/assets/icons/plus_circle_yellow.svg"
              alt="Yellow plus circle"
              class="mr-6"
            />

            <div>Award-winning designs</div>
          </div>
          <div class="flex items-center mb-7">
            <img
              src="@/assets/icons/plus_circle_yellow.svg"
              alt="Yellow plus circle"
              class="mr-6"
            />

            <div>High construction quality standards</div>
          </div>
          <div class="flex items-center">
            <img
              src="@/assets/icons/plus_circle_yellow.svg"
              alt="Yellow plus circle"
              class="mr-6"
            />

            <div>Strong partnership with HomeFounder</div>
          </div>
        </div>
      </div>

      <div class="col-span-full text-center text-body-2 mt-12">
        *Estimated value based on the market prices and community. Pending
        appraisal
      </div>
    </div>
  </div>
</template>

<script>
import { roundNumberToShorthandCurrency } from "@/helpers/currencyFormaterHelper.js";
import { PrimaryImageOnlyBaseCta } from "@/components/buttons";
import { RESERVED, SOLD } from "@/constants";

export default {
  components: {
    PrimaryImageOnlyBaseCta,
  },
  props: {
    houseModels: {
      type: Array,
      default: function () {
        return [];
      },
      required: true,
    },
  },
  data() {
    return {
      RESERVED,
      SOLD,
    };
  },
  computed: {
    houseButtons: function () {
      return this.houseModels.map((model) => ({
        id: model.id,
        available: model.available,
        name: model.name,
        rooms: `${model.bedrooms} bd / ${model.bathrooms} ba`,
      }));
    },
    selectedHouseOutsideImage: function () {
      const outsideImage = this.selectedHouse?.houseImages?.find(
        (img) => img.name === "Outside"
      );
      if (outsideImage) return outsideImage.src;
      else return "";
    },
  },
  methods: {
    formatNumber(num) {
      if (num != null || num != undefined) {
        return num.toLocaleString();
      }
      return 0;
    },
    goToHouse(house) {
      this.$router.push({ path: "/locations/hollysprings/" + house });
    },
    roundNumberToShorthandCurrency,
  },
};
</script>

<style scoped>
.house-card {
  @apply col-span-full lg:col-span-6 rounded-16 bg-creme-50 flex flex-col items-center w-full mx-auto mb-4 xl:mb-0 max-w-[640px];
  transition: background-color 0.2s ease-in-out;
}

.house-card img {
  transform-origin: 50% 50%;
  transition: transform 0.5s ease-in-out;
}

.house-card:hover {
  @apply bg-creme-200;
}

.house-card:hover img:first-child {
  transform: scale(1.1);
}

.last-house-card {
  @apply col-span-full lg:col-span-6 rounded-16 bg-creme-200 flex flex-col mb-4 xl:mb-0 max-w-[640px] px-8 py-12 mx-auto;
}

hr {
  @apply w-full my-12 h-[2px];
}

b {
  @apply text-body-2 xl:text-body-1;
}

.reserved-badge {
  @apply bg-gray-900 text-creme-50 absolute top-[30px] right-[-75px] z-10 font-semibold text-body-2 h-10 w-64 flex justify-center items-center rotate-45;
}
.sold-badge {
  @apply bg-yellow-500 absolute top-[30px] right-[-75px] z-10 font-semibold text-body-2 h-10 w-64 flex justify-center items-center rotate-45;
}
</style>

import Vue from "vue";

import { Hub, Auth } from "aws-amplify";

Hub.listen("auth", (data) => {
  switch (data.payload.event) {
    case "signIn":
      console.log("user signed in");
      setSignedIn(true);
      break;
    case "signUp":
      console.log("user signed up");
      break;
    case "signOut":
      console.log("user signed out");
      setSignedIn(false);
      break;
    case "signIn_failure":
      console.log("user sign in failed");
      setSignedIn(false);
      break;
    case "configured":
      console.log("the Auth module is configured");
  }
});

/* eslint-disable no-unused-vars */
Auth.currentAuthenticatedUser()
  .then((data) => {
    setSignedIn(true);
  })
  .catch((err) => {
    setSignedIn(false);
  });

const state = Vue.observable({
  signedIn: false,
});

export const isSignedIn = () => state.signedIn;
const setSignedIn = (v) => (state.signedIn = v);

<template>
  <div class="w-full">
    <div class="text-h4 font-semibold text-gray-900">Need Help?</div>
    <div class="mt-8 rounded-16 px-6 py-6 bg-creme-300">
      <div class="flex flex-row justify-between">
        <div class="flex flex-col space-y-2">
          <div class="text-gray-900 text-body-1 font-semibold">Andrew G</div>
          <div class="text-gray-900 text-body-2 font-regular">
            Your Home Specialist
          </div>
        </div>
        <img
          class="w-16 h-16"
          :src="require('@/assets/images/home_specialist_photo.png')"
          alt="Home Specialist"
        />
      </div>
      <div class="pt-6">
        <div class="text-body-3 text-gray-900 font-regular">
          &quot;Hi {{ firstName }}, I am your Home Specialist and will guide you
          through the wohle journey. Please don&apos;t hesitate to reach out if
          you have any questions.<br /><br />Looking forward to talking to you
          soon,<br />- Andrew&quot;
        </div>
      </div>
      <div class="pt-6 flex flex-row items-center justify-center">
        <PrimaryBaseCta
          class="xl:w-full"
          startImage="icons/phone_blue.png"
          type="secondary"
          fixedSize="small"
          text="Schedule call"
        />
      </div>
      <div class="pt-3 flex flex-row items-center justify-center">
        <a
          class="text-body-2 text-blue-500 font-semibold"
          href="mailto:admin@homefounder.com"
          >andrew@homefounder.com</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import { PrimaryBaseCta } from "@/components";
export default {
  name: "NeedHelp",
  components: {
    PrimaryBaseCta,
  },
  props: {
    firstName: {
      type: String,
      default: "Founder",
    },
  },
};
</script>
<style scoped></style>

<template>
  <div
    :id="saveHomeId"
    class="mt-12 pt-12 sm:pt-0 pb-48 sm:pb-0 sm:mt-32 sm:mb-36 text-black bg-creme-300 sm:bg-creme-200 edge-to-edge-mobile"
  >
    <h3 class="text-h3 font-semibold mb-3">Save your home</h3>

    <p class="mb-12 text-body-2 xl:text-h4">
      Create an account to save your home selections and start pre-approval and
      lot reservation process. Read about next steps.
    </p>

    <PrimaryBaseCta
      text="Create account to save your progress"
      endImage="ico_circle_arrow_white.svg"
      fixedSize="small"
    />
  </div>
</template>

<script>
import { PrimaryBaseCta } from "@/components/buttons";
import { BUILDER_COMPONENTS_IDS } from "@/constants";

export default {
  name: "SaveHome",
  components: { PrimaryBaseCta },
  data() {
    return {
      model: null,
      saveHomeId: BUILDER_COMPONENTS_IDS.SAVE_HOME,
    };
  },
};
</script>

<style scoped>
.edge-to-edge-mobile {
  @apply w-screen sm:w-full ml-[-32px] pl-[32px] sm:ml-0 sm:pl-0 overflow-scroll;
}
</style>

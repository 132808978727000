<template>
  <div class="w-full">
    <div class="text-h4 font-semibold text-gray-900">Upcoming release</div>
    <div class="mt-8 px-8 py-8 rounded-16 border border-gray-900">
      <div class="flex flex-col xl:flex-row xl:space-x-16">
        <div class="flex flex-col">
          <div class="text-gray-900 font-semibold text-body-2">
            Douglasville, GA
          </div>
          <div class="text-gray-900 text-h3 font-semibold">Holly Springs</div>
          <div class="pt-3 text-gray-900 font-extrabold text-body-3">
            Open for reservation in April 2022
          </div>
          <div class="pt-6 text-gray-900 font-regular text-body-2">
            Holly Springs is a well-established neighborhood in Douglasville,
            GA. With easy access to both downtown and the state park, it&apos;s
            the perfect balance of space and convenience.
          </div>
          <div class="pt-6">
            <img
              class="rounded-16"
              :src="require('@/assets/images/np_hero.jpg')"
              alt="Holly Springs"
            />
          </div>
        </div>
        <div class="w-full flex flex-col">
          <PrimaryBaseCta
            text="Learn More"
            type="secondary"
            fixedSize="small"
            endImage="ico_circle_arrow.svg"
            class="order-3 xl:order-1 mt-8 xl:mt-0"
            @click="navigateItem('/how-it-works')"
          />
          <div class="flex flex-row xl:flex-col order-1">
            <div class="pt-8 space-y-1 w-1/2 xl:w-full">
              <div class="text-h4 text-gray-900 font-semibold">6</div>
              <div class="text-body-2 text-gray-900 font-regular">
                Available Homesites
              </div>
            </div>
            <div class="pt-8 space-y-1 w-1/2 xl:w-full">
              <div class="text-h4 text-gray-900 font-semibold">3</div>
              <div class="text-body-2 text-gray-900 font-regular">
                Home model options
              </div>
            </div>
          </div>
          <div class="flex flex-row xl:flex-col order-1">
            <div class="pt-8 space-y-1 w-1/2 xl:w-full">
              <div class="text-h4 text-gray-900 font-semibold">$500,000+</div>
              <div class="text-body-2 text-gray-900 font-regular">
                Appraised Home Values
              </div>
              <PrimaryBaseCta
                text="Save $166k+. Here's how."
                fixedSize="small"
                type="link"
                @click="navigateItem('/home#calculator')"
              />
            </div>
            <div class="pt-8 space-y-1 w-1/2 xl:w-full">
              <div class="text-h4 text-gray-900 font-semibold">Dec '22</div>
              <div class="text-body-2 text-gray-900 font-regular">
                Move-In Estimate
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PrimaryBaseCta } from "@/components";
export default {
  name: "UpcomingRelease",
  components: {
    PrimaryBaseCta,
  },
  methods: {
    navigateItem(slug) {
      this.$router.push({
        path: `${slug}`,
      });
    },
  },
};
</script>
<style scoped></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full flex flex-col"},[_c('div',{staticClass:"text-h4 font-semibold text-gray-900 pb-8"},[_vm._v("Next Step")]),(_vm.didPreApprovalFail)?_c('div',[_c('PreApprovalError')],1):_c('div',{staticClass:"flex flex-col"},[_c('NextStepSlot',{class:[
        _vm.isPreRelease ? 'order-2' : '',
        !_vm.isPreRelease && !_vm.isPreApproved && !_vm.isHomeSelectedAgg
          ? 'order-1'
          : '',
        !_vm.isPreRelease && _vm.isPreApproved ? 'order-2' : '',
        !_vm.isPreRelease && _vm.isPreApproved && !_vm.isHomeSelectedAgg ? 'order-1' : '' ],scopedSlots:_vm._u([{key:"timelineCircle",fn:function(){return [_c('div',{staticClass:"timeline-circle",class:[_vm.isHomeSelectedAgg ? 'bg-yellow-500' : '']},[(!_vm.isHomeSelectedAgg && !_vm.isPreRelease)?_c('div',{staticClass:"timeline-selected-circle"}):_vm._e(),(_vm.isHomeSelectedAgg && !_vm.isPreRelease)?_c('div',{staticClass:"timeline-completed"}):_vm._e()])]},proxy:true},{key:"timelineContent",fn:function(){return [(!_vm.isPreRelease && !_vm.isHomeSelectedAgg)?_c('div',[_c('div',{staticClass:"timeline-title"},[_vm._v(" Next: Explore and pick your home and lot ")]),_c('div',{staticClass:"mt-4 text-gray-900 text-body-3 font-regular xl:w-full w-1/2"},[_vm._v(" Explore available lots, home models and see your potential savings. Start customizing your future home. ")]),_c('PrimaryBaseCta',{staticClass:"mt-4 xl:w-full w-[fit-content]",attrs:{"text":"Explore Homes","fixedSize":"small","endImage":"ico_circle_arrow_white.svg"}})],1):(!_vm.isPreRelease && _vm.isHomeSelectedAgg)?_c('div',{staticClass:"timeline-title-inactive"},[_vm._v(" Pick your home and lot ")]):_c('div',[_c('div',[_vm._v("Pick your home and lot")]),_c('div',{staticClass:"font-semibold text-gray-900 text-body-3"},[_vm._v(" Upcoming: "+_vm._s(_vm.upcomingReleaseDate)+" ")])])]},proxy:true}])}),_c('NextStepSlot',{class:[
        _vm.isPreRelease ? 'order-1' : '',
        !_vm.isPreRelease && !_vm.isPreApproved && !_vm.isHomeSelectedAgg
          ? 'order-2'
          : '',
        _vm.isPreRelease && _vm.isPreApproved ? 'order-1' : '',
        !_vm.isPreRelease && _vm.isPreApproved && _vm.isHomeSelectedAgg ? 'order-2' : '' ],scopedSlots:_vm._u([{key:"timelineCircle",fn:function(){return [_c('div',{staticClass:"timeline-circle",class:[_vm.isPreApproved ? 'bg-yellow-500' : '']},[(
              (!_vm.isPreRelease && !_vm.isPreApproved) ||
              (!_vm.isPreApproved && _vm.isHomeSelected) ||
              (_vm.isPreRelease && !_vm.isPreApproved)
            )?_c('div',{staticClass:"timeline-selected-circle"}):_vm._e(),(_vm.isPreApproved)?_c('div',{staticClass:"timeline-completed"}):_vm._e()])]},proxy:true},{key:"timelineContent",fn:function(){return [(!_vm.isPreApproved)?_c('div',[(!_vm.isPreRelease && !_vm.isPreApproved)?_c('div',{staticClass:"timeline-title"},[_vm._v(" Get pre-approved ")]):_vm._e(),(_vm.isPreRelease)?_c('div',{staticClass:"timeline-title"},[_vm._v(" Next: Get pre-approved ")]):_vm._e(),(
              _vm.isPreRelease &&
              !_vm.isPreApproved &&
              (_vm.isFinancingSoftQualStarted || _vm.isFinancingSoftQualPassed)
            )?_c('div',[(_vm.isFinancingSoftQualStarted)?_c('div',{staticClass:"mt-4 text-gray-900 text-body-3 font-regular xl:w-full w-1/2"},[_vm._v(" Prepare and get ahead of the line before the release. Go through a quick pre-approval process with our financial partner. ")]):_c('div',{staticClass:"mt-4 text-gray-900 text-body-3 font-regular xl:w-full w-1/2"},[_vm._v(" Note that it might take a few hours for your approval status to change after you submit your documents with financial partner ")]),_c('PrimaryBaseCta',{staticClass:"mt-4 xl:w-full w-[fit-content]",attrs:{"text":"Pre-approval: In progress","startImage":"icons/clock_white.png","fixedSize":"small"}}),(_vm.isFinancingSoftQualStarted)?_c('div',{staticClass:"mt-3 bg-creme-300 px-4 py-3 rounded-8 w-[fit-content] text-body-3 text-gray-900"},[_c('div',{staticClass:"font-bold"},[_vm._v("TODO - Get time of meeting")]),_c('div',{staticClass:"pt-1"},[_vm._v("Upcoming time")])]):_vm._e()],1):_vm._e(),(
              !_vm.isPreApproved &&
              !_vm.isFinancingSoftQualStarted &&
              !_vm.isFinancingSoftQualPassed
            )?_c('div',[_c('div',{staticClass:"mt-3 bg-creme-300 px-2 py-1 rounded-8 w-[fit-content] text-body-3 text-gray-900 font-bold"},[_vm._v(" "+_vm._s(_vm.startPreApprovalDate)+" ")]),_c('div',{staticClass:"mt-4 text-gray-900 text-body-3 font-regular xl:w-full w-1/2"},[_vm._v(" Prepare and get ahead of the line before the release. Go through a quick pre-approval process with our financial partner. ")]),_c('PrimaryBaseCta',{staticClass:"mt-4 xl:w-full w-[fit-content]",attrs:{"text":"Get pre-approved","fixedSize":"small","endImage":!_vm.isPreRelease && !_vm.isHomeSelectedAgg
                  ? 'ico_circle_arrow.svg'
                  : 'ico_circle_arrow_white.svg',"type":!_vm.isPreRelease && !_vm.isHomeSelectedAgg ? 'secondary' : 'primary'}})],1):_vm._e()]):_c('div',[_c('div',{staticClass:"timeline-title-inactive"},[_vm._v("Get pre-approved")]),(_vm.isPreRelease)?_c('div',{staticClass:"text-body-3 text-gray-900 font-regular pt-3"},[_vm._v(" Congratulations, you are pre-approved! Once homes and lots become avilable in "+_vm._s(_vm.lotsAvailableDate)+" you will be able to immediately reserve on the day of release, getting ahead of the line. ")]):_vm._e()])]},proxy:true}])}),_c('NextStepSlot',{staticClass:"order-5",scopedSlots:_vm._u([{key:"timelineCircle",fn:function(){return [_c('div',{staticClass:"timeline-circle",class:[
            !_vm.isPreRelease && _vm.isPreApproved && _vm.isHomeReservedAgg
              ? 'bg-yellow-500'
              : '' ]},[(
              !_vm.isPreRelease &&
              _vm.isPreApproved &&
              _vm.isHomeSelected &&
              !_vm.isHomeReservedAgg
            )?_c('div',{staticClass:"timeline-selected-circle"}):_vm._e(),(!_vm.isPreRelease && _vm.isPreApproved && _vm.isHomeReservedAgg)?_c('div',{staticClass:"timeline-completed"}):_vm._e()])]},proxy:true},{key:"timelineContent",fn:function(){return [(
            !_vm.isPreRelease &&
            _vm.isPreApproved &&
            _vm.isHomeSelectedAgg &&
            !_vm.isHomeReservedAgg
          )?_c('div',[_c('div',{staticClass:"timeline-title"},[_vm._v("Finalize picks and reserve")]),_c('div',{staticClass:"mt-3 bg-creme-300 px-2 py-1 rounded-8 w-[fit-content] text-body-3 text-gray-900 font-bold"},[_vm._v(" Reserve by "+_vm._s(_vm.reserveDate)+" ")]),_c('div',{staticClass:"mt-4 text-gray-900 text-body-3 font-regular xl:w-full w-1/2"},[_vm._v(" Review your home and lot picks and reserve. You will have 48 hours to review and sign Purchase and Sale agreement ")]),_c('PrimaryBaseCta',{staticClass:"mt-4 xl:w-full w-[fit-content]",attrs:{"text":"Review and reserve","fixedSize":"small","endImage":"ico_circle_arrow_white.svg"}})],1):(
            !_vm.isPreRelease &&
            _vm.isPreApproved &&
            _vm.isHomeSelectedAgg &&
            _vm.isHomeReservedAgg
          )?_c('div',[_c('div',{staticClass:"timeline-title-inactive"},[_vm._v(" Finalize picks and reserve ")])]):_c('div',[_c('div',{staticClass:"timeline-title-inactive"},[_vm._v("Review and Reserve")])])]},proxy:true}])}),_c('NextStepSlot',{staticClass:"order-5",scopedSlots:_vm._u([{key:"timelineCircle",fn:function(){return [_c('div',{staticClass:"timeline-circle",class:[
            !_vm.isPreRelease &&
            _vm.isPreApproved &&
            _vm.isHomeReservedAgg &&
            _vm.isReadyForPandSAgg &&
            _vm.isHomeUnderContractAgg
              ? 'bg-yellow-500'
              : '' ]},[(
              !_vm.isPreRelease &&
              _vm.isPreApproved &&
              _vm.isHomeReservedAgg &&
              _vm.isReadyForPandSAgg &&
              !_vm.isHomeUnderContractAgg
            )?_c('div',{staticClass:"timeline-selected-circle"}):_vm._e(),(
              !_vm.isPreRelease &&
              _vm.isPreApproved &&
              _vm.isHomeReservedAgg &&
              _vm.isReadyForPandSAgg &&
              _vm.isHomeUnderContractAgg
            )?_c('div',{staticClass:"timeline-completed"}):_vm._e()])]},proxy:true},{key:"timelineContent",fn:function(){return [(
            !_vm.isPreRelease &&
            _vm.isPreApproved &&
            _vm.isHomeReservedAgg &&
            _vm.isReadyForPandSAgg &&
            !_vm.isHomeUnderContractAgg
          )?_c('div',[_c('div',{staticClass:"timeline-title"},[_vm._v("Sign Purchase & Sale agreement")]),_c('div',{staticClass:"mt-3 bg-creme-300 px-2 py-1 rounded-8 w-[fit-content] text-body-3 text-gray-900 font-bold"},[_vm._v(" Sign by TODO_CALCULATE_DATE_BASED_ON_DEAL ")]),_c('div',{staticClass:"mt-4 text-gray-900 text-body-3 font-regular xl:w-full w-1/2"},[_vm._v(" You have 48 hours to review and sign Purchase and Sale agreement. Please upload relevant documents by TODO_CALCULATE_DATE_BASED_ON_DEAL to keep your reservation. ")]),_c('PrimaryBaseCta',{staticClass:"mt-4 xl:w-full w-[fit-content]",attrs:{"text":"Sign P&S agreement","fixedSize":"small","endImage":"ico_circle_arrow_white.svg"}})],1):_c('div',[_c('div',{staticClass:"timeline-title-inactive"},[_vm._v(" Sign Purchase & Sale agreement ")])])]},proxy:true}])}),_c('NextStepSlot',{staticClass:"order-5",scopedSlots:_vm._u([{key:"timelineCircle",fn:function(){return [_c('div',{staticClass:"timeline-circle",class:[
            !_vm.isPreRelease &&
            _vm.isPreApproved &&
            _vm.isHomeReservedAgg &&
            _vm.isReadyForPandSAgg &&
            _vm.isHomeUnderContractAgg &&
            _vm.isFinancingFinalApprovalPassed
              ? 'bg-yellow-500'
              : '' ]},[(
              !_vm.isPreRelease &&
              _vm.isPreApproved &&
              _vm.isHomeReservedAgg &&
              _vm.isReadyForPandSAgg &&
              _vm.isHomeUnderContractAgg &&
              !_vm.isFinancingFinalApprovalPassed
            )?_c('div',{staticClass:"timeline-selected-circle"}):_vm._e(),(
              !_vm.isPreRelease &&
              _vm.isPreApproved &&
              _vm.isHomeReservedAgg &&
              _vm.isReadyForPandSAgg &&
              _vm.isHomeUnderContractAgg &&
              _vm.isFinancingFinalApprovalPassed
            )?_c('div',{staticClass:"timeline-completed"}):_vm._e()])]},proxy:true},{key:"timelineContent",fn:function(){return [(
            !_vm.isPreRelease &&
            _vm.isPreApproved &&
            _vm.isHomeReservedAgg &&
            _vm.isReadyForPandSAgg &&
            _vm.isHomeUnderContractAgg &&
            !_vm.isFinancingFinalApprovalPassed
          )?_c('div',[_c('div',{staticClass:"timeline-title"},[_vm._v("Secure financing")]),_c('div',{staticClass:"mt-4 text-gray-900 text-body-3 font-regular xl:w-full w-1/2"},[_vm._v(" Work with loan officer and home specialist to finalize your loan. ")]),_c('PrimaryBaseCta',{staticClass:"mt-4 xl:w-full w-[fit-content]",attrs:{"text":"Loan Approval","fixedSize":"small","endImage":"ico_circle_arrow_white.svg"}})],1):_c('div',[_c('div',{staticClass:"timeline-title-inactive"},[_vm._v("Secure financing")])])]},proxy:true}])}),_c('NextStepSlot',{staticClass:"order-5",scopedSlots:_vm._u([{key:"timelineCircle",fn:function(){return [_c('div',{staticClass:"timeline-circle",class:[
            !_vm.isPreRelease &&
            _vm.isPreApproved &&
            _vm.isHomeReservedAgg &&
            _vm.isReadyForPandSAgg &&
            _vm.isHomeUnderContractAgg &&
            _vm.isFinancingFinalApprovalPassed &&
            _vm.isHomeClosed
              ? 'bg-yellow-500'
              : '' ]},[(
              !_vm.isPreRelease &&
              _vm.isPreApproved &&
              _vm.isHomeReservedAgg &&
              _vm.isReadyForPandSAgg &&
              _vm.isHomeUnderContractAgg &&
              _vm.isFinancingFinalApprovalPassed &&
              !_vm.isHomeClosed
            )?_c('div',{staticClass:"timeline-selected-circle"}):_vm._e(),(
              !_vm.isPreRelease &&
              _vm.isPreApproved &&
              _vm.isHomeReservedAgg &&
              _vm.isReadyForPandSAgg &&
              _vm.isHomeUnderContractAgg &&
              _vm.isFinancingFinalApprovalPassed &&
              _vm.isHomeClosed
            )?_c('div',{staticClass:"timeline-completed"}):_vm._e()])]},proxy:true},{key:"timelineContent",fn:function(){return [_c('div',{class:[
            !_vm.isPreRelease &&
            _vm.isPreApproved &&
            _vm.isHomeReservedAgg &&
            _vm.isReadyForPandSAgg &&
            _vm.isHomeUnderContractAgg &&
            _vm.isFinancingFinalApprovalPassed &&
            !_vm.isHomeClosed
              ? 'timeline-title'
              : 'timeline-title-inactive' ]},[_vm._v(" Close on your home ")]),_c('div',{staticClass:"text-body-3 text-gray-900"},[_vm._v("Dealine: May 28")])]},proxy:true}])}),_c('NextStepSlot',{staticClass:"order-5",attrs:{"isEnd":true},scopedSlots:_vm._u([{key:"timelineCircle",fn:function(){return [_c('div',{staticClass:"timeline-circle"},[(
              !_vm.isPreRelease &&
              _vm.isPreApproved &&
              _vm.isHomeReservedAgg &&
              _vm.isReadyForPandSAgg &&
              _vm.isHomeUnderContractAgg &&
              _vm.isFinancingFinalApprovalPassed &&
              _vm.isHomeClosed
            )?_c('div',{staticClass:"timeline-selected-circle"}):_vm._e()])]},proxy:true},{key:"timelineContent",fn:function(){return [_c('div',{class:[
            !_vm.isPreRelease &&
            _vm.isPreApproved &&
            _vm.isHomeReservedAgg &&
            _vm.isReadyForPandSAgg &&
            _vm.isHomeUnderContractAgg &&
            _vm.isFinancingFinalApprovalPassed &&
            _vm.isHomeClosed
              ? 'timeline-title'
              : 'timeline-title-inactive' ]},[_vm._v(" Track construction and move in ")]),_c('div',{staticClass:"text-body-3 text-gray-900"},[_vm._v("Est: December 2022")])]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <button
    type="button"
    class="text-hfbase font-semibold text-black bg-yellow px-6 py-3 border border-black leading-normal rounded-xl flex align-center"
    @click="click"
  >
    {{ text }}
    <svg
      class="ml-2"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 1C19.0751 1 24 5.92487 24 12C24 18.0751 19.0751 23 13 23C6.92487 23 2 18.0751 2 12C2.00667 5.92763 6.92763 1.00667 13 1ZM13 20.8C17.8341 20.7988 21.7621 16.8984 21.7975 12.0645C21.833 7.23059 17.9626 3.27309 13.129 3.20098C8.29551 3.12887 4.30876 6.96916 4.2 11.802L4.2 9.8407L4.2 12C4.20545 16.8578 8.14215 20.7945 13 20.8Z"
        fill="#2C2525"
      />
      <circle cx="13" cy="12" r="5" fill="#2C2525" />
    </svg>
  </button>
</template>

<script>
export default {
  name: "SelectedButton",
  props: {
    text: {
      type: String,
      default: "Selected",
    },
    eventName: {
      type: String,
      default: "selected",
    },
  },
  methods: {
    click() {
      this.$emit(`${this.eventName}_selected`);
    },
  },
};
</script>

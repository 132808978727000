<template>
  <div class="max-container">
    <div class="main-grid">
      <div
        class="col-span-full text-center lg:text-left lg:col-span-4 lg:col-start-2 mb-12"
      >
        <h2 class="text-h3 lg:text-h2 font-semibold mb-6 lg:mb-8">
          Important Dates
        </h2>

        <p class="text-body-2 lg:text-body-1 mb-6 mx-auto lg:mx-0">
          Don't miss out on your ticket to cruise past everyone else in this
          housing market craze.
        </p>

        <router-link
          to="/how-it-works"
          class="text-blue-500 font-semibold text-body-2 lg:text-h4"
        >
          Learn how the process works ->
        </router-link>
      </div>

      <div
        class="col-span-1 col-start-1 lg:col-span-2 lg:col-start-6 flex justify-center relative"
      >
        <span class="yellow-circle" />
        <hr class="vertical-hr" />
      </div>

      <div class="col-span-5 lg:col-span-4 lg:col-start-8">
        <div class="timeline-item">
          <span>Now</span>
          <h4>Preview period is open</h4>

          <p>
            Explore the neighborhood, home options, and get pre-approved. A Home
            Specialist will guide you through every step.
          </p>

          <PrimaryBaseCta
            text="Schedule a call"
            fixedSize="small"
            endImage="ico_circle_arrow_white.svg"
            @click="toggleModal"
          />
        </div>
      </div>

      <div
        class="col-span-1 col-start-1 lg:col-span-2 lg:col-start-6 flex justify-center relative"
      >
        <span class="yellow-circle" />
        <hr class="vertical-hr" />
      </div>

      <div class="col-span-5 lg:col-span-4 lg:col-start-8">
        <div class="timeline-item">
          <span>May 1 - May 15</span>
          <h4>Reservation window</h4>

          <p>
            Opportunity to purchase is open for 2 weeks. Pre-approval with our
            lending partner is required to reserve a home. May 15th is the last
            day to sign a Purchase & Sale Agreement
          </p>
        </div>
      </div>

      <div
        class="col-span-1 col-start-1 lg:col-span-2 lg:col-start-6 flex justify-center relative"
      >
        <span class="yellow-circle" />
        <hr class="vertical-hr" />
      </div>

      <div class="col-span-5 lg:col-span-4 lg:col-start-8">
        <div class="timeline-item">
          <span>June 15</span>
          <h4>Last day to close</h4>
        </div>
      </div>

      <div
        class="col-span-1 col-start-1 lg:col-span-2 lg:col-start-6 flex justify-center relative"
      >
        <span class="yellow-circle" />
      </div>

      <div class="col-span-5 lg:col-span-4 lg:col-start-8">
        <div class="timeline-item">
          <span>Dec 2022</span>
          <h4>Move in!</h4>
        </div>
      </div>
    </div>

    <LocationInterestModal
      v-if="modalOpened"
      :preselectedHomeModel="name"
      @closeLocationInterestModal="toggleModal"
    />
  </div>
</template>

<script>
import { PrimaryBaseCta } from "@/components/buttons";
import { AVAILABLE } from "@/constants";

export default {
  name: "ImportantDates",
  components: {
    PrimaryBaseCta,
    LocationInterestModal: () => import("../modals/LocationInterestModal.vue"),
  },
  props: {
    houseName: {
      type: String,
    },
    availability: {
      type: String,
    },
  },
  data() {
    return {
      modalOpened: false,
    };
  },
  methods: {
    toggleModal() {
      this.modalOpened = !this.modalOpened;
    },
    name() {
      return this.availability === AVAILABLE ? this.houseName : "";
    },
  },
};
</script>

<style scoped>
.timeline-item {
  @apply text-body-1 lg:text-h4 mb-16;
}
.timeline-item h4 {
  @apply font-semibold mt-2 mb-4;
}
.timeline-item p {
  @apply text-body-2 lg:text-body-1 mb-6;
}

.vertical-hr {
  /* To overcome the grid gap, we use +2rem */
  height: calc(100% + 32px);
  @apply w-[2px] bg-gray-900 opacity-10 absolute;
}

.yellow-circle {
  @apply bg-yellow-500 min-w-[36px] w-9 h-9 rounded-100 z-10;
}
</style>

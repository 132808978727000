<template>
  <div class="text-black">
    <hr class="mt-8 border bg-black opacity-20" />

    <div class="flex flex-row justify-between items-center">
      <h3 class="text-h4 xl:font-semibold my-8 xl:max-w-[170px]">
        {{ addon.name }}
      </h3>
      <div class="flex flex-col text-body-3 text-right">
        <span class="text-body-1 font-semibold xl:flex xl:flex-row">
          +{{ $currencyNoDecimal.format(addon.value) }}
          <span class="hidden xl:block ml-1"> to home value </span>
        </span>
        <span class="xl:hidden">to home value</span>
        <span>
          +{{ $currencyNoDecimal.format(addon.price) }} to home price
        </span>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-x-8">
      <div
        class="col-span-full xl:col-span-1 xl:order-2 text-body-2 my-8 xl:mt-0 xl:mb-8 max-w-[90%] xl:w-full flex flex-col justify-between items-end"
      >
        <p class="block">
          <span class="limit-overflow-text-mobile">
            {{ addon.description }}
          </span>
          <span class="md:hidden font-semibold">Read more</span>
        </p>

        <PrimaryBaseCta
          :text="`Add ${addon.name}`"
          type="tertiary"
          endImage="icons/yellow_dot_with_plus.svg"
          fixedSize="small"
          class="mt-8"
          :class="[addonClicked ? 'hidden' : 'hidden xl:flex']"
          @click="onAddonClick(addon)"
        />

        <button
          class="added-button"
          :class="[addonClicked ? 'hidden xl:flex' : 'hidden']"
          @click="onAddonClick(addon)"
        >
          Added
          <img
            src="@/assets/icons/checkmark_full.svg"
            class="w-full ml-3"
            alt="checkmark icon"
          />
        </button>
      </div>

      <Carousel
        v-if="addon.images"
        :id="addon.alt"
        :images="addon.images.items"
        :paginationDotsOnly="true"
        :fixedHeight="200"
        class="xl:mb-8 col-span-full xl:col-span-1"
      />

      <PrimaryBaseCta
        :text="`Add ${addon.name}`"
        type="tertiary"
        endImage="icons/yellow_dot_with_plus.svg"
        class="w-full col-span-full mt-8"
        :class="[addonClicked ? 'hidden' : 'flex xl:hidden']"
        @click="onAddonClick(addon)"
      />

      <button
        class="added-button col-span-full mt-8"
        :class="[addonClicked ? 'flex xl:hidden' : 'hidden']"
        @click="onAddonClick(addon)"
      >
        Added
        <img
          src="@/assets/icons/checkmark_full.svg"
          class="ml-3"
          alt="checkmark icon"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { Carousel } from "@/components/carousels";
import { PrimaryBaseCta } from "@/components/buttons";
import { BUILDER_EVENTS } from "@/constants";

export default {
  name: "Addon",
  components: {
    Carousel,
    PrimaryBaseCta,
  },
  props: {
    addon: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      addonClicked: false,
    };
  },
  methods: {
    onAddonClick(val) {
      this.addonClicked = !this.addonClicked;
      this.$root.$emit(BUILDER_EVENTS.ADDON_SELECTION, val);
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .limit-overflow-text-mobile {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.force-hidden {
  display: none !important;
}

.added-button {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  @apply items-center justify-center cursor-pointer bg-yellow-500 border border-gray-900  rounded-16 px-5 py-3 mt-8 font-semibold text-body-2;
}
</style>

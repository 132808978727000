<template>
  <div
    class="max-container text-body-2 xl:text-body-1 px-8 md:px-16 lg:px-16 xl:px-24 py-4 md:py-8 lg:py-8 xl:py-12"
  >
    <div class="text-h4 xl:text-h3 font-semibold mt-8">Privacy policy</div>

    <p class="c36" id="h.30j0zll">
      <span class="c0"
        >Effective Date / Date of Last Revision: &nbsp;May 24, 2022</span
      >
    </p>

    <ol class="c8 lst-kix_list_23-0 start" start="1">
      <li class="c10 li-bullet-0">
        <span class="c4"><a href="#1">WHO WE ARE</a></span>
      </li>
      <li class="c10 li-bullet-0">
        <span class="c4"><a href="#2">HOW TO CONTACT US</a></span>
      </li>
      <li class="c10 li-bullet-0">
        <span class="c4"><a href="#3">WHY AND HOW WE COLLECT DATA</a></span>
      </li>
      <li class="c10 li-bullet-0">
        <span class="c4"
          ><a href="#4">PROTECTING YOUR PRIVACY GENERALLY</a></span
        >
      </li>
      <li class="c10 li-bullet-0">
        <span class="c4"><a href="#5">CHILDREN&rsquo;S PRIVACY</a></span>
      </li>
      <li class="c10 li-bullet-0">
        <span class="c4"><a href="#6">TYPES OF INFORMATION WE COLLECT</a></span>
      </li>
      <li class="c10 li-bullet-0">
        <span class="c4"><a href="#7">HOW WE USE DATA</a></span>
      </li>
      <li class="c10 li-bullet-0">
        <span class="c4"
          ><a href="#8">INFORMATION WE SHARE WITH THIRD PARTIES</a></span
        >
      </li>
      <li class="c10 li-bullet-0">
        <span class="c4"
          ><a href="#9">HOW TO ACCESS &amp; CONTROL YOUR PERSONAL DATA</a></span
        >
      </li>
      <li class="c10 li-bullet-0">
        <span class="c4"><a href="#10">SECURITY</a></span>
      </li>
      <li class="c10 li-bullet-0">
        <span class="c4"><a href="#11">LINKS TO THIRD-PARTY SITES</a></span>
      </li>
      <li class="c10 li-bullet-0">
        <span class="c4"><a href="#12">DATA RETENTION</a></span>
      </li>
      <li class="c10 li-bullet-0">
        <span class="c4"><a href="#13">ACCESSIBILITY</a></span>
      </li>
      <li class="c10 li-bullet-0">
        <span class="c4"><a href="#14">NOTICE TO NEVADA RESIDENTS</a></span>
      </li>
      <li class="c10 li-bullet-0">
        <span class="c4"><a href="#15">NOTICE TO CALIFORNIA RESIDENTS</a></span>
      </li>
    </ol>
    <ol class="c8 lst-kix_list_14-0 start" start="1" id="1">
      <li class="c25 li-bullet-0" id="h.1fob9te">
        <span class="c4">WHO WE ARE</span>
      </li>
    </ol>
    <p class="c55" id="h.3znysh7">
      <span class="c22">HomeFounder (&ldquo;</span
      ><span class="c11">HomeFounder</span
      ><span class="c22">,&rdquo; &ldquo;</span><span class="c11">we</span
      ><span class="c22">,&rdquo; &ldquo;</span><span class="c11">our</span
      ><span class="c22">,&rdquo; or &ldquo;</span><span class="c11">us</span
      ><span class="c22"
        >&rdquo;) is an innovative startup program designed to save home buyers
        money when purchasing new construction homes. &nbsp;HomeFounder brings
        together qualified homebuyers early in the land acquisition, development
        and construction phase and allows them to directly invest in their
        community without outside investors. &nbsp;HomeFounder has launched a
        publicly available website at </span
      ><span class="c22 c38"
        ><a class="c1" href="https://www.homefounder.com"
          >www.homefounder.com</a
        ></span
      ><span class="c22">&nbsp;(&ldquo;</span><span class="c11">Site</span
      ><span class="c22"
        >&rdquo;) where individuals may obtain more information about
        HomeFounder&rsquo;s projects and services (&ldquo;</span
      ><span class="c11">Services</span><span class="c0">&rdquo;). &nbsp;</span>
    </p>
    <p class="c30">
      <span class="c22"
        >Some of the data HomeFounder collects is considered &ldquo;personally
        identifiable information,&rdquo; &ldquo;personal information&rdquo; or
        &ldquo;personal data&rdquo; under applicable law (collectively referred
        to in this Privacy Policy as &ldquo;</span
      ><span class="c11">Personal Data</span
      ><span class="c0"
        >&rdquo;). &nbsp;Generally, Personal Data is information that can be
        reasonably connected to an identified or identifiable individual.
        &nbsp;It does not include deidentified or anonymized data.</span
      >
    </p>
    <p class="c13 c32"><span class="c0"></span></p>
    <p class="c13">
      <span class="c0"
        >The purpose of this Privacy Policy is to disclose to you how we
        collect, use and share information, and how we attempt to safeguard the
        data you provide to us. &nbsp;We are committed to securing data that we
        collect concerning you (&ldquo;</span
      ><span class="c4">you</span
      ><span class="c0"
        >&rdquo;) and complying with applicable data protection and privacy
        laws</span
      ><span class="c22 c56">. &nbsp;</span>
    </p>
    <p class="c30 c32"><span class="c0"></span></p>
    <ol class="c8 lst-kix_list_14-0" start="2" id="2">
      <li class="c25 li-bullet-0">
        <h1 id="h.2et92p0" style="display: inline">
          <span class="c4">HOW TO CONTACT US</span>
        </h1>
      </li>
    </ol>
    <h2 class="c43">
      <span class="c0"
        >If you have any questions regarding data protection or your rights,
        please contact us:</span
      >
    </h2>
    <p class="c27 c28" id="h.tyjcwt">
      <span class="c22 c29">HomeFounder</span><span class="c22"><br /></span
      ><span class="c22 c29">by Gemini Studio, Inc.</span
      ><span class="c22"><br /></span
      ><span class="c0 c29"
        >6 Liberty Square, PO Box 266, Boston, MA 02109</span
      >
    </p>
    <p class="c27 c28" id="h.3dy6vkm">
      <span class="c38 c22"
        ><a class="c1" href="mailto:privacy@homefounder.com"
          >team@homefounder.com</a
        ></span
      ><span class="c0">&nbsp;</span>
    </p>
    <p class="c26 c32"><span class="c4"></span></p>
    <ol class="c8 lst-kix_list_14-0" start="3" id="3">
      <li class="c10 li-bullet-0">
        <span class="c4">WHY AND HOW WE COLLECT DATA</span>
      </li>
    </ol>
    <p class="c46">
      <span class="c0"
        >We collect, process and use Personal Data to allow you to utilize the
        functionality and features we provide on our Site, to provide you with
        information about our Services, to provide our Services and to
        communicate with you. The legal basis for our collection of Personal
        Data is with your consent, for our legitimate business purposes and/or
        to comply with our legal and contractual obligations. &nbsp;We obtain
        Personal Data directly from you, through cookies and other technologies,
        and from third parties as described in this Policy and any other privacy
        notices we may provide to you when you interact with us.</span
      >
    </p>
    <ol class="c8 lst-kix_list_16-0 start" start="1">
      <li class="c28 c44 li-bullet-0">
        <span class="c4">Information and content you provide.</span
        ><span class="c0"
          >&nbsp; We collect the content, communications and other information
          you provide when you use our Site and Services, including when you
          sign up for notifications about upcoming releases, create an account,
          create or share content, and message or otherwise communicate with
          others. &nbsp; &nbsp;</span
        >
      </li>
    </ol>
    <p class="c27 c17"><span class="c0"></span></p>
    <ol class="c8 lst-kix_list_16-0" start="2">
      <li class="c16 li-bullet-0" id="h.1t3h5sf">
        <span class="c4">Usage. &nbsp;</span
        ><span class="c0"
          >We collect data on how you use our Site and Services, such as the
          content you view or engage with, the features you use, the time,
          frequency and duration of activities.
        </span>
      </li>
    </ol>
    <p class="c27 c17"><span class="c0"></span></p>
    <ol class="c8 lst-kix_list_16-0" start="3">
      <li class="c16 li-bullet-0">
        <span class="c4"
          >Information from our service providers and partners.</span
        ><span class="c0"
          >&nbsp; Sometimes our service providers share information with us.
          &nbsp;</span
        >
      </li>
    </ol>
    <p class="c27 c17"><span class="c0"></span></p>
    <ol class="c8 lst-kix_list_16-0" start="4">
      <li class="c16 li-bullet-0">
        <span class="c4">Information from other Site users</span
        ><span class="c0"
          >. We collect content, communications and information that other users
          of the Site provide about you or tag you in.</span
        >
      </li>
    </ol>
    <p class="c27 c17"><span class="c0"></span></p>
    <ol class="c8 lst-kix_list_16-0" start="5">
      <li class="c16 li-bullet-0">
        <span class="c4">Device information.</span
        ><span class="c0"
          >&nbsp; We collect information from and about the computers, phones,
          connected TVs and other web-connected devices that you use to access
          our Site.</span
        >
      </li>
    </ol>
    <p class="c17 c27"><span class="c0"></span></p>
    <ol class="c8 lst-kix_list_14-0" start="4" id="4">
      <li class="c10 li-bullet-0" id="h.4d34og8">
        <span class="c4">PROTECTING YOUR PRIVACY GENERALLY</span
        ><span class="c0">. &nbsp;</span>
      </li>
    </ol>
    <p class="c26 c32"><span class="c4"></span></p>
    <ol class="c8 lst-kix_list_19-1 start" start="1">
      <li class="c3 li-bullet-0" id="h.2s8eyo1">
        <span class="c4">Industry-Standard Protection</span
        ><span class="c0">.</span><span class="c4">&nbsp;</span
        ><span class="c0"
          >We utilize industry-standard security measures to safeguard the
          information we collect, maintain and use. These measures include
          technical and procedural steps to protect your data from misuse,
          unauthorized access or disclosure, loss, alteration or destruction.
          However, we do not and cannot guarantee the performance or adequacy of
          such security measures.</span
        >
      </li>
      <li class="c3 li-bullet-0" id="h.17dp8vu">
        <span class="c4">Limitation on Access.</span
        ><span class="c0"
          >&nbsp; Access to any Personal Data we collect and store is generally
          restricted to our employees and/or contractors who require it to
          perform a job or other contracted function. We require vendors and
          contractors we work with to use reasonable, industry standard
          protocols to maintain the confidentiality, security, and integrity of
          Personal Data.
        </span>
      </li>
    </ol>
    <ol class="c8 lst-kix_list_14-0" start="5" id="5">
      <li class="c10 li-bullet-0" id="h.3rdcrjn">
        <span class="c4">CHILDREN&rsquo;S PRIVACY. &nbsp;</span>
      </li>
    </ol>
    <p class="c26 c32"><span class="c0"></span></p>
    <p class="c26">
      <span class="c0"
        >We do not intentionally collect any Personal Data from children under
        the age of eighteen (18) years of age. &nbsp;We do not sell the Personal
        Data of children under the age of eighteen (18) years of age.
        &nbsp;</span
      >
    </p>
    <p class="c26 c32"><span class="c0"></span></p>
    <ol class="c8 lst-kix_list_14-0" start="6" id="6">
      <li class="c10 li-bullet-0">
        <span class="c4">TYPES OF INFORMATION WE COLLECT</span
        ><span class="c0">. &nbsp;</span>
      </li>
    </ol>
    <p class="c26 c32"><span class="c4"></span></p>
    <ol class="c8 lst-kix_list_20-0 start" start="1">
      <li class="c16 li-bullet-0">
        <span class="c20">Information we collect from you.</span
        ><span class="c0"
          >&nbsp; We may collect the following categories of Personal
          Data:</span
        >
      </li>
    </ol>
    <p class="c27 c32"><span class="c0"></span></p>
    <ul class="c8 lst-kix_list_17-0 start">
      <li class="c2 li-bullet-1">
        <span class="c0"
          >Contact information (name, local and permanent address, telephone
          number, electronic mail address)</span
        >
      </li>
      <li class="c2 li-bullet-2">
        <span class="c0"
          >Your employment information and annual salary amount</span
        >
      </li>
      <li class="c2 li-bullet-2">
        <span class="c0">Your date of birth, gender and gender identity</span>
      </li>
      <li class="c2 li-bullet-1">
        <span class="c0">Your country of domicile</span>
      </li>
      <li class="c2 li-bullet-2">
        <span class="c0">Your social media account names / handles</span>
      </li>
      <li class="c2 li-bullet-1">
        <span class="c0">Any other information you provide to us &nbsp;</span>
      </li>
    </ul>
    <ol class="c8 lst-kix_list_20-0" start="2">
      <li class="c3 li-bullet-0" id="h.26in1rg">
        <span class="c20">Information We Collect Via Tracking Technology.</span
        ><span class="c0">&nbsp; </span>
      </li>
    </ol>
    <p class="c15">
      <span class="c0"
        >We use cookies and other tracking technologies (such as pixels and web
        beacons) (collectively, &ldquo;</span
      ><span class="c4">cookies</span
      ><span class="c0"
        >&rdquo;) to collect information as you navigate the Site. &nbsp;Cookies
        are small files of information that are stored by your web browser
        software on your computer hard drive, mobile or other devices (e.g.,
        smartphones or tablets). &nbsp;</span
      ><span class="c18"
        ><router-link to="/cookies">You may review </router-link></span
      ><span class="c38 c22 c52"
        ><router-link to="/cookies">our Cookie Policy here</router-link></span
      ><span class="c6">.</span><span class="c0">&nbsp; </span>
    </p>
    <p class="c15">
      <span class="c0"
        >You can control the use of cookies at the browser level by setting your
        web browser controls to accept or refuse cookies. &nbsp;If you choose to
        block all bookies (including essential cookies) you may not be able to
        access all or parts of the Site. &nbsp;In addition, most advertising
        networks offer you a way to opt out of targeted advertising.&nbsp; If
        you would like to find out more information on this, please visit the
        websites: </span
      ><span class="c37 c22"
        ><a class="c1" href="http://www.aboutads.info/choices/"
          >http://www.aboutads.info/choices/</a
        ></span
      ><span class="c0">&nbsp;or </span
      ><span class="c22 c37"
        ><a class="c1" href="http://www.youronlinechoices.com"
          >http://www.youronlinechoices.com</a
        ></span
      ><span class="c0">.</span>
    </p>
    <ol class="c8 lst-kix_list_14-0" start="7" id="7">
      <li class="c10 li-bullet-0" id="h.lnxbz9">
        <span class="c4">HOW WE USE YOUR PERSONAL DATA</span
        ><span class="c0">.</span>
      </li>
    </ol>
    <p class="c45">
      <span class="c0">We use the Personal Data we collect to: </span>
    </p>
    <ul class="c8 lst-kix_list_22-0 start">
      <li class="c9 li-bullet-0">
        <span class="c0">Communicate with you;</span>
      </li>
      <li class="c9 li-bullet-0">
        <span class="c0"
          >Allow you to communicate with others on the Site;</span
        >
      </li>
      <li class="c9 li-bullet-0">
        <span class="c0"
          >Provide you with information, newsletters and offers;</span
        >
      </li>
      <li class="c9 li-bullet-0">
        <span class="c0">Provide our Services;</span>
      </li>
      <li class="c9 li-bullet-0"><span class="c0">Provide support;</span></li>
      <li class="c9 li-bullet-0">
        <span class="c0">Improve our Services;</span>
      </li>
      <li class="c9 li-bullet-0">
        <span class="c0"
          >Maintain, improve and customize our Site and Services;</span
        >
      </li>
      <li class="c9 li-bullet-0">
        <span class="c0"
          >Defend our legal rights and the rights of others;</span
        >
      </li>
      <li class="c9 li-bullet-0">
        <span class="c0">Efficiently maintain our business; and</span>
      </li>
      <li class="c9 li-bullet-0">
        <span class="c0">Comply with applicable law.</span>
      </li>
    </ul>
    <ol class="c8 lst-kix_list_14-0" start="8" id="8">
      <li class="c31 c35 li-bullet-0" id="h.35nkun2">
        <span class="c4">INFORMATION WE SHARE WITH THIRD PARTIES</span
        ><span class="c0">.</span>
      </li>
    </ol>
    <p class="c32 c33"><span class="c0"></span></p>
    <p class="c33">
      <span class="c0"
        >We do not sell personal data. &nbsp;We may share the information that
        we collect about you in the following limited ways:</span
      >
    </p>
    <p class="c33 c32"><span class="c0"></span></p>
    <ol class="c8 lst-kix_list_18-1 start" start="1">
      <li class="c3 li-bullet-0" id="h.1ksv4uv">
        <span class="c20">With other users of the Site</span
        ><span class="c0"
          >. &nbsp;If you choose to share your information on the Site, other
          users will be able to see your information and communicate with you.
          &nbsp;</span
        >
      </li>
      <li class="c3 li-bullet-0">
        <span class="c20">With service providers</span
        ><span class="c0"
          >. &nbsp;We may share information with service providers who:</span
        >
      </li>
    </ol>
    <ul class="c8 lst-kix_list_17-1 start">
      <li class="c19 li-bullet-0">
        <span class="c0"
          >perform data services on our behalf (e.g., email, hosting,
          maintenance, backup, analysis, etc.);
        </span>
      </li>
      <li class="c19 li-bullet-0">
        <span class="c0"
          >prepare, deploy and analyze advertising content on our behalf;</span
        >
      </li>
      <li class="c19 li-bullet-0">
        <span class="c0"
          >provide other services on our behalf, such as accounting services,
          and other various services; and</span
        >
      </li>
      <li class="c19 li-bullet-0">
        <span class="c0">process payment information on our behalf.</span>
      </li>
    </ul>
    <ol class="c8 lst-kix_list_24-0 start" start="3">
      <li class="c3 li-bullet-0" id="h.44sinio">
        <span class="c20"
          >To Meet Our Legal Requirements or Exercise Our Rights</span
        ><span class="c0"
          >. &nbsp;We may share information with third parties in the following
          instances:</span
        >
      </li>
    </ol>
    <ul class="c8 lst-kix_list_17-1 start">
      <li class="c19 li-bullet-0">
        <span class="c0">When we are required to do so by law.</span>
      </li>
      <li class="c19 li-bullet-0">
        <span class="c0"
          >In connection with any legal proceedings or prospective legal
          proceedings.</span
        >
      </li>
      <li class="c19 li-bullet-0">
        <span class="c0"
          >To establish, exercise, or defend our legal rights, including
          providing information to others for the purposes of fraud
          prevention.</span
        >
      </li>
      <li class="c19 li-bullet-0">
        <span class="c0"
          >When we reasonably believe it is appropriate to apply to a court or
          other competent authority for disclosure of that Personal Data where,
          in our reasonable opinion, such court or authority would be reasonably
          likely to order disclosure of that Personal data.</span
        >
      </li>
      <li class="c19 li-bullet-0">
        <span class="c0"
          >We may share information with other persons or entities as part of
          our own corporate transactions, such as during financing or any asset
          sale.</span
        >
      </li>
    </ul>
    <ol class="c8 lst-kix_list_14-0" start="9" id="9">
      <li class="c53 c31 c54 li-bullet-0">
        <span class="c4">HOW TO ACCESS &amp; CONTROL YOUR PERSONAL DATA</span>
      </li>
    </ol>
    <p class="c5 c31">
      <span class="c0"
        >We respect your privacy and provide ways for you to choose whether to
        share your information with us or any third parties. &nbsp;</span
      >
    </p>
    <p class="c5 c7"><span class="c4"></span></p>
    <p class="c5 c31">
      <span class="c21 c23">You may decline to provide information.</span
      ><span class="c0"
        >&nbsp; You have the right to decide not to share information with
        us.</span
      >
    </p>
    <p class="c5 c7"><span class="c0"></span></p>
    <p class="c5 c31">
      <span class="c23 c21">You may request deletion.</span
      ><span class="c0"
        >&nbsp; You may submit a request to update or delete your personal data
        by contacting us at us at </span
      ><span class="c18"
        ><a class="c1" href="mailto:privacy@homefounder.com"
          >team@homefounder.com</a
        ></span
      ><span class="c0">.</span>
    </p>
    <p class="c5 c7"><span class="c0"></span></p>
    <p class="c5 c31">
      <span class="c24 c11"
        >PLEASE NOTE, HOWEVER, THAT IF YOU CHOOSE NOT TO PROVIDE US WITH CERTAIN
        INFORMATION OR ASK US TO DELETE YOUR INFORMATION, WE MAY NOT BE ABLE TO
        PROVIDE YOU WITH SOME OF OUR SERVICES. &nbsp;IN ADDITION, IN SOME
        CIRCUMSTANCES, WE MAY NOT BE ABLE TO MODIFY OR DELETE DATA DUE TO OUR
        OWN OBLIGATIONS.</span
      >
    </p>
    <p class="c5 c7"><span class="c22 c24"></span></p>
    <p class="c5 c31" id="h.2jxsxqh">
      <span class="c23 c21"
        >You may review and request changes to your personal data. &nbsp;</span
      ><span class="c0"
        >You may use your account to access, correct, or view certain personal
        data we have collected which is associated with your account. &nbsp; You
        may also contact us at </span
      ><span class="c18"
        ><a class="c1" href="mailto:privacy@homefounder.com"
          >team@homefounder.com</a
        ></span
      ><span class="c0">&nbsp;to request information or changes. &nbsp;</span>
    </p>
    <p class="c5 c31" id="h.z337ya">
      <span class="c0"><br /></span
      ><span class="c23 c21">You may opt out of marketing communications. </span
      ><span class="c0"
        >You can always choose whether you wish to receive communications we
        send via email, SMS messages, telephone calls and postal mail. &nbsp;To
        do so, contact us at </span
      ><span class="c18"
        ><a class="c1" href="mailto:privacy@homefounder.com"
          >team@homefounder.com</a
        ></span
      ><span class="c0">.</span>
    </p>
    <p class="c5 c17"><span class="c0"></span></p>
    <p class="c31 c39">
      <span class="c21">You may control cookie used.</span
      ><span class="c22"
        >&nbsp; You may opt out of cookies by through your account settings in
        the Site or as described in Section 6 (b) above.</span
      >
    </p>
    <ol class="c8 lst-kix_list_14-0" start="10" id="10">
      <li class="c12 li-bullet-0" id="h.3j2qqm3">
        <span class="c4">SECURITY</span>
      </li>
    </ol>
    <p class="c13">
      <span class="c0"
        >When you register for an account with us, you may be required to choose
        or will be assigned a password which enables you to access our Site.
        &nbsp;You are responsible for keeping this password confidential and for
        all activity that takes place through your account credentials. Do not
        share your password with anyone. &nbsp;If your password is compromised,
        change &nbsp;your password within your account. &nbsp;If you believe
        your account may have been compromised, please notify us
        immediately.</span
      >
    </p>
    <p class="c13 c32"><span class="c0"></span></p>
    <p class="c13">
      <span class="c0"
        >We take industry standard security measures to protect your
        information. Unfortunately, the transmission of information via the
        internet is not completely secure. &nbsp;Although we will do our best to
        protect your Personal Data, we cannot guarantee the security of your
        data. &nbsp;</span
      >
    </p>
    <p class="c13 c32"><span class="c0"></span></p>
    <ol class="c8 lst-kix_list_14-0" start="11" id="11">
      <li class="c25 li-bullet-0">
        <span class="c4">LINKS TO THIRD-PARTY SITES</span>
      </li>
    </ol>
    <p class="c48">
      <span class="c0"
        >Our Site may contain links to other websites and companies that may be
        of interest to you. &nbsp;If you click on any of those links, you will
        leave our Site. &nbsp;We are not responsible for third-party websites.
        &nbsp;Therefore, please consult the terms of service and privacy
        policies of those sites when you visit them.</span
      >
    </p>
    <ol class="c8 lst-kix_list_14-0" start="12" id="12">
      <li class="c25 li-bullet-0"><span class="c4">DATA RETENTION</span></li>
    </ol>
    <p class="c48">
      <span class="c0"
        >We will store your Personal Data for as long as: (a) you maintain an
        account with us; (b) you continue using our Site and Services; (c) your
        Personal Data is stored in our back up servers and/or services; and (d)
        as long as we are legally required to retain the Personal Data.
      </span>
    </p>
    <ol class="c8 lst-kix_list_14-0" start="13" id="13">
      <li class="c14 li-bullet-0">
        <span class="c4">ACCESSIBILITY &nbsp;</span>
      </li>
    </ol>
    <p class="c27 c17"><span class="c0"></span></p>
    <p class="c30 c29" id="h.1y810tw">
      <span class="c0"
        >We are committed to ensuring this Privacy Policy is accessible to
        individuals with disabilities. &nbsp;If you wish to access this Privacy
        Policy in an alternate format, or would like to report an issue you are
        experiencing on our Site or Services, please email us at </span
      ><span class="c18"
        ><a class="c1" href="mailto:privacy@homefounder.com"
          >team@homefounder.com</a
        ></span
      ><span class="c0"
        >. &nbsp;You expressly agree to attempt to resolve any and all issues
        with accessibility directly with us and in good faith prior to
        instituting any legal action against us.</span
      >
    </p>
    <p class="c30 c29 c32"><span class="c0"></span></p>
    <ol class="c8 lst-kix_list_14-0" start="14" id="14">
      <li class="c5 c31 c53 li-bullet-0">
        <span class="c4 c29">NOTICE TO NEVADA RESIDENTS</span>
      </li>
    </ol>
    <p class="c5 c32"><span class="c0 c29"></span></p>
    <p class="c5">
      <span class="c0 c29"
        >Nevada law allows Nevada residents to opt-out of the sale of certain
        types of personal information. Subject to a number of exceptions, Nevada
        law defines &ldquo;sale&rdquo; to mean the exchange of certain types of
        personal information for monetary consideration to a person for the
        person to license or sell the information to additional persons. We do
        not currently sell personal information as defined in the Nevada law.
        However, if you are a Nevada resident, you still may submit a verified
        request to opt-out of sales and we will record your instructions and
        incorporate them in the future if our policy changes. Opt-out requests
        may be sent to </span
      ><span class="c18 c29"
        ><a class="c1" href="mailto:privacy@homefounder.com"
          >team@homefounder.com</a
        ></span
      ><span class="c0 c29">. </span
      ><span class="c0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    </p>
    <p class="c5 c32"><span class="c24 c22"></span></p>
    <ol class="c8 lst-kix_list_14-0" start="15" id="15">
      <li class="c10 c29 li-bullet-0">
        <span class="c4 c29">NOTICE TO CALIFORNIA RESIDENTS</span>
      </li>
    </ol>
    <p class="c30 c17 c29"><span class="c22 c29 c47"></span></p>
    <p class="c30">
      <span class="c22">The California Consumer Privacy Act</span
      ><span class="c11">&nbsp;</span><span class="c22">(&ldquo;</span
      ><span class="c11">CCPA</span
      ><span class="c22"
        >&rdquo;) and the California Privacy Rights Act (&ldquo;</span
      ><span class="c11">CPRA</span
      ><span class="c0"
        >&rdquo;) provide certain rights to California residents regarding the
        collection, use, disclosure, and sale of personal information. &nbsp;As
        of the date of last revision of this Privacy Policy, we were not / are
        not subject to the requirements of the CCPA or CPRA. &nbsp;If this
        changes, we will update our Privacy Policy. &nbsp;In the interim, of you
        would like to contact us to access and control your Personal Data,
        please see the options and tools we provide in Section 9 above.
        &nbsp;Please also feel free to contact us with any questions.</span
      >
    </p>
    <p class="c30 c29 c32"><span class="c0"></span></p>
    <p class="c30">
      <span class="c11">Shine the Light Law: &nbsp;</span
      ><span class="c0"
        >If you are a California resident, California Civil Code &sect; 1798.83
        permits you to request information regarding the disclosure of your
        personal information by us to third parties for the third-parties&rsquo;
        direct marketing purposes (as those terms are defined in that statute).
        HOMEFOUNDER does not sell or disclose information to third parties for
        their direct marketing purposes. &nbsp;</span
      >
    </p>
    <p class="c30 c32"><span class="c0"></span></p>
  </div>
</template>

<script>
export default {
  name: "Privacy",
};
</script>

<style scoped>
p,
ol,
li {
  @apply text-body-2 xl:text-body-1 !important;
}

a,
a:visited {
  @apply text-blue-500 !important;
}

.lst-kix_list_14-1 > li:before {
  content: "" counter(lst-ctn-kix_list_14-1, lower-latin) ". ";
}
.lst-kix_list_14-3 > li:before {
  content: "" counter(lst-ctn-kix_list_14-3, decimal) ". ";
}
.lst-kix_list_25-8 > li {
  counter-increment: lst-ctn-kix_list_25-8;
}
ul.lst-kix_list_28-0 {
  list-style-type: none;
}
.lst-kix_list_21-8 > li {
  counter-increment: lst-ctn-kix_list_21-8;
}
ol.lst-kix_list_9-0.start {
  counter-reset: lst-ctn-kix_list_9-0 0;
}
.lst-kix_list_14-0 > li:before {
  content: "" counter(lst-ctn-kix_list_14-0, decimal) ". ";
}
.lst-kix_list_14-4 > li:before {
  content: "" counter(lst-ctn-kix_list_14-4, lower-latin) ". ";
}
ul.lst-kix_list_28-1 {
  list-style-type: none;
}
.lst-kix_list_14-5 > li:before {
  content: "" counter(lst-ctn-kix_list_14-5, lower-roman) ". ";
}
.lst-kix_list_14-7 > li:before {
  content: "" counter(lst-ctn-kix_list_14-7, lower-latin) ". ";
}
.lst-kix_list_14-6 > li:before {
  content: "" counter(lst-ctn-kix_list_14-6, decimal) ". ";
}
ol.lst-kix_list_20-2.start {
  counter-reset: lst-ctn-kix_list_20-2 0;
}
.lst-kix_list_9-0 > li {
  counter-increment: lst-ctn-kix_list_9-0;
}
ul.lst-kix_list_28-2 {
  list-style-type: none;
}
ul.lst-kix_list_28-3 {
  list-style-type: none;
}
ul.lst-kix_list_28-4 {
  list-style-type: none;
}
ul.lst-kix_list_28-5 {
  list-style-type: none;
}
ol.lst-kix_list_25-3.start {
  counter-reset: lst-ctn-kix_list_25-3 0;
}
ul.lst-kix_list_28-6 {
  list-style-type: none;
}
ul.lst-kix_list_28-7 {
  list-style-type: none;
}
ul.lst-kix_list_28-8 {
  list-style-type: none;
}
.lst-kix_list_14-2 > li:before {
  content: "" counter(lst-ctn-kix_list_14-2, lower-roman) ". ";
}
ol.lst-kix_list_23-2.start {
  counter-reset: lst-ctn-kix_list_23-2 0;
}
.lst-kix_list_20-7 > li {
  counter-increment: lst-ctn-kix_list_20-7;
}
ol.lst-kix_list_26-7 {
  list-style-type: none;
}
ol.lst-kix_list_26-6 {
  list-style-type: none;
}
ol.lst-kix_list_26-8 {
  list-style-type: none;
}
ol.lst-kix_list_26-3 {
  list-style-type: none;
}
ul.lst-kix_list_17-1 {
  list-style-type: none;
}
ol.lst-kix_list_26-2 {
  list-style-type: none;
}
ul.lst-kix_list_17-0 {
  list-style-type: none;
}
ol.lst-kix_list_26-5 {
  list-style-type: none;
}
ol.lst-kix_list_26-4 {
  list-style-type: none;
}
ol.lst-kix_list_26-1 {
  list-style-type: none;
}
ol.lst-kix_list_26-0 {
  list-style-type: none;
}
ul.lst-kix_list_17-8 {
  list-style-type: none;
}
ul.lst-kix_list_17-7 {
  list-style-type: none;
}
ol.lst-kix_list_21-6.start {
  counter-reset: lst-ctn-kix_list_21-6 0;
}
ul.lst-kix_list_17-6 {
  list-style-type: none;
}
ul.lst-kix_list_17-5 {
  list-style-type: none;
}
.lst-kix_list_24-7 > li {
  counter-increment: lst-ctn-kix_list_24-7;
}
ul.lst-kix_list_17-4 {
  list-style-type: none;
}
ul.lst-kix_list_17-3 {
  list-style-type: none;
}
.lst-kix_list_14-8 > li:before {
  content: "" counter(lst-ctn-kix_list_14-8, lower-roman) ". ";
}
ul.lst-kix_list_17-2 {
  list-style-type: none;
}
ol.lst-kix_list_26-7.start {
  counter-reset: lst-ctn-kix_list_26-7 0;
}
ol.lst-kix_list_10-4.start {
  counter-reset: lst-ctn-kix_list_10-4 0;
}
.lst-kix_list_5-0 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_14-8 > li {
  counter-increment: lst-ctn-kix_list_14-8;
}
.lst-kix_list_24-7 > li:before {
  content: "" counter(lst-ctn-kix_list_24-7, lower-latin) ". ";
}
ul.lst-kix_list_27-0 {
  list-style-type: none;
}
ul.lst-kix_list_27-1 {
  list-style-type: none;
}
.lst-kix_list_24-8 > li:before {
  content: "" counter(lst-ctn-kix_list_24-8, lower-roman) ". ";
}
ul.lst-kix_list_27-2 {
  list-style-type: none;
}
.lst-kix_list_5-3 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_9-5.start {
  counter-reset: lst-ctn-kix_list_9-5 0;
}
ol.lst-kix_list_24-6.start {
  counter-reset: lst-ctn-kix_list_24-6 0;
}
.lst-kix_list_5-2 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_5-1 > li:before {
  content: "o ";
}
.lst-kix_list_24-2 > li:before {
  content: "" counter(lst-ctn-kix_list_24-2, lower-roman) ". ";
}
ol.lst-kix_list_16-0 {
  list-style-type: none;
}
.lst-kix_list_5-7 > li:before {
  content: "o ";
}
ul.lst-kix_list_8-4 {
  list-style-type: none;
}
ul.lst-kix_list_8-5 {
  list-style-type: none;
}
ol.lst-kix_list_20-7.start {
  counter-reset: lst-ctn-kix_list_20-7 0;
}
.lst-kix_list_5-6 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_5-8 > li:before {
  content: "\0025aa ";
}
ul.lst-kix_list_8-2 {
  list-style-type: none;
}
ul.lst-kix_list_8-3 {
  list-style-type: none;
}
ul.lst-kix_list_8-8 {
  list-style-type: none;
}
.lst-kix_list_24-3 > li:before {
  content: "" counter(lst-ctn-kix_list_24-3, decimal) ". ";
}
ul.lst-kix_list_8-6 {
  list-style-type: none;
}
.lst-kix_list_24-4 > li:before {
  content: "" counter(lst-ctn-kix_list_24-4, lower-latin) ". ";
}
ul.lst-kix_list_8-7 {
  list-style-type: none;
}
.lst-kix_list_9-4 > li {
  counter-increment: lst-ctn-kix_list_9-4;
}
ul.lst-kix_list_27-3 {
  list-style-type: none;
}
.lst-kix_list_24-5 > li:before {
  content: "" counter(lst-ctn-kix_list_24-5, lower-roman) ". ";
}
ul.lst-kix_list_27-4 {
  list-style-type: none;
}
.lst-kix_list_5-4 > li:before {
  content: "o ";
}
ul.lst-kix_list_27-5 {
  list-style-type: none;
}
ul.lst-kix_list_27-6 {
  list-style-type: none;
}
.lst-kix_list_5-5 > li:before {
  content: "\0025aa ";
}
ul.lst-kix_list_8-0 {
  list-style-type: none;
}
ul.lst-kix_list_27-7 {
  list-style-type: none;
}
ul.lst-kix_list_8-1 {
  list-style-type: none;
}
ul.lst-kix_list_27-8 {
  list-style-type: none;
}
.lst-kix_list_24-6 > li:before {
  content: "" counter(lst-ctn-kix_list_24-6, decimal) ". ";
}
ol.lst-kix_list_14-1.start {
  counter-reset: lst-ctn-kix_list_14-1 0;
}
.lst-kix_list_23-6 > li:before {
  content: "" counter(lst-ctn-kix_list_23-6, decimal) ". ";
}
.lst-kix_list_6-1 > li:before {
  content: "o ";
}
.lst-kix_list_6-3 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_23-3 > li:before {
  content: "" counter(lst-ctn-kix_list_23-3, decimal) ". ";
}
.lst-kix_list_23-7 > li:before {
  content: "" counter(lst-ctn-kix_list_23-7, lower-latin) ". ";
}
.lst-kix_list_6-0 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_6-4 > li:before {
  content: "o ";
}
ol.lst-kix_list_14-8.start {
  counter-reset: lst-ctn-kix_list_14-8 0;
}
.lst-kix_list_23-2 > li:before {
  content: "" counter(lst-ctn-kix_list_23-2, lower-roman) ". ";
}
ul.lst-kix_list_16-2 {
  list-style-type: none;
}
ol.lst-kix_list_23-7.start {
  counter-reset: lst-ctn-kix_list_23-7 0;
}
ul.lst-kix_list_16-1 {
  list-style-type: none;
}
.lst-kix_list_23-0 > li:before {
  content: "" counter(lst-ctn-kix_list_23-0, decimal) ". ";
}
.lst-kix_list_23-8 > li:before {
  content: "" counter(lst-ctn-kix_list_23-8, lower-roman) ". ";
}
.lst-kix_list_23-1 > li:before {
  content: "" counter(lst-ctn-kix_list_23-1, lower-latin) ". ";
}
.lst-kix_list_6-2 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_24-1 > li:before {
  content: "" counter(lst-ctn-kix_list_24-1, lower-latin) ". ";
}
ul.lst-kix_list_16-8 {
  list-style-type: none;
}
ul.lst-kix_list_16-7 {
  list-style-type: none;
}
ul.lst-kix_list_16-6 {
  list-style-type: none;
}
ul.lst-kix_list_16-5 {
  list-style-type: none;
}
.lst-kix_list_24-0 > li:before {
  content: "" counter(lst-ctn-kix_list_24-0, lower-latin) ". ";
}
ul.lst-kix_list_16-4 {
  list-style-type: none;
}
.lst-kix_list_6-8 > li:before {
  content: "\0025aa ";
}
ul.lst-kix_list_16-3 {
  list-style-type: none;
}
ol.lst-kix_list_26-2.start {
  counter-reset: lst-ctn-kix_list_26-2 0;
}
.lst-kix_list_6-5 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_6-7 > li:before {
  content: "o ";
}
.lst-kix_list_23-4 > li:before {
  content: "" counter(lst-ctn-kix_list_23-4, lower-latin) ". ";
}
.lst-kix_list_23-5 > li:before {
  content: "" counter(lst-ctn-kix_list_23-5, lower-roman) ". ";
}
.lst-kix_list_6-6 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_10-6.start {
  counter-reset: lst-ctn-kix_list_10-6 0;
}
.lst-kix_list_7-4 > li:before {
  content: "o ";
}
.lst-kix_list_7-6 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_19-7.start {
  counter-reset: lst-ctn-kix_list_19-7 0;
}
.lst-kix_list_22-2 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_22-6 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_24-1.start {
  counter-reset: lst-ctn-kix_list_24-1 0;
}
.lst-kix_list_7-2 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_22-0 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_22-8 > li:before {
  content: "\0025aa ";
}
ol.lst-kix_list_9-7 {
  list-style-type: none;
}
ol.lst-kix_list_9-8 {
  list-style-type: none;
}
.lst-kix_list_24-3 > li {
  counter-increment: lst-ctn-kix_list_24-3;
}
.lst-kix_list_13-7 > li:before {
  content: "o ";
}
ol.lst-kix_list_9-3 {
  list-style-type: none;
}
ol.lst-kix_list_9-4 {
  list-style-type: none;
}
ol.lst-kix_list_9-5 {
  list-style-type: none;
}
.lst-kix_list_7-8 > li:before {
  content: "\0025aa ";
}
ol.lst-kix_list_9-6 {
  list-style-type: none;
}
ol.lst-kix_list_23-0.start {
  counter-reset: lst-ctn-kix_list_23-0 0;
  margin: 2rem 0;
}
ol.lst-kix_list_9-0 {
  list-style-type: none;
}
ol.lst-kix_list_9-1 {
  list-style-type: none;
}
ol.lst-kix_list_9-2 {
  list-style-type: none;
}
.lst-kix_list_22-4 > li:before {
  content: "o ";
}
.lst-kix_list_15-5 > li:before {
  content: "\0025aa ";
}
ol.lst-kix_list_26-0.start {
  counter-reset: lst-ctn-kix_list_26-0 0;
}
.lst-kix_list_9-8 > li {
  counter-increment: lst-ctn-kix_list_9-8;
}
.lst-kix_list_4-1 > li:before {
  content: "o ";
}
ol.lst-kix_list_25-5.start {
  counter-reset: lst-ctn-kix_list_25-5 0;
}
.lst-kix_list_15-7 > li:before {
  content: "o ";
}
.lst-kix_list_4-3 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_4-5 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_25-5 > li:before {
  content: "" counter(lst-ctn-kix_list_25-5, lower-roman) ". ";
}
.lst-kix_list_10-5 > li {
  counter-increment: lst-ctn-kix_list_10-5;
}
.lst-kix_list_15-1 > li:before {
  content: "o ";
}
.lst-kix_list_24-4 > li {
  counter-increment: lst-ctn-kix_list_24-4;
}
.lst-kix_list_25-7 > li:before {
  content: "" counter(lst-ctn-kix_list_25-7, lower-latin) ". ";
}
.lst-kix_list_15-3 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_24-4.start {
  counter-reset: lst-ctn-kix_list_24-4 0;
}
ul.lst-kix_list_29-0 {
  list-style-type: none;
}
ol.lst-kix_list_9-2.start {
  counter-reset: lst-ctn-kix_list_9-2 0;
}
.lst-kix_list_20-0 > li {
  counter-increment: lst-ctn-kix_list_20-0;
}
ol.lst-kix_list_18-1 {
  list-style-type: none;
}
.lst-kix_list_9-3 > li {
  counter-increment: lst-ctn-kix_list_9-3;
}
.lst-kix_list_26-5 > li {
  counter-increment: lst-ctn-kix_list_26-5;
}
.lst-kix_list_19-2 > li {
  counter-increment: lst-ctn-kix_list_19-2;
}
.lst-kix_list_12-3 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_12-1 > li:before {
  content: "o ";
}
ul.lst-kix_list_29-1 {
  list-style-type: none;
}
ul.lst-kix_list_29-2 {
  list-style-type: none;
}
ul.lst-kix_list_29-3 {
  list-style-type: none;
}
ul.lst-kix_list_29-4 {
  list-style-type: none;
}
ul.lst-kix_list_29-5 {
  list-style-type: none;
}
ul.lst-kix_list_29-6 {
  list-style-type: none;
}
.lst-kix_list_23-6 > li {
  counter-increment: lst-ctn-kix_list_23-6;
}
ul.lst-kix_list_29-7 {
  list-style-type: none;
}
ul.lst-kix_list_29-8 {
  list-style-type: none;
}
ol.lst-kix_list_25-8.start {
  counter-reset: lst-ctn-kix_list_25-8 0;
}
.lst-kix_list_10-4 > li {
  counter-increment: lst-ctn-kix_list_10-4;
}
.lst-kix_list_14-1 > li {
  counter-increment: lst-ctn-kix_list_14-1;
}
.lst-kix_list_21-4 > li {
  counter-increment: lst-ctn-kix_list_21-4;
}
.lst-kix_list_13-3 > li:before {
  content: "\0025cf ";
}
ul.lst-kix_list_18-0 {
  list-style-type: none;
}
.lst-kix_list_13-5 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_12-5 > li:before {
  content: "\0025aa ";
}
ul.lst-kix_list_18-8 {
  list-style-type: none;
}
ul.lst-kix_list_18-7 {
  list-style-type: none;
}
ul.lst-kix_list_18-6 {
  list-style-type: none;
}
ul.lst-kix_list_18-5 {
  list-style-type: none;
}
.lst-kix_list_12-7 > li:before {
  content: "o ";
}
ul.lst-kix_list_18-4 {
  list-style-type: none;
}
ul.lst-kix_list_18-3 {
  list-style-type: none;
}
ul.lst-kix_list_18-2 {
  list-style-type: none;
}
ol.lst-kix_list_21-1.start {
  counter-reset: lst-ctn-kix_list_21-1 0;
}
.lst-kix_list_25-1 > li {
  counter-increment: lst-ctn-kix_list_25-1;
}
.lst-kix_list_13-1 > li:before {
  content: "o ";
}
.lst-kix_list_24-8 > li {
  counter-increment: lst-ctn-kix_list_24-8;
}
.lst-kix_list_30-5 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_19-0.start {
  counter-reset: lst-ctn-kix_list_19-0 0;
}
ol.lst-kix_list_21-3.start {
  counter-reset: lst-ctn-kix_list_21-3 0;
}
.lst-kix_list_30-1 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_3-0 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_25-6.start {
  counter-reset: lst-ctn-kix_list_25-6 0;
}
.lst-kix_list_30-2 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_20-5.start {
  counter-reset: lst-ctn-kix_list_20-5 0;
}
ul.lst-kix_list_5-7 {
  list-style-type: none;
}
ul.lst-kix_list_5-8 {
  list-style-type: none;
}
ul.lst-kix_list_5-5 {
  list-style-type: none;
}
ul.lst-kix_list_5-6 {
  list-style-type: none;
}
.lst-kix_list_21-8 > li:before {
  content: "" counter(lst-ctn-kix_list_21-8, lower-roman) ". ";
}
.lst-kix_list_26-5 > li:before {
  content: "" counter(lst-ctn-kix_list_26-5, lower-roman) ". ";
}
.lst-kix_list_16-0 > li {
  counter-increment: lst-ctn-kix_list_16-0;
}
ul.lst-kix_list_5-0 {
  list-style-type: none;
}
.lst-kix_list_10-0 > li {
  counter-increment: lst-ctn-kix_list_10-0;
}
.lst-kix_list_3-4 > li:before {
  content: "o ";
}
ul.lst-kix_list_5-3 {
  list-style-type: none;
}
.lst-kix_list_3-3 > li:before {
  content: "\0025cf ";
}
ul.lst-kix_list_5-4 {
  list-style-type: none;
}
.lst-kix_list_26-8 > li:before {
  content: "" counter(lst-ctn-kix_list_26-8, lower-roman) ". ";
}
ul.lst-kix_list_5-1 {
  list-style-type: none;
}
.lst-kix_list_8-0 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_30-6 > li:before {
  content: "\0025cf ";
}
ul.lst-kix_list_5-2 {
  list-style-type: none;
}
.lst-kix_list_8-7 > li:before {
  content: "o ";
}
.lst-kix_list_3-8 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_21-0 > li:before {
  content: "" counter(lst-ctn-kix_list_21-0, lower-latin) ". ";
}
ol.lst-kix_list_10-7.start {
  counter-reset: lst-ctn-kix_list_10-7 0;
}
.lst-kix_list_26-1 > li:before {
  content: "" counter(lst-ctn-kix_list_26-1, lower-latin) ". ";
}
.lst-kix_list_21-1 > li:before {
  content: "" counter(lst-ctn-kix_list_21-1, lower-latin) ". ";
}
.lst-kix_list_8-3 > li:before {
  content: "\0025cf ";
}
ul.lst-kix_list_13-5 {
  list-style-type: none;
}
ul.lst-kix_list_13-4 {
  list-style-type: none;
}
ul.lst-kix_list_13-3 {
  list-style-type: none;
}
ul.lst-kix_list_13-2 {
  list-style-type: none;
}
ul.lst-kix_list_13-1 {
  list-style-type: none;
}
.lst-kix_list_3-7 > li:before {
  content: "o ";
}
ul.lst-kix_list_13-0 {
  list-style-type: none;
}
.lst-kix_list_26-4 > li:before {
  content: "" counter(lst-ctn-kix_list_26-4, lower-latin) ". ";
}
.lst-kix_list_8-4 > li:before {
  content: "o ";
}
.lst-kix_list_19-1 > li {
  counter-increment: lst-ctn-kix_list_19-1;
}
.lst-kix_list_10-2 > li {
  counter-increment: lst-ctn-kix_list_10-2;
}
ol.lst-kix_list_26-4.start {
  counter-reset: lst-ctn-kix_list_26-4 0;
}
ul.lst-kix_list_13-8 {
  list-style-type: none;
}
.lst-kix_list_11-1 > li:before {
  content: "o ";
}
ul.lst-kix_list_13-7 {
  list-style-type: none;
}
ul.lst-kix_list_13-6 {
  list-style-type: none;
}
.lst-kix_list_21-5 > li:before {
  content: "" counter(lst-ctn-kix_list_21-5, lower-roman) ". ";
}
.lst-kix_list_21-4 > li:before {
  content: "" counter(lst-ctn-kix_list_21-4, lower-latin) ". ";
}
.lst-kix_list_11-0 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_9-3.start {
  counter-reset: lst-ctn-kix_list_9-3 0;
}
.lst-kix_list_26-0 > li:before {
  content: "" counter(lst-ctn-kix_list_26-0, upper-latin) ". ";
}
.lst-kix_list_8-8 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_16-8 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_16-7 > li:before {
  content: "o ";
}
ol.lst-kix_list_19-5.start {
  counter-reset: lst-ctn-kix_list_19-5 0;
}
.lst-kix_list_4-8 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_21-5 > li {
  counter-increment: lst-ctn-kix_list_21-5;
}
.lst-kix_list_4-7 > li:before {
  content: "o ";
}
.lst-kix_list_14-2 > li {
  counter-increment: lst-ctn-kix_list_14-2;
}
ol.lst-kix_list_20-0.start {
  counter-reset: lst-ctn-kix_list_20-0 0;
}
.lst-kix_list_17-0 > li:before {
  content: "\0025cf ";
}
ul.lst-kix_list_4-8 {
  list-style-type: none;
}
.lst-kix_list_16-0 > li:before {
  content: "" counter(lst-ctn-kix_list_16-0, lower-latin) ". ";
}
ol.lst-kix_list_26-3.start {
  counter-reset: lst-ctn-kix_list_26-3 0;
}
ul.lst-kix_list_4-6 {
  list-style-type: none;
}
ul.lst-kix_list_4-7 {
  list-style-type: none;
}
.lst-kix_list_21-0 > li {
  counter-increment: lst-ctn-kix_list_21-0;
}
.lst-kix_list_25-1 > li:before {
  content: "" counter(lst-ctn-kix_list_25-1, lower-latin) ". ";
}
ul.lst-kix_list_4-0 {
  list-style-type: none;
}
.lst-kix_list_25-0 > li:before {
  content: "" counter(lst-ctn-kix_list_25-0, decimal) ". ";
}
.lst-kix_list_16-4 > li:before {
  content: "o ";
}
ul.lst-kix_list_4-1 {
  list-style-type: none;
}
ol.lst-kix_list_10-8.start {
  counter-reset: lst-ctn-kix_list_10-8 0;
}
.lst-kix_list_16-3 > li:before {
  content: "\0025cf ";
}
ul.lst-kix_list_4-4 {
  list-style-type: none;
}
ul.lst-kix_list_4-5 {
  list-style-type: none;
}
ul.lst-kix_list_4-2 {
  list-style-type: none;
}
ul.lst-kix_list_4-3 {
  list-style-type: none;
}
ol.lst-kix_list_18-1.start {
  counter-reset: lst-ctn-kix_list_18-1 0;
}
ul.lst-kix_list_12-6 {
  list-style-type: none;
}
ol.lst-kix_list_23-6 {
  list-style-type: none;
}
ul.lst-kix_list_12-5 {
  list-style-type: none;
}
ol.lst-kix_list_23-5 {
  list-style-type: none;
}
.lst-kix_list_17-7 > li:before {
  content: "o ";
}
ul.lst-kix_list_12-4 {
  list-style-type: none;
}
ol.lst-kix_list_23-8 {
  list-style-type: none;
}
ul.lst-kix_list_12-3 {
  list-style-type: none;
}
ol.lst-kix_list_23-7 {
  list-style-type: none;
}
ul.lst-kix_list_12-2 {
  list-style-type: none;
}
ol.lst-kix_list_23-2 {
  list-style-type: none;
}
ul.lst-kix_list_12-1 {
  list-style-type: none;
}
ol.lst-kix_list_21-4.start {
  counter-reset: lst-ctn-kix_list_21-4 0;
}
ol.lst-kix_list_23-1 {
  list-style-type: none;
}
.lst-kix_list_17-8 > li:before {
  content: "\0025aa ";
}
ul.lst-kix_list_12-0 {
  list-style-type: none;
}
ol.lst-kix_list_23-4 {
  list-style-type: none;
}
ol.lst-kix_list_23-3 {
  list-style-type: none;
}
.lst-kix_list_17-3 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_23-0 {
  list-style-type: none;
}
.lst-kix_list_17-4 > li:before {
  content: "\0025cf ";
}
ul.lst-kix_list_12-8 {
  list-style-type: none;
}
ol.lst-kix_list_20-6.start {
  counter-reset: lst-ctn-kix_list_20-6 0;
}
ul.lst-kix_list_12-7 {
  list-style-type: none;
}
.lst-kix_list_7-0 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_19-6.start {
  counter-reset: lst-ctn-kix_list_19-6 0;
}
ol.lst-kix_list_9-7.start {
  counter-reset: lst-ctn-kix_list_9-7 0;
}
.lst-kix_list_26-2 > li {
  counter-increment: lst-ctn-kix_list_26-2;
}
.lst-kix_list_22-5 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_2-4 > li:before {
  content: "o ";
}
.lst-kix_list_2-8 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_21-2 > li {
  counter-increment: lst-ctn-kix_list_21-2;
}
.lst-kix_list_22-1 > li:before {
  content: "o ";
}
.lst-kix_list_27-4 > li:before {
  content: "o ";
}
.lst-kix_list_20-2 > li {
  counter-increment: lst-ctn-kix_list_20-2;
}
.lst-kix_list_7-3 > li:before {
  content: "\0025cf ";
}
ul.lst-kix_list_7-5 {
  list-style-type: none;
}
.lst-kix_list_10-0 > li:before {
  content: "" counter(lst-ctn-kix_list_10-0, decimal) ". ";
}
ul.lst-kix_list_7-6 {
  list-style-type: none;
}
.lst-kix_list_23-3 > li {
  counter-increment: lst-ctn-kix_list_23-3;
}
ul.lst-kix_list_7-3 {
  list-style-type: none;
}
.lst-kix_list_9-7 > li {
  counter-increment: lst-ctn-kix_list_9-7;
}
ol.lst-kix_list_21-7.start {
  counter-reset: lst-ctn-kix_list_21-7 0;
}
ul.lst-kix_list_7-4 {
  list-style-type: none;
}
.lst-kix_list_13-8 > li:before {
  content: "\0025aa ";
}
ol.lst-kix_list_14-6.start {
  counter-reset: lst-ctn-kix_list_14-6 0;
}
.lst-kix_list_18-3 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_18-7 > li:before {
  content: "\0025cf ";
}
ul.lst-kix_list_7-7 {
  list-style-type: none;
}
ul.lst-kix_list_7-8 {
  list-style-type: none;
}
ol.lst-kix_list_26-8.start {
  counter-reset: lst-ctn-kix_list_26-8 0;
}
ul.lst-kix_list_7-1 {
  list-style-type: none;
}
.lst-kix_list_19-6 > li {
  counter-increment: lst-ctn-kix_list_19-6;
}
ul.lst-kix_list_7-2 {
  list-style-type: none;
}
.lst-kix_list_27-0 > li:before {
  content: "\0025cf ";
}
ul.lst-kix_list_7-0 {
  list-style-type: none;
}
.lst-kix_list_7-7 > li:before {
  content: "o ";
}
.lst-kix_list_20-4 > li {
  counter-increment: lst-ctn-kix_list_20-4;
}
.lst-kix_list_15-4 > li:before {
  content: "o ";
}
.lst-kix_list_9-5 > li {
  counter-increment: lst-ctn-kix_list_9-5;
}
ol.lst-kix_list_19-1.start {
  counter-reset: lst-ctn-kix_list_19-1 0;
}
ol.lst-kix_list_24-8 {
  list-style-type: none;
}
.lst-kix_list_10-4 > li:before {
  content: "" counter(lst-ctn-kix_list_10-4, lower-latin) ". ";
}
.lst-kix_list_10-8 > li:before {
  content: "" counter(lst-ctn-kix_list_10-8, lower-roman) ". ";
}
ol.lst-kix_list_20-4.start {
  counter-reset: lst-ctn-kix_list_20-4 0;
}
.lst-kix_list_4-0 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_24-5 {
  list-style-type: none;
}
ul.lst-kix_list_15-3 {
  list-style-type: none;
}
ol.lst-kix_list_24-4 {
  list-style-type: none;
}
ul.lst-kix_list_15-2 {
  list-style-type: none;
}
ol.lst-kix_list_24-7 {
  list-style-type: none;
}
ol.lst-kix_list_25-2.start {
  counter-reset: lst-ctn-kix_list_25-2 0;
}
.lst-kix_list_15-0 > li:before {
  content: "\0025cf ";
}
ul.lst-kix_list_15-1 {
  list-style-type: none;
}
.lst-kix_list_15-8 > li:before {
  content: "\0025aa ";
}
ol.lst-kix_list_24-6 {
  list-style-type: none;
}
ul.lst-kix_list_15-0 {
  list-style-type: none;
}
ol.lst-kix_list_24-1 {
  list-style-type: none;
}
ol.lst-kix_list_19-4.start {
  counter-reset: lst-ctn-kix_list_19-4 0;
}
ol.lst-kix_list_14-3.start {
  counter-reset: lst-ctn-kix_list_14-3 0;
}
ol.lst-kix_list_24-0 {
  list-style-type: none;
}
ol.lst-kix_list_24-3 {
  list-style-type: none;
}
ol.lst-kix_list_24-2 {
  list-style-type: none;
}
.lst-kix_list_4-4 > li:before {
  content: "o ";
}
.lst-kix_list_25-4 > li:before {
  content: "" counter(lst-ctn-kix_list_25-4, lower-latin) ". ";
}
.lst-kix_list_19-4 > li {
  counter-increment: lst-ctn-kix_list_19-4;
}
ul.lst-kix_list_15-8 {
  list-style-type: none;
}
ul.lst-kix_list_15-7 {
  list-style-type: none;
}
ul.lst-kix_list_15-6 {
  list-style-type: none;
}
.lst-kix_list_9-3 > li:before {
  content: "" counter(lst-ctn-kix_list_9-3, decimal) ". ";
}
ul.lst-kix_list_15-5 {
  list-style-type: none;
}
ul.lst-kix_list_15-4 {
  list-style-type: none;
}
.lst-kix_list_24-1 > li {
  counter-increment: lst-ctn-kix_list_24-1;
}
.lst-kix_list_25-8 > li:before {
  content: "" counter(lst-ctn-kix_list_25-8, lower-roman) ". ";
}
ol.lst-kix_list_25-1.start {
  counter-reset: lst-ctn-kix_list_25-1 0;
}
ol.lst-kix_list_14-7 {
  list-style-type: none;
}
ol.lst-kix_list_14-4.start {
  counter-reset: lst-ctn-kix_list_14-4 0;
}
ol.lst-kix_list_14-8 {
  list-style-type: none;
}
.lst-kix_list_9-7 > li:before {
  content: "" counter(lst-ctn-kix_list_9-7, lower-latin) ". ";
}
ol.lst-kix_list_26-6.start {
  counter-reset: lst-ctn-kix_list_26-6 0;
}
.lst-kix_list_29-4 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_29-8 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_14-3 {
  list-style-type: none;
}
ol.lst-kix_list_21-8.start {
  counter-reset: lst-ctn-kix_list_21-8 0;
}
ol.lst-kix_list_14-4 {
  list-style-type: none;
}
ol.lst-kix_list_14-5 {
  list-style-type: none;
}
ol.lst-kix_list_14-6 {
  list-style-type: none;
}
.lst-kix_list_11-4 > li:before {
  content: "o ";
}
ol.lst-kix_list_14-0 {
  list-style-type: none;
}
.lst-kix_list_12-4 > li:before {
  content: "o ";
}
ol.lst-kix_list_14-1 {
  list-style-type: none;
}
ol.lst-kix_list_14-2 {
  list-style-type: none;
}
ul.lst-kix_list_6-6 {
  list-style-type: none;
}
ul.lst-kix_list_6-7 {
  list-style-type: none;
}
ul.lst-kix_list_6-4 {
  list-style-type: none;
}
.lst-kix_list_20-5 > li:before {
  content: "" counter(lst-ctn-kix_list_20-5, lower-roman) ". ";
}
ul.lst-kix_list_6-5 {
  list-style-type: none;
}
ul.lst-kix_list_6-8 {
  list-style-type: none;
}
.lst-kix_list_29-0 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_28-8 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_1-0 > li:before {
  content: "\0025a0 ";
}
ol.lst-kix_list_19-2.start {
  counter-reset: lst-ctn-kix_list_19-2 0;
}
.lst-kix_list_20-1 > li:before {
  content: "\0025cf ";
}
ul.lst-kix_list_6-2 {
  list-style-type: none;
}
.lst-kix_list_11-8 > li:before {
  content: "\0025aa ";
}
ul.lst-kix_list_6-3 {
  list-style-type: none;
}
.lst-kix_list_24-6 > li {
  counter-increment: lst-ctn-kix_list_24-6;
}
ul.lst-kix_list_6-0 {
  list-style-type: none;
}
.lst-kix_list_12-0 > li:before {
  content: "\0025cf ";
}
ul.lst-kix_list_6-1 {
  list-style-type: none;
}
.lst-kix_list_28-4 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_1-4 > li:before {
  content: "o ";
}
.lst-kix_list_13-0 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_21-7 > li {
  counter-increment: lst-ctn-kix_list_21-7;
}
ol.lst-kix_list_25-0.start {
  counter-reset: lst-ctn-kix_list_25-0 0;
}
ol.lst-kix_list_25-8 {
  list-style-type: none;
}
ol.lst-kix_list_25-7 {
  list-style-type: none;
}
ol.lst-kix_list_25-4 {
  list-style-type: none;
}
ol.lst-kix_list_25-3 {
  list-style-type: none;
}
.lst-kix_list_14-4 > li {
  counter-increment: lst-ctn-kix_list_14-4;
}
ol.lst-kix_list_25-6 {
  list-style-type: none;
}
.lst-kix_list_13-4 > li:before {
  content: "o ";
}
ol.lst-kix_list_25-5 {
  list-style-type: none;
}
ol.lst-kix_list_25-0 {
  list-style-type: none;
}
ol.lst-kix_list_25-2 {
  list-style-type: none;
}
.lst-kix_list_10-7 > li {
  counter-increment: lst-ctn-kix_list_10-7;
}
ol.lst-kix_list_26-5.start {
  counter-reset: lst-ctn-kix_list_26-5 0;
}
ol.lst-kix_list_25-1 {
  list-style-type: none;
}
ol.lst-kix_list_19-3.start {
  counter-reset: lst-ctn-kix_list_19-3 0;
}
.lst-kix_list_2-0 > li:before {
  content: "\002212 ";
}
.lst-kix_list_25-4 > li {
  counter-increment: lst-ctn-kix_list_25-4;
}
.lst-kix_list_18-1 > li {
  counter-increment: lst-ctn-kix_list_18-1;
}
ol.lst-kix_list_14-5.start {
  counter-reset: lst-ctn-kix_list_14-5 0;
}
.lst-kix_list_28-0 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_9-8.start {
  counter-reset: lst-ctn-kix_list_9-8 0;
}
.lst-kix_list_1-8 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_27-8 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_12-8 > li:before {
  content: "\0025aa ";
}
ol.lst-kix_list_20-3.start {
  counter-reset: lst-ctn-kix_list_20-3 0;
}
.lst-kix_list_19-0 > li:before {
  content: "" counter(lst-ctn-kix_list_19-0, decimal) ". ";
}
.lst-kix_list_19-1 > li:before {
  content: "" counter(lst-ctn-kix_list_19-1, lower-latin) ". ";
}
ul.lst-kix_list_1-0 {
  list-style-type: none;
}
.lst-kix_list_23-8 > li {
  counter-increment: lst-ctn-kix_list_23-8;
}
.lst-kix_list_19-4 > li:before {
  content: "" counter(lst-ctn-kix_list_19-4, lower-latin) ". ";
}
ol.lst-kix_list_26-1.start {
  counter-reset: lst-ctn-kix_list_26-1 0;
}
.lst-kix_list_19-2 > li:before {
  content: "" counter(lst-ctn-kix_list_19-2, lower-roman) ". ";
}
.lst-kix_list_19-3 > li:before {
  content: "" counter(lst-ctn-kix_list_19-3, decimal) ". ";
}
ol.lst-kix_list_21-0.start {
  counter-reset: lst-ctn-kix_list_21-0 1;
}
ul.lst-kix_list_20-1 {
  list-style-type: none;
}
ol.lst-kix_list_24-5.start {
  counter-reset: lst-ctn-kix_list_24-5 0;
}
ul.lst-kix_list_1-3 {
  list-style-type: none;
}
ul.lst-kix_list_1-4 {
  list-style-type: none;
}
.lst-kix_list_19-0 > li {
  counter-increment: lst-ctn-kix_list_19-0;
}
ul.lst-kix_list_1-1 {
  list-style-type: none;
}
ul.lst-kix_list_1-2 {
  list-style-type: none;
}
ul.lst-kix_list_1-7 {
  list-style-type: none;
}
ul.lst-kix_list_1-8 {
  list-style-type: none;
}
ul.lst-kix_list_1-5 {
  list-style-type: none;
}
ul.lst-kix_list_1-6 {
  list-style-type: none;
}
ol.lst-kix_list_9-6.start {
  counter-reset: lst-ctn-kix_list_9-6 0;
}
ol.lst-kix_list_19-8.start {
  counter-reset: lst-ctn-kix_list_19-8 0;
}
.lst-kix_list_26-7 > li {
  counter-increment: lst-ctn-kix_list_26-7;
}
.lst-kix_list_19-8 > li:before {
  content: "" counter(lst-ctn-kix_list_19-8, lower-roman) ". ";
}
ol.lst-kix_list_14-7.start {
  counter-reset: lst-ctn-kix_list_14-7 0;
}
ol.lst-kix_list_20-8.start {
  counter-reset: lst-ctn-kix_list_20-8 0;
}
.lst-kix_list_19-5 > li:before {
  content: "" counter(lst-ctn-kix_list_19-5, lower-roman) ". ";
}
.lst-kix_list_19-6 > li:before {
  content: "" counter(lst-ctn-kix_list_19-6, decimal) ". ";
}
.lst-kix_list_19-7 > li:before {
  content: "" counter(lst-ctn-kix_list_19-7, lower-latin) ". ";
}
.lst-kix_list_9-2 > li {
  counter-increment: lst-ctn-kix_list_9-2;
}
ol.lst-kix_list_23-8.start {
  counter-reset: lst-ctn-kix_list_23-8 0;
}
.lst-kix_list_24-5 > li {
  counter-increment: lst-ctn-kix_list_24-5;
}
ol.lst-kix_list_21-5.start {
  counter-reset: lst-ctn-kix_list_21-5 0;
}
.lst-kix_list_20-5 > li {
  counter-increment: lst-ctn-kix_list_20-5;
}
.lst-kix_list_19-7 > li {
  counter-increment: lst-ctn-kix_list_19-7;
}
.lst-kix_list_14-3 > li {
  counter-increment: lst-ctn-kix_list_14-3;
}
ol.lst-kix_list_24-0.start {
  counter-reset: lst-ctn-kix_list_24-0 2;
}
.lst-kix_list_25-6 > li {
  counter-increment: lst-ctn-kix_list_25-6;
}
.lst-kix_list_21-6 > li {
  counter-increment: lst-ctn-kix_list_21-6;
}
.lst-kix_list_10-3 > li {
  counter-increment: lst-ctn-kix_list_10-3;
}
.lst-kix_list_18-0 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_18-1 > li:before {
  content: "" counter(lst-ctn-kix_list_18-1, lower-latin) ". ";
}
.lst-kix_list_18-2 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_26-0 > li {
  counter-increment: lst-ctn-kix_list_26-0;
}
li.li-bullet-2:before {
  margin-left: -36pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 36pt;
}
ol.lst-kix_list_25-4.start {
  counter-reset: lst-ctn-kix_list_25-4 0;
}
ol.lst-kix_list_14-2.start {
  counter-reset: lst-ctn-kix_list_14-2 0;
}
.lst-kix_list_23-4 > li {
  counter-increment: lst-ctn-kix_list_23-4;
}
ol.lst-kix_list_23-1.start {
  counter-reset: lst-ctn-kix_list_23-1 0;
}
.lst-kix_list_23-1 > li {
  counter-increment: lst-ctn-kix_list_23-1;
}
ul.lst-kix_list_22-0 {
  list-style-type: none;
}
.lst-kix_list_25-2 > li {
  counter-increment: lst-ctn-kix_list_25-2;
}
.lst-kix_list_2-7 > li:before {
  content: "o ";
}
.lst-kix_list_27-1 > li:before {
  content: "o ";
}
ul.lst-kix_list_22-1 {
  list-style-type: none;
}
ul.lst-kix_list_22-2 {
  list-style-type: none;
}
.lst-kix_list_24-2 > li {
  counter-increment: lst-ctn-kix_list_24-2;
}
ul.lst-kix_list_22-3 {
  list-style-type: none;
}
ul.lst-kix_list_22-4 {
  list-style-type: none;
}
.lst-kix_list_2-5 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_27-3 > li:before {
  content: "\0025cf ";
}
ul.lst-kix_list_22-5 {
  list-style-type: none;
}
ul.lst-kix_list_22-6 {
  list-style-type: none;
}
ul.lst-kix_list_22-7 {
  list-style-type: none;
}
.lst-kix_list_27-5 > li:before {
  content: "\0025aa ";
}
ol.lst-kix_list_10-3.start {
  counter-reset: lst-ctn-kix_list_10-3 0;
}
.lst-kix_list_18-6 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_9-4.start {
  counter-reset: lst-ctn-kix_list_9-4 0;
}
ul.lst-kix_list_3-7 {
  list-style-type: none;
}
.lst-kix_list_14-6 > li {
  counter-increment: lst-ctn-kix_list_14-6;
}
ul.lst-kix_list_3-8 {
  list-style-type: none;
}
.lst-kix_list_10-1 > li:before {
  content: "" counter(lst-ctn-kix_list_10-1, lower-latin) ". ";
}
.lst-kix_list_21-3 > li {
  counter-increment: lst-ctn-kix_list_21-3;
}
.lst-kix_list_18-4 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_18-8 > li:before {
  content: "\0025cf ";
}
ul.lst-kix_list_3-1 {
  list-style-type: none;
}
ul.lst-kix_list_22-8 {
  list-style-type: none;
}
ul.lst-kix_list_3-2 {
  list-style-type: none;
}
ul.lst-kix_list_3-0 {
  list-style-type: none;
}
ul.lst-kix_list_3-5 {
  list-style-type: none;
}
ul.lst-kix_list_3-6 {
  list-style-type: none;
}
ul.lst-kix_list_3-3 {
  list-style-type: none;
}
ul.lst-kix_list_3-4 {
  list-style-type: none;
}
.lst-kix_list_10-7 > li:before {
  content: "" counter(lst-ctn-kix_list_10-7, lower-latin) ". ";
}
.lst-kix_list_10-5 > li:before {
  content: "" counter(lst-ctn-kix_list_10-5, lower-roman) ". ";
}
li.li-bullet-1:before {
  margin-left: -36pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 36pt;
}
.lst-kix_list_10-3 > li:before {
  content: "" counter(lst-ctn-kix_list_10-3, decimal) ". ";
}
ul.lst-kix_list_11-7 {
  list-style-type: none;
}
ul.lst-kix_list_11-6 {
  list-style-type: none;
}
ol.lst-kix_list_20-8 {
  list-style-type: none;
}
ul.lst-kix_list_11-5 {
  list-style-type: none;
}
ul.lst-kix_list_11-4 {
  list-style-type: none;
}
ul.lst-kix_list_11-3 {
  list-style-type: none;
}
ol.lst-kix_list_20-5 {
  list-style-type: none;
}
ul.lst-kix_list_11-2 {
  list-style-type: none;
}
ol.lst-kix_list_20-4 {
  list-style-type: none;
}
ul.lst-kix_list_11-1 {
  list-style-type: none;
}
ol.lst-kix_list_20-7 {
  list-style-type: none;
}
ul.lst-kix_list_11-0 {
  list-style-type: none;
}
ol.lst-kix_list_20-6 {
  list-style-type: none;
}
.lst-kix_list_9-2 > li:before {
  content: "" counter(lst-ctn-kix_list_9-2, lower-roman) ". ";
}
ol.lst-kix_list_20-0 {
  list-style-type: none;
}
.lst-kix_list_26-4 > li {
  counter-increment: lst-ctn-kix_list_26-4;
}
ol.lst-kix_list_20-3 {
  list-style-type: none;
}
ol.lst-kix_list_20-2 {
  list-style-type: none;
}
ol.lst-kix_list_14-0.start {
  counter-reset: lst-ctn-kix_list_14-0 0;
}
ul.lst-kix_list_11-8 {
  list-style-type: none;
}
.lst-kix_list_20-8 > li:before {
  content: "" counter(lst-ctn-kix_list_20-8, lower-roman) ". ";
}
.lst-kix_list_9-0 > li:before {
  content: "" counter(lst-ctn-kix_list_9-0, decimal) ". ";
}
.lst-kix_list_29-7 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_29-5 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_20-0 > li:before {
  content: "" counter(lst-ctn-kix_list_20-0, lower-latin) ". ";
}
ol.lst-kix_list_10-7 {
  list-style-type: none;
}
.lst-kix_list_9-6 > li:before {
  content: "" counter(lst-ctn-kix_list_9-6, decimal) ". ";
}
ol.lst-kix_list_24-3.start {
  counter-reset: lst-ctn-kix_list_24-3 0;
}
ol.lst-kix_list_10-8 {
  list-style-type: none;
}
ol.lst-kix_list_10-3 {
  list-style-type: none;
}
.lst-kix_list_9-4 > li:before {
  content: "" counter(lst-ctn-kix_list_9-4, lower-latin) ". ";
}
ol.lst-kix_list_10-4 {
  list-style-type: none;
}
.lst-kix_list_23-5 > li {
  counter-increment: lst-ctn-kix_list_23-5;
}
ol.lst-kix_list_10-5 {
  list-style-type: none;
}
.lst-kix_list_11-3 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_10-6 {
  list-style-type: none;
}
.lst-kix_list_20-6 > li:before {
  content: "" counter(lst-ctn-kix_list_20-6, decimal) ". ";
}
.lst-kix_list_23-0 > li {
  counter-increment: lst-ctn-kix_list_23-0;
}
ol.lst-kix_list_10-0 {
  list-style-type: none;
}
ol.lst-kix_list_10-1 {
  list-style-type: none;
}
ul.lst-kix_list_2-8 {
  list-style-type: none;
}
ol.lst-kix_list_10-2 {
  list-style-type: none;
}
.lst-kix_list_29-1 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_20-4 > li:before {
  content: "" counter(lst-ctn-kix_list_20-4, lower-latin) ". ";
}
.lst-kix_list_11-5 > li:before {
  content: "\0025aa ";
}
ul.lst-kix_list_2-2 {
  list-style-type: none;
}
.lst-kix_list_29-3 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_20-2 > li:before {
  content: "" counter(lst-ctn-kix_list_20-2, lower-roman) ". ";
}
ol.lst-kix_list_21-2.start {
  counter-reset: lst-ctn-kix_list_21-2 0;
}
ul.lst-kix_list_2-3 {
  list-style-type: none;
}
ul.lst-kix_list_2-0 {
  list-style-type: none;
}
ul.lst-kix_list_2-1 {
  list-style-type: none;
}
.lst-kix_list_9-8 > li:before {
  content: "" counter(lst-ctn-kix_list_9-8, lower-roman) ". ";
}
ul.lst-kix_list_2-6 {
  list-style-type: none;
}
.lst-kix_list_20-6 > li {
  counter-increment: lst-ctn-kix_list_20-6;
}
.lst-kix_list_1-1 > li:before {
  content: "o ";
}
ul.lst-kix_list_2-7 {
  list-style-type: none;
}
.lst-kix_list_11-7 > li:before {
  content: "o ";
}
ul.lst-kix_list_2-4 {
  list-style-type: none;
}
ul.lst-kix_list_2-5 {
  list-style-type: none;
}
ol.lst-kix_list_25-7.start {
  counter-reset: lst-ctn-kix_list_25-7 0;
}
.lst-kix_list_1-3 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_10-5.start {
  counter-reset: lst-ctn-kix_list_10-5 0;
}
.lst-kix_list_28-3 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_28-7 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_21-8 {
  list-style-type: none;
}
.lst-kix_list_1-7 > li:before {
  content: "o ";
}
ol.lst-kix_list_21-7 {
  list-style-type: none;
}
ol.lst-kix_list_21-4 {
  list-style-type: none;
}
.lst-kix_list_1-5 > li:before {
  content: "\0025aa ";
}
ol.lst-kix_list_21-3 {
  list-style-type: none;
}
.lst-kix_list_28-5 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_21-6 {
  list-style-type: none;
}
ol.lst-kix_list_9-1.start {
  counter-reset: lst-ctn-kix_list_9-1 0;
}
.lst-kix_list_14-7 > li {
  counter-increment: lst-ctn-kix_list_14-7;
}
ol.lst-kix_list_21-5 {
  list-style-type: none;
}
ol.lst-kix_list_24-2.start {
  counter-reset: lst-ctn-kix_list_24-2 0;
}
ol.lst-kix_list_21-0 {
  list-style-type: none;
}
ol.lst-kix_list_21-2 {
  list-style-type: none;
}
ol.lst-kix_list_21-1 {
  list-style-type: none;
}
.lst-kix_list_2-1 > li:before {
  content: "o ";
}
.lst-kix_list_27-7 > li:before {
  content: "o ";
}
.lst-kix_list_25-7 > li {
  counter-increment: lst-ctn-kix_list_25-7;
}
.lst-kix_list_19-8 > li {
  counter-increment: lst-ctn-kix_list_19-8;
}
.lst-kix_list_2-3 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_28-1 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_19-6 {
  list-style-type: none;
}
ol.lst-kix_list_19-7 {
  list-style-type: none;
}
ol.lst-kix_list_24-8.start {
  counter-reset: lst-ctn-kix_list_24-8 0;
}
ol.lst-kix_list_19-8 {
  list-style-type: none;
}
.lst-kix_list_30-3 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_19-2 {
  list-style-type: none;
}
.lst-kix_list_26-8 > li {
  counter-increment: lst-ctn-kix_list_26-8;
}
ol.lst-kix_list_19-3 {
  list-style-type: none;
}
ol.lst-kix_list_19-4 {
  list-style-type: none;
}
.lst-kix_list_30-4 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_30-8 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_19-5 {
  list-style-type: none;
}
.lst-kix_list_20-8 > li {
  counter-increment: lst-ctn-kix_list_20-8;
}
ol.lst-kix_list_19-0 {
  list-style-type: none;
}
.lst-kix_list_9-1 > li {
  counter-increment: lst-ctn-kix_list_9-1;
}
ol.lst-kix_list_19-1 {
  list-style-type: none;
}
.lst-kix_list_3-1 > li:before {
  content: "o ";
}
.lst-kix_list_3-2 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_26-6 > li:before {
  content: "" counter(lst-ctn-kix_list_26-6, decimal) ". ";
}
.lst-kix_list_26-7 > li:before {
  content: "" counter(lst-ctn-kix_list_26-7, lower-latin) ". ";
}
.lst-kix_list_14-0 > li {
  counter-increment: lst-ctn-kix_list_14-0;
}
.lst-kix_list_8-1 > li:before {
  content: "o ";
}
.lst-kix_list_8-2 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_3-5 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_30-7 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_21-2 > li:before {
  content: "" counter(lst-ctn-kix_list_21-2, lower-roman) ". ";
}
.lst-kix_list_8-5 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_8-6 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_26-2 > li:before {
  content: "" counter(lst-ctn-kix_list_26-2, lower-roman) ". ";
}
.lst-kix_list_26-3 > li:before {
  content: "" counter(lst-ctn-kix_list_26-3, decimal) ". ";
}
.lst-kix_list_3-6 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_21-6 > li:before {
  content: "" counter(lst-ctn-kix_list_21-6, decimal) ". ";
}
.lst-kix_list_21-7 > li:before {
  content: "" counter(lst-ctn-kix_list_21-7, lower-latin) ". ";
}
.lst-kix_list_11-2 > li:before {
  content: "\0025aa ";
}
ol.lst-kix_list_16-0.start {
  counter-reset: lst-ctn-kix_list_16-0 0;
}
.lst-kix_list_21-3 > li:before {
  content: "" counter(lst-ctn-kix_list_21-3, decimal) ". ";
}
.lst-kix_list_25-5 > li {
  counter-increment: lst-ctn-kix_list_25-5;
}
.lst-kix_list_16-6 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_10-1.start {
  counter-reset: lst-ctn-kix_list_10-1 0;
}
.lst-kix_list_25-2 > li:before {
  content: "" counter(lst-ctn-kix_list_25-2, lower-roman) ". ";
}
.lst-kix_list_17-1 > li:before {
  content: "o ";
}
.lst-kix_list_25-3 > li:before {
  content: "" counter(lst-ctn-kix_list_25-3, decimal) ". ";
}
.lst-kix_list_16-1 > li:before {
  content: "o ";
}
.lst-kix_list_25-0 > li {
  counter-increment: lst-ctn-kix_list_25-0;
}
.lst-kix_list_16-2 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_26-6 > li {
  counter-increment: lst-ctn-kix_list_26-6;
}
.lst-kix_list_16-5 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_19-3 > li {
  counter-increment: lst-ctn-kix_list_19-3;
}
.lst-kix_list_23-7 > li {
  counter-increment: lst-ctn-kix_list_23-7;
}
ol.lst-kix_list_10-2.start {
  counter-reset: lst-ctn-kix_list_10-2 0;
}
.lst-kix_list_17-2 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_30-0 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_17-6 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_17-5 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_2-6 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_27-2 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_22-3 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_14-5 > li {
  counter-increment: lst-ctn-kix_list_14-5;
}
.lst-kix_list_7-1 > li:before {
  content: "o ";
}
.lst-kix_list_7-5 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_9-6 > li {
  counter-increment: lst-ctn-kix_list_9-6;
}
.lst-kix_list_27-6 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_19-5 > li {
  counter-increment: lst-ctn-kix_list_19-5;
}
.lst-kix_list_22-7 > li:before {
  content: "o ";
}
ol.lst-kix_list_23-3.start {
  counter-reset: lst-ctn-kix_list_23-3 0;
}
.lst-kix_list_23-2 > li {
  counter-increment: lst-ctn-kix_list_23-2;
}
.lst-kix_list_18-5 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_13-6 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_20-3 > li {
  counter-increment: lst-ctn-kix_list_20-3;
}
.lst-kix_list_25-3 > li {
  counter-increment: lst-ctn-kix_list_25-3;
}
.lst-kix_list_26-3 > li {
  counter-increment: lst-ctn-kix_list_26-3;
}
.lst-kix_list_10-6 > li {
  counter-increment: lst-ctn-kix_list_10-6;
}
ul.lst-kix_list_30-3 {
  list-style-type: none;
}
ol.lst-kix_list_10-0.start {
  counter-reset: lst-ctn-kix_list_10-0 0;
}
ul.lst-kix_list_30-4 {
  list-style-type: none;
}
ul.lst-kix_list_30-5 {
  list-style-type: none;
}
.lst-kix_list_15-6 > li:before {
  content: "\0025cf ";
}
ul.lst-kix_list_30-6 {
  list-style-type: none;
}
ol.lst-kix_list_24-7.start {
  counter-reset: lst-ctn-kix_list_24-7 0;
}
ul.lst-kix_list_30-7 {
  list-style-type: none;
}
ul.lst-kix_list_30-8 {
  list-style-type: none;
}
ul.lst-kix_list_30-0 {
  list-style-type: none;
}
.lst-kix_list_10-2 > li:before {
  content: "" counter(lst-ctn-kix_list_10-2, lower-roman) ". ";
}
ul.lst-kix_list_30-1 {
  list-style-type: none;
}
ul.lst-kix_list_30-2 {
  list-style-type: none;
}
.lst-kix_list_20-7 > li:before {
  content: "" counter(lst-ctn-kix_list_20-7, lower-latin) ". ";
}
.lst-kix_list_4-2 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_4-6 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_23-6.start {
  counter-reset: lst-ctn-kix_list_23-6 0;
}
.lst-kix_list_15-2 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_10-8 > li {
  counter-increment: lst-ctn-kix_list_10-8;
}
.lst-kix_list_26-1 > li {
  counter-increment: lst-ctn-kix_list_26-1;
}
.lst-kix_list_25-6 > li:before {
  content: "" counter(lst-ctn-kix_list_25-6, decimal) ". ";
}
.lst-kix_list_10-6 > li:before {
  content: "" counter(lst-ctn-kix_list_10-6, decimal) ". ";
}
.lst-kix_list_9-1 > li:before {
  content: "" counter(lst-ctn-kix_list_9-1, lower-latin) ". ";
}
.lst-kix_list_9-5 > li:before {
  content: "" counter(lst-ctn-kix_list_9-5, lower-roman) ". ";
}
.lst-kix_list_29-6 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_24-0 > li {
  counter-increment: lst-ctn-kix_list_24-0;
}
.lst-kix_list_12-2 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_11-6 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_20-3 > li:before {
  content: "" counter(lst-ctn-kix_list_20-3, decimal) ". ";
}
.lst-kix_list_1-2 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_29-2 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_23-5.start {
  counter-reset: lst-ctn-kix_list_23-5 0;
}
.lst-kix_list_21-1 > li {
  counter-increment: lst-ctn-kix_list_21-1;
}
.lst-kix_list_10-1 > li {
  counter-increment: lst-ctn-kix_list_10-1;
}
.lst-kix_list_28-6 > li:before {
  content: "\0025cf ";
}
.lst-kix_list_1-6 > li:before {
  content: "\0025cf ";
}
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_list_12-6 > li:before {
  content: "\0025cf ";
}
ol.lst-kix_list_23-4.start {
  counter-reset: lst-ctn-kix_list_23-4 0;
}
.lst-kix_list_2-2 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_13-2 > li:before {
  content: "\0025aa ";
}
.lst-kix_list_28-2 > li:before {
  content: "\0025cf ";
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c14 {
  margin-left: 18pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c20 {
  -webkit-text-decoration-skip: none;
  color: #000000;
  font-weight: 400;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;

  font-style: normal;
}
.c18 {
  -webkit-text-decoration-skip: none;
  color: #0563c1;
  font-weight: 400;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;

  font-style: normal;
}
.c12 {
  margin-left: 18pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c19 {
  margin-left: 54pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c16 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c9 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 10pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c25 {
  margin-left: 18pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c10 {
  margin-left: 18pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c3 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c2 {
  margin-left: 54pt;
  padding-top: 0pt;
  padding-left: 18pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c15 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c4 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}

.c44 {
  padding-top: 14pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c0 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;

  font-style: normal;
}
.c26 {
  margin-left: 18pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c37 {
  -webkit-text-decoration-skip: none;
  color: #0000ff;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;

  font-style: normal;
}
.c6 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;

  font-style: normal;
}
.c5 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c36 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin: 2rem 0;
}
.c39 {
  padding-top: 0pt;
  padding-bottom: 7.5pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c54 {
  padding-top: 14pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c55 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c45 {
  padding-top: 0pt;
  padding-bottom: 10pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c48 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c30 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c13 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c49 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c27 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c46 {
  padding-top: 14pt;
  padding-bottom: 14pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c43 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c50 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c35 {
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c47 {
  color: #2c2e2f;
  text-decoration: none;
  vertical-align: baseline;

  font-style: normal;
}
.c24 {
  color: #313131;
  text-decoration: none;
  vertical-align: baseline;

  font-style: normal;
}
.c41 {
  color: #333333;
  text-decoration: none;
  vertical-align: baseline;

  font-style: normal;
}
.c56 {
  color: #242424;
  text-decoration: none;
  vertical-align: baseline;

  font-style: normal;
}
.c34 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;

  font-style: normal;
}
.c23 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
}
.c38 {
  -webkit-text-decoration-skip: none;
  color: #0563c1;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.c33 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
}
.c52 {
  vertical-align: baseline;

  font-style: normal;
}
.c42 {
  margin-left: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
}
.c51 {
  font-weight: 400;
  font-style: normal;
}
.c21 {
  font-style: italic;
  font-weight: 400;
}
.c7 {
  margin-left: 18pt;
  height: 11pt;
}
.c1 {
  color: inherit;
  text-decoration: inherit;
}
.c17 {
  margin-left: 36pt;
  height: 11pt;
}
.c22 {
  font-weight: 400;
}
.c40 {
  max-width: 482.4pt;
  padding: 64.8pt 64.8pt 64.8pt 64.8pt;
}
.c11 {
  font-weight: 700;
}
.c8 {
  padding: 0;
  margin: 0;
}
.c29 {
}
.c53 {
  padding-left: 0pt;
}
.c32 {
  height: 11pt;
}
.c31 {
  margin-left: 18pt;
}
.c28 {
  margin-left: 36pt;
}
.title {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;

  padding-bottom: 3pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.subtitle {
  padding-top: 18pt;
  color: #666666;
  padding-bottom: 4pt;
  line-height: 1.1500000000000001;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
}
p {
  margin: 0;
  color: #000000;
}
h1 {
  padding-top: 0pt;
  color: #000000;

  padding-bottom: 12pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 0pt;
  color: #000000;

  padding-bottom: 12pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 0pt;
  color: #000000;

  padding-bottom: 12pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 0pt;
  color: #000000;

  padding-bottom: 12pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 0pt;
  color: #000000;

  padding-bottom: 12pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 0pt;
  color: #000000;

  padding-bottom: 12pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>

<template>
  <div :class="globalSectionClass">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: function () {
        return "default";
      },
      required: false,
    },
  },
  computed: {
    globalSectionClass: function () {
      var classNames = "global-section";
      if (this.type) {
        classNames += " global-section-" + this.type;
      }
      return classNames;
    },
  },
};
</script>

<style scoped>
.global-section {
  @apply py-12 md:py-16 lg:py-24;
}
.global-section-light {
  @apply bg-creme-100;
}
.global-section-dark {
  @apply bg-creme-200;
}
.global-section-50 {
  @apply bg-creme-50;
}

.global-section-100 {
  @apply bg-creme-100;
}

.global-section-200 {
  @apply bg-creme-200;
}

.global-section-300 {
  @apply bg-creme-300;
}

.global-section-founder {
  /* background: linear-gradient(180deg, #fcfbf8 0%, #f5f0e9 100%); */
  @apply bg-creme-200;
  padding-bottom: 0;
}

.global-section-bottom-only {
  padding-top: 0;
}
.global-section-top-only {
  padding-bottom: 0;
}

.global-section-top-only-light {
  padding-bottom: 0;
  @apply bg-creme-100;
}
</style>

<template>
  <div class="custom-marker-wrapper" :class="getMarkerStatus">
    <div class="content" :class="getMarkerStatus">
      <div class="lot-title" :class="getMarkerStatus">
        {{ currentLot.shortName }}
      </div>
      <div
        v-if="getMarkerStatus === AVAILABLE || getMarkerStatus === SELECTED"
        class="lot-info"
        :class="getMarkerStatus"
      >
        <span
          >{{ roundNumberToShorthandCurrency(currentLot.house.price) }}
          total
        </span>
        <span>{{ currentLot.lotArea }}</span>
      </div>
      <div
        v-if="getMarkerStatus === UNAVAILABLE"
        class="lot-info"
        :class="getMarkerStatus"
      >
        <span>'{{ selectedHouseModel.name }}'</span>
        <span>unavailable</span>
      </div>
      <div
        v-if="getMarkerStatus === RESERVED"
        class="lot-info"
        :class="getMarkerStatus"
      >
        reserved
      </div>
      <div
        v-if="getMarkerStatus === SOLD"
        class="lot-info"
        :class="getMarkerStatus"
      >
        <span>sold</span>
      </div>
    </div>
    <div class="downward-triangle" :class="getMarkerStatus" />
    <div class="marker-dot" :class="getMarkerStatus" />
  </div>
</template>

<script>
import { roundNumberToShorthandCurrency } from "@/helpers/currencyFormaterHelper.js";
import { getLotStatus } from "@/helpers/lotStatusMapResolver.js";
import {
  AVAILABLE,
  SELECTED,
  UNAVAILABLE,
  SOLD,
  RESERVED,
  SELF_RESERVED,
} from "@/constants";

export default {
  props: {
    currentLot: {
      type: Object,
      default: () => [],
    },
    selectedLot: {
      type: Object,
      default: () => {},
    },
    selectedHouseModel: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  data() {
    return { AVAILABLE, SELECTED, UNAVAILABLE, SOLD, RESERVED, SELF_RESERVED };
  },

  computed: {
    getMarkerStatus() {
      // For now hardcoded/sameish values until we get real data from the BE
      return getLotStatus(
        this.currentLot,
        this.selectedLot.id,
        this.selectedHouseModel.id
      );
    },
  },
  methods: {
    roundNumberToShorthandCurrency,
  },
};
</script>

<style scoped>
/* Whole marker wrapper */
.custom-marker-wrapper {
  /* Fix marker position */
  @apply top-[-60px];
}

.custom-marker-wrapper.selected {
  /* Fix marker position */
  @apply top-[-85px];
}

.custom-marker-wrapper:hover .downward-triangle.available {
  @apply bg-creme-300;
}

.custom-marker-wrapper:hover .content.available {
  @apply bg-creme-300;
}

.custom-marker-wrapper:hover .marker-dot.available {
  @apply bg-creme-300;
}

/* Content of the marker (rectangle) */
.content {
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* TODO - goes over the triangle */
  @apply rounded-4 bg-creme-50 border-2 border-black border-b-0 flex flex-col justify-center items-center h-[80px] w-[86px] z-10 relative;
}

.content.unavailable {
  @apply bg-creme-500;
}

.content.selected {
  @apply bg-yellow-500 h-[112px] w-[114px];
}

.content.reserved {
  @apply bg-black h-[62px];
}

.content.self-reserved {
  @apply bg-yellow-500 h-[62px];
}

.content.sold {
  @apply bg-black h-[62px];
}

/* Marker title */
.lot-title {
  @apply text-h4 font-semibold;
}

.lot-title.selected {
  @apply text-h3;
}

.lot-title.reserved {
  @apply text-white;
}

.lot-title.self-reserved {
  @apply mt-4;
}

.lot-title.sold {
  @apply text-white;
}

/* Marker subtitle */
.lot-info {
  @apply text-body-3 flex flex-col items-center mt-1;
}

.lot-info.selected {
  @apply text-body-2;
}

.lot-info.reserved {
  @apply font-extrabold text-white uppercase;
}

.lot-info.sold {
  @apply font-extrabold text-white uppercase;
}

/* Triangle on the bottom of the marker */
.downward-triangle {
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); TODO*/
  transform: rotate(-32.5deg) skewX(25deg) scaleY(0.93); /* scaled to fit under content */
  @apply bg-creme-50 border-2 border-black rounded-4 absolute w-[52px] h-[52px] top-[53px] left-[17px] z-[5];
}

.downward-triangle.unavailable {
  @apply bg-creme-500;
}

.downward-triangle.selected {
  @apply bg-yellow-500 top-[76px] left-[22px] w-[69px] h-[69px];
}

.downward-triangle.reserved {
  @apply bg-black top-[35px] left-[17px];
}

.downward-triangle.self-reserved {
  @apply bg-yellow-500 top-[35px] left-[17px];
}
.downward-triangle.sold {
  @apply bg-black top-[35px] left-[17px];
}

/* Marker dot */
.marker-dot {
  border-radius: 16px / 10px;
  @apply absolute w-[33px] h-[20px] bg-creme-50 z-0 left-[27px] bottom-[-36px] border-2 border-black;
}

.marker-dot.unavailable {
  @apply bg-creme-500;
}

.marker-dot.selected {
  @apply bg-yellow-500 left-[41px] bottom-[-45px];
}

.marker-dot.reserved {
  @apply bg-creme-200 left-[27px] bottom-[-35px];
}

.marker-dot.self-reserved {
  @apply bg-yellow-500 left-[27px] bottom-[-35px];
}

.marker-dot.sold {
  @apply bg-creme-200 left-[27px] bottom-[-35px];
}
</style>

<template>
  <div class="text-gray-900">
    <div class="flex flex-col justify-center">
      <div class="title">Forgot password?</div>
      <div class="mt-2">
        We have sent you the verification code to your email
      </div>
    </div>
    <form @submit.prevent="submit" class="flex flex-col mt-16">
      <div class="label">Verification code</div>
      <TextInput
        placeholder="Code"
        inputType="text"
        :required="true"
        v-model="verificationCode"
      />

      <div class="separator"></div>
      <div class="label">New Password</div>
      <TextInput
        placeholder="Enter"
        inputType="password"
        :required="true"
        v-model="newPassword"
      />

      <div
        v-if="errorMessage"
        class="flex flex-col mt-8 text-red-600 text-body-1"
      >
        {{ errorMessage }}
      </div>
      <div
        class="flex flex-col-reverse md:flex-row items-center justify-between my-12"
      >
        <div class="text-body-2 pt-3 md:pt-0 text-center">
          Go back to
          <span
            class="text-blue-500 font-semibold cursor-pointer"
            @click="showLogin()"
          >
            Log in
          </span>
        </div>

        <PrimaryBaseCta
          class="min-w-[148px]"
          text="Reset password"
          eventName="submit"
          fixedSize="small"
          :disabled="disabled"
          @click="submit"
        />
      </div>
    </form>
  </div>
</template>
<script>
import { TextInput, PrimaryBaseCta } from "@/components";
import { Auth } from "aws-amplify";

export default {
  name: "ForgotPassword",
  components: {
    TextInput,
    PrimaryBaseCta,
  },
  props: {
    email: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      verificationCode: "",
      newPassword: "",
      disabled: false,
      errorMessage: null,
    };
  },
  methods: {
    async submit() {
      try {
        if (
          this.email !== "" &&
          this.verificationCode !== "" &&
          this.newPassword !== ""
        ) {
          this.disabled = true;
          const result = await Auth.forgotPasswordSubmit(
            this.email,
            this.verificationCode,
            this.newPassword
          );

          if (result === "SUCCESS") {
            this.showLogin();
          } else {
            throw new Error("Problem submiting verification code", result);
          }
          this.disabled = false;
        }
      } catch (e) {
        this.errorMessage = e.message;
        this.disabled = false;
      }
    },
    showLogin() {
      this.$emit("showLogin");
    },
  },
};
</script>
<style scoped>
.title {
  @apply text-h3 font-semibold;
}
.label {
  @apply text-body-1 font-semibold pb-3;
}
.separator {
  @apply mt-8 xl:mt-12;
}
.login-text {
  @apply text-body-2 font-semibold;
}
</style>

<template>
  <div class="max-container">
    <BasicSpinner v-if="loading" class="h-full m-auto" />
    <div v-else class="main-grid">
      <h2
        class="col-span-full lg:col-start-2 lg:col-span-10 text-h3 lg:text-h2 font-semibold text-center"
      >
        Explore more homes <br />
        in Holly Springs
      </h2>

      <p class="col-span-full lg:col-start-2 lg:col-span-10 mx-auto mb-16">
        <PrimaryBaseCta
          type="link"
          text="The Estates at Holly Springs"
          endImage="icons/arrow_right_blue.svg"
          @click="$router.push({ path: '/locations/hollysprings' })"
        />
      </p>

      <div
        class="col-span-full lg:col-span-3 lg:col-start-2 text-body-2 lg:text-body-1"
      >
        <p class="mb-8">
          The Estates at Holly Springs houses HomeFounder's first home
          opportunities. Located in Douglasville, GA, it has extensive amenities
          and is conveniently located just 30 minutes away from downtown
          Atlanta.
        </p>

        <p class="mb-8">Explore available homes to find your perfect place.</p>

        <PrimaryBaseCta
          type="link"
          text="Download brochure"
          fixedSize="medium"
          endImage="icons/arrow_down_blue.svg"
        />
      </div>

      <div
        v-if="houseModels && houseModels.length"
        class="col-span-full lg:col-span-7 lg:col-start-5 mt-4 lg:mt-0 flex flex-row items-center lg:items-start justify-center md:justify-around lg:justify-between flex-wrap"
      >
        <div v-for="house in houseModels" :key="house.id" class="house-card">
          <router-link :to="house.houseSlug">
            <div class="rounded-t-16 h-[185px] overflow-hidden relative">
              <img
                v-if="house.houseImages.length"
                :src="house.houseImages[0].src"
                :alt="house.houseImages[0].alt"
                class="h-full w-full absolute top-0 object-cover"
              />
              <div
                v-if="house.availability === RESERVED"
                class="reserved-badge"
              >
                Reserved
              </div>
              <div v-if="house.availability === SOLD" class="sold-badge">
                Sold
              </div>
            </div>

            <div class="p-6 w-full">
              <div class="flex flex-row justify-between relative w-full">
                <div class="text-body-2">
                  <div class="text-body-1 font-semibold mb-2">
                    {{ house.name }} ({{ house.lot.name }})
                  </div>
                  <div>{{ house.address }}</div>
                  <div class="mt-4">
                    <span class="pr-6 lg:pr-4">
                      <b>{{ house.lot.size }}</b> ac
                    </span>
                    <span class="pr-6 lg:pr-4">
                      <b>{{ house.squareFootage }}</b> sqft
                    </span>
                    <span class="pr-6 lg:pr-4">
                      <b>{{ house.bedrooms }}</b> bds
                    </span>
                    <span class="pr-6 lg:pr-4">
                      <b>{{ house.bathrooms }}</b> ba
                    </span>
                  </div>
                </div>

                <img
                  class="absolute top-0 right-0 rounded-16 w-[60px] h-[60px]"
                  :src="house.lot.image"
                  alt="House lot image"
                />
              </div>
              <div class="mt-7 flex flex-row justify-between">
                <div class="flex flex-row">
                  <div class="mr-4 lg:mr-5">
                    <div class="text-body-3">Guaranteed price</div>
                    <span class="font-semibold text-body-2">{{
                      $currencyNoDecimal.format(house.costs.price)
                    }}</span>
                  </div>
                  <div>
                    <div class="text-body-3">Independent appraisal*</div>
                    <div>
                      <span class="text-body-2 font-semibold mr-2 mb-3">
                        {{ $currencyNoDecimal.format(house.costs.value) }}</span
                      >
                      <span
                        class="text-body-3 bg-yellow-500 font-semibold py-1 px-2 rounded-8 h-[fit-content] whitespace-nowrap"
                      >
                        Save
                        {{
                          roundNumberToShorthandCurrency(
                            house.costs.value - house.costs.price
                          )
                        }}+
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <div
        class="col-span-full lg:col-span-10 lg:col-start-2 text-center lg:text-right text-body-3 mt-12"
      >
        *As evaluated by a third-party appraiser. Homes will be re-appraised
        during underwriting.
      </div>
    </div>
  </div>
</template>

<script>
import { roundNumberToShorthandCurrency } from "@/helpers/currencyFormaterHelper.js";
import { PrimaryBaseCta } from "@/components/buttons";
import { getLocation } from "@/mocks/useLocationsModel.js";
import { BasicSpinner } from "@/components";
import { RESERVED, SOLD } from "@/constants";

export default {
  name: "Explore",
  components: {
    PrimaryBaseCta,
    BasicSpinner,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
    houseName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      location: null,
      loading: true,
      RESERVED,
      SOLD,
    };
  },
  computed: {
    houseModels() {
      return (
        this.location.houseModels.models.filter(
          (model) => model.name !== this.houseName
        ) || []
      );
    },
  },
  methods: {
    roundNumberToShorthandCurrency,
    getLocation,
    async fetchHouseModels() {
      try {
        const data = await getLocation(this.slug);

        if (data) {
          this.loading = false;
          this.location = data;
        }
      } catch (error) {
        // TODO: Handle error fetching data
        console.error("Error fetching data");
      }
    },
  },
  created() {
    this.fetchHouseModels();
  },
};
</script>

<style scoped>
.house-card {
  @apply text-body-1 rounded-16 bg-creme-50 flex flex-col items-center flex-wrap max-w-[330px] lg:w-[49%] mb-8 overflow-hidden;
}

.house-card img:first-child {
  transform-origin: 50% 50%;
  transition: transform 0.5s ease-in-out;
}

.house-card > a:hover {
  transition: background-color 0.5s ease-in-out;
  @apply bg-creme-300;
}

.house-card:hover img:first-child {
  transform: scale(1.1);
}

hr {
  @apply w-full my-12 h-[2px];
}

.reserved-badge {
  @apply bg-gray-900 text-creme-50 absolute top-[30px] right-[-75px] z-10 font-semibold text-body-2 h-10 w-64 flex justify-center items-center rotate-45;
}
.sold-badge {
  @apply bg-yellow-500 absolute top-[30px] right-[-75px] z-10 font-semibold text-body-2 h-10 w-64 flex justify-center items-center rotate-45;
}
</style>

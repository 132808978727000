<template>
  <footer class="bg-creme-300">
    <div class="p-8 md:p-16 lg:p-16">
      <div class="flex flex-col lg:flex-row justify-between">
        <div>
          <img src="@/assets/logo-monochrome.svg" alt="Company logo" />
        </div>

        <div class="flex flex-wrap justify-between">
          <div class="footer-links top-spacing">
            <h4>HELP</h4>
            <router-link to="/faq">FAQ</router-link>
            <router-link to="/contact">Contact us</router-link>
          </div>
          <div class="footer-links top-spacing">
            <h4>COMPANY</h4>
            <router-link to="/about">About us</router-link>
            <router-link to="/privacy">Privacy policy</router-link>
            <router-link to="/terms">Terms of service</router-link>
            <router-link to="/cookies">Cookie policy</router-link>
          </div>
        </div>
      </div>

      <div class="top-spacing text-body-2">
        © {{ year }} HomeFounder. All Rights Reserved
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
footer {
  @apply flex flex-col justify-between text-gray-900;
}

.top-spacing {
  @apply mt-12 lg:mt-0;
}

.footer-links {
  @apply sm:w-1/2 md:w-auto lg:w-auto xl:w-auto;
}

.footer-links:not(:last-child) {
  @apply mr-3 md:mr-24 sm:mr-0;
}

.footer-links a {
  @apply text-body-2 font-semibold mt-4 block;
}

h4 {
  letter-spacing: 0.02em;
  @apply uppercase text-body-3;
}
</style>

<template>
  <div class="carousel-wrapper" :class="{ 'shrink-carousel': shrinkCarousel }">
    <div class="h-full flex flex-col">
      <div id="carousel-header">
        <div
          class="h-full flex justify-between max-w-[1312px] items-center lg:mx-auto px-3 sm:px-6 lg:px-12"
        >
          <div class="md:w-[128px]">
            <PrimaryImageOnlyBaseCta
              image="icons/close_white.svg"
              type="tertiary"
              :dark="true"
              class="border-white"
              @click="internalClose"
            />
          </div>
          <div class="flex flex-row justify-center">
            <!-- TODO Implement selecting of houses -->
            <p class="text-h4 text-center text-creme-300 mr-4 cursor-pointer">
              "{{ selectedHouse.name }}"
            </p>
            <img
              class="cursor-pointer"
              src="@/assets/icons/arrow_down_white.svg"
              alt="Arrow down"
            />
          </div>
          <div
            class="flex justify-end cursor-pointer"
            @click="toggleHomeDetailsModal"
          >
            <div
              class="text-creme-300 text-base font-semibold hidden md:block mr-1"
            >
              Home details
            </div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM4 12.172C4.04732 16.5732 7.64111 20.1095 12.0425 20.086C16.444 20.0622 19.9995 16.4875 19.9995 12.086C19.9995 7.68451 16.444 4.10977 12.0425 4.086C7.64111 4.06246 4.04732 7.59876 4 12V12.172ZM14 17H11V13H10V11H13V15H14V17ZM13 9H11V7H13V9Z"
                fill="#EAE4D6"
              />
            </svg>
          </div>
        </div>
      </div>
      <div id="carousel-content">
        <Swiper
          ref="mySwiper"
          :options="swiperOptions"
          @slideChange="setActiveIndex"
        >
          <SwiperSlide :key="image.id" v-for="image in modalImages">
            <div class="swiper-zoom-container max-h-[80%]">
              <img
                class="max-h-full mx-auto"
                :src="image.src"
                :alt="image.name"
              />
            </div>
          </SwiperSlide>
          <div
            class="swiper-pagination"
            :class="isLandscape ? 'hidden' : ''"
            slot="pagination"
          ></div>
        </Swiper>
      </div>
      <div id="carousel-footer">
        <div
          class="w-full flex items-center justify-center flex-col lg:flex-row max-w-[1312px] lg:mx-auto"
        >
          <div class="text-white px-6 lg:px-0">
            <div class="lg:ml-12 text-center lg:text-left">
              <div class="text-h4 font-semibold mb-2 lg:mb-0">Outside</div>
              <div
                class="flex flex-row justify-center lg:justify-start mb-4 lg:mb-0 max-w-[80%] m-auto lg:m-0"
              >
                <div class="shortened-text">
                  We used the best materials for exterior, starting with...
                </div>
                <span
                  class="font-semibold cursor-pointer hover:underline min-w-max"
                  @click="toggleDetailsModal"
                  >Read More</span
                >
              </div>
            </div>
          </div>
          <div
            class="lg:ml-auto h-full text-white"
            :class="isLandscape ? 'hidden' : ''"
          >
            <div
              class="flex flex-row h-full justify-center items-center text-body-2 lg:mr-12"
            >
              <div
                class="mr-5 images-button"
                :class="activeTab === 'photos' ? 'images-button-active' : ''"
                @click="swipeToIndex(0)"
              >
                Photos
              </div>
              <div
                class="images-button"
                :class="
                  activeTab === 'floorplans' ? 'images-button-active' : ''
                "
                @click="swipeToIndex(floorplanImagesStartIndex)"
              >
                Floorplans
              </div>
            </div>
          </div>
        </div>
      </div>
      <DetailsModal v-if="showHomeDetailsModal" @close="toggleHomeDetailsModal">
        <h2 class="text-h3 lg:text-h2 font-semibold mb-4 mt-6">
          {{ selectedHouse.name }}
        </h2>

        <p class="text-body-2">
          This new two-story home design will suit households to a T. Included
          on the first floor are a living room with a fireplace, a breakfast
          room, a formal dining room and a bedroom that could serve as a guest
          room. The patio is perfect for sipping sweet tea. Upstairs are
          spacious bedrooms and a versatile loft.
          <span class="font-semibold">Read more</span>
        </p>

        <div class="grid grid-cols-8 mt-16">
          <div class="col-span-2 flex flex-col">
            <img
              class="mb-2 w-8 h-8"
              src="@/assets/icons/home.svg"
              alt="Home icon"
            />
            <h3 class="text-h3 mb-1">{{ this.selectedHouse.squareFootage }}</h3>
            <span class="text-body-3">Square ft.</span>
          </div>

          <div class="col-span-1 flex flex-col">
            <img
              class="mb-2 w-8 h-8"
              src="@/assets/icons/bed.svg"
              alt="Bed icon"
            />
            <h3 class="text-h3 mb-1">
              {{ this.selectedHouse.numberBedrooms }}
            </h3>
            <span class="text-body-3">Beds</span>
          </div>

          <div class="col-span-1 flex flex-col">
            <img
              class="mb-2 w-8 h-8"
              src="@/assets/icons/bath.svg"
              alt="Bath icon"
            />
            <h3 class="text-h3 mb-1">{{ this.selectedHouse.bathrooms }}</h3>
            <span class="text-body-3">Baths</span>
          </div>

          <div class="col-span-4 flex flex-col mt-auto text-right">
            <div class="h-10"></div>
            <h3 class="text-h3 mb-1 font-semibold">
              {{ this.selectedHouse.price }}
            </h3>
            <span class="text-body-3">
              Starting Price (excluding land cost)
            </span>
          </div>
        </div>

        <div class="mt-16 flex flex-col">
          <h4 class="text-h4 font-semibold mb-3">Features</h4>
          <div class="grid grid-cols-2 gap-2 text-body-2">
            <div
              class="col-span-1"
              :key="feature.id"
              v-for="feature in selectedHouse.features"
            >
              {{ feature.name }}
            </div>
          </div>
        </div>
      </DetailsModal>

      <DetailsModal v-if="showDetailsModal" @close="toggleDetailsModal">
        <h2 class="text-h3 lg:text-h2 font-extrabold mb-4 mt-6">Outside</h2>
        <div class="text-body-2 mt-3">
          <p>
            We used the best materials for exterior, starting with. This new
            two-story home design will suit households to a T.
          </p>

          <p class="mt-3">
            Included on the first floor are a living room with a fireplace, a
            breakfast room, a formal dining room and a bedroom that could serve
            as a guest room.
          </p>

          <p class="mt-3">
            The patio is perfect for sipping sweet tea. Upstairs are spacious
            bedrooms and a versatile loft. Read more
          </p>
        </div>

        <img
          class="w-full mt-12"
          src="@/assets/images/roof.png"
          alt="Roof picture"
        />
        <p class="text-body-3 w-full text-center mt-3">
          Roof materials are truly exceptional...
        </p>

        <div class="mt-16 flex flex-col">
          <h4 class="text-h4 font-semibold mb-3">Features</h4>
          <div class="grid grid-cols-2 gap-2 text-body-2">
            <div
              class="col-span-1"
              :key="feature.id"
              v-for="feature in selectedHouse.features"
            >
              {{ feature.name }}
            </div>
          </div>
        </div>
      </DetailsModal>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { DetailsModal } from "@/components/modals";
import { PrimaryImageOnlyBaseCta } from "@/components/buttons";

export default {
  name: "FullscreenHouseCarousel",
  components: {
    Swiper,
    SwiperSlide,
    PrimaryImageOnlyBaseCta,
    DetailsModal,
  },
  props: {
    selectedHouseId: {
      type: Number,
      default: 0,
    },
    houseModels: {
      type: Array,
      default: () => [],
      required: true,
    },
    showFloorplansFirst: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        zoom: true,
      },
      selectedHouse: {},
      activeTab: "photos",
      activeIndex: null,
      showHomeDetailsModal: false,
      showDetailsModal: false,
      shrinkCarousel: false,
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    modalImages() {
      const { houseImages, floorplanImages } = this.selectedHouse;

      return houseImages && floorplanImages
        ? [...houseImages, ...floorplanImages]
        : [];
    },
    floorplanImagesStartIndex() {
      // First we spread house images, so floorplan images are after house images
      return this.selectedHouse?.houseImages?.length ?? 0;
    },
    isLandscape() {
      return window.innerHeight <= 500;
    },
  },
  methods: {
    internalClose() {
      this.$emit("close");
    },
    toggleHomeDetailsModal() {
      this.showHomeDetailsModal = !this.showHomeDetailsModal;
      this.shrinkCarousel = !this.shrinkCarousel;
    },
    toggleDetailsModal() {
      this.showDetailsModal = !this.showDetailsModal;
      this.shrinkCarousel = !this.shrinkCarousel;
    },
    setActiveTab(tab) {
      if (this.activeTab !== tab) this.activeTab = tab;
    },
    swipeToIndex(index = 0) {
      if (index >= this.floorplanImagesStartIndex)
        this.setActiveTab("floorplans");
      else this.setActiveTab("photos");

      this.swiper.slideTo(index, 500, false);
    },
    setActiveIndex() {
      this.activeIndex = this.swiper.activeIndex;

      if (this.activeIndex >= this.floorplanImagesStartIndex) {
        this.setActiveTab("floorplans");
      } else {
        this.setActiveTab("photos");
      }
    },
  },
  mounted() {
    this.selectedHouse = this.houseModels.find(
      (model) => model.id === this.selectedHouseId
    );

    if (this.showFloorplansFirst) {
      this.setActiveTab("floorplans");
      this.swipeToIndex(this.floorplanImagesStartIndex);
    } else {
      this.setActiveTab("photos");
    }
  },
};
</script>

<style scoped>
.carousel-wrapper {
  transition: padding-bottom 0.3s ease-in-out;
  @apply fixed top-0 bottom-0 left-0 right-0 w-screen h-screen z-50 bg-black;
}
.shrink-carousel {
  transition: padding-bottom 0.3s ease-in-out;
  @apply pb-[20vh] md:pb-4;
}

.swiper-slide {
  @apply flex justify-center items-center;
}

.swiper-pagination >>> .swiper-pagination-bullet {
  @apply w-3 h-3 mr-2 border border-creme-200 bg-transparent !important;
}
.swiper-pagination >>> .swiper-pagination-bullet-active {
  @apply bg-creme-200 !important;
}

#carousel-header {
  @apply w-full h-[15%];
}

#carousel-content {
  @apply flex h-[65%];
}

#carousel-footer {
  @apply w-full h-[25%] flex justify-center items-center lg:justify-end;
}

.shortened-text {
  text-overflow: ellipsis;
  @apply text-body-2 max-w-[360px] lg:max-w-[400px] whitespace-nowrap overflow-hidden;
}

.images-button {
  @apply py-2 px-4 cursor-pointer;
}

.images-button-active {
  @apply text-creme-200 bg-creme-300 bg-opacity-20 rounded-32;
}
</style>

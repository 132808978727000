<template>
  <section class="relative flex">
    <div class="banner z-10">
      <div class="max-container h-full relative">
        <div class="banner-content">
          <div
            class="text-h3 xl:text-h2 font-semibold mb-8 text-left min-w-[280px] xl:min-w-[330px]"
          >
            Reserve by <br />
            June 12
          </div>

          <div
            class="text-body-2 xl:text-h4 mb-12 lg:max-w-[205px] xl:max-w-[320px]"
          >
            Contact our team of Home Specialists to lock in this incredible
            price.
          </div>

          <PrimaryBaseCta
            text="Contact us to reserve"
            class=""
            endImage="ico_circle_arrow_white.svg"
            @click="toggleModal"
          />
        </div>
      </div>
    </div>

    <img class="banner-image" :src="banner.src" :alt="banner.alt" />

    <LocationInterestModal
      v-if="modalOpened"
      :preselectedHomeModel="houseName"
      @closeLocationInterestModal="toggleModal"
    />
  </section>
</template>

<script>
import { PrimaryBaseCta } from "@/components";
import { AVAILABLE } from "@/constants";

export default {
  name: "ReserveBy",
  components: {
    PrimaryBaseCta,
    LocationInterestModal: () => import("../modals/LocationInterestModal.vue"),
  },
  data() {
    return {
      modalOpened: false,
    };
  },
  props: {
    banner: {
      type: Object,
      required: true,
    },
    houseName: {
      type: String,
    },
    availability: {
      type: String,
    },
  },
  methods: {
    toggleModal() {
      this.modalOpened = !this.modalOpened;
    },
    name() {
      return this.availability === AVAILABLE ? this.houseName : "";
    },
  },
};
</script>

<style scoped>
.banner {
  @apply w-full xl:max-w-[1540px] xl:mx-auto min-h-[500px] md:min-h-[700px] xl:min-h-[950px];
}

.banner-image {
  @apply absolute w-full h-full object-cover;
}

.banner-content {
  @apply absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] xl:translate-x-0 xl:translate-y-0 xl:top-32 xl:left-36 rounded-16 flex flex-col p-8 xl:p-12 w-min;
  @apply bg-creme-50 text-gray-900 text-h4;
}
</style>

<template>
  <button
    class="flex flex-row button-container-base"
    :class="[
      `${type}-background`,
      `button-container-${fixedSize}`,
      isSelected ? 'bg-gray-900 bg-opacity-5' : '',
    ]"
    @click="onClickHandler"
    :type="eventName"
  >
    <div
      :class="[
        `button-img-wrapper-${fixedSize}`,
        `button-img-wrapper-start-${fixedSize}`,
      ]"
      v-if="startImage"
    >
      <img v-if="startImage" :src="require('@/assets/' + startImage)" />
    </div>
    <div
      class="button-text"
      v-if="text"
      :class="[`text-size-${fixedSize}`, `${type}-text-color`]"
    >
      {{ text }}
    </div>
    <div
      v-if="endImage"
      :class="[
        `button-img-wrapper-${fixedSize}`,
        `button-img-wrapper-end-${fixedSize}`,
      ]"
    >
      <img v-if="endImage" :src="require('@/assets/' + endImage)" />
    </div>
  </button>
</template>
<script>
export default {
  name: "NavigationCta",
  props: {
    text: {
      type: String,
      default: "Nav",
    },
    startImage: {
      type: String,
      default: null,
    },
    endImage: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "primary",
    },
    fixedSize: {
      type: String,
      default: "responsive",
    },
    eventName: {
      type: String,
      default: "click",
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: this.isSelected,
    };
  },
  methods: {
    onClickHandler() {
      this.$emit(`${this.eventName}`);
    },
  },
};
</script>
<style scoped>
.button-container-base {
  @apply items-center justify-center rounded-16 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed;
}

.button-container-responsive {
  @apply lg:py-4 lg:px-5 py-2 px-4;
}
.button-container-medium {
  @apply py-4 px-5;
}
.button-container-small {
  @apply py-2 px-4;
}

.button-img-wrapper-responsive {
  @apply flex flex-row items-center justify-center lg:w-5 lg:h-5 w-4 h-4;
}
.button-img-wrapper-responsive > img {
  @apply lg:min-w-[20px] lg:min-h-[20px] min-w-[16px] min-h-[16px];
}
.button-img-wrapper-start-responsive {
  @apply lg:mr-3 mr-2;
}
.button-img-wrapper-medium {
  @apply flex flex-row items-center justify-center w-5 h-5;
}
.button-img-wrapper-medium > img {
  @apply min-w-[20px] min-h-[20px];
}
.button-img-wrapper-start-medium {
  @apply mr-3;
}
.button-img-wrapper-small {
  @apply flex flex-row items-center justify-center w-4 h-4;
}
.button-img-wrapper-small > img {
  @apply min-w-[16px] min-h-[16px];
}
.button-img-wrapper-start-small {
  @apply mr-2;
}

.button-img-wrapper-end-responsive {
  @apply lg:ml-3 ml-2;
}
.button-img-wrapper-end-medium {
  @apply ml-3;
}
.button-img-wrapper-end-small {
  @apply ml-2;
}

.primary-background {
  @apply hover:bg-gray-900 hover:bg-opacity-5 active:bg-gray-900 active:bg-opacity-10 selection:text-gray-900;
}
.secondary-background {
  @apply bg-none;
}

.button-text {
  @apply font-semibold;
}
.text-size-responsive {
  @apply lg:text-h4 text-body-2;
}
.text-size-medium {
  @apply text-h4;
}
.text-size-small {
  @apply text-body-2;
}

.primary-text-color {
  @apply text-gray-900;
}
.secondary-text-color {
  @apply text-gray-900 hover:text-blue-600 hover:text-opacity-10 active:to-blue-500 disabled:text-gray-900 selection:text-blue-500;
}
</style>

const choices = [
  {
    name: "Inside the Perimeter",
    values: [
      {
        name: "Atlanta",
        value: "atlanta",
      },
      {
        name: "Alpharetta",
        value: "alpharetta",
      },
      {
        name: "Decatur",
        value: "decatur",
      },
      {
        name: "Johns Creek",
        value: "johns_creek",
      },
      {
        name: "Marietta",
        value: "marietta",
      },
      {
        name: "McDonough",
        value: "mcdonough",
      },
      {
        name: "Milton",
        value: "milton",
      },
      {
        name: "Peachtree City",
        value: "peachtree_city",
      },
      {
        name: "Roswell",
        value: "roswell",
      },
      {
        name: "Sandy Springs",
        value: "sandy_springs",
      },
      {
        name: "Smyrna",
        value: "smyrna",
      },
      {
        name: "South Fulton",
        value: "south_fulton",
      },
      {
        name: "Other",
        value: "other",
      },
    ],
  },
];

export const getLocationList = () => choices;

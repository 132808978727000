<template>
  <div>
    <div class="bg-creme-50">
      <HiwTopSection />
      <HiwTimeline />
    </div>
  </div>
</template>
<script>
import { HiwTopSection, HiwTimeline } from "@/components/hiw";
export default {
  name: "HowItWorks",
  components: {
    HiwTopSection,
    HiwTimeline,
  },
};
</script>

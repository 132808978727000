<template>
  <global-section type="dark">
    <div class="max-container">
      <div class="main-grid">
        <div
          class="col-span-full flex flex-col justify-center items-center w-full text-gray-900"
        >
          <div class="flex flex-col items-center text-center">
            <div class="text-h3 xl:text-h2 font-semibold">
              Ready to take the <span class="text-blue-500">first step</span>?
            </div>

            <div class="mt-4 text-body-2 xl:text-h4">
              Browse our upcoming communities or get notified for our future
              locations.
            </div>

            <div class="mt-12 mb-8">
              <PrimaryBaseCta
                @click="onClick()"
                fixedSize="medium"
                text="See our first community"
                endImage="ico_circle_arrow_white.svg"
              />
            </div>

            <router-link
              to="/locations"
              class="text-body-2 xl:text-h4 text-blue-500 font-semibold"
            >
              Looking for another location?
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </global-section>
</template>
<script>
import { PrimaryBaseCta, GlobalSection } from "@/components";
export default {
  components: {
    PrimaryBaseCta,
    GlobalSection,
  },
  methods: {
    onClick() {
      this.$router.push({
        path: "/locations/hollysprings",
      });
    },
  },
};
</script>
<style scoped>
.timeline-container {
  @apply col-start-1 col-span-full xl:col-start-2;
}
.line-wrapper {
  @apply w-24 flex flex-col;
}
.line {
  @apply h-32 w-0 border border-gray-900 mx-auto;
}
</style>

import { render, staticRenderFns } from "./SelectionTag.vue?vue&type=template&id=892e0d86&scoped=true&"
import script from "./SelectionTag.vue?vue&type=script&lang=js&"
export * from "./SelectionTag.vue?vue&type=script&lang=js&"
import style0 from "./SelectionTag.vue?vue&type=style&index=0&id=892e0d86&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "892e0d86",
  null
  
)

export default component.exports
<template>
  <section
    id="map"
    class="w-full"
    :class="[
      showFullscreenMap
        ? 'fixed top-auto bottom-0 right-0 left-0 z-[100] h-[100vh] bg-creme-200'
        : 'h-[305px] sm:h-[685px]',
    ]"
  >
    <div v-if="showFullscreenMap" class="fullscreen-map-header">
      <div class="lg:h-16 grid grid-cols-12 items-center my-auto px-12 w-full">
        <div class="col-span-2">
          <PrimaryImageOnlyBaseCta
            image="icons/close_black.svg"
            type="tertiary"
            :dark="true"
            class="border-maintextcolor w-10 h-10"
            @click="toggleFullscreenMap"
          />
        </div>
        <div
          class="col-span-6 col-start-4 pt-1 sm:col-span-4 sm:col-start-5 text-body-1 sm:text-h4 text-center font-semibold"
        >
          Choose your lot
        </div>
      </div>
    </div>

    <div class="w-full h-full relative">
      <MapboxMap
        :mapStyle="globalMapStyle"
        :lotArray="lotArray"
        :key="showFullscreenMap"
        :selectedHouseModel="selectedHouseModel"
        :selectedLot="selectedLot"
        @lot_selection="onLotChange"
      />
      <div id="map-controls-wrapper">
        <PrimaryImageOnlyBaseCta
          type="tertiary"
          image="icons/fullscreen_arrows.svg"
          fixedSize="w-11 h-11"
          class="absolute right-8 top-8 z-10"
          v-on:click="toggleFullscreenMap"
          v-if="!showFullscreenMap"
        />
        <MapStylesToggle
          :mapStyle="globalMapStyle"
          :fullscreenEnabled="showFullscreenMap"
          @StyleChanged="onStyleChange"
        />
        <FullscreenMapLotList
          v-if="showFullscreenMap"
          :selectedLot="selectedLot"
          :selectedHouseModel="selectedHouseModel"
          :lotArray="lotArray"
          @lot_selection="onLotChange"
        />
      </div>
    </div>
  </section>
</template>

<script>
import MapboxMap from "./MapboxMap.vue";
import { PrimaryImageOnlyBaseCta } from "@/components/buttons";
import MapStylesToggle from "./MapStylesToggle.vue";
import FullscreenMapLotList from "./FullscreenMapLotList.vue";
import { SATELLITE, BUILDER_EVENTS } from "@/constants";
import { getMapLotArray } from "@/mocks/useMapLotArrayModel.js";

const { LOT_SELECTION } = BUILDER_EVENTS;

export default {
  name: "MapboxWrapper",
  components: {
    MapboxMap,
    PrimaryImageOnlyBaseCta,
    MapStylesToggle,
    FullscreenMapLotList,
  },
  props: {
    selectedHouseModel: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      globalMapStyle: SATELLITE,
      showFullscreenMap: false,
      lotArray: getMapLotArray(),
      selectedLot: {},
    };
  },
  mounted() {},
  methods: {
    toggleFullscreenMap() {
      this.showFullscreenMap = !this.showFullscreenMap;
      if (this.showFullscreenMap) {
        document.querySelector("html").classList.add("overflow-hidden");
      } else document.querySelector("html").classList.remove("overflow-hidden");
    },
    onStyleChange(value) {
      this.globalMapStyle = value;
    },
    onLotChange(selectedLot) {
      this.selectedLot = selectedLot;
      this.$root.$emit(LOT_SELECTION, selectedLot.name);
    },
  },
};
</script>

<style scoped>
.fullscreen-map-header {
  @apply z-[100] w-full h-24 sm:h-32 bg-creme-200 rounded-t-24 flex justify-center;
}
</style>

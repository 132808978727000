<template>
  <div class="main-container">
    <global-section>
      <div class="flex justify-center -mt-4 md:-mt-8 lg:-mt-16">
        <div
          class="flex flex-row text-body-1 lg:text-h3 font-semibold text-center mb-2"
        >
          <img
            src="@/assets/icons/mapPin.svg"
            alt="map pin icon"
            class="mr-2 w-6 md:w-auto"
          />
          Atlanta, GA
        </div>
      </div>
      <div
        class="text-h4 xs:text-h5exception md:text-h2 lg:text-h1 font-semibold text-center"
      >
        <h1>
          New homes you’ll love,<br /><span
            class="bg-yellow-500 px-2 rounded-12 lg:rounded-16"
            >sold at cost.</span
          >
        </h1>
      </div>
      <div
        class="max-container bg-creme-200 flex flex-col-reverse lg:flex-row justify-between mt-12 lg:mt-16 lg:mb-20"
      >
        <div
          class="w-full lg:w-[384px] flex flex-col items-center lg:items-start"
        >
          <p class="text-body-2 lg:text-h4 my-12">
            No compromises here! Save up to $80k on a well-built new
            construction home, by putting a downpayment 12 months before you
            move in.
            <br /><br />
            Our upcoming 30-home development in the Atlanta metro area will be
            pre-selling soon.
          </p>
          <PrimaryBaseCta
            text="Interested? Let’s chat!"
            class="shrink-0"
            fixedSize="large"
            endImage="ico_circle_arrow_white.svg"
            @click="navigateToSubscribe"
          />
          <PrimaryBaseCta
            type="link"
            text="Other locations? Tell us!"
            endImage="icons/arrow_right_blue.svg"
            class="mt-3"
            @click="navigateToSubscribe"
          />
        </div>
        <div class="info">
          <div class="hero-image-container">
            <div class="hero-image"></div>
            <div class="hidden lg:block yellow-bar"></div>
          </div>
          <div class="hero-info flex flex-col mx-8 md:mx-16 lg:mx-4">
            <div
              class="hero-info-box rounded-16 bg-white mt-[-112px] lg:mt-[-160px] overflow-hidden"
            >
              <div class="h-[112px] lg:h-40">
                <div
                  class="flex flex-row px-4 lg:px-8 justify-between items-center h-full"
                >
                  <div class="w-32 text-center">
                    <div class="font-extrabold text-body-3 lg:text-body-2 mb-1">
                      Market value
                    </div>
                    <div class="font-semibold text-body-1 lg:text-h3">
                      $400k
                    </div>
                    <div
                      class="font-semibold text-body-3 lg:text-body-1 mt-2 lg:mt-4"
                    >
                      $2,044/mo
                    </div>
                  </div>
                  <div class="w-32 text-center">
                    <div class="font-extrabold text-body-3 lg:text-body-2 mb-1">
                      You pay
                    </div>
                    <div class="font-semibold text-body-1 lg:text-h3">
                      $320k
                    </div>
                    <div
                      class="font-semibold text-body-3 lg:text-body-1 mt-2 lg:mt-4"
                    >
                      $1,635/mo
                    </div>
                  </div>
                  <div class="w-32 text-center">
                    <div class="font-extrabold text-body-3 lg:text-body-2 mb-1">
                      You save (est.)
                    </div>
                    <div class="font-semibold text-body-1 lg:text-h3">
                      <span class="bg-yellow-500 rounded-8 lg:rounded-12 px-2"
                        >$80k</span
                      >
                    </div>
                    <div
                      class="font-semibold text-body-3 lg:text-body-1 mt-2 lg:mt-4"
                    >
                      $409/mo
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="h-[56px] lg:h-[72px] bg-creme-300 flex flex-row justify-around items-center font-semibold text-body-3 lg:text-body-2 px-4 lg:px-0"
              >
                <div class="lg:w-1/4 flex flex-col items-center">
                  <div>Downpayment</div>
                  <div>Fall 2022</div>
                </div>
                <img
                  src="@/assets/icons/timeline_arrow.svg"
                  class="w-16 lg:w-auto"
                />
                <div class="lg:w-1/4 flex flex-col items-center">
                  <div>Move-in</div>
                  <div>12 months later</div>
                </div>
              </div>
            </div>
            <div class="text-body-3 mt-5 text-center font-semibold">
              10% down, 5.5% rate, 30 yr fixed mortgage, excluding PMI
            </div>
          </div>
        </div>
      </div>
    </global-section>
  </div>
</template>

<script>
import { PrimaryBaseCta, GlobalSection } from "@/components";

export default {
  name: "StartingPage",
  components: {
    PrimaryBaseCta,
    GlobalSection,
  },
  methods: {
    navigateToSubscribe() {
      this.$router.push({
        path: "/learn-more",
      });
    },
  },
};
</script>

<style scoped>
.info {
  @apply lg:w-full lg:max-w-[640px] ml-[-32px] mr-[-32px] md:ml-[-64px] md:mr-[-64px] lg:mr-0 lg:ml-16 flex-grow;
}

.hero-image-container {
  @apply w-full h-full lg:max-w-[640px] relative;
}

.hero-image {
  background: url("~@/assets/images/home_page_house_HQ.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  z-index: 1000;

  @apply lg:rounded-16 relative block w-full h-[340px] lg:h-full;
}

.yellow-bar {
  position: absolute;
  bottom: -16px;
  width: 104%;
  left: -2%;
  height: 90px;
  @apply bg-yellow-500 rounded-24;
}

.hero-info-box {
  z-index: 2000;
}

.max-container {
  max-width: 1088px;
}
</style>

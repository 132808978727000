export const HUBSPOT_PAGES = {
  HOLLYSPRINGS: "hollysprings",
  LOCATIONS: "locations",
  CONTACT: "contact",
  SUBSCRIBE: "subscribe",
  LEARN_MORE: "learn-more",
};

export const HUBSPOT_DATA = {
  OBJECT_TYPE_ID: "0-1",
  COOKIE_NAME: "hubspotutk",
  FIRST_NAME: "firstname",
  LAST_NAME: "lastname",
  EMAIL: "email",
  CONSTRUCTION_TIMELINE: "construction_timeline_2",
  IS_AGENT: "is_agent",
  HOME_MODEL_INTEREST: "holly_springs_home_choice",
  IS_FTHB: "is_fthb",
  LEAD_FORM_COMMENTS: "lead_form_comments",
  LOCATIONS_INTERESTED: "locations_interested",
  IDEAL_MOVE_DATE: "ideal_move_date",
};

export const MOVE_IN_DATES_ARRAY = [
  { name: "In 0-3 months", value: "0_3mo" },
  { name: "In 4-6 months", value: "4_6mo" },
  { name: "In 6-9 months", value: "7_9mo" },
  { name: "In 10-12 months", value: "10_12mo" },
  { name: "In 13-18 months", value: "13-18mo" },
  { name: "In 1.5+ years", value: "18plusmo" },
  {
    name: "Anytime, for the right opportunity",
    value: "anytime",
  },
];

export const CONSTRUCTION_TIMELINE_ARRAY = [
  {
    name: 'Move in immediately <strong class="ml-1">(Pay market price)</strong>',
    value: "0",
  },
  {
    name: 'Move in 6 months after closing  <strong class="ml-1">(Save ~81K)</strong>',
    value: "6",
  },
  {
    name: 'Move in 12 months after closing  <strong class="ml-1">(Save ~$122K)</strong>',
    value: "12",
  },
  {
    name: 'Move in 18 months after closing  <strong class="ml-1">(Save ~$162K)</strong>',
    value: "18",
  },
];

export const RENT_OR_OWN_ARRAY = [
  { name: "I rent the place I live in", value: "rent" },
  { name: "I own the place I live in", value: "own" },
  { name: "Other", value: "other" },
];

export const DWELLING_ARRAY = [
  { name: "Apartment", value: "Apartment" },
  { name: "Condominium", value: "Condominium" },
  { name: "Townhouse", value: "Townhouse" },
  { name: "Single-Family Home", value: "Single-Family Home" },
  { name: "Other", value: "Other" },
];

export const MAIN_REASONS_ARRAY = [
  {
    name: "Need (or will need) more space",
    value: "Need (or will need) more space",
  },
  { name: "Safer neighborhood", value: "Safer neighborhood" },
  { name: "More vibrant neighborhood", value: "More vibrant neighborhood" },
  {
    name: "Investment/ financial security",
    value: "Investment/ financial security",
  },
  {
    name: "Desire to become a homeowner",
    value: "Desire to become a homeowner",
  },
  { name: "Need to relocate", value: "Need to relocate" },
  { name: "Downsizing", value: "Downsizing" },
  { name: "Other", value: "Other" },
];

export const LIVE_WITH_ARRAY = [
  { name: "I plan to live by myself", value: "I plan to live by myself" },
  { name: "Roommates", value: "Roommates" },
  { name: "My significant other", value: "My significant other" },
  { name: "My family and kids", value: "My family and kids" },
  { name: "Other", value: "Other" },
];

export const BEDROOMS_ARRAY = [
  { name: "1", value: "1" },
  { name: "2", value: "2" },
  { name: "3", value: "3" },
  { name: "4", value: "4" },
  { name: "5", value: "5" },
  { name: "6+", value: "6+" },
];

export const MOST_IMPORTANT_ARRAY = [
  { name: "Neighborhood safety", value: "1Neighborhood safety" },
  {
    name: "Proximity to shops and the highway",
    value: "Proximity to shops and the highway",
  },
  {
    name: "Home quality (build, finish, hardware, etc.)",
    value: "Home quality (build, finish, hardware, etc.)",
  },
  { name: "Well-designed floor plans", value: "Well-designed floor plans" },
  { name: "Large home size", value: "Large home size" },
  {
    name: "Getting an amazing deal (price vs home appraisal value)",
    value: "Getting an amazing deal (price vs home appraisal value)",
  },
  {
    name: "Outdoor space (yard size, deck space, landscaping, etc.)",
    value: "Outdoor space (yard size, deck space, landscaping, etc.)",
  },
  {
    name: "Frequent updates during land development and construction",
    value: "Frequent updates during land development and construction",
  },
  {
    name: "Dedicated customer rep with stellar service",
    value: "Dedicated customer rep with stellar service",
  },
  {
    name: "Choice of lot location within a new development (e.g. south-facing, waterfront, etc.)",
    value:
      "Choice of lot location within a new development (e.g. south-facing, waterfront, etc.)",
  },
  {
    name: "Customizability of the home (countertops, appliances, paint color, etc.)",
    value:
      "Customizability of the home (countertops, appliances, paint color, etc.)",
  },
  {
    name: "Good neighbors and community",
    value: "Good neighbors and community",
  },
  {
    name: "Amenities within the community (gym, swimming pool, tennis court, etc.)",
    value:
      "Amenities within the community (gym, swimming pool, tennis court, etc.)",
  },
  { name: "Other", value: "Other" },
];

export const AGE_RANGE_ARRAY = [
  { name: "18-24", value: "18-24" },
  { name: "25-29", value: "25-29" },
  { name: "30-39", value: "30-39" },
  { name: "40-49", value: "40-49" },
  { name: "50-59", value: "50-59" },
  { name: "60+", value: "60+" },
];

export const HOUSEHOLD_SIZE_ARRAY = [
  { name: "1 (just me)", value: "1" },
  { name: "2", value: "2" },
  { name: "3", value: "3" },
  { name: "4", value: "4" },
  { name: "5", value: "5" },
  { name: "6+", value: "6+" },
];

export const EMPLOYMENT_STATUS_ARRAY = [
  { name: "Full-time employed", value: "Full-time employed" },
  { name: "Part-time employed", value: "Part-time employed" },
  { name: "Self-employed", value: "Self-employed" },
  { name: "Business owner", value: "Business owner" },
  { name: "Unemployed", value: "Unemployed" },
  { name: "Other", value: "Other" },
];

export const FTHB_ARRAY = [
  { name: "Yes", value: true },
  { name: "No", value: false },
];

export const INCOME_ARRAY = [
  { name: "below $50K", value: "below $50K" },
  { name: "$50-75K", value: "$50-75K" },
  { name: "$75-100K", value: "$75-100K" },
  { name: "$100-150K", value: "$100-150K" },
  { name: "$150-200K", value: "$150-200K" },
  { name: "$200-300K", value: "$200-300K" },
  { name: "$300K+", value: "$300K+" },
];

export const PAY_ARRAY = [
  { name: "Financing / Mortgage", value: "Financing / Mortgage" },
  { name: "Cash", value: "Cash" },
  { name: "Other", value: "Other" },
];

export const DOWNPAYMENT_ARRAY = [
  { name: "less than $20K", value: "less than $20K" },
  { name: "$20-40K", value: "$20-40K" },
  { name: "$40-60K", value: "$40-60K" },
  { name: "$60-80K", value: "$60-80K" },
  { name: "$80K-$100K", value: "$80K-$100K" },
  { name: "$100K-$120K", value: "$100K-$120K" },
  { name: "over $120K", value: "over $120K" },
  { name: "N/A, I can pay 100% in cash", value: "N/A, I can pay 100% in cash" },
];

export const MONTHLY_PAYMENT_ARRAY = [
  { name: "below $1000 / month", value: "below $1000 / month" },
  { name: "$1000 - $1500 / month", value: "$1000 - $1500 / month" },
  { name: "$1500 - $2000 / month", value: "$1500 - $2000 / month" },
  { name: "$2000 - $2500 / month", value: "$2000 - $2500 / month" },
  { name: "$2500 - $3000 / month", value: "$2500 - $3000 / month" },
  { name: "$3000 - $3500 / month", value: "$3000 - $3500 / month" },
  { name: "$3500 - $4000 / month", value: "$3500 - $4000 / month" },
  { name: "over $4000 / month", value: "over $4000 / month" },
];

export const BORROW_ARRAY = [
  {
    name: "None— I wouldn’t borrow money for a bigger downpayment",
    value: "None— I wouldn’t borrow money for a bigger downpayment",
  },
  {
    name: "Some amount, but less than 20% down",
    value: "Some amount, but less than 20% down",
  },
  { name: "Enough to put 20% down", value: "Enough to put 20% down" },
  { name: "As much as I could", value: "As much as I could" },
];

export const CREDIT_SCORE_ARRAY = [
  { name: "below 620", value: "below 620" },
  { name: "620-659", value: "620-659" },
  { name: "660-699", value: "660-699" },
  { name: "700-759", value: "700-759" },
  { name: "760-850", value: "760-850" },
  { name: "850+", value: "850+" },
  { name: "I am not sure", value: "I am not sure" },
  {
    name: "I am not comfortable sharing",
    value: "I am not comfortable sharing",
  },
];

export const AGENT_ARRAY = [
  {
    name: "I’m not comfortable without an agent to represent me",
    value: "I’m not comfortable without an agent to represent me",
  },
  { name: "I am not sure yet", value: "I am not sure yet" },
  {
    name: "I’m comfortable buying without an agent",
    value: "I’m comfortable buying without an agent",
  },
];

export const HUBSPOT_API_URL = {
  HOLLYSPRINGS:
    "https://api.hsforms.com/submissions/v3/integration/submit/21550466/29a18ceb-1c22-45fa-b0bd-9f01976ef7b2",
  LOCATIONS:
    "https://api.hsforms.com/submissions/v3/integration/submit/21550466/b6a747af-c8a5-4fe6-9164-937fbadbafa6",
  CONTACT:
    "https://api.hsforms.com/submissions/v3/integration/submit/21550466/76c8940b-1fef-40f6-a9b9-77282973678d",
  SUBSCRIBE:
    "https://api.hsforms.com/submissions/v3/integration/submit/21550466/68512b8d-be55-4022-9c33-4d38201545ef",
  LEARN_MORE:
    "https://api.hsforms.com/submissions/v3/integration/submit/21550466/9dff3a2f-4f5a-43d9-9c7e-75195041738a",
};

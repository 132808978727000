<template>
  <div :id="addonsId" class="mt-32">
    <BuilderHeader title="Pick add-ons" />

    <div v-if="!addons" class="text-black">
      Addons not available for this model
    </div>

    <div v-else>
      <p class="text-body-2">
        We carefully picked add-ons that maximize your comfort and add to
        appraise value, which makes choosing these a great investment into your
        house
      </p>

      <div v-if="addons">
        <Addon v-for="addon in addons" :key="addon.id" :addon="addon" />
      </div>
    </div>
  </div>
</template>

<script>
import { BuilderHeader } from "@/components/text";
import Addon from "./Addon.vue";
import { BUILDER_COMPONENTS_IDS } from "@/constants";

export default {
  name: "HomeAddons",
  components: {
    BuilderHeader,
    Addon,
  },
  props: ["addons"],
  data() {
    return {
      addonsId: BUILDER_COMPONENTS_IDS.ADDONS,
    };
  },
};
</script>

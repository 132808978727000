var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative",class:[_vm.showFullscreenCarousel ? 'fullscreen-carousel' : '']},[(_vm.showFullscreenCarousel)?_c('PrimaryImageOnlyBaseCta',{staticClass:"absolute left-6 top-6 z-10 border-white w-10 h-10",attrs:{"type":"tertiary","image":"icons/close_white.svg","dark":true},on:{"click":_vm.toggleFullscreenCarousel}}):_vm._e(),(!_vm.showFullscreenCarousel)?_c('PrimaryImageOnlyBaseCta',{staticClass:"absolute right-6 top-6 z-10 w-10 h-10",attrs:{"type":"tertiary","image":"icons/fullscreen_arrows.svg"},on:{"click":_vm.toggleFullscreenCarousel}}):_vm._e(),_c('Swiper',{ref:"mySwiper",class:[
      _vm.showFullscreenCarousel ? 'fullscreen-swiper' : '',
      _vm.activeTab === 'photos' && !_vm.showFullscreenCarousel
        ? 'linear-gradient'
        : '' ],style:(_vm.fixedHeight && !_vm.showFullscreenCarousel ? ("height: " + _vm.fixedHeight + "px") : ''),attrs:{"options":_vm.swiperOptions}},[_vm._l((_vm.allImages),function(image){return _c('SwiperSlide',{key:image.id},[_c('img',{staticClass:"swiper-lazy rounded-16 h-full w-full object-center",class:[
          !_vm.showFullscreenCarousel && !_vm.hasOnlyFloorplansImages
            ? 'object-cover'
            : 'object-contain',
          _vm.activeTab === 'floorplans' ? 'h-full' : '' ],attrs:{"src":image.src,"alt":image.alt}}),_c('div',{staticClass:"swiper-lazy-preloader"})])}),_c('div',{staticClass:"swiper-pagination text-body-2",attrs:{"slot":"pagination"},slot:"pagination"}),_c('div',{staticClass:"swiper-button-prev",class:[
        _vm.paginationDotsOnly ? 'hidden' : '',
        _vm.hideSwiperButton ? 'hidden' : '' ],attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",class:[
        _vm.paginationDotsOnly ? 'hidden' : '',
        _vm.hideSwiperButton ? 'hidden' : '' ],attrs:{"slot":"button-next"},slot:"button-next"})],2),(_vm.floorplansImages.length && _vm.images.length)?_c('div',{staticClass:"absolute bottom-4 right-0 z-10 hidden xl:block"},[_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"mr-5 py-2 px-4 cursor-pointer rounded-32",class:[
          this.activeTab === 'photos'
            ? 'text-creme-200 bg-creme-300 bg-opacity-20'
            : '',
          this.showFullscreenCarousel ? 'text-creme-200' : '' ],on:{"click":function($event){return _vm.swipeToIndex(0)}}},[_vm._v(" Photos ")]),_c('div',{staticClass:"mr-5 py-2 px-4 cursor-pointer rounded-32",class:[
          this.activeTab === 'floorplans'
            ? 'bg-creme-300 font-semibold'
            : 'text-creme-200',
          this.showFullscreenCarousel ? 'text-creme-200 bg-opacity-20' : '' ],on:{"click":function($event){return _vm.swipeToIndex(_vm.images.length)}}},[_vm._v(" Floorplans ")])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }